import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';

export default function AttachFileMItem({ index, handleDelete, file }) {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.grey[200],
          borderRadius: '8px',
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant={'body2'}
          sx={{
            color: (theme) => theme.palette.info.main,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <b>{index + 1}</b> &nbsp;
          {file.name}
        </Typography>
        <IconButton
          onClick={() => {
            handleDelete(index);
          }}
          size={'small'}
        >
          <CloseRoundedIcon sx={{ height: '15px', width: '15px' }} />
        </IconButton>
      </Box>
    </Grid>
  );
}
