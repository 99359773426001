import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {
  COMPLIANCE_PATHS,
  HM_DASHBOARD,
  PATH_DASHBOARD,
} from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    const roles =
      user.groups?.map((role) => role.name.toString().toLowerCase()) || [];

    if (roles.includes('compliance') || roles.includes('owner')) {
      return <Navigate to={COMPLIANCE_PATHS.root} />;
    } else if (roles.includes('audit') || roles.includes('administration')) {
      return <Navigate to={PATH_DASHBOARD.root} />;
    } else if (roles.includes('resident')) {
      return <Navigate to={HM_DASHBOARD.root} />;
    }
  }

  return <>{children}</>;
}
