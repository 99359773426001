import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import expandFill from '@iconify/icons-eva/expand-fill';
import collapseFill from '@iconify/icons-eva/collapse-fill';
// material
import { useTheme } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Divider,
  IconButton,
  Portal,
  Typography,
  useMediaQuery,
} from '@mui/material';
//
import { shallowEqual, useSelector } from 'react-redux';
import { EMAIL_TYPE, SMS_TYPE } from './ConversationsV2';
import SMSCompose from './SMSCompose';
import MailComposeV2 from './MailComposeV2';
import { RootStyle } from './utils';

// ----------------------------------------------------------------------

const MessageCompose = ({
  isOpenCompose,
  onCloseCompose,
  isReply,
  setIsReply,
  reject,
  isProperty = false,
  isForward,
}) => {
  const theme = useTheme();
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [fullScreen, setFullScreen] = useState(false);
  const [messageType, setMessageType] = useState(
    selectedFilter.filters.conversation_type
  );

  const isEmail = useMemo(() => messageType === EMAIL_TYPE, [messageType]);
  const isSMS = useMemo(() => messageType === SMS_TYPE, [messageType]);

  const handleExitFullScreen = () => setFullScreen(false);
  const handleEnterFullScreen = () => setFullScreen(true);

  const handleClose = useCallback(() => {
    onCloseCompose();
    setFullScreen(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    setMessageType(selectedFilter?.filters?.conversation_type);
  }, [selectedFilter]);

  if (!isOpenCompose) {
    return null;
  }
  return (
    <Portal>
      <Backdrop open={fullScreen || isMobile} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...((fullScreen || isMobile) && {
            top: 0,
            right: { xs: 12, md: 40 },
            zIndex: 1999,
            margin: 'auto',
            maxHeight: '95vh',
            width: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
            height: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
          }),
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: 'calc(100% - 88px)' }}>
            <Typography variant="h6">
              {isReply ? 'Reply message' : 'New Message'}
            </Typography>

            {isReply && isEmail && (
              <Typography
                variant="caption"
                display="block"
                noWrap
                sx={{ width: '100%' }}
              >
                <strong>Subject:</strong> {selectedConversation?.subject}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {!isMobile && (
            <IconButton
              onClick={
                fullScreen ? handleExitFullScreen : handleEnterFullScreen
              }
              size="large"
            >
              <Icon
                icon={fullScreen ? collapseFill : expandFill}
                width={20}
                height={20}
              />
            </IconButton>
          )}

          <IconButton onClick={handleClose} size="large">
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />

        {isEmail && (
          <MailComposeV2
            onCloseCompose={onCloseCompose}
            isReply={isReply}
            setIsReply={setIsReply}
            isForward={isForward}
            reject={reject}
            setFullScreen={setFullScreen}
            isProperty={isProperty}
          />
        )}

        {isSMS && (
          <SMSCompose
            onCloseCompose={onCloseCompose}
            isReply={isReply}
            setIsReply={setIsReply}
            reject={reject}
            setFullScreen={setFullScreen}
            isProperty={isProperty}
          />
        )}
      </RootStyle>
    </Portal>
  );
};

export default memo(MessageCompose);

MessageCompose.propTypes = {
  isOpenCompose: PropTypes.bool,
  onCloseCompose: PropTypes.func,
};
