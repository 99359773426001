import axios from '../utils/axios';

export default class BaseEmailAndPhoneService {
  constructor(emailEndpoint, phonesEndpoint) {
    this.emailEndpoint = emailEndpoint;
    this.phonesEndpoint = phonesEndpoint;
  }

  getValidEmails = (emails) => {
    const emailList = [];
    emails.forEach((email) => {
      if (!email.email.endsWith('prontohousing.io')) {
        if (email.is_primary) {
          emailList.splice(0, 0, email);
        } else {
          emailList.push(email);
        }
      }
    });
    return emailList;
  };

  fetchUserEmails = async (queryParam) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.emailEndpoint}/?${queryParam}&ordering=created`)
        .then((response) => {
          resolve(this.getValidEmails(response.data));
        })
        .catch((error) => {
          console.error(error);
          reject([]);
        });
    });
  };

  addEmailAddress = async (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.emailEndpoint}/`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  sendEmailVerification = async (payload) => {
    return {};
  };

  patchEmailAddress = async (emailID, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${this.emailEndpoint}/${emailID}/`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteEmailAddress = async (emailID) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.emailEndpoint}/${emailID}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  fetchUserPhones = async (queryParams) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${this.phonesEndpoint}/?${queryParams}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject([]);
        });
    });
  };

  addPhoneNumber = async (payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.phonesEndpoint}/`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  editPhoneNumberDetails = async (phoneID, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${this.phonesEndpoint}/${phoneID}/`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  deletePhoneNumber = async (phoneID) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${this.phonesEndpoint}/${phoneID}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  sendPhoneVerificationCode = async (payload) => {
    return {};
  };
}
