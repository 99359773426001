import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import LoginStateManager from './authentication/login/V2/LoginStateManager';
import useAuth from 'src/hooks/useAuth';
import useBroadcastChannel from 'src/hooks/useBroadcastChannel';

interface JwtToken {
  sub: string;
  name: string;
  admin?: boolean;
  iat?: number;
  exp?: number;
  [key: string]: unknown;
}

enum BannerColor {
  WARNING = 0,
  CRITICAL = 1,
}

// in a 24 hours token, this will give us a 2-hour warning
export const EXPIRY_WARNING_PERCENTAGE = 0.9173;
// in a 24 hours token, this will give us a 1-hour warning
export const EXPIRY_CRITICAL_PERCENTAGE = 0.9583;

const SessionExpiryAlert: FC = () => {
  const { user, logout } = useAuth();
  const { message, sendMessage } = useBroadcastChannel(
    `${user?.id}-session-alert`
  );

  const [open, setOpen] = useState(false);

  const [displayWarningBanner, setDisplayWarningBanner] = useState(false);
  const [bannerColor, setBannerColor] = useState<BannerColor>(
    BannerColor.WARNING
  );
  const [timeLeft, setTimeLeft] = useState<string | null>(null);

  const [token, setToken] = useState<JwtToken | null>(
    localStorage.getItem('refresh')
      ? jwtDecode(localStorage.getItem('refresh'))
      : null
  );

  function formatTime(timeArray: number[]): string {
    return timeArray.map((time) => String(time).padStart(2, '0')).join(':');
  }

  const hideAlert = useCallback(() => {
    setOpen(false);
    setDisplayWarningBanner(false);
  }, []);

  const onExtendSessionSuccess = useCallback(
    ({ refresh }) => {
      hideAlert();
      sendMessage({ type: 'extend-session' });
      setToken(jwtDecode(refresh));
    },
    [hideAlert, sendMessage]
  );

  const calculateTimeLeft = useCallback((): string | null => {
    if (!token) {
      return null;
    }
    const currentTime = Date.now();
    const tokenExpiry = token.exp * 1000;
    const difference = tokenExpiry - currentTime;

    if (difference <= 0) {
      return null;
    }
    const exp = token.exp * 1000;
    const iat = token.iat * 1000;
    const totalLifespan = exp - iat;
    const warningTime = iat + totalLifespan * EXPIRY_WARNING_PERCENTAGE;
    const criticalTime = iat + totalLifespan * EXPIRY_CRITICAL_PERCENTAGE;

    if (currentTime >= warningTime) {
      setBannerColor(BannerColor.WARNING);
    }

    if (currentTime >= criticalTime) {
      setBannerColor(BannerColor.CRITICAL);
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return formatTime([hours, minutes, seconds]);
  }, [token]);

  const showAlert = useCallback(() => {
    setDisplayWarningBanner(true);
    setOpen(true);
  }, []);

  const getTokenRemainingTime = (token: JwtToken): number => {
    const currentTime = Date.now();
    return Math.ceil(token.exp * 1000 - currentTime);
  };

  const createDisplayAlertTimeout = useCallback(() => {
    const exp = token.exp * 1000;
    const iat = token.iat * 1000;

    const totalLifespan = exp - iat;
    const warningTime = totalLifespan * EXPIRY_WARNING_PERCENTAGE;
    const warningTimestamp = iat + warningTime;

    const currentTime = Date.now();
    const timeUntilWarning = warningTimestamp - currentTime;

    if (timeUntilWarning > 0) {
      console.log('Modal will show at: ', new Date(warningTimestamp));
      return setTimeout(() => {
        showAlert();
      }, timeUntilWarning);
    } else {
      showAlert();
    }
    return null;
  }, [showAlert, token]);

  const createLogoutTimer = useCallback(() => {
    const tokenRemainingTime = getTokenRemainingTime(token);
    if (tokenRemainingTime > 0) {
      return setTimeout(() => {
        logout();
      }, tokenRemainingTime);
    } else {
      logout();
    }
    return null;
  }, [logout, token]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const timeLeftTimerID = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    const modalTimeoutID = createDisplayAlertTimeout();
    const logoutTimer = createLogoutTimer();

    return () => {
      if (timeLeftTimerID) {
        clearInterval(timeLeftTimerID);
      }
      if (modalTimeoutID) {
        clearTimeout(modalTimeoutID);
      }
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, [calculateTimeLeft, createDisplayAlertTimeout, createLogoutTimer, token]);

  useEffect(() => {
    const onMessageReceived = (token: string): void => {
      hideAlert();
      setToken(jwtDecode(token));
    };
    if (!message?.type) {
      return;
    }
    if (message.type === 'extend-session') {
      onMessageReceived(localStorage.getItem('refresh'));
    }
  }, [hideAlert, message]);

  const bannerStyle = useMemo((): CSSProperties => {
    return {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: bannerColor === BannerColor.WARNING ? '#FFFCC8' : 'red',
      color: bannerColor === BannerColor.WARNING ? 'black' : 'white',
      padding: '10px',
      textAlign: 'center',
      cursor: 'pointer',
    };
  }, [bannerColor]);

  return (
    <>
      {displayWarningBanner && (
        <div onClick={() => setOpen(true)} style={bannerStyle}>
          Your session will expire in <strong>{timeLeft}</strong>. Click here to
          extend it.
        </div>
      )}
      <Dialog fullWidth open={open}>
        <DialogTitle variant="h5">
          Your session will expire in: {timeLeft}
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{
              display: 'flex',
              gap: '10px',
              flexDirection: 'column',
              paddingTop: '10px',
            }}
          >
            <Typography variant={'body1'}>
              If your run out of time you will lose any unsaved work.
            </Typography>
            <Typography variant={'body1'} gutterBottom>
              Please log in again to extend your current session.
            </Typography>
            <LoginStateManager
              extendSession={true}
              onExtendSessionSuccess={onExtendSessionSuccess}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="text">
            Ignore for now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionExpiryAlert;
