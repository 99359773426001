import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from '../../redux/store';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import SidebarFooter from './SidebarFooter';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import tenantSidebarConfig from './TenantSidebarConfig';
import complianceSidebarConfig from './ComplianceSidebarConfig';
import regulatoryManagerConfig from './RegulatoryManagerConfig';
import SidebarControl from './SidebarControl';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme, width = 280 }) => ({
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: width,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { memberType, householdMember } = useSelector((state) => state.main);
  const { isLoading } = useSelector((state) => state.main);
  const currentRoles = user.groups.map((role) => role.name.toLowerCase());

  const [sidebar, setSidebar] = useState(<></>);
  const [isCompliance, setIsCompliance] = useState(false);
  const { selectedProperty } = useSelector((state) => state.property);

  useEffect(() => {
    /**
     * Set sidebar options and configuration, based on the memberType or if it's loading
     */

    let sidebarConfiguration = [];

    if (isLoading) {
      sidebarConfiguration = [];
    } else {
      /*
       * Select the sidebar depending on the user roles
       * 1. Validate for compliance
       * 2. Validate for Pronto Administration
       * 3. Validate for resident
       */

      const isCompliance_ = Boolean(pathname.startsWith('/workbench'));
      const isProntoAdministration_ = pathname.startsWith('/pronto-admin');

      if (
        isCompliance_ &&
        (currentRoles.includes('compliance') || currentRoles.includes('owner'))
      ) {
        sidebarConfiguration = complianceSidebarConfig;
      } else if (
        isProntoAdministration_ &&
        (currentRoles.includes('audit') ||
          currentRoles.includes('administrator'))
      ) {
        sidebarConfiguration = regulatoryManagerConfig;
      } else if (currentRoles.includes('resident')) {
        sidebarConfiguration = tenantSidebarConfig(memberType, householdMember);
      } else {
        // Unauthorized
        console.error('USER IS NOT AUTHORIZED');
      }
    }

    setSidebar(
      <NavSection
        navConfig={sidebarConfiguration}
        user={user}
        emptyMessage="Select a property to see more options."
        sx={{ textTransform: 'none' }}
        onClose={onCloseSidebar}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, pathname, selectedProperty, memberType, householdMember]);
  // const { user } = useAuth();

  useEffect(() => {
    setIsCompliance(pathname.startsWith('/workbench'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            textDecoration: 'none',
            ml: 2,
          }}
          width="100%"
          mt={0}
        >
          <Logo isCompliance={isCompliance} />
        </Box>
      </Box>

      {sidebar}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle width={isOpenSidebar ? DRAWER_WIDTH : 0}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          <SidebarFooter />
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={isOpenSidebar}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
          <SidebarFooter />
        </Drawer>

        {renderContent && <SidebarControl />}
      </MHidden>
    </RootStyle>
  );
}
