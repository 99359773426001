import React, { useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Button,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import axiosInstance from 'src/utils/axios';
import { fDatePost } from 'src/utils/formatTime';
import { useDispatch } from '../../redux/store';
import { updateAttachmentData } from 'src/redux/slices/conversationsV2';
import { getFilenameAndExtension } from '../ConversationsV2/utils';

const DMEditAttachmentPopOver = ({ attachment, title }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: attachment.name || '',
      file_date:
        (attachment?.file_date &&
          new Date(`${attachment.file_date}T00:00:00`)) ||
        null,
    },
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const body = {
          name: values.name,
          file_date: (values.file_date && fDatePost(values.file_date)) || null,
        };

        const { data } = await axiosInstance.patch(
          `communication/new_conversation/message_attachment/${attachment.id}/`,
          body
        );
        const { ext, url } = getFilenameAndExtension(
          data?.file_attachment_url || ''
        );

        dispatch(updateAttachmentData({ ...data, ext, url }));

        enqueueSnackbar('Attachment updated successfully.', {
          variant: 'success',
        });
        handleClose();
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Something went wrong saving the attachment.', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const { values, getFieldProps, setFieldValue, handleSubmit, isValid } =
    formik;

  const open = Boolean(anchorEl);
  const id = open ? 'edit-attachment-popover' : undefined;

  return (
    <Box>
      <Tooltip title={title} placement="bottom" arrow>
        <IconButton size="small" onClick={handleBtnClick}>
          <ModeEditIcon sx={{ fontSize: 18 }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <FormikProvider value={formik}>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Box sx={{ p: 2, width: 300 }}>
              <TextField
                {...getFieldProps('name')}
                size="small"
                fullWidth
                label="Name"
                sx={{ mb: 2 }}
                value={values.name || ''}
              />

              <DesktopDatePicker
                label="Date"
                fullWidth
                inputFormat="MM/dd/yyyy"
                onChange={(date, e) => setFieldValue('file_date', date)}
                value={values.file_date}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Box>

            <Box sx={{ pb: 2, pr: 2, textAlign: 'right' }}>
              <Button
                onClick={handleClose}
                sx={{ mr: 2 }}
                variant="text"
                size="small"
                color="inherit"
              >
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                disabled={!isValid}
                variant="contained"
                size="small"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Popover>
    </Box>
  );
};

export default React.memo(DMEditAttachmentPopOver);
