import { createSlice } from '@reduxjs/toolkit';
import { CONTENT_TYPES } from 'src/components/ProntoHousingAdministration/QuestionMapping/constants';
import { reduceQits } from 'src/components/ProntoHousingAdministration/QuestionMapping/utils';
import { store } from 'src/redux/store';
import axios from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import _ from 'lodash';

const SUCCESS_HTTP_CODES = [200, 201];

const qitFields = [
  'help_text',
  'id',
  'is_mapped',
  'necessity',
  'number_of_answers_required',
  'object_id',
  'page',
  'question_group.age_max',
  'question_group.age_min',
  'question_group.help_text',
  'question_group.householdmember_type',
  'question_group.id',
  'question_group.name',
  'question_group.order',
  'question_group.question',
  'question_group.question_choice',
  'question_group.render_types',
  'question_group.type',
  'section',
  'question',
  'for_compliance',
  'order',
  'question_context',
  'number_of_files_required_per_answer',
  'number_of_pages_required_per_file',
  'help_text',
  'question_choices',
  'member_types',
  'is_TIC',
  'question_group.tic_group_obj.tic_setups.id',
  'question_group.tic_group_obj.tic_setups.program',
  'tic_element.tic_group.tic_setups.id',
  'tic_element.tic_group.tic_setups.program',
].join(',');
const qitExpands = [
  'question.question_choices',
  'question.example_file',
  'question_group.householdmember_type',
  'question_group.question.question_choices',
  'question_group.render_types',
  'page',
  'section',
  'question_choices',
  'member_types',
  'question_group.tic_group_obj.tic_setups.program',
  'tic_element.tic_group.tic_setups.program',
].join(',');

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isOptionsLoading: false,
  isQitsLoading: true,
  isDitsLoading: false,
  error: false,
  contentTypeId: null,
  objectId: null,
  sections: [],
  questionnaireChildQuestions: [],
  plainQits: [],
  documents: [],
  formList: [],
  contentTypes: [],
  qits: [],
  mits: [],
  sits: [],
  defaultSits: [],
  dits: [],
  selectedDocument: '',
  mappings: [],
  mitMappings: [],
  sitMappings: [],
  newAnnotations: [],
  calculationMappings: [],
  mappingState: {
    isSaving: false,
    willBeCreated: false,
    createData: null,
    requestBody: null,
    resetNavSelection: false,
    mitBody: null,
    choicesSelection: {
      qitParent: null,
      choices: [],
      renderType: '',
      blank: false,
    },
    hhmt: {
      parent: null,
      options: [],
      all: false,
      renderType: null,
    },
    multiGroup: {
      groupId: null,
      question_group_render_type: null,
      question_group_render_type_choice: null,
    },
    textQuestionTypes: {
      qitId: null,
      renderType: null,
    },
    contextConfiguration: {
      contextId: null,
      renderType: null,
    },
    miscTICBucketCalculationConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    miscTICHasAnswersConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    miscTICMatchAnswersConfiguration: {
      renderType: null,
      questionGroupRenderType: null,
      questionGroupRenderTypeChoice: null,
    },
    readyToMap: false,
    selectedNode: {},
    configChoices: false,
  },
  sectionFormOptions: {
    sections: [],
    pages: [],
    questions: [],
    hhmts: [],
  },
  hiddenQits: null,
  hoverElement: {
    elementId: null,
    type: '',
  },
  editingGroup: null,
};

const slice = createSlice({
  name: 'Question Mapping',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startQitsLoading(state) {
      state.isQitsLoading = true;
    },

    startDitsLoading(state) {
      state.isDitsLoading = true;
    },

    // START OPTIONS LOADING
    startOptionsLoading(state) {
      state.isOptionsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET FORMS
    getFormsSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },
    // GET FORMS
    getFormListSuccess(state, action) {
      state.isLoading = false;
      state.formList = action.payload;
    },

    // GET FORMS
    updateFormFileURL(state, action) {
      state.isLoading = false;
      const updatedForm = action.payload;
      const oldForms = state.formList?.results
        ? state.formList.results
        : state.formList;
      const newForms = oldForms.map((f) => {
        return f.form?.id === updatedForm.id
          ? {
              ...f,
              form: {
                ...f.form,
                file: updatedForm.file,
                pages: [...updatedForm.pages],
              },
            }
          : f;
      });
      state.formList = state.formList?.results
        ? { ...state.formList, results: newForms }
        : newForms;
    },

    // GET MAPPINGS
    addMappingToStore(state, action) {
      state.mappings = [...state.mappings, action.payload];
    },
    addMitMappingToStore(state, action) {
      state.mitMappings = [...state.mitMappings, action.payload];
    },
    addSitMappingToStore(state, action) {
      state.sitMappings = [...state.sitMappings, action.payload];
    },
    addCalculationMappingToStore(state, action) {
      state.calculationMappings = [
        ...state.calculationMappings,
        action.payload,
      ];
    },
    removeMapping(state, action) {
      const newMappings = [...state.mappings];
      const toDeleteIdx = newMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newMappings.splice(toDeleteIdx, 1);
      }

      state.mappings = newMappings;
    },

    setMappings(state, action) {
      state.mappings = action.payload;
    },

    setMitMappings(state, action) {
      state.mitMappings = action.payload;
    },

    setSitMappings(state, action) {
      state.sitMappings = action.payload;
    },

    setCalculationMappings(state, action) {
      state.calculationMappings = action.payload;
    },

    setNewAnnotations(state, action) {
      state.newAnnotations = action.payload;
    },

    setDeletedAnnotations(state, action) {
      state.deletedAnnotations = action.payload;
    },

    removeMitMapping(state, action) {
      const newMitMappings = [...state.mitMappings];
      const toDeleteIdx = newMitMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newMitMappings.splice(toDeleteIdx, 1);
      }

      state.mitMappings = newMitMappings;
    },
    removeSitMapping(state, action) {
      const newSitMappings = [...state.sitMappings];
      const toDeleteIdx = newSitMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newSitMappings.splice(toDeleteIdx, 1);
      }

      state.sitMappings = newSitMappings;
    },
    removeCalculationMapping(state, action) {
      const newCalculationMappings = [...state.calculationMappings];
      const toDeleteIdx = newCalculationMappings.findIndex(
        (el) => el.id === action.payload
      );

      if (toDeleteIdx >= 0) {
        newCalculationMappings.splice(toDeleteIdx, 1);
      }

      state.calculationMappings = newCalculationMappings;
    },
    // END MAPPINGS

    // GET QITs
    getQitsSuccess(state, action) {
      state.isQitsLoading = false;
      state.sections = action.payload.sections;
      state.plainQits = action.payload.plainQits;
    },

    // GET MITs
    getMitsSuccess(state, action) {
      state.isLoading = false;
      state.mits = action.payload;
    },
    addMitsSuccess(state, action) {
      state.mits = [...state.mits, ...action.payload];
      state.isLoading = false;
    },
    deleteMitSuccess(state, action) {
      const updatedMits = [...state.mits];
      const mitIdx = updatedMits.findIndex((mit) => mit.id === action.payload);
      if (mitIdx >= 0) {
        updatedMits.splice(mitIdx, 1);
      }
      state.mits = updatedMits;
    },

    // GET SITs
    getSitsSuccess(state, action) {
      state.sits = action.payload;
      state.isLoading = false;
    },

    // GET DEFAULT SITs
    getDefaultSitsSuccess(state, action) {
      state.defaultSits = action.payload;
      state.isLoading = false;
    },

    //GET DITs
    getDitsSuccess(state, action) {
      state.dits = action.payload;
      state.isDitsLoading = false;
    },

    // Get form Options
    getSectionFormOptionsSuccess(state, action) {
      state.isOptionsLoading = false;
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        ...action.payload,
      };
    },

    // get contentTypes
    getContentTypesSuccess(state, action) {
      state.isLoading = false;
      state.contentTypes = action.payload;
    },

    updateLibraryQuestionSuccess(state, action) {
      const { question } = action.payload;
      const updatedSections = [...state.sections];

      const currentQuestionOptions = [...state.sectionFormOptions.questions];
      const currentPlainQits = [...state.plainQits];
      const questionOptionIndex = currentQuestionOptions.findIndex(
        (qoption) => qoption.id === question.id
      );

      state.sections = patchTreeQuestions(updatedSections, question);
      state.plainQits = currentPlainQits.map((pq) => ({
        ...pq,
        question: {
          ...(pq?.question || {}),
          ...(pq?.question?.id === question?.id ? question : {}),
        },
      }));

      if (questionOptionIndex >= 0) {
        currentQuestionOptions[questionOptionIndex] = {
          ...currentQuestionOptions[questionOptionIndex],
          name: question?.name,
        };
        state.sectionFormOptions.questions = currentQuestionOptions;
      }
    },

    updateSelectableQuestions(state, action) {
      const { question } = action.payload;
      const currentQuestionOptions = [...state.sectionFormOptions.questions];
      state.sectionFormOptions.questions = [
        ...currentQuestionOptions,
        question,
      ];
    },

    // Set document
    setSelectedDocument(state, action) {
      state.selectedDocument = action.payload;
    },

    // // Set document
    // setPages(state, action) {
    //   state.setSections = action.payload;
    // },

    // reset data
    resetData(state) {
      state.sections = [];
      state.mits = [];
      state.qits = [];
      state.sits = [];
      state.plainQits = [];
      state.contentTypes = [];
      state.documents = [];
      state.selectedDocument = '';
      state.mappings = [];
      state.mitMappings = [];
      state.sitMappings = [];
      state.calculationMappings = [];
      state.contentTypeId = null;
      state.objectId = null;
      state.editingGroup = null;
      state.mappingState = {
        ...state.mappingState,
        resetNavSelection: false,
        isSaving: false,
        willBeCreated: false,
        createData: null,
        requestBody: null,
        mitBody: null,
        choicesSelection: {
          qitParent: null,
          choices: [],
          renderType: '',
          blank: false,
        },
        hhmt: {
          parent: null,
          options: [],
          all: false,
          enderType: null,
        },
        multiGroup: {
          groupId: null,
          question_group_render_type: null,
          question_group_render_type_choice: null,
        },
        textQuestionTypes: {
          qitId: null,
          renderType: null,
        },
        contextConfiguration: {
          contextId: null,
          renderType: null,
        },
        miscTICBucketCalculationConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICHasAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICMatchAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        readyToMap: false,
        selectedNode: {},
        configChoices: false,
      };
      state.ticSetupsIds = [];
    },

    // set element IDs
    setIds(state, action) {
      state.contentTypeId = action.payload.contentTypeId;
      state.objectId = action.payload.objectId;
    },

    // set mapping options
    setMappingOptions(state, action) {
      state.mappingState = { ...state.mappingState, ...action.payload };
    },

    resetMappingOptions(state) {
      state.mappingState = {
        ...state.mappingState,
        isSaving: false,
        resetNavSelection: false,
        willBeCreated: false,
        createData: null,
        requestBody: null,
        mitBody: null,
        choicesSelection: {
          qitParent: null,
          choices: [],
          renderType: '',
          blank: false,
        },
        hhmt: {
          parent: null,
          options: [],
          all: false,
          renderType: null,
        },
        multiGroup: {
          groupId: null,
          question_group_render_type: null,
          question_group_render_type_choice: null,
        },
        textQuestionTypes: {
          qitId: null,
          renderType: null,
        },
        contextConfiguration: {
          contextId: null,
          renderType: null,
        },
        miscTICBucketCalculationConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICHasAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        miscTICMatchAnswersConfiguration: {
          renderType: null,
          questionGroupRenderType: null,
          questionGroupRenderTypeChoice: null,
        },
        readyToMap: false,
        selectedNode: {},
        configChoices: false,
      };
    },

    // SECTIONS
    removeSection(state, action) {
      const updatedSections = [...state.sections];

      // remove section by index
      if (updatedSections[action.payload]) {
        updatedSections.splice(action.payload, 1);
        state.sections = updatedSections;
      }
    },
    addSectionSuccess(state, action) {
      state.sections = [...state.sections, action.payload];
      state.isLoading = false;
    },
    updateSection(state, action) {
      const updatedSections = [...state.sections];
      const sectionIdx = updatedSections.findIndex(
        (section) => section.id === action.payload.id
      );

      if (sectionIdx >= 0) {
        updatedSections[sectionIdx] = {
          ...updatedSections[sectionIdx],
          ...action.payload.value,
        };
      }

      state.sections = updatedSections;
      state.isLoading = false;
    },

    // PAGES
    updatePageByIndex(state, action) {
      const { index, value = {}, section } = action.payload;
      const updatedSections = [...state.sections];

      if (updatedSections[section]?.pages[index]) {
        updatedSections[section].pages[index] = {
          ...updatedSections[section]?.pages[index],
          ...value,
        };
        state.sections = updatedSections;
      }
    },

    // for update question groups
    updateGroupByIndex(state, action) {
      const { index, value = {}, section, page } = action.payload;
      const updatedSections = [...state.sections];
      if (updatedSections[section]?.pages[page]?.question_groups[index]) {
        updatedSections[section].pages[page].question_groups[index] = {
          ...updatedSections[section].pages[page].question_groups[index],
          ...value,
        };
        state.sections = updatedSections;
      }
    },

    updatePage(state, action) {
      const updatedSections = [...state.sections];
      const sectionIdx = updatedSections.findIndex(
        (section) => section.id === action.payload.sectionId
      );

      if (
        sectionIdx >= 0 &&
        Array.isArray(updatedSections[sectionIdx]?.pages) &&
        updatedSections[sectionIdx]?.pages.find(
          (page) => page.id === action.payload.pageId
        )
      ) {
        const updatedPages = [...(updatedSections[sectionIdx]?.pages || [])];
        const pageIdx = updatedSections[sectionIdx]?.pages.findIndex(
          (page) => page.id === action.payload.pageId
        );

        if (pageIdx >= 0) {
          updatedSections[sectionIdx].pages[pageIdx] = {
            ...updatedPages[pageIdx],
            ...action.payload.value,
          };
        }
      }

      state.sections = updatedSections;
      state.isLoadingSection = false;
    },

    deletePage(state, action) {
      const updatedSections = [...state.sections];
      const { pageIndex, sectionIndex } = action.payload;

      if (updatedSections[sectionIndex]?.pages[pageIndex]) {
        updatedSections[sectionIndex].pages.splice(pageIndex, 1);
        state.sections = updatedSections;
      }
    },

    // GROUPS
    addPageGroupSuccess(state, action) {
      const { sectionIndex, section, plainQits } = action.payload;
      const updatedSections = [...state.sections];
      const notSavedSection = updatedSections[sectionIndex]?.notSavedSection;
      const sectionOverrides = notSavedSection
        ? { sectionId: section.id, notSavedSection: false, wasCreated: true }
        : {};

      state.sections = buildSectionsTree(plainQits, sectionOverrides);
    },

    updateGroupSuccess(state, action) {
      const updatedSections = [...state.sections];
      const {
        pageIndex,
        sectionIndex,
        groupIndex,
        value,
        plainQits = [],
        onlyUpdateGroupProp = false,
      } = action.payload;

      if (
        onlyUpdateGroupProp &&
        updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
          groupIndex
        ]
      ) {
        const groupId =
          updatedSections[sectionIndex].pages[pageIndex].question_groups[
            groupIndex
          ].id;

        // update group data on the qits
        const groupQuestions = [
          ...(updatedSections[sectionIndex].pages[pageIndex].question_groups[
            groupIndex
          ]?.questions || []),
        ].map((q) => ({
          ...q,
          qit: {
            ...(q?.qit || {}),
            question_group: {
              ...(q?.qit?.question_group || {}),
              ...value,
            },
          },
        }));

        // update group data on the plain qits
        const newPlainQits = [...(state?.plainQits || [])].map((q) => ({
          ...q,
          question_group: {
            ...(q.question_group.id === groupId
              ? { ...(q.question_group || {}), ...value }
              : { ...(q.question_group || {}) }),
          },
        }));

        updatedSections[sectionIndex].pages[pageIndex].question_groups[
          groupIndex
        ] = {
          ...updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
            groupIndex
          ],
          ...value,
          questions: groupQuestions,
        };

        state.plainQits = newPlainQits;
        state.sections = updatedSections;
      } else if (!onlyUpdateGroupProp && plainQits?.length) {
        state.sections = buildSectionsTree(plainQits);
      }
    },

    deletePageGroup(state, action) {
      const updatedSections = [...state.sections];
      const newPlainQits = [...state.plainQits];
      const { pageIndex, sectionIndex, groupIndex } = action.payload;

      if (
        updatedSections[sectionIndex] &&
        updatedSections[sectionIndex]?.pages[pageIndex] &&
        updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
          groupIndex
        ]
      ) {
        const groupQits = updatedSections[sectionIndex]?.pages[
          pageIndex
        ]?.question_groups[groupIndex].questions
          .reduce(reduceQits, [])
          .map((q) => q.qit.id);
        updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups.splice(
          groupIndex,
          1
        );

        // if the page runs out of groups, it should be removed
        if (
          updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups
            ?.length === 0
        ) {
          updatedSections[sectionIndex]?.pages.splice(pageIndex, 1);
        }
        state.sections = updatedSections;

        if (groupQits?.length > 0) {
          state.plainQits = newPlainQits.filter(
            (qit) => !groupQits.includes(qit.id)
          );
        }
      }
    },

    // QITS
    updateQitSuccess(state, action) {
      const {
        sectionIndex,
        pageIndex,
        groupIndex,
        qitContextIndex,
        qitIndex,
        value,
      } = action.payload;
      const updatedSections = [...state.sections];
      const newPlainQits = [...state.plainQits];
      const updatedQitIdx = newPlainQits.findIndex(
        (pq) => pq.id === value?.qit?.id
      );

      // if qitContextIndex means that is a question context
      if (qitContextIndex === null) {
        if (
          updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
            groupIndex
          ]?.questions[qitIndex]
        ) {
          updatedSections[sectionIndex].pages[pageIndex].question_groups[
            groupIndex
          ].questions[qitIndex] = {
            ...updatedSections[sectionIndex].pages[pageIndex].question_groups[
              groupIndex
            ].questions[qitIndex],
            ...value,
          };
        }
      } else {
        if (
          updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
            groupIndex
          ]?.questions[qitContextIndex]?.qits[qitIndex]
        ) {
          updatedSections[sectionIndex].pages[pageIndex].question_groups[
            groupIndex
          ].questions[qitContextIndex].qits[qitIndex] = {
            ...updatedSections[sectionIndex].pages[pageIndex].question_groups[
              groupIndex
            ].questions[qitContextIndex].qits[qitIndex],
            ...value,
          };
        }
      }

      // update plainQits when the qit change
      if (updatedQitIdx >= 0) {
        newPlainQits[updatedQitIdx] = {
          ...newPlainQits[updatedQitIdx],
          ...value.qit,
        };
        state.plainQits = newPlainQits;
      }

      state.sections = updatedSections;
    },

    deleteQit(state, action) {
      const {
        sectionIndex,
        pageIndex,
        groupIndex,
        qitIndex,
        qitId,
        contextQitsIds,
      } = action.payload;
      const updatedSections = [...state.sections];
      const newPlainQits = [...state.plainQits];

      if (
        updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
          groupIndex
        ]?.questions[qitIndex]
      ) {
        const finalQuestions = [
          ...updatedSections[sectionIndex].pages[pageIndex].question_groups[
            groupIndex
          ].questions,
        ];
        finalQuestions.splice(qitIndex, 1);
        updatedSections[sectionIndex].pages[pageIndex].question_groups[
          groupIndex
        ].questions = finalQuestions;
        state.sections = updatedSections;
        if (contextQitsIds) {
          state.plainQits = newPlainQits.filter(
            (qit) => !contextQitsIds.includes(qit.id)
          );
        } else {
          state.plainQits = newPlainQits.filter((qit) => qit.id !== qitId);
        }
      }
    },

    getSectionQits(state, action) {
      state.isLoadingSection = false;
      state.sections = action.payload.sectionsWithLoadedData;
    },

    setQits(state, action) {
      state.qits = action.payload;
      state.isQitsLoading = false;
    },

    setPlaingQits(state, action) {
      state.plainQits = action.payload;
    },

    setQuestionnaireChildQuestions(state, action) {
      state.questionnaireChildQuestions = action.payload;
    },

    setHiddenQits(state, action) {
      state.hiddenQits = action.payload;
    },

    setDocumentTemplate(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form.template = action.payload.template;
      state.formList.results = docs;
    },

    setDocumentAutoRequestSignatures(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form.auto_request_signatures =
        action.payload.auto_request_signatures;
      state.formList.results = docs;
    },

    setDocumentForm(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form[action.payload.initValProperty] =
        action.payload.values[action.payload.initValProperty];
      state.formList.results = docs;
    },

    setDocumentFormQuestions(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex(
        (element) => element.form.id === action.payload.id
      );
      docs[idx].form.parent_qit = action.payload.parent_qit;
      docs[idx].form.parent_choice = action.payload.parent_choice;
      state.formList.results = docs;
    },

    // Hover element
    setHoverElement(state, action) {
      state.hoverElement = action.payload;
    },

    removeHoverElement(state) {
      state.hoverElement = {
        elementId: null,
        type: '',
      };
    },

    // New group
    setEditingGroup(state, action) {
      state.editingGroup = action.payload;
    },

    updatePageOption(state, action) {
      const newPageData = action.payload;
      const newPages = (state.sectionFormOptions?.pages || []).map((p) =>
        p.id === newPageData.id ? newPageData : p
      );
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        pages: newPages,
      };
    },

    updateSectionOption(state, action) {
      const newSectionData = action.payload;
      const newSections = (state.sectionFormOptions?.sections || []).map((s) =>
        s.id === newSectionData.id ? newSectionData : s
      );
      state.sectionFormOptions = {
        ...state.sectionFormOptions,
        sections: newSections,
      };
    },

    // Tic Setups Ids
    setTicSetupsIds(state, action) {
      state.ticSetupsIds = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetData,
  setIds,
  setSelectedDocument,
  setMappingOptions,
  resetMappingOptions,
  setMappings,
  resetQits,
  addMappingToStore,
  removeMapping,
  updateSection,
  updatePage,
  updatePageByIndex,
  updateQitSuccess,
  addSitMappingToStore,
  addCalculationMappingToStore,
  addMitMappingToStore,
  setMitMappings,
  setSitMappings,
  removeMitMapping,
  removeSitMapping,
  removeCalculationMapping,
  updateGroup,
  deletePageGroup,
  deletePage,
  addSectionSuccess,
  removeSection,
  updateGroupSuccess,
  setPlaingQits,
  setQuestionnaireChildQuestions,
  setHiddenQits,
  deleteQit,
  updateLibraryQuestionSuccess,
  updateSelectableQuestions,
  setDocumentTemplate,
  setDocumentAutoRequestSignatures,
  setDocumentForm,
  setDocumentFormQuestions,
  addMitsSuccess,
  setSections,
  setHoverElement,
  removeHoverElement,
  setEditingGroup,
  updateFormFileURL,
  updateGroupByIndex,
  updatePageOption,
  updateSectionOption,
  setCalculationMappings,
  setNewAnnotations,
  setDeletedAnnotations,
  setTicSetupsIds,
} = slice.actions;

// ----------------------------------------------------------------------
export function mapQits(qit) {
  if (!qit) {
    return;
  }
  return {
    qit: qit,
    name: qit.question?.name,
    //help_text: qit.question?.help_text,
    id: qit.question?.id,
    necessity: `${qit.necessity}`,
    number_of_answers_required: qit?.number_of_answers_required || '',
    number_of_files_required_per_answer:
      qit?.number_of_files_required_per_answer || '',
    number_of_pages_required_per_file:
      qit?.number_of_pages_required_per_file || '',
    order: qit?.question?.order,
    qitOrder: qit?.order,
    for_compliance: qit?.for_compliance,
    question_context: qit?.question_context,
    question_type: qit?.question?.question_type,
    help_text: qit?.help_text || '',
    question_choices: qit?.question_choices || [],
    member_types: qit?.member_types || [],
  };
}

export function getForms(certificationConfigId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `form_library/form_table/?expand=form&certification_config=${certificationConfigId}`
      );
      dispatch(slice.actions.getFormsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFormList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `form_library/form_table/?${parseQueryParams(queryParams)}`
      );
      let formList = _.cloneDeep(response.data);
      formList.results = formList.results.map((form) => {
        return {
          ...form,
          form: {
            ...form.form,
            hasSignatures: Boolean(
              form.form?.has_mappings?.signatures?.length > 0
            ),
            hasMappings: Boolean(
              form.form?.has_mappings?.questions?.length > 0 ||
                form.form?.has_mappings?.misc?.length > 0
            ),
          },
        };
      });
      dispatch(slice.actions.getFormListSuccess(formList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addSection({
  name,
  object_id,
  content_type,
  successFunc,
  errFunc,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const res = await axios.post(`survey/section/`, { name });

      if (!SUCCESS_HTTP_CODES.includes(res.status)) {
        console.error(res);
        return;
      }

      await axios.post('survey/regulatory_manager/', {
        object_id,
        content_type,
        section: res.data.id,
      });

      dispatch(slice.actions.addSectionSuccess(res.data));
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      if (errFunc) {
        errFunc();
      }
    }
  };
}

export function getSectionFormOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startOptionsLoading());
    try {
      const { data: hhmts } = await axios.get(
        '/household/household_member_type/'
      );

      dispatch(
        slice.actions.getSectionFormOptionsSuccess({
          hhmts: Array.isArray(hhmts)
            ? hhmts.sort((a, b) => a.weight - b.weight)
            : [],
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getGroup(question_group) {
  const schemaParams = {
    expand: [
      'householdmember_type',
      'question',
      'question.question_choices',
      'question_choice',
    ],
  };

  const { data } = await axios.get(
    `questionnaire/question_library/question_group/${
      question_group.id
    }/?${parseQueryParams(schemaParams)}`
  );
  if (data) {
    return data;
  }
}

export async function getQitsByQuestionGroup(questionGroupId) {
  const { data } = await axios.get(
    `questionnaire/question_library/question_intermediate_table/?question_group=${questionGroupId}&expand=${qitExpands}&fields=${qitFields}`
  );
  return data;
}

const extractSectionsTreeFromQitsArray = (qits = []) => {
  return qits.reduce((acc, curr) => {
    const result = [...acc];
    let sectionIdx = result?.findIndex(
      (sect) => sect?.id === curr?.section?.id
    );

    if (sectionIdx === -1) {
      result.push({ ...curr.section, pages: [] });
      sectionIdx = result.findIndex((sect) => sect?.id === curr?.section?.id);
    }

    let pageIdx = result[sectionIdx]?.pages?.findIndex(
      (page) => page?.id === curr?.page?.id
    );
    if (pageIdx === -1 && result[sectionIdx]?.pages) {
      result[sectionIdx].pages.push({ ...curr?.page, question_groups: [] });
      pageIdx = result[sectionIdx].pages.findIndex(
        (page) => page?.id === curr?.page?.id
      );
    }

    let groupIdx = result[sectionIdx]?.pages[
      pageIdx
    ]?.question_groups?.findIndex(
      (group) => group?.id === curr?.question_group?.id
    );
    if (
      groupIdx === -1 &&
      result[sectionIdx]?.pages[pageIdx]?.question_groups
    ) {
      result[sectionIdx].pages[pageIdx].question_groups.push({
        ...curr?.question_group,
        questions: [],
      });
      groupIdx = result[sectionIdx].pages[pageIdx].question_groups.findIndex(
        (group) => group?.id === curr?.question_group?.id
      );
    }

    return result;
  }, []);
};

// this function buils the questionnaire tree based on the list of qits
export const buildSectionsTree = (
  qits,
  sectionOverrides = {},
  calculations = []
) => {
  const buildedTree = extractSectionsTreeFromQitsArray(qits);

  for (let x = 0; x < buildedTree.length; x++) {
    if (sectionOverrides?.sectionId === buildedTree[x]?.id) {
      buildedTree[x] = { ...buildedTree[x], ...sectionOverrides };
    }

    let section = buildedTree[x];
    const sectionQits = qits.filter((qit) => qit.section?.id === section?.id);
    section.calculations = calculations.filter(
      (calc) =>
        calc.type === 'section' && section.calculations.includes(calc.id)
    );

    section.firstQitOrder = sectionQits[0]?.order;
    section.lastQitOrder = sectionQits[sectionQits.length - 1]?.order;
    for (let y = 0; y < section.pages.length; y++) {
      const page = section.pages[y];
      const pageQits = sectionQits.filter((qit) => qit?.page?.id === page?.id);
      page.firstQitOrder = pageQits[0]?.order;
      page.lastQitOrder = pageQits[pageQits.length - 1]?.order;

      if (section?.wasCreated) {
        page.notSaved = false;
        page.wasCreated = true;
      }

      for (let z = 0; z < page.question_groups.length; z++) {
        const group = page.question_groups[z];
        const groupQits = pageQits.filter(
          (qit) => qit.question_group?.id === group?.id
        );

        if (page?.wasCreated) {
          group.shouldOpen = true;
        }
        group.calculations = calculations.filter(
          (calc) =>
            calc.type === 'question_group' && group.id === calc.question_group
        );
        group.firstQitOrder = groupQits[0]?.order;
        group.lastQitOrder = groupQits[groupQits.length - 1]?.order;
        group.questions = transFormIncomingQits(groupQits); // qits && contexts
      }
    }
  }

  return buildedTree;
};

export function fetchQuestionnaireFormData(certificationConfigId) {
  return async (dispatch) => {
    dispatch(slice.actions.startQitsLoading());

    try {
      const { data: qits } = await axios.get(
        `questionnaire_configuration_schema/?certification_config=${certificationConfigId}`
      );
      const { data: calculations } = await axios.get(
        `/calculations/calculation/?certification_config=${certificationConfigId}`
      );
      const sortedQits = qits.sort((a, b) => a.order - b.order);
      dispatch(
        slice.actions.getQitsSuccess({
          sections: buildSectionsTree(sortedQits, {}, calculations),
          plainQits: sortedQits,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
}

const getContentTypeName = (model, key) => {
  if (model === 'certification') {
    if (key === 'lease_start') {
      return 'Lease Start Date';
    }
    if (key === 'lease_end') {
      return 'Lease End Date';
    }
    if (key === 'move_in_date') {
      return 'Move In Date';
    }
    if (key === 'move_out_date') {
      return 'Move Out Date';
    }
  }
  if (model === 'householdmember') {
    if (key === 'email_addresses') {
      return 'Email Address';
    }
    if (key === 'mobile_phones') {
      return 'Mobile Phone';
    }
  }

  return key
    .replaceAll('_', ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const CONTENT_TYPES_PARAMS = {
  app_label__in: [
    'property_portfolio',
    'household',
    'user_profile',
    'certification',
    'TICFlex',
  ],
  model__in: [
    'propertyownercompany',
    'property',
    'unit',
    'building',
    'household',
    'user',
    'householdmembertype',
    'householdmember',
    'certification',
    'certificationconfig',
    'ticbucketcalculation',
    'tichasanswers',
    'ticmatchanswers',
  ],
};

export function getContentTypes(
  certificationconfigId = null,
  ticSetupIDs = null
) {
  return async (dispatch) => {
    try {
      let endpointURL = `mapping_question/content_types/?${parseQueryParams(
        CONTENT_TYPES_PARAMS
      )}`;

      const { data } = await axios.get(endpointURL);

      for (let i = 0; i < data.length; i++) {
        const contentType = data[i];
        let endpoint = CONTENT_TYPES[contentType?.model]?.endpoint;

        // Check if certificationconfigId is provided and append it
        if (endpoint.includes('certification_config')) {
          if (certificationconfigId !== null) {
            endpoint += `${certificationconfigId}/`;
          }
        }

        if (endpoint) {
          try {
            if (endpoint === 'tic_flex/tic_bucket/') {
              endpoint =
                `certification/certification_config/${certificationconfigId}?expand=` +
                [
                  'tic_setups.buckets.calculations.tic_setups',
                  'tic_setups.buckets.calculations.member_types',
                ].join(',') +
                '&fields=' +
                [
                  'tic_setups.id',
                  'tic_setups.buckets.name',
                  'tic_setups.buckets.id',
                  'tic_setups.buckets.calculations.id',
                  'tic_setups.buckets.calculations.name',
                  'tic_setups.buckets.calculations.format',
                  'tic_setups.buckets.calculations.formula',
                  'tic_setups.buckets.calculations.is_for_certification',
                  'tic_setups.buckets.calculations.member_types.id',
                  'tic_setups.buckets.calculations.tic_setups.id',
                ].join(',');

              const { data } = await axios.get(endpoint);

              let finalBucketData = [];
              data.tic_setups.forEach((ticSetup) => {
                const ticSetupID = ticSetup.id;
                ticSetup.buckets.forEach((bucket) => {
                  let calculations = bucket.calculations.filter((calculation) =>
                    calculation.tic_setups.some(
                      (ticSetup) => ticSetup.id === ticSetupID
                    )
                  );
                  finalBucketData.push({
                    calculations: calculations,
                    name: bucket.name,
                    id: bucket.id,
                  });
                });
              });
              contentType.fields = finalBucketData;
            } else if (
              [
                'tic_flex/tic_has_answers/',
                'tic_flex/tic_match_answers/',
              ].includes(endpoint)
            ) {
              if (ticSetupIDs.length) {
                endpoint +=
                  `?tic_setups__id__in=${ticSetupIDs.join(
                    ','
                  )}&expand=member_types&fields=` +
                  [
                    'id',
                    'name',
                    'answers_level',
                    'member_types.id',
                    'member_types.display_name',
                  ].join(',');
                const { data } = await axios.get(endpoint);

                contentType.fields = data;
              } else {
                contentType.fields = [];
              }
            } else {
              const { data } = await axios.options(endpoint);
              let fieldsData;

              // Check if the 'actions' property exists
              if (data.actions && data.actions.POST) {
                fieldsData = data.actions.POST;
              } else {
                fieldsData = data;
              }

              if (fieldsData) {
                contentType.fields = Object.keys(fieldsData)
                  // Define filter conditions with variable names
                  .filter((key) => {
                    const isAttrField =
                      key.startsWith('attr_') &&
                      CONTENT_TYPES[contentType?.model].fields.some((field) =>
                        key.includes(field)
                      );
                    const isExactMatchField =
                      !key.startsWith('attr_') &&
                      CONTENT_TYPES[contentType?.model].fields.includes(key);

                    return isAttrField || isExactMatchField;
                  })
                  .map((key) => ({
                    id: key,
                    name: getContentTypeName(
                      contentType?.model || '',
                      key.startsWith('attr_') ? key.replace('attr_', '') : key
                    ),
                  }));
              } else {
                contentType.fields = [];
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      dispatch(slice.actions.getContentTypesSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMitsByAsset(certificationConfigId) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `mapping_question/misc_intermediate_table/?certification_config=${certificationConfigId}`
      );

      dispatch(slice.actions.getMitsSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteMit({ id, successFunc, errFunc }) {
  return async (dispatch) => {
    try {
      await axios.delete(`mapping_question/misc_intermediate_table/${id}`);
      dispatch(slice.actions.deleteMitSuccess(id));
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.error(error);
      if (errFunc) {
        errFunc();
      }
    }
  };
}

export function getSitsByAsset(queryParams, isDefault) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `signature/signature_intermediate_table/?${parseQueryParams(
          queryParams
        )}`
      );
      if (!isDefault) {
        dispatch(slice.actions.getSitsSuccess(data));
      } else {
        dispatch(slice.actions.getDefaultSitsSuccess(data));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSit(body, successFunc, errFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('signature/signature_intermediate_table/', body);
      successFunc();
    } catch (error) {
      errFunc(error);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSit(sitId, body, successFunc, errFunc) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(
        `signature/signature_intermediate_table/${sitId}/`,
        body
      );
      successFunc();
    } catch (error) {
      errFunc(error);
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSit({ sitId, successFunc, errFunc }) {
  return async (dispatch) => {
    try {
      await axios.delete(`signature/signature_intermediate_table/${sitId}/`);
      successFunc();
    } catch (error) {
      errFunc();
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

const transFormIncomingQits = (qits) => {
  if (!Array.isArray(qits)) {
    return [];
  }
  const questionGroupQits = [...qits].map((qit) => mapQits(qit));
  const contextIds = [
    ...new Set(
      questionGroupQits
        .filter((qit) => Boolean(qit.question_context))
        .map((qit) => qit.question_context)
    ),
  ];
  const contextList = contextIds.map((contextId) => {
    const contextQits = questionGroupQits.filter(
      (qit) => qit.question_context === contextId
    );
    return {
      id: contextId,
      contextId: contextId,
      qitOrder: Math.min(...contextQits.map((qit) => qit.qitOrder)),
      qits: contextQits,
    };
  });

  return [
    ...questionGroupQits.filter((el) => !el.question_context),
    ...contextList,
  ].sort((a, b) => a.qitOrder - b.qitOrder);
};

const buildPageGroupBody = (group) => ({
  question: group?.question?.id || null,
  question_choice: group?.question_choice || null,
  householdmember_type:
    group?.householdmember_type?.length > 0
      ? group?.householdmember_type
          .map((hhmt) => hhmt?.id || hhmt)
          .filter(Boolean)
      : [],
  name: group?.name || '',
  type: group?.type || '',
  age_max: group?.age_max !== '' ? group?.age_max : null,
  age_min: group?.age_min !== '' ? group?.age_min : null,
  help_text: group?.help_text || '',
});

const buildQitBody = ({
  question,
  groupId,
  pageId,
  sectionId,
  certificationConfigId,
}) => ({
  question: question.id,
  necessity: question.necessity,
  number_of_answers_required: question.number_of_answers_required,
  question_group: groupId,
  page: pageId,
  section: sectionId,
  certification_config: certificationConfigId,
  for_compliance: question.for_compliance,
  question_context: question.question_context,
  number_of_files_required_per_answer:
    question?.number_of_files_required_per_answer || undefined,
  number_of_pages_required_per_file:
    question?.number_of_pages_required_per_file || undefined,
  help_text: question?.help_text || '',
  question_choices: question?.question_choices || [],
  member_types: question?.member_types || [],
});

export function patchStoreGroup({
  pageIndex,
  sectionIndex,
  groupIndex,
  group,
  successFunc,
  section = null,
}) {
  return async (dispatch) => {
    const { plainQits } = store.getState().questionMapping;

    // build group data to update the redux store
    const groupQits = await getQitsByQuestionGroup(group.id);
    const fetchedGroup = await getGroup(group);

    // compute new plain qits
    const newPlainQits = [...plainQits];
    for (const q of groupQits) {
      const existingIndex = newPlainQits.findIndex((pq) => pq.id === q.id);
      if (existingIndex >= 0) {
        newPlainQits[existingIndex] = q;
      } else {
        newPlainQits.push(q);
      }
    }

    const finalGroup = {
      ...group,
      ...fetchedGroup,
      questions: transFormIncomingQits(groupQits),
      edited: false,
      shouldOpen: true,
      question_choice: fetchedGroup?.question_choice?.id || null,
    };

    dispatch(slice.actions.setPlaingQits(newPlainQits));

    if (!section) {
      dispatch(
        slice.actions.updateGroupSuccess({
          pageIndex,
          sectionIndex,
          groupIndex,
          value: finalGroup,
          plainQits: newPlainQits,
        })
      );
    }
    if (section) {
      dispatch(
        slice.actions.addPageGroupSuccess({
          sectionIndex,
          section,
          plainQits: newPlainQits,
        })
      );
    }

    if (successFunc) {
      successFunc(finalGroup, groupIndex);
    }
  };
}

export function addGroup({
  group,
  sectionId,
  pageId,
  objectId,
  contentTypeId,
  successFunc,
  errFunc,
  sectionIndex,
  pageIndex,
  groupIndex,
  section,
}) {
  return async (dispatch) => {
    try {
      // create the group
      const { data } = await axios.post(
        'questionnaire/question_library/question_group/',
        buildPageGroupBody(group)
      );

      // build QITs http requests
      for (const question of group?.questions || []) {
        await axios.post(
          `questionnaire/question_library/question_intermediate_table/`,
          buildQitBody({
            question,
            groupId: data?.id,
            pageId,
            sectionId,
            objectId,
            contentTypeId,
          })
        );
      }

      dispatch(
        patchStoreGroup({
          pageIndex,
          sectionIndex,
          groupIndex,
          group: data,
          successFunc,
          section,
        })
      );
    } catch (error) {
      console.error(error);
      if (errFunc) {
        errFunc();
      }
    }
  };
}

export function editGroup({
  group,
  sectionId,
  pageId,
  objectId,
  contentTypeId,
  successFunc,
  errFunc,
  sectionIndex,
  pageIndex,
  groupIndex,
}) {
  return async (dispatch) => {
    try {
      // update group data
      const { data } = await axios.patch(
        `questionnaire/question_library/question_group/${group.id}/`,
        buildPageGroupBody(group)
      );
      for (const question of group?.questions || []) {
        const qitBody = buildQitBody({
          question,
          groupId: data?.id,
          pageId,
          sectionId,
          objectId,
          contentTypeId,
        });

        if (question?.qit?.id) {
          await axios.patch(
            `questionnaire/question_library/question_intermediate_table/${question?.qit?.id}/`,
            qitBody
          );
        } else {
          await axios.post(
            `questionnaire/question_library/question_intermediate_table/`,
            qitBody
          );
        }
      }

      dispatch(
        patchStoreGroup({
          pageIndex,
          sectionIndex,
          groupIndex,
          group: data,
          successFunc,
        })
      );
    } catch (error) {
      console.error(error);
      if (errFunc) {
        errFunc();
      }
    }
  };
}

const buildGroupRequest = (groups = []) => {
  // build http requests to get all the section groups qits
  const requests = [];
  for (let i = 0; i < groups?.length; i++) {
    const group = groups[i];
    requests.push(
      axios.get(
        `questionnaire/question_library/question_intermediate_table/?question_group=${group?.id}&fields=id`
      )
    );
  }
  return requests;
};

const resetPagesData = (pages = []) => {
  const finalPages = [];

  for (let i = 0; i < pages.length; i++) {
    const page = { ...pages[i] };
    let groups = [...(page?.question_groups || [])];
    groups = groups.map((group) => ({ ...group, questions: [] }));
    page.question_groups = groups;
    finalPages.push(page);
  }

  return finalPages;
};

export function updateAllSectionQits({
  section,
  successFunc,
  errorFunc,
  currentSectionId,
}) {
  return async (dispatch) => {
    const modifiedSection = { ...section };
    const pages = modifiedSection?.pages || [];
    const groups = pages.reduce(
      (acc, curr) => acc.concat(curr?.question_groups || []),
      []
    );

    try {
      // get all the section qits in order to patch the [section] field
      let qits = await Promise.all(buildGroupRequest(groups));
      qits = qits.reduce((acc, curr) => acc.concat(curr.data), []);

      // build http requests to patch all the qits
      const qitsRequests = qits.map((qit) =>
        axios.patch(
          `questionnaire/question_library/question_intermediate_table/${qit.id}/`,
          { section: section.id }
        )
      );
      await Promise.all(qitsRequests);
      modifiedSection.pages = resetPagesData(section?.pages);
      if (successFunc) {
        successFunc(modifiedSection);
      }
    } catch (error) {
      console.error(error);
      if (errorFunc) {
        errorFunc(error);
      }
    }
  };
}

export function updateAllPageQits({
  page,
  successFunc,
  errorFunc,
  currentPageId,
  sectionId,
}) {
  return async (dispatch) => {
    const modifiedPage = { ...page, wasChangedByUser: false };

    try {
      let qits = await Promise.all(
        buildGroupRequest(modifiedPage?.question_groups || [])
      );
      qits = qits.reduce((acc, curr) => acc.concat(curr.data), []);

      // build http requests to patch all the qits
      const qitsRequests = qits.map((qit) =>
        axios.patch(
          `questionnaire/question_library/question_intermediate_table/${qit.id}/`,
          { page: page.id }
        )
      );
      await Promise.all(qitsRequests);
      modifiedPage.question_groups = (modifiedPage?.question_groups || []).map(
        (group) => ({
          ...group,
          questions: (group?.questions || []).map((question) => ({
            ...question,
            qit: {
              ...(question?.qit || {}),
              page: page.id,
            },
          })),
        })
      );

      if (successFunc) {
        successFunc(modifiedPage);
      }
    } catch (error) {
      console.error(error);
      if (errorFunc) {
        errorFunc(error);
      }
    }
  };
}

export function patchGroupField({
  groupId,
  pageIndex,
  sectionIndex,
  groupIndex,
  value,
  successFunc,
  errorFunc,
  rawValue,
}) {
  return async (dispatch) => {
    try {
      await axios.patch(
        `questionnaire/question_library/question_group/${groupId}/`,
        value
      );
      dispatch(
        slice.actions.updateGroupSuccess({
          pageIndex,
          sectionIndex,
          groupIndex,
          value: { ...value, ...rawValue },
          onlyUpdateGroupProp: true,
        })
      );
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      if (errorFunc) {
        errorFunc();
      }
      console.error(error);
    }
  };
}

export function patchGroupQit({
  id,
  sectionIndex,
  pageIndex,
  groupIndex,
  qitContextIndex = null,
  qitIndex,
  value,
  successFunc,
  errorFunc,
}) {
  return async (dispatch) => {
    try {
      await axios.patch(
        `questionnaire/question_library/question_intermediate_table/${id}/`,
        value
      );
      const { data } = await axios.get(
        `questionnaire/question_library/question_intermediate_table/${id}/?expand=${qitExpands}&fields=${qitFields}`
      );
      dispatch(
        slice.actions.updateQitSuccess({
          sectionIndex,
          pageIndex,
          groupIndex,
          qitContextIndex,
          qitIndex,
          value: mapQits(data),
        })
      );
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      if (errorFunc) {
        errorFunc();
      }
      console.error(error);
    }
  };
}

export function buildReorderQitsPayload(sectionsTree = []) {
  let finalQits = [];

  for (let x = 0; x < sectionsTree.length; x++) {
    const section = sectionsTree[x];
    for (let y = 0; y < section.pages.length; y++) {
      const page = section.pages[y];
      for (let z = 0; z < page.question_groups.length; z++) {
        const group = page.question_groups[z];
        const resultQits = group?.questions.reduce(reduceQits, []);

        finalQits = finalQits.concat(resultQits);
      }
    }
  }

  finalQits = finalQits.map((el, i) => ({ ...el.qit, order: i }));

  return {
    newQits: finalQits,
    payload: finalQits.map((qit) => ({ qit: qit.id, order: qit.order })),
  };
}

export function switchSections({
  oldIndex,
  newIndex,
  sections,
  contentTypeId,
  objectId,
  successFunc,
  errorFunc,
}) {
  return async (dispatch) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const targetSection = sections[oldIndex] ? { ...sections[oldIndex] } : null;

    if (targetSection) {
      updatedSections.splice(oldIndex, 1);
      updatedSections.splice(newIndex, 0, targetSection);
      const reorderedQits = buildReorderQitsPayload(updatedSections);
      const newSections = buildSectionsTree(reorderedQits.newQits);
      dispatch(
        reorderQits({
          reorderedQits: reorderedQits.payload,
          sections: newSections,
          contentTypeId,
          objectId,
          successFunc,
          errorFunc,
          plainQits: reorderedQits.newQits,
        })
      );
    }
  };
}

export function switchPages({
  oldIndex,
  newIndex,
  sectionIndex,
  sections,
  contentTypeId,
  objectId,
  successFunc,
  errorFunc,
}) {
  return async (dispatch) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const targetPage = updatedSections[sectionIndex].pages[oldIndex]
      ? { ...updatedSections[sectionIndex].pages[oldIndex] }
      : null;

    if (targetPage) {
      updatedSections[sectionIndex].pages.splice(oldIndex, 1);
      updatedSections[sectionIndex].pages.splice(newIndex, 0, targetPage);

      const reorderedQits = buildReorderQitsPayload(updatedSections);
      const newSections = buildSectionsTree(reorderedQits.newQits);

      dispatch(
        reorderQits({
          reorderedQits: reorderedQits.payload,
          sections: newSections,
          contentTypeId,
          objectId,
          successFunc,
          errorFunc,
          plainQits: reorderedQits.newQits,
        })
      );
    }
  };
}

export function switchQits({
  oldIndex,
  newIndex,
  sectionIndex,
  pageIndex,
  groupIndex,
  sections,
  contentTypeId,
  objectId,
  successFunc,
  errorFunc,
}) {
  return async (dispatch) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const targetQuestion = updatedSections[sectionIndex]?.pages[pageIndex]
      ?.question_groups[groupIndex].questions[oldIndex]
      ? {
          ...updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
            groupIndex
          ].questions[oldIndex],
        }
      : null;

    if (targetQuestion) {
      updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
        groupIndex
      ].questions.splice(oldIndex, 1);
      updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
        groupIndex
      ].questions.splice(newIndex, 0, targetQuestion);

      const reorderedQits = buildReorderQitsPayload(updatedSections);
      const newSections = buildSectionsTree(reorderedQits.newQits);

      dispatch(
        reorderQits({
          reorderedQits: reorderedQits.payload,
          sections: newSections,
          contentTypeId,
          objectId,
          successFunc,
          errorFunc,
          plainQits: reorderedQits.newQits,
        })
      );
    }
  };
}

export function switchGroups({
  oldIndex,
  newIndex,
  sectionIndex,
  pageIndex,
  sections,
  contentTypeId,
  objectId,
  successFunc,
  errorFunc,
}) {
  return async (dispatch) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const targetGroup = updatedSections[sectionIndex]?.pages[pageIndex]
      ?.question_groups[oldIndex]
      ? {
          ...updatedSections[sectionIndex]?.pages[pageIndex]?.question_groups[
            oldIndex
          ],
        }
      : null;

    if (targetGroup) {
      updatedSections[sectionIndex].pages[pageIndex].question_groups.splice(
        oldIndex,
        1
      );
      updatedSections[sectionIndex].pages[pageIndex].question_groups.splice(
        newIndex,
        0,
        targetGroup
      );

      const reorderedQits = buildReorderQitsPayload(updatedSections);
      const newSections = buildSectionsTree(reorderedQits.newQits);

      dispatch(
        reorderQits({
          reorderedQits: reorderedQits.payload,
          sections: newSections,
          contentTypeId,
          objectId,
          successFunc,
          errorFunc,
          plainQits: reorderedQits.newQits,
        })
      );
    }
  };
}

export function reorderQits({
  reorderedQits,
  sections,
  certificationConfigId,
  successFunc,
  errorFunc,
  plainQits,
}) {
  const { sections: prevSections, plainQits: prevPlainQits } =
    store.getState()?.questionMapping || {};

  return async (dispatch) => {
    const invalidSchema = plainQits.some((currentQit, i) => {
      const topQits = plainQits.slice(0, i); // get qits from 0 to Question Index

      // if the question doesn't have a parent question is skipped
      if (!currentQit?.question_group?.question?.id) {
        return false;
      }

      // if the parent question is above their children questions the final order IS OK
      const parentQuestion = topQits.find(
        (topQit) =>
          topQit?.question?.id === currentQit?.question_group?.question?.id
      );

      // if the parent question IS NOT above the child question the final order is invalid
      return !parentQuestion;
    });

    try {
      if (invalidSchema) {
        errorFunc(plainQits);
        return;
      }

      dispatch(slice.actions.getQitsSuccess({ sections, plainQits }));
      await axios.post(
        `questionnaire/question_library/update_order_qits/?certification_config=${certificationConfigId}`,
        reorderedQits
      );
      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getQitsSuccess({
          sections: prevSections,
          plainQits: prevPlainQits,
        })
      );
      if (errorFunc) {
        errorFunc(error);
      }
    }
  };
}

export function getAllDits(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startDitsLoading());
    try {
      const response = await axios.get(
        `certification/dit/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getDitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

const replaceQit = (actualQuestion, newQuestion) => {
  return {
    ...actualQuestion,
    name: newQuestion?.name,
    qit: {
      ...actualQuestion.qit,
      question: {
        ...actualQuestion.qit.question,
        ...newQuestion,
      },
    },
  };
};

const patchTreeQuestions = (sections, updatedQuestion) => {
  const clonedSections = [...sections];

  for (let x = 0; x < clonedSections.length; x++) {
    let section = clonedSections[x];

    for (let y = 0; y < section.pages.length; y++) {
      const page = section.pages[y];
      for (let z = 0; z < page.question_groups.length; z++) {
        const group = page.question_groups[z];

        for (let a = 0; a < group.questions.length; a++) {
          const question = group.questions[a];

          if (group.questions[a]?.qit?.question?.id !== updatedQuestion?.id) {
            continue;
          }

          if (
            !question.contextId &&
            group.questions[a]?.qit?.question?.id === updatedQuestion?.id
          ) {
            group.questions[a] = replaceQit(
              group.questions[a],
              updatedQuestion
            );
          }

          if (question.contextId) {
            for (let b = 0; b < question.qits.length; b++) {
              question.qits[b] = replaceQit(question.qits[b], updatedQuestion);
            }
          }
        }
      }
    }
  }
  return clonedSections;
};
