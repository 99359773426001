import { store } from '../../redux/store';
import {
  buildTooltipDom,
  CUSTOM_DATA_KEY,
  parseJson,
} from '../ProntoHousingAdministration/QuestionMapping/utils';

const docManagerConfig = {
  licenseKey: process.env.REACT_APP_PDF_LICENSE_KEY,
  path: '/webviewer/lib',
  initialDoc: '',
  fullAPI: true,
  disableFlattenedAnnotations: true,
  disabledElements: [
    'pageInsertionHeader',
    'insertPageAbove',
    'insertPageBelow',
    'toolbarGroup-Forms',
    'signaturePanelButton',
    'outlinesPanelButton',
    'annotationCommentButton',
    'linkButton',
    'fileAttachmentToolGroupButton',
    'replacePage',
    'documentControl',
  ],
  css: '/pdf_doc_manager.css' /* https://pdfjs.community/t/extra-space-appears-below-toolbar-in-small-screen-size/966 */,
};

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

/**
 * Any global configuration for the document manager must be placed on this function
 */
export function initializeDocumentManager(
  instance,
  {
    user,
    isMappingTool = false,
    disabledFeatures = [],
    disabledTools = [],
    openLeftPanelOnMount = true,
    enableTooltipInfo = false,
    disableAllTools = false,
    readOnly = false,
  }
) {
  if (!instance) {
    return;
  }

  const { UI, Core } = instance;
  const { documentViewer, Annotations, annotationManager } = Core;

  // set username for annotations
  annotationManager.setCurrentUser(user?.displayName || 'Guest');
  // set user as admin to give permissions to edit all the annotations
  annotationManager.promoteUserToAdmin();

  // disable reply action for free text annotations
  instance.UI.disableReplyForAnnotations((annotation) => annotation);

  // set property color on the annotation create text
  const propertyFormFillColor =
    store.getState()?.property?.selectedProperty?.form_fill_color;
  const propertyFormFillRgbColor = (propertyFormFillColor &&
    hexToRgb(propertyFormFillColor)) || [0, 0, 0];

  // set default styles for free text too annotation
  if (isMappingTool) {
    // set styles for the mapping tool
    instance.Core.documentViewer.getTool('AnnotationCreateFreeText').setStyles({
      StrokeThickness: 1,
      StrokeColor: new instance.Annotations.Color(63, 115, 179),
      TextColor: new instance.Annotations.Color(63, 115, 179),
      FillColor: new instance.Annotations.Color(255, 255, 255),
      FontSize: '10pt',
      TextVerticalAlign: 'bottom',
      LockedContents: true,
    });
  } else {
    documentViewer
      .getTool('AnnotationCreateFreeText')
      .setStyles((currentStyle) => ({
        StrokeThickness: 0,
        FontSize: '14pt',
        TextColor: new Annotations.Color(...propertyFormFillRgbColor),
        FillColor: new Annotations.Color(),
        Font: 'Helvetica',
      }));
  }

  // configure UI interface and available features
  instance.disableFeatures([
    'Print',
    'FilePicker',
    'LocalStorage',
    'Download',
    ...disabledFeatures,
  ]);
  if (disableAllTools) {
    UI.disableTools();
    UI.disableElements(['ribbons', 'toolsHeader', 'toggleNotesButton']);
    Core.annotationManager.enableReadOnlyMode();
  } else {
    // set user name for annotations
    annotationManager.setCurrentUser(user?.displayName || 'Guest');
    // set user as admin to give permissions to edit all the annotations
    annotationManager.promoteUserToAdmin();

    UI.disableTools([
      'SignatureCreateTool',
      'SignatureFormFieldCreateTool',
      'AnnotationCreateSignature',
      'SignatureFormFieldCreateTool',
      'FileAttachmentCreateTool',
      ...disabledTools,
    ]);
    UI.setToolbarGroup('toolbarGroup-View');
  }

  // open left panel on mount
  if (openLeftPanelOnMount) {
    UI.openElements(['leftPanel']);
  }

  // set fit mode and hide the fields when the component load a document
  Core.documentViewer.addEventListener('documentLoaded', async () => {
    const FitMode = instance?.FitMode;
    instance.setFitMode(FitMode?.FitWidth);

    documentViewer.getAnnotationsLoadedPromise().then(() => {
      annotationManager.getFieldManager().forEachField((field) => {
        field.setVisible(false);
      });
    });
  });

  // enable tooltip builder
  if (enableTooltipInfo) {
    instance.UI.setAnnotationContentOverlayHandler((annotation) => {
      const CustomData = parseJson(annotation.getCustomData(CUSTOM_DATA_KEY));
      let node = null;

      if (CustomData?.tooltipData) {
        node = buildTooltipDom(CustomData.tooltipData);
      }

      return node;
    });
  }

  if (readOnly) {
    UI.disableFeatures([
      'ThumbnailMultiselect',
      'ThumbnailReordering',
      'ThumbnailMerging',
    ]);
    documentViewer.enableReadOnlyMode();
    annotationManager.enableReadOnlyMode();
  }
}

export default docManagerConfig;
