/* eslint-disable */
import axios from 'src/utils/axios';
import { fDate } from './formatTime';

export const keyBeautify = (keys) => {
  const nueva = keys.replace(/_|#|-|@|<>/g, ' ');
  let upper = nueva.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
  upper = upper.replace('Paneldynamic ', '');
  return upper;
};

export const answerFilter = (question, responses) => {
  /*
   * Search, obtain and gives back the answer
   */

  if (!question || !responses) {
    return '-';
  } else {
    let fixedValue = null;
    let answer = Object.entries(responses).filter(([key, value]) => {
      if (question.name === key) {
        switch (question.type) {
          case 'bootstrapdatepicker':
            fixedValue = fDate(value);
            break;
          default:
            fixedValue = value;
            break;
        }

        return fixedValue;
      }
      return fixedValue;
    });

    if (answer && answer.flat()[1]) {
      return fixedValue;
    } else if (fixedValue) {
      return fixedValue;
    } else {
      return null;
    }
  }
};

export const conditionQuestionFinder = (groupData, allSurveys) => {
  /**
   * Check all of the questions and returns the formikId and value that should display
   */

  if (groupData && groupData.question && allSurveys) {
    let foundSurvey = {
      survey: {},
      questionChoice: null,
    };

    const foundQuestion = allSurveys.filter(
      (survey) => survey.qit.question.id === groupData.question
    )[0];
    foundSurvey.questionChoice = groupData.question_choice;
    foundSurvey.survey = foundQuestion;
    return foundSurvey;
  }

  return null;
};

const checkFoundSurvey = (foundSurvey, group, allValues) => {
  if (foundSurvey) {
    if (
      (foundSurvey.survey &&
        allValues[foundSurvey.survey.formikId] &&
        allValues[foundSurvey.survey.formikId] ===
          foundSurvey.questionChoice) ||
      (foundSurvey.survey &&
        allValues[foundSurvey.survey.formikId] &&
        allValues[foundSurvey.survey.formikId].includes(
          foundSurvey.questionChoice
        ))
    ) {
      group.groupData.shouldDisplay = true;
    } else {
      group.groupData.shouldDisplay = false;
    }
  } else {
    group.groupData.shouldDisplay = true;
  }
};

const fetchVisibleIf = async (groupId, surveyId) => {
  const { data } = await axios.get(
    `questionnaire/question_library/question_group/${groupId}/visible/?survey=${surveyId}`
  );

  if (data) {
    return data.visible;
  } else {
    return false;
  }
};

export const conditionMatcher = async (
  allGroups,
  allSurveys,
  allValues,
  memberAge,
  shouldFetch = false
) => {
  /*
   * Check if the group should display, returns all the groups with the property "should display: bool"
   */

  if (!allGroups) {
    return;
  }

  let allGroupsWithMatchCondition = [...allGroups];

  for (let group of allGroupsWithMatchCondition) {
    const foundSurvey = conditionQuestionFinder(group.groupData, allSurveys);

    /*
     Para el becario:
     Found survey es el survey_record que tiene todas las respuestas, y las respuestas son las que indican si el grupo debe verse o no
     El problema es que foundSurvey no existe aqui, porque solo busca dentro de Esta pagina, por eso te regresa Null
     */

    if (shouldFetch && foundSurvey?.questionChoice && !foundSurvey.survey) {
      /*
       * Aqui revisamos tres cosas,
       * 1. que se deba hacer fetch
       * 2. que el grupo tenga una condicion
       * 3. Que el survey_record no este en la pagina
       * Si no, va a hacer fetch en todos los grupos y no te va a mostrar nada
       * */
      if (group.groupData.shouldDisplay === undefined) {
        // Esto es para evitar que haga fetch por segunda vez
        await fetchVisibleIf(group.groupData.id, allSurveys[0].survey).then(
          (response) => {
            group.groupData.shouldDisplay = response;
            // Tuve que hacer un cagadero. le pase la funcion que re renderice despues de recibir la respuesta
            shouldFetch(10);
          }
        );
      }
    } else if (group.groupData.age_min || group.groupData.age_max) {
      let minAge = group.groupData.age_min ? group.groupData.age_min : 0;
      let maxAge = group.groupData.age_max ? group.groupData.age_max : 200;

      if (memberAge > minAge && memberAge <= maxAge) {
        checkFoundSurvey(foundSurvey, group, allValues);
      } else {
        group.groupData.shouldDisplay = false;
      }
    } else {
      checkFoundSurvey(foundSurvey, group, allValues);
    }
  }

  return allGroupsWithMatchCondition;
};

export const questionPackageBuilder = (
  question,
  responses,
  pageIndex,
  panelDynamicCondition,
  panelDynamicResponses,
  summary
) => {
  /*
   * Build question with it's necessary properties
   */

  let condition = null;
  let response = '-';

  if (panelDynamicCondition) {
    condition = panelDynamicCondition;
  } else {
    condition = question.visble_if;
  }

  if (question.answers && question.answers.length > 0 && !summary) {
    if (panelDynamicResponses) {
      let answer = '-';
      if (panelDynamicResponses[question.name]) {
        answer = panelDynamicResponses[question.name];
      }
      response = answer;
    } else if (question.answers.length > 0) {
      let answer = question.answers.sort(
        (a, b) =>
          new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime()
      )[0];
      if (
        question.type === 'bootstrapdatepicker' ||
        question.type === 'date' ||
        question.name === 'date_of_birth'
      ) {
        response = fDate(answer.value);
      } else {
        response = answer.value;
      }
    }
  }

  return {
    id: question.id,
    name: question.name,
    rawTitle: question.title,
    title: keyBeautify(question.name),
    isRequired: question.isRequired ? question.isRequired : false,
    answer: summary ? answerFilter(question, responses) : response,
    visibleIf: question.visibleIf ? question.visibleIf : false,
    display: conditionMatcher(condition, responses),
    pageIndex: pageIndex,
    group: question.group ? question.group : '',
    type: question.type ? question.type : '',
    files: responses[question?.name] ? responses[question?.name] : '',
  };
};

export const filterQuestionsBySection = (section, responses, summary) => {
  /*
   * Filter and build all questions in a section, even if they are inside panelDynamic
   **/
  let allQuestionfromSection = [];

  section.survey_pages.forEach((page, pageIndex) => {
    page.survey_questions.forEach((question) => {
      if (question.name && question.html === '') {
        if (
          /paneldynamic/.test(question.name) &&
          responses &&
          responses[question.name] &&
          !summary
        ) {
          responses[question.name].forEach((panelDynamicResponses) => {
            question.sub_questions.forEach((panelDynamicQuestions) => {
              if (panelDynamicQuestions.html === '') {
                allQuestionfromSection.push(
                  questionPackageBuilder(
                    panelDynamicQuestions,
                    responses,
                    pageIndex,
                    null,
                    panelDynamicResponses,
                    summary
                  )
                );
              }
            });
          });
        } else if (
          question.html === '' &&
          !/paneldynamic/.test(question.name)
        ) {
          allQuestionfromSection.push(
            questionPackageBuilder(
              question,
              responses,
              pageIndex,
              null,
              null,
              summary
            )
          );
        }
      }
    });
  });

  return allQuestionfromSection;
};

export const groupBuilder = (section) => {
  let groups = [];
  let currentGroup = '';

  if (section) {
    section.pages.forEach((page) => {
      page.elements.forEach((question) => {
        if (question.group && question.group !== currentGroup) {
          currentGroup = question.group;
          let group = {
            groupName: currentGroup,
          };

          groups.push(group);
        }
      });
    });
  }

  return groups;
};

export const allSurveySectionQuestionBuilder = (user, section, responses) => {
  if (section) {
    return {
      sectionName: section.name,
      // groups: groupBuilder(section.section),
      firstName: user.first_name,
      lastName: user.last_name,
      sectionQuestions: filterQuestionsBySection(section, responses),
    };
  }
  return {};
};

export const allSurveyQuestionBuilder = (member, survey, responses) => {
  /*
   * Filter and obtain all the questions in the whole questionnaire
   */
  const allSections = [];

  if (survey && survey.survey_sections) {
    survey.survey_sections.forEach((section, index) => {
      allSections.push({
        sectionName: section.name,
        // groups: groupBuilder(section.section),
        firstName: member.first_name,
        lastName: member.last_name,
        sectionQuestions: filterQuestionsBySection(section, responses),
      });
    });
  }
  return allSections;
};

export const allSectionsQuestionBuilder = (user, responses, summary) => {
  /*
   * Filter and obtain all the questions in the whole questionnaire
   */
  const allSections = [];
  let questionnaire;
  if (user.householdmember_compliance.length > 1) {
    questionnaire = user.householdmember_compliance.filter(
      (member) => member.survey_id
    )[0];
  } else {
    questionnaire = user.householdmember_compliance[0];
  }

  if (questionnaire && questionnaire.survey_id.survey_sections) {
    questionnaire.survey_id.survey_sections.forEach((section, index) => {
      allSections.push({
        sectionName: section.name,
        // groups: groupBuilder(section.section),
        firstName: user.first_name,
        lastName: user.last_name,
        sectionQuestions: filterQuestionsBySection(section, responses, summary),
      });
    });
  }

  return allSections;
};

export const countRequiredQuestions = (allQuestions) => {
  let unnansweredRequiredQuestionsCount = 0;
  let unnansweredQuestionsCount = 0;

  allQuestions.forEach((question) => {
    if (question.display && question.isRequired && question.answer === '-') {
      unnansweredRequiredQuestionsCount += 1;
    }

    if (question.answer === '-') {
      unnansweredQuestionsCount += 1;
    }
  });

  let status;

  if (unnansweredQuestionsCount === allQuestions.length) {
    status = 'unanswered';
  } else if (unnansweredRequiredQuestionsCount > 0) {
    status = 'incomplete';
  } else {
    status = 'complete';
  }

  return {
    unnansweredQuestionsCount,
    unnansweredRequiredQuestionsCount,
    status,
  };
};

export const unnansweredOrIncompleteQuestionnaireSection = (section) => {
  if (section) {
    const sectionsStatus = countRequiredQuestions(section.sectionQuestions);
    return {
      sectionName: section.sectionName,
      sectionQuestions: section.sectionQuestions,
      required: sectionsStatus.unnansweredRequiredQuestionsCount,
      unnanswered: sectionsStatus.unnansweredQuestionsCount,
      status: sectionsStatus.status,
      groups: section.groups,
      firstName: section.firstName,
      lastName: section.lastName,
    };
  }

  return {};
};

export const unnansweredOrIncompleteQuestionnaire = (allSections) => {
  let sectionWithStatus = [];

  if (allSections && allSections.length > 0) {
    allSections.forEach((section) => {
      const sectionsStatus = countRequiredQuestions(section.sectionQuestions);
      sectionWithStatus.push({
        sectionName: section.sectionName,
        sectionQuestions: section.sectionQuestions,
        required: sectionsStatus.unnansweredRequiredQuestionsCount,
        unnanswered: sectionsStatus.unnansweredQuestionsCount,
        status: sectionsStatus.status,
        groups: section.groups,
        firstName: section.firstName,
        lastName: section.lastName,
      });
    });
  }

  return sectionWithStatus;
};

export const getAllSections = (details) => {
  /*
   * Obtain and set all sections' name and index
   **/
  let surveySections;
  let sections = [];

  if (details && details.householdmember_compliance) {
    if (details.householdmember_compliance.length > 1) {
      surveySections =
        details.householdmember_compliance[1]?.survey_id?.survey_sections;
    } else {
      surveySections =
        details.householdmember_compliance[0]?.survey_id?.survey_sections;
    }

    surveySections.forEach((section, index) => {
      sections.push({
        sectionName: section.name,
        responses: {},
        index: index,
      });
    });
  }

  return sections;
};

export const getResponsesByUser = (responses = [], allSections = {}) => {
  /*
   * Obtain and set all users questionnaire's answers, into one array
   **/

  const allSectionsWithResponses = [...allSections];

  let sectionsToReturn = [];
  allSectionsWithResponses.forEach((sectionToSearch) => {
    let sectionWithAnswers = { ...sectionToSearch };
    sectionWithAnswers.responses = responses;
    sectionsToReturn.push(sectionWithAnswers);
  });

  return sectionsToReturn;
};

export const setAllUsersCardStatus = (users) => {
  /*
   * Obtain and set all users questionnaire's status and answers, into one array
   **/

  let userStatusAndAnswer = [];

  if (users) {
    users.forEach((user) => {
      const userSectionsArr = getAllSections(user);
      const userSectionWithAnswers = getResponsesByUser(
        user.householdmember_compliance,
        userSectionsArr
      );
      userStatusAndAnswer.push(userSectionWithAnswers);
    });
  }

  return userStatusAndAnswer;
};
