import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, InputAdornment, OutlinedInput, Toolbar } from '@mui/material';
import { MCircularProgress } from 'src/components/@material-extend';
// icons
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(() => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TemplateListToolbar.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
};

// ----------------------------------------------------------------------

export default function TemplateListToolbar({ search, onSearch, isLoading }) {
  return (
    <RootStyle>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SearchStyle
          value={search}
          onChange={(event) => onSearch(event.target.value)}
          placeholder="Search template..."
          startAdornment={
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          }
        />
        {isLoading && (
          <MCircularProgress size={24} color="primary" sx={{ ml: 2 }} />
        )}
      </Box>
    </RootStyle>
  );
}
