import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
// redux
import { useDispatch } from '../../redux/store';
//
import Markdown from '../Markdown';
import Scrollbar from '../Scrollbar';
import MailDetailsToolbar from './MailDetailsToolbar';
import ConversationDetailItemSkeleton from './ConversationSkeleton';
import { shallowEqual, useSelector } from 'react-redux';
import {
  FILTER_IDS,
  getFullConversation,
  getSelectedConversationMessages,
  setSelectedConversation,
  setSelectedFilter,
  updateSelectedConversation,
  updateSelectedConversationMsg,
} from 'src/redux/slices/conversationsV2';
import { nanoid } from 'nanoid';
import MailDetailsRow from './MailDetailsRow';
import ChatRoomAttachmentDialog from './ChatRoomAttachmentDialog';
import {
  EXPAND_CONVERSATION,
  EXPAND_MESSAGES,
  FIELDS_CONVERSATION,
  FIELDS_MESSAGES,
  getMessageById,
  selectMessage,
} from './utils';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import ConversationDocumentManager from '../ConversationManager/ConversationDocumentManager';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const MarkdownWrapperStyle = styled('div')(({ theme }) => ({
  '& > p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid red',
  },
}));

// ----------------------------------------------------------------------

const MailDetails = ({ handleOpenCompose }) => {
  const dispatch = useDispatch();
  const { conversationKey } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const conversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );

  const sidebarLabels = useSelector(
    ({ conversationsV2 }) => conversationsV2?.labels,
    shallowEqual
  );
  const { user } = useAuth();
  const { pathname } = useLocation();
  const isUserDashboard = pathname.startsWith('/workbench/user');
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );

  const isLoading = useSelector(
    ({ conversationsV2 }) => conversationsV2?.isLoading,
    shallowEqual
  );
  const update = useSelector(
    ({ conversationsV2 }) => conversationsV2?.update,
    shallowEqual
  );

  const [collapsedElements, setCollapsedElements] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const intervalRef = useRef(null);

  const updateCollapsedItems = useCallback(
    (msgId) => {
      if (collapsedElements.includes(msgId)) {
        setCollapsedElements((st) => st.filter((id) => id !== msgId));
      } else {
        setCollapsedElements((st) => [...st, msgId]);
      }
    },
    [collapsedElements]
  );

  const updateMessage = useCallback(async () => {
    if (!selectedAttachment?.messageId || !conversation?.id) {
      return;
    }
    const newMessage = await getMessageById(selectedAttachment.messageId);

    dispatch(
      updateSelectedConversationMsg({
        messageId: selectedAttachment.messageId,
        conversationId: conversation.id,
        value: newMessage,
      })
    );
  }, [conversation?.id, dispatch, selectedAttachment?.messageId]);

  const handleSelectMessage = useCallback(
    (e, msg) => {
      selectMessage(e, {
        msg,
        conversationId: conversation?.id,
        dispatch,
        enqueueSnackbar,
      });
    },
    [conversation?.id, dispatch, enqueueSnackbar]
  );

  const fetchFullConversation = useCallback(
    (conversationId, showLoader = true) => {
      dispatch(
        getFullConversation(
          conversationId,
          {
            expand: EXPAND_CONVERSATION,
            fields: FIELDS_CONVERSATION,
          },
          conversation?.isUnread,
          showLoader
        )
      );
    },
    [conversation?.isUnread, dispatch]
  );

  const fetchConversationMessages = useCallback(() => {
    dispatch(
      getSelectedConversationMessages(conversationKey, {
        fields: FIELDS_MESSAGES,
        expand: EXPAND_MESSAGES,
      })
    );
  }, [conversationKey, dispatch]);

  const allAttachments = useMemo(
    () =>
      conversation?.messages?.length > 0
        ? conversation.messages.reduce(
            (acc, curr) => [...acc, ...curr.files],
            []
          )
        : [],
    [conversation?.messages]
  );

  useEffect(() => {
    clearInterval(intervalRef.current);
    if (!conversation?.id) {
      return;
    }

    intervalRef.current = setInterval(() => {
      if (!conversation?.pdf_file_url) {
        axios
          .get(
            `communication/new_conversation/conversation/${conversation.id}/?fields=pdf_file_url`
          )
          .then((response) => {
            if (response.status === 200 && response.data?.pdf_file_url) {
              dispatch(
                updateSelectedConversation({
                  value: { pdf_file_url: response.data.pdf_file_url },
                  conversationId: conversation.id,
                })
              );
            }
          });
      }

      fetchConversationMessages();
    }, 30000);

    return () => clearInterval(intervalRef.current);
  }, [
    conversation.id,
    conversation?.pdf_file_url,
    dispatch,
    fetchConversationMessages,
  ]);

  useEffect(() => {
    if (update && conversationKey) {
      fetchFullConversation(conversationKey);
    }
  }, [conversationKey, fetchFullConversation, update]);

  useEffect(() => {
    if (conversationKey) {
      fetchFullConversation(conversationKey);
    }
  }, [conversationKey, fetchFullConversation]);

  // remove selected conversation when the component is unmounted
  useEffect(() => {
    return () => {
      dispatch(
        setSelectedConversation({
          conversation: null,
          conversationHadNewMessages: false,
        })
      );
    };
  }, [dispatch]);

  // if the sidebar filter is not correct, set the corresponding filter for this conversation type
  useEffect(() => {
    const filterId = !conversation?.certification
      ? FILTER_IDS.unassigned
      : FILTER_IDS.all;
    if (
      conversation?.conversation_type !==
        selectedFilter?.filters?.conversation_type ||
      filterId !== selectedFilter?.id
    ) {
      dispatch(
        setSelectedFilter({
          ...sidebarLabels[filterId],
          filters:
            {
              ...sidebarLabels[filterId].filters,
              ...(isUserDashboard
                ? { as_specialist: true, file_owner: user?.id }
                : {}),
            } || {},
        })
      );
    }
  }, [
    conversation?.certification,
    conversation?.conversation_type,
    dispatch,
    isUserDashboard,
    selectedFilter?.filters?.conversation_type,
    selectedFilter?.id,
    sidebarLabels,
    user?.id,
  ]);

  if (!conversation) {
    return null;
  }

  if (isLoading) {
    return (
      <RootStyle>
        <ConversationDetailItemSkeleton />
      </RootStyle>
    );
  }

  return (
    <RootStyle>
      <MailDetailsToolbar
        selectMessage={handleSelectMessage}
        firstMessage={conversation?.messages[0]}
        conversation={conversation}
        handleOpenCompose={handleOpenCompose}
        isDone={
          conversation?.certification
            ? conversation?.certification.is_done
            : true
        }
      />

      <Divider />

      <Scrollbar
        sx={{
          flexGrow: 1,
          height: '100%',
          '& .simplebar-content': { height: '100%' },
        }}
      >
        {conversation?.messages[0] && (
          <Box sx={{ px: { xs: 3, md: 5 } }}>
            <Typography variant="h3" gutterBottom>
              {conversation?.subject}
            </Typography>
          </Box>
        )}

        {conversation.messages.length >= 1 ? (
          conversation.messages.map((msg, i) => (
            <MarkdownWrapperStyle key={msg?.id || nanoid()}>
              {i > 0 && (
                <MailDetailsRow
                  conversation={conversation}
                  selectMessage={handleSelectMessage}
                  msg={msg}
                  isOpen={collapsedElements.includes(msg?.id)}
                  updateCollapsedItems={updateCollapsedItems}
                  isDone={
                    conversation?.certification
                      ? conversation?.certification.is_done
                      : true
                  }
                />
              )}

              {/* FIRST EMAIL BLOCK */}
              {(i === 0 || (i > 0 && collapsedElements.includes(msg?.id))) && (
                <>
                  <Box sx={{ px: { xs: 3, md: 5 }, py: 3 }}>
                    <Markdown
                      children={msg?.content.replaceAll('\n', '<br/>')}
                    />
                  </Box>
                  {msg?.files?.length > 0 && (
                    <ConversationDocumentManager
                      conversationId={conversation?.id}
                      certificationId={conversation?.certification?.id}
                      certificationConfig={
                        conversation?.certification?.certification_config
                      }
                      msg={msg}
                      pdfFileUrl={conversation?.pdf_file_url}
                      files={msg.files || []}
                      attachments={allAttachments || []}
                      members={
                        conversation?.certification?.household
                          ?.household_members || []
                      }
                      subject={conversation?.subject}
                    />
                  )}
                </>
              )}
              <Divider sx={{ mb: 1 }} />
            </MarkdownWrapperStyle>
          ))
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="column"
            sx={{ height: '100%' }}
          >
            <Typography sx={{ color: 'gray' }}>
              It looks like someone started a conversation but never sent a
              message on this thread.
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{ mt: 1, mb: 1 }}
              onClick={handleOpenCompose}
              startIcon={<ReplyIcon />}
            >
              Reply
            </Button>
          </Stack>
        )}
      </Scrollbar>

      {selectedAttachment && (
        <ChatRoomAttachmentDialog
          certificationId={conversation?.certification?.id}
          certification={conversation?.certification}
          members={conversation?.certification?.household?.household_members}
          attachmentSelected={selectedAttachment}
          setAttachmentSelected={setSelectedAttachment}
          handleRefresh={updateMessage}
        />
      )}
    </RootStyle>
  );
};

export default memo(MailDetails);
