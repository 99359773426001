import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  unit: {},
  unitList: [],
  unitDropdownList: [],
  unitTypes: [],
  AMIs: [],
  preferenceGroupsList: [],
  programTypesList: [],
};

const slice = createSlice({
  name: 'Unit',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single property
    getUnitSuccess(state, action) {
      state.isLoading = false;
      state.unit = action.payload;
    },

    // GET Property list
    getUnitListSuccess(state, action) {
      state.isLoading = false;
      state.unitList = action.payload;
    },

    createUnitSuccess(state, action) {
      state.isLoading = false;
    },

    updateUnitSuccess(state, action) {
      state.isLoading = false;
      state.unit = action.payload;
    },

    getUnitTypesSuccess(state, action) {
      state.isLoading = false;
      state.unitTypes = action.payload;
    },

    getAMIsSuccess(state, action) {
      state.isLoading = false;
      state.AMIs = action.payload;
    },

    getPreferenceGroupsSuccess(state, action) {
      state.isLoading = false;
      state.preferenceGroupsList = action.payload;
    },

    getProgramTypesSuccess(state, action) {
      state.isLoading = false;
      state.programTypesList = action.payload;
    },

    getUnitDropdownListSuccess(state, action) {
      state.isLoading = false;
      state.unitDropdownList = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUnit(unitId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/units/${unitId}/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getUnitSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnitList(propertyId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/units/?property=${propertyId}&${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getUnitListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnitDropdownList(propertyId, applicantPoolID, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/units/?building__property=${propertyId}&building__applicant_pool=${applicantPoolID}&${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getUnitDropdownListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUnit(unitData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('property_portfolio/units/', unitData);
      dispatch(slice.actions.createUnitSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUnit(unitId, unitData, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `property_portfolio/units/${unitId}/?${parseQueryParams(queryParams)}`,
        unitData
      );
      dispatch(slice.actions.updateUnitSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnitTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('property_portfolio/unit_type/');
      dispatch(slice.actions.getUnitTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAMIs() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('property_portfolio/ami/');
      dispatch(slice.actions.getAMIsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPreferenceGroupsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        'property_portfolio/unit_preference_group/'
      );
      dispatch(
        slice.actions.getPreferenceGroupsSuccess(
          response.data.map((x) => x.name)
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProgramTypesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('certification/program_type/');
      dispatch(slice.actions.getProgramTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
