import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Icon } from '@iconify/react';
import outlineAdminPanelSettings from '@iconify/icons-ic/outline-admin-panel-settings';
import outlineWorkOutline from '@iconify/icons-ic/outline-work-outline';
import homeOutline from '@iconify/icons-eva/home-outline';

// material
import { alpha } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
// routes
import {
  COMPLIANCE_PATHS,
  HM_DASHBOARD,
  PATH_DASHBOARD,
} from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { useDispatch } from 'react-redux';
import { resetMemberType } from 'src/redux/slices/Main/mainActions';
import { resetPropertyState } from 'src/redux/slices/Compliance/Property';
import { resetHousehold } from 'src/redux/slices/Tenant/Tenant';
import { resetQuestionnaireData } from 'src/redux/slices/Questionnaire/QuestionnaireSummary';
import { resetProgressData } from 'src/redux/slices/Questionnaire/QuestionnaireProgress';

import outlineSettings from '@iconify/icons-ic/outline-settings';
// ----------------------------------------------------------------------

export const getUserFullName = (user) => {
  const { first_name, middle_name, last_name } = user?.household_member || {};
  const fullName = `${first_name || ''} ${middle_name || ''} ${
    last_name || ''
  }`;
  return fullName.trim() || user?.displayName;
};

export default function AccountPopover({ isResident, bigIcon = false }) {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    const isCompliance = pathname.includes('/workbench');
    // const isDashboard = pathname.includes('/workbench/user');
    // const isResident =  pathname.includes('/user') ? !isDashboard : false;

    const options = [];

    if (user?.isProntoAdmin) {
      options.push({
        label: 'Pronto Administration',
        icon: outlineAdminPanelSettings,
        linkTo: PATH_DASHBOARD.root,
      });
    }

    if (!isResident) {
      if (isCompliance) {
        options.push(
          {
            label: 'Home',
            icon: homeOutline,
            linkTo: '/',
          },
          {
            label: 'User Settings',
            icon: outlineSettings,
            linkTo: COMPLIANCE_PATHS.user.settings,
          }
        );
      } else {
        options.push({
          label: 'Workbench',
          icon: outlineWorkOutline,
          linkTo: COMPLIANCE_PATHS.root,
        });
      }
    } else {
      options.push({
        label: 'Home',
        icon: homeOutline,
        linkTo: '/',
      });
      options.push({
        label: 'User Settings',
        icon: outlineSettings,
        linkTo: HM_DASHBOARD.tenant.user.mySettings,
      });
    }
    setMenuOptions(options);
  }, [pathname, user?.isProntoAdmin, isResident]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(resetQuestionnaireData());
      dispatch(resetProgressData());
      dispatch(resetHousehold());
      dispatch(resetPropertyState());
      dispatch(resetMemberType());
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout.', { variant: 'error' });
    }
  };

  return (
    <>
      <Badge
        variant="dot"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MIconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: isResident || bigIcon ? 40 : 30,
            height: isResident || bigIcon ? 40 : 30,
            marginBottom: 0.5,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                top: 0,
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.5),
              },
            }),
          }}
        >
          <MyAvatar
            sx={{
              width: isResident || bigIcon ? 40 : 30,
              height: isResident || bigIcon ? 40 : 30,
              marginBottom: 0.5,
            }}
          />
        </MIconButton>
      </Badge>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {getUserFullName(user)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        {user.badge && (
          <>
            <Divider sx={{ my: 1 }} />
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              ml={1}
              mr={1}
            >
              <img src={user?.badge?.icon} alt="badge" width={40} />
              <Typography fontWeight="bold" fontSize={15}>
                {user?.badge?.label}
              </Typography>
            </Stack>
          </>
        )}

        <Divider sx={{ my: 1 }} />

        {menuOptions.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
