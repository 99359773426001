import React from 'react';

const TrashBin = ({ stroke = '#DA0101', height = 24, width = 22 }) => {
  return (
    <svg
      style={{ display: 'block', height: height }}
      width={width}
      height={height}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3999 5.4H20.5999M7.3999 1.8H14.5999M8.5999 17.4V10.2M13.3999 17.4V10.2M15.1999 22.2H6.7999C5.47442 22.2 4.3999 21.1255 4.3999 19.8L3.85198 6.64996C3.82358 5.96821 4.3686 5.4 5.05094 5.4H16.9489C17.6312 5.4 18.1762 5.96821 18.1478 6.64996L17.5999 19.8C17.5999 21.1255 16.5254 22.2 15.1999 22.2Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashBin;
