import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';

export const useSicky = ({
  nodeRef,
  maxScrollNodeRef = null,
  offsetTop = 0,
  transition = false,
  active = true,
  name = nanoid(),
}) => {
  const [state, setState] = useState({
    node: null,
    name,
    offsetTop,
    nodeHasInitialStyles: false,
    windowWidth: window.innerWidth,
    topLimit: 0,
  });

  // set initial styles to transform the HTML node
  const setNodeInitialStyle = useCallback(
    (node) => {
      if (!node || state.nodeHasInitialStyles) {
        return;
      }
      node.style.zIndex = 99;
      node.style.position = 'relative';
      node.style.borderRadius = '8px';
      if (transition) {
        node.style.transition = 'transform .1s ease';
      }
    },
    [state.nodeHasInitialStyles, transition]
  );

  // set transformation value on the node element
  const setTransform = useCallback((node, scroll = 0) => {
    if (!node) {
      return;
    }
    node.style.transform = `translateY(${scroll}px)`;

    // add the shadow only once when the node transformation start
    if (!node?.style?.boxShadow && scroll > 0) {
      node.style.boxShadow = '0px 5px 12px 0px rgba(0,0,0,0.1)';
    }

    // remove the box shadow when the transformation ends
    if (Boolean(node?.style?.boxShadow) && scroll === 0) {
      node.style.boxShadow = '';
    }
  }, []);

  useEffect(() => {
    if (!nodeRef.current || !active) {
      return;
    }

    const onResize = debounce(() => {
      if (state.windowWidth !== window.innerWidth) {
        setTransform(nodeRef.current);
        setState((st) => ({
          ...st,
          node: null,
          windowWidth: window.innerWidth,
          topLimit: 0,
        }));
      }
    }, 200);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [active, nodeRef, setTransform, state.windowWidth]);

  useEffect(() => {
    if (!nodeRef.current || !active) {
      return;
    }

    let latestKnownScrollY = 0;
    let ticking = false;

    const update = () => {
      // reset the tick so we can
      // capture the next onScroll
      ticking = false;

      if (
        Boolean(maxScrollNodeRef) &&
        maxScrollNodeRef?.getBoundingClientRect()?.top - offsetTop <=
          (nodeRef.current?.getBoundingClientRect()?.height || 0)
      ) {
        return;
      }

      if (latestKnownScrollY > state.topLimit - offsetTop) {
        setTransform(
          nodeRef.current,
          latestKnownScrollY - (state.topLimit - offsetTop)
        );
      } else if (latestKnownScrollY <= state.topLimit - offsetTop) {
        setTransform(nodeRef.current);
      }
    };

    const requestTick = () => {
      if (!ticking) {
        requestAnimationFrame(update);
      }
      ticking = true;
    };

    const onScroll = () => {
      latestKnownScrollY = window.scrollY;
      requestTick();
    };

    window.removeEventListener('scroll', onScroll);

    // START A NEW LISTENER WITH THE OFFSET UPDATED
    setNodeInitialStyle(nodeRef.current);

    setState((st) => ({
      ...st,
      node: nodeRef?.current,
      nodeHasInitialStyles: true,
      topLimit: nodeRef.current.getBoundingClientRect()?.top + window.scrollY,
    }));

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [
    active,
    maxScrollNodeRef,
    nodeRef,
    offsetTop,
    setNodeInitialStyle,
    setTransform,
    state.topLimit,
  ]);

  return state;
};
