import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
// icons
import EditIcon from '@mui/icons-material/Edit';
// components
import TemplateList from './TemplateList';

// ----------------------------------------------------------------------

const TemplateModal = ({ propertyId, categories, title, onCloseFunc }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    onCloseFunc();
  };

  return (
    <>
      {/* Button */}
      <IconButton size="small" onClick={handleOpen}>
        <EditIcon fontSize="small" />
      </IconButton>

      {/* Modal */}
      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle onClick={(event) => event.stopPropagation()}>
          {title || 'Templates'}
        </DialogTitle>
        <DialogContent onClick={(event) => event.stopPropagation()}>
          <TemplateList propertyId={propertyId} categories={categories} />
        </DialogContent>
        <DialogActions
          style={{ paddingTop: 0 }}
          onClick={(event) => event.stopPropagation()}
        >
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TemplateModal.propTypes = {
  propertyId: PropTypes.number,
  categories: PropTypes.array,
  title: PropTypes.string,
  onCloseFunc: PropTypes.func,
};

TemplateModal.defaultProps = {
  onCloseFunc: () => null,
};

export default TemplateModal;
