import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import closeFill from '@iconify/icons-eva/close-fill';
import expandFill from '@iconify/icons-eva/expand-fill';
import collapseFill from '@iconify/icons-eva/collapse-fill';
// material
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Backdrop,
  Box,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Portal,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { InputStyle, RootStyle } from './MailCompose';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import axios from '../../utils/axios';
import axiosInstance from '../../utils/axios';
import parseQueryParams from '../../utils/query';
import useAuth from '../../hooks/useAuth';
import { FAX_TYPE } from './ConversationsV2';
import { setShouldReload } from '../../redux/slices/conversationsV2';
import xss from 'xss';
import FCSelectFiles from './FCSelectFiles';
import Label from '../Label';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';

// ----------------------------------------------------------------------
const FaxCompose = ({ isOpenCompose, onCloseCompose }) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();
  const certification = useSelector(
    ({ certification }) => certification?.certification,
    shallowEqual
  );
  const { user_certification } = useSelector(
    ({ userDashboard: { communication } }) => ({
      user_certification: communication.user_certification,
    }),
    shallowEqual
  );
  const [contactsOptions, setContactsOptions] = useState([]);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [fullScreen, setFullScreen] = useState(false);
  const handleExitFullScreen = () => setFullScreen(false);
  const handleEnterFullScreen = () => setFullScreen(true);

  const selectedCertification = useMemo(() => {
    if (certification?.id) {
      return certification;
    } else if (user_certification.id) {
      return user_certification;
    }
    return null;
  }, [certification, user_certification]);

  const schema = {
    recipient: Yup.array()
      .min(1, 'You must select one recipient.')
      .required('Recipient is required.'),
    subject: Yup.string()
      .required('Subject is required.')
      .max(55, 'Only 55 characters are allowed.'),
    notes: Yup.string().nullable().required('Notes are required.'),
    files: Yup.array()
      .min(1, 'You must select at least 1.')
      .required('Files are required.'),
  };

  const initialValues = {
    recipient: [],
    contact_fax_number: '',
    subject: '',
    notes: '',
    files: [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validateOnMount: true,
    validationSchema: Yup.object().shape(schema),
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        const payload = {
          contacts: !!values.recipient.length ? [values.recipient[0].id] : null,
          contact_fax_number: values?.contact_fax_number || null,
          subject: values.subject || '',
          certification: selectedCertification?.id,
          compliance_users: [user.id],
          conversation_type: FAX_TYPE,
          property: selectedCertification?.property,
        };

        const { data } = await axios.post(
          'communication/new_conversation/conversation/',
          payload
        );

        const certif_docs = values?.files.map((file) => file.id);
        if (data.id) {
          const messagePayload = {
            conversation: data.id,
            content: xss(values.notes),
            message_type: FAX_TYPE,
            sender_user: user.id,
            certification_documents: certif_docs,
          };

          await axios.post(
            `communication/new_conversation/message/`,
            messagePayload
          );

          enqueueSnackbar('Fax is being sent.', { variant: 'info' });
          handleClose();
          resetForm();
          setSubmitting(false);
          dispatch(setShouldReload(true));
        } else {
          throw new Error(data.toString());
        }
      } catch (error) {
        setErrors(error);
        setSubmitting(false);
        enqueueSnackbar('There was an error sending the fax.', {
          variant: 'error',
        });
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
    isValid,
  } = formik;

  const handleClose = useCallback(() => {
    onCloseCompose();
    resetForm();
    setFullScreen(false);
  }, [onCloseCompose, resetForm]);

  useEffect(() => {
    const fetchContacts = async () => {
      if (!selectedCertification?.id) {
        setContactsOptions([]);
        return;
      }
      try {
        const certParams = parseQueryParams({
          expand: ['household', 'property', 'property.portfolio'],
          fields: [
            'household.id',
            'property.id',
            'property.portfolio',
            'property.portfolio.id',
            'property.portfolio.property_owner_company',
          ],
        });

        const { data } = await axiosInstance.get(
          `certification/certification/${selectedCertification?.id}/?${certParams}`
        );

        const filter = {
          for_household: data?.household?.id,
          fax_number__fax_number__isnull: false,
          property: selectedCertification?.property,
          expand: ['fax_number'],
          fields: ['fax_number', 'first_name', 'last_name', 'household'],
        };

        const params = parseQueryParams(filter);
        const { data: contacts } = await axios.get(`contacts/?${params}`);
        setContactsOptions(
          contacts.map((con) => ({
            ...con,
            name: `${con.first_name} ${con.last_name} ${
              con.fax_number
                ? formatPhoneNumber(con.fax_number.fax_number.substring(2))
                : ''
            }`,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };
    if (selectedCertification?.id) {
      fetchContacts();
    }
  }, [
    selectedCertification?.id,
    selectedCertification?.property,
    contactsOptions.length,
    isOpenCompose,
  ]);

  if (!isOpenCompose) {
    return null;
  }

  return (
    <Portal>
      <Backdrop open={fullScreen || isMobile} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...((fullScreen || isMobile) && {
            top: 0,
            right: { xs: 12, md: 40 },
            zIndex: 1999,
            margin: 'auto',
            maxHeight: '95vh',
            width: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
            height: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
          }),
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: 'calc(100% - 88px)' }}>
            <Typography variant="h6">{'New Fax'}</Typography>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {!isMobile && (
            <IconButton
              onClick={
                fullScreen ? handleExitFullScreen : handleEnterFullScreen
              }
              size="large"
            >
              <Icon
                icon={fullScreen ? collapseFill : expandFill}
                width={20}
                height={20}
              />
            </IconButton>
          )}

          <IconButton onClick={handleClose} size="large">
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />

        <Grid container sx={{ height: '100%' }}>
          {/*FORM*/}
          <Grid item xs={6}>
            <Typography variant={'body2'} m={2}>
              <b>Cover Sheet</b>
            </Typography>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '90%',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  multiple
                  sx={{
                    '& .MuiAutocomplete-inputRoot': { pl: 3, py: 1 },
                    '& .MuiAutocomplete-input': { pl: 3, py: 1 },
                    '& .MuiAutocomplete-inputRoot::before': {
                      borderColor: 'grey.300',
                    },
                    '& .MuiAutocomplete-endAdornment': { display: 'none' },
                  }}
                  isOptionEqualToValue={(opt, val) => {
                    return +val?.id === +opt?.id;
                  }}
                  options={contactsOptions}
                  getOptionLabel={(opt) => {
                    return opt?.name || '';
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.name}{' '}
                      {!option?.household && (
                        <Label sx={{ ml: 1 }} variant="ghost" color="default">
                          Property
                        </Label>
                      )}
                    </Box>
                  )}
                  value={values?.recipient}
                  onChange={(event, value) => {
                    if (value.length > 1) {
                      setFieldValue('recipient', [value[1]]);
                    } else {
                      setFieldValue(
                        'contact_fax_number',
                        value[0].fax_number.id
                      );
                      setFieldValue('recipient', value);
                    }
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        label={option.name}
                        sx={{
                          '&.MuiChip-root.Mui-disabled': {
                            opacity: 1,
                          },
                        }}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        size="small"
                        variant="standard"
                        placeholder={'Recipient'}
                        inputProps={{ ...params.inputProps, maxLength: 1 }}
                      />
                      {Boolean(touched.recipient && errors.recipient) && (
                        <FormHelperText sx={{ pl: 3 }} error>
                          {errors.recipient}
                        </FormHelperText>
                      )}
                    </>
                  )}
                />

                <InputStyle
                  fullWidth
                  disableUnderline
                  placeholder="Subject"
                  inputProps={{ maxLength: 55 }}
                  {...getFieldProps('subject')}
                />
                {Boolean(touched.subject && errors.subject) && (
                  <FormHelperText sx={{ pl: 3 }} error>
                    {errors.subject}
                  </FormHelperText>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'auto',
                  }}
                >
                  <TextField
                    fullWidth
                    multiline
                    value={values.notes}
                    maxRows={13}
                    onChange={(e) => {
                      setFieldValue('notes', e.target.value);
                    }}
                    placeholder="Notes"
                    sx={{
                      pl: 1,
                      mb: 'auto',
                      '& fieldset': {
                        border: 'none !important',
                      },
                    }}
                  />
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Divider />
                <Box
                  sx={{ py: 2, px: 3, display: 'flex', alignItems: 'center' }}
                >
                  <LoadingButton
                    onClick={handleSubmit}
                    disabled={!isValid}
                    loading={isSubmitting}
                    variant="contained"
                    sx={{ mr: 2 }}
                  >
                    Send
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ marginRight: '-1px' }}
          />

          {/*FILES*/}
          <Grid item xs={6}>
            <Typography variant={'body2'} m={2}>
              <b>Fax Document</b>
            </Typography>
            <Divider />
            <FCSelectFiles
              certificationFile={selectedCertification?.certification_file}
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              errors={errors}
            />
          </Grid>
        </Grid>
      </RootStyle>
    </Portal>
  );
};

export default memo(FaxCompose);

FaxCompose.propTypes = {
  isOpenCompose: PropTypes.bool,
  onCloseCompose: PropTypes.func,
};
