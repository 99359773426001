import axios from '../utils/axios';
import parseQueryParams from '../utils/query';

// consider moving this to a service constants file
export const REQUEST_STATUS = {
  WAITING: 'WAITING',
  PASSED: 'PASSED',
  FAILED: 'FAILED',
};

export default class PropertyHomeService {
  createTemplateFile = async (
    certificationId = null,
    formId = null,
    selectedMemberId = null
  ) => {
    if (!selectedMemberId || !formId) {
      return REQUEST_STATUS.FAILED;
    }
    let requestStatus = REQUEST_STATUS.WAITING;
    await axios
      .post(`certification/document/create_from_form_template/`, {
        certification: certificationId,
        form: formId,
        member: selectedMemberId,
      })
      .catch(() => {
        requestStatus = REQUEST_STATUS.FAILED;
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          requestStatus = REQUEST_STATUS.PASSED;
        }
      });
    return requestStatus;
  };

  getCertificationDetails = async (certificationId, queryParams) => {
    let response = {};
    try {
      response = await axios.get(
        `certification/certification/${certificationId}/?${parseQueryParams(
          queryParams
        )}`
      );
      response.requestStatus = REQUEST_STATUS.PASSED;
    } catch (error) {
      response.requestStatus = REQUEST_STATUS.FAILED;
      response.error = error;
    }
    return response;
  };
}

export const propertyHomeServiceInstance = new PropertyHomeService();
