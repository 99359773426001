import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const TemplatesWarning: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const handleVisibility = (): void => {
    setVisible((state) => !state);
  };

  return (
    <>
      <Button
        size="small"
        id="templates-menu"
        sx={{ mr: 2 }}
        onClick={handleVisibility}
      >
        Templates
      </Button>
      <Dialog open={visible}>
        <DialogTitle>Templates</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Please assign this email to a specific household’s certification to
            use the Template feature.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleVisibility}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplatesWarning;
