import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isContactListReady: false,
  error: false,
  contactList: [],
  contact: {},
};

const slice = createSlice({
  name: 'Contacts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Form List
    getContactListSuccess(state, action) {
      state.isLoading = false;
      state.isContactListReady = true;
      state.contactList = action.payload;
    },

    getContactSuccess(state, action) {
      state.isLoading = false;
      state.contact = action.payload;
    },

    resetContactList(state) {
      state.isContactListReady = false;
      state.contactList = [];
    },
  },
});

//Actions
export const { resetContactList } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContactList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `contacts/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getContactListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContactListByHousehold(
  household_id,
  property_id,
  queryParams
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const householdParam = household_id ? `household=${household_id}` : '';
      const response = await axios.get(
        `contacts/?${householdParam}&property=${property_id}&${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getContactListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContact(contactId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `contacts/${contactId}/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getContactSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
