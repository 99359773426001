import { Box, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import fax16Filled from '@iconify/icons-fluent/fax-16-filled';

export default function FaxToggleButton({ status }) {
  return (
    <Tooltip title={status?.tooltip} arrow placement="top">
      <Box>
        <IconButton
          onClick={status?.handleChange}
          sx={{ pointerEvents: !!status.handleChange ? 'auto' : 'none' }}
          m={1}
          size="large"
        >
          <Icon icon={fax16Filled} />
          {!!status.icon && (
            <Icon
              icon={status.icon}
              style={{
                color: status.color,
                width: '12px',
                height: '12px',
                right: '15%',
                top: '52%',
                position: 'absolute',
              }}
            />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  );
}
