import React from 'react';
import PropTypes from 'prop-types';
// utils
import { MODES } from './utils';
// components
import CDMMainMode from './CDMMainMode';
import CDMTreeHeader from './CDMTreeHeader';
import CDMFileManagerMode from './CDMFileManagerMode';
import CDMQuestionAnswerMode from './CDMQuestionAnswerMode';
import FTSelectCertificationField from '../ConversationsV2/FTSelectCertificationField';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

const CDMTree = ({
  pdfInstance,
  dmState,
  selectedPages,
  resetActionMode,
  updateDmState,
  handleClose,
  shouldSelectCertification,
  readOnly = false,
}) => {
  const selectCertifs = shouldSelectCertification ? (
    <Box sx={{ mb: 1 }}>
      <FTSelectCertificationField
        property={shouldSelectCertification.property}
      />
    </Box>
  ) : (
    <></>
  );

  return (
    <>
      {!dmState.actionMode && (
        <CDMMainMode
          readOnly={readOnly}
          pdfInstance={pdfInstance}
          dmState={dmState}
          selectedPages={selectedPages}
          updateDmState={updateDmState}
          handleClose={handleClose}
          selectCertifs={selectCertifs}
          selectCertification={shouldSelectCertification?.onSubmit}
        />
      )}
      {dmState.actionMode && (
        <>
          <CDMTreeHeader
            actionMode={dmState.actionMode}
            pagesInput={dmState.pagesInput}
          />
          {dmState.actionMode === MODES.toFileManager && (
            <CDMFileManagerMode
              dmState={dmState}
              resetActionMode={resetActionMode}
              selectCertification={shouldSelectCertification?.onSubmit}
            />
          )}
          {dmState.actionMode === MODES.toQuestionAnswer && (
            <CDMQuestionAnswerMode
              dmState={dmState}
              resetActionMode={resetActionMode}
              selectCertification={shouldSelectCertification?.onSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

// -----------------------------------------------------------------------

CDMTree.propTypes = {
  pdfInstance: PropTypes.object,
  dmState: PropTypes.object,
  selectedPages: PropTypes.array,
  resetActionMode: PropTypes.func,
  updateDmState: PropTypes.func,
  handleClose: PropTypes.func,
};

CDMTree.defaultProps = {
  pdfInstance: null,
  dmState: {},
  selectedPages: [],
  resetActionMode: () => null,
  updateDmState: () => null,
  handleClose: () => null,
};

export default React.memo(CDMTree);
