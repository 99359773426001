import PropTypes from 'prop-types';
// material
import { Chip, Tooltip, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

// ----------------------------------------------------------------------
const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-root': {
    borderRadius: '3px',
    justifyContent: 'space-between',
    height: '16px',
  },
  '& .MuiChip-label': {
    paddingLeft: '5px',
    paddingRight: '5px',
  },
}));

export default function HouseholdLanguage({
  preferredLanguage,
  noDecoration = false,
  green = false,
}) {
  if (!preferredLanguage) {
    return null;
  }

  return (
    <Tooltip title={preferredLanguage} placement="top" arrow>
      <StyledChip
        size="small"
        clickable
        sx={{ bgcolor: (theme) => green && theme.palette.primary.lighter }}
        label={
          <Typography
            sx={{
              fontSize: 11,
              fontWeight: 'bold',
              textDecoration: 'none',
              display: noDecoration ? 'inline-block' : 'block',

              color: (theme) => green && theme.palette.primary.main,
            }}
          >
            {preferredLanguage.substring(0, 2).toUpperCase()}
          </Typography>
        }
      />
    </Tooltip>
  );
}

HouseholdLanguage.propTypes = {
  preferredLanguage: PropTypes.string,
  noDecoration: PropTypes.bool,
};
