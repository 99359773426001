import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

EmptyTable.propTypes = {
  objectName: PropTypes.string,
};

export default function EmptyTable({ objectName = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography variant="body2" align="center">
        No <strong>{objectName}</strong> were found.
      </Typography>
    </Paper>
  );
}
