import TemplatesEngineService from '../services/TemplatesEngine.service';
import parseQueryParams from 'src/utils/query';

const getTemplatesByCategory = async (
  item,
  queryParams,
  setTemplateList
): Promise<void> => {
  const categoryName = item.categoryName;
  const params = `${queryParams}&${parseQueryParams({
    category__name: categoryName,
  })}`;

  return TemplatesEngineService.getCommunicationTemplates(params)
    .then((response) => {
      setTemplateList((prev) => ({
        ...prev,
        [item.key]: response.data,
      }));
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getCommunicationTemplates = async (
  queryParams,
  setTemplateList
): Promise<PromiseSettledResult<unknown>[]> => {
  const templateListData = [
    {
      categoryName: 'Initial Outreach',
      key: 'initialOutreach',
    },
    {
      categoryName: 'Follow Up',
      key: 'followUp',
    },
    {
      categoryName: 'Compliance Specialist',
      key: 'complianceSpecialist',
    },
    {
      categoryName: 'Certification Notice',
      key: 'certificationNotice',
    },
  ];

  const promises = [];
  const parsedParams = parseQueryParams({
    ...queryParams,
  });
  templateListData.forEach((item) => {
    promises.push(getTemplatesByCategory(item, parsedParams, setTemplateList));
  });

  return Promise.allSettled(promises);
};
