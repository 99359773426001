import { useEffect } from 'react';
import {
  DEFAULT_ROWS_PER_PAGE,
  ROWS_PER_PAGE_OPTIONS_ALL,
} from 'src/utils/tables';

/**
 * Store the selected rowsPerPage value on the browser local storage
 */
export default function usePaginationLS({
  key = '',
  rowsPerPage = ROWS_PER_PAGE_OPTIONS_ALL[0],
  rowsPerPageOptions = [],
}) {
  const lsRowsPerPage = +window.localStorage.getItem(key);

  useEffect(() => {
    if (
      rowsPerPageOptions.includes(+rowsPerPage) ||
      rowsPerPageOptions.find((r) => +r?.value === rowsPerPage)
    ) {
      window.localStorage.setItem(key, rowsPerPage);
    }
  }, [key, rowsPerPage, rowsPerPageOptions]);

  return {
    key,
    rowsPerPage,
    lsRowsPerPage: lsRowsPerPage >= 0 ? lsRowsPerPage : null,
  };
}

export function getLsValue(key = '', defalutRows = DEFAULT_ROWS_PER_PAGE) {
  const val = window.localStorage.getItem(key);
  if (val && !Number.isNaN(val)) {
    return +val;
  }
  return defalutRows;
}
