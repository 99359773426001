import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// material
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// utils
import { fDateTime } from 'src/utils/formatTime';
// redux
import { getStatusNotesRecentList } from 'src/redux/slices/Compliance/Certification';
// components
import Scrollbar from 'src/components/Scrollbar';
import EmptyTable from 'src/components/EmptyTable';

export const StatusNotesRecentList = ({
  certificationId,
  refreshNotes,
  setRefreshNotes,
  onEditNote,
}) => {
  const dispatch = useDispatch();
  const { statusNotesRecentList, isLoading } = useSelector(
    (state) => state.certification
  );

  const _getStatusNotesRecentList = () => {
    dispatch(
      getStatusNotesRecentList({
        certification: certificationId,
        expand: ['author', 'category'],
        fields: [
          'id',
          'note',
          'author.first_name',
          'author.last_name',
          'category.name',
          'timestamp',
        ],
        page_size: 4,
      })
    );
  };

  useEffect(() => {
    if (refreshNotes) {
      _getStatusNotesRecentList();
      setRefreshNotes(false);
    }
  }, [refreshNotes]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredRecentStatusNotes =
    'results' in statusNotesRecentList ? statusNotesRecentList.results : [];
  const isRecentStatusNotesNotFound = filteredRecentStatusNotes.length === 0;

  return (
    <Box>
      {!isRecentStatusNotesNotFound && (
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Most Recent Notes
        </Typography>
      )}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 600 }}>
          <Table>
            {!isLoading && !isRecentStatusNotesNotFound && (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ boxShadow: 'none' }}>
                      Timestamp
                    </TableCell>
                    <TableCell align="left" style={{ boxShadow: 'none' }}>
                      Note
                    </TableCell>
                    <TableCell align="left" style={{ boxShadow: 'none' }}>
                      Category
                    </TableCell>
                    <TableCell align="left" style={{ boxShadow: 'none' }}>
                      Author
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRecentStatusNotes.map((statusNote) => (
                    <TableRow
                      key={statusNote.id}
                      tabIndex={-1}
                      hover
                      onClick={() => onEditNote(statusNote)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}
                      >
                        {fDateTime(statusNote.timestamp)}
                      </TableCell>
                      <TableCell align="left" sx={{ verticalAlign: 'top' }}>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {statusNote.note}
                        </div>
                      </TableCell>
                      <TableCell align="left" sx={{ verticalAlign: 'top' }}>
                        {statusNote.category?.name || ''}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ verticalAlign: 'top' }}
                      >{`${statusNote.author.first_name} ${statusNote.author.last_name}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
            {!isLoading && isRecentStatusNotesNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                    <EmptyTable objectName="Status Notes" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={4} style={{ padding: 0 }}>
                    <Skeleton variant="text" height={80} width={'100%'} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Box>
  );
};

StatusNotesRecentList.propTypes = {
  certificationId: PropTypes.number,
  refreshNotes: PropTypes.bool,
  setRefreshNotes: PropTypes.func,
  onEditNote: PropTypes.func,
};
