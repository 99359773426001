import process from 'process';
import React from 'react';

// mock api
// import './_apis_';
// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// material
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// redux
import { persistor, store } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';

// components
import LoadingScreen from './components/LoadingScreen';

// Using for Auth (Check doc https://minimals.cc/docs/authentication)
import { AuthProvider } from './contexts/JWTContext';

//
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
// import LogRocket from 'logrocket';
import { datadogRum } from '@datadog/browser-rum';
import { DEFAULT_APP_VERSION } from './redux/slices/Main/main';
import { registerLicense } from '@syncfusion/ej2-base';
import { environmentsWithDatadog, getEnvironmentName } from 'src/AppUtils';

if (typeof window !== 'undefined') {
  window.process = process;
}

if (
  environmentsWithDatadog.includes(getEnvironmentName()) &&
  process?.env?.REACT_APP_DATADOG_ID &&
  process?.env?.REACT_APP_DATADOG_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_ID,
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: 'loki',
    env: getEnvironmentName(),
    version: process?.env?.REACT_APP_VERSION || DEFAULT_APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [process.env.REACT_APP_API_ROOT].filter(Boolean),
  });

  datadogRum.startSessionReplayRecording();
}

// ----------------------------------------------------------------------
/* if (process.env.REACT_APP_API_ROOT?.includes('prontohousing.io')) {
 LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID || 'pwnx6n/pronto-housing');
 } */
// ----------------------------------------------------------------------
if (process?.env?.REACT_APP_SYNCFUSION_KEY) {
  registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);
}
const container =
  document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
root.render(
  // <StrictMode>
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <App />
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>
  // </StrictMode>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
