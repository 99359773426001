import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import xss from 'xss';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import axios from 'src/utils/axios';
import axiosInstance from 'src/utils/axios';
import { uniq } from 'lodash';
// material
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormHelperText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
//
import { useSnackbar } from 'notistack';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  getConversation,
  sendMessageSuccess,
  setShouldReload,
  updateConversation,
} from 'src/redux/slices/conversationsV2';
import MessageTemplates from './Templates';
import { buildMessageFormData, getMessageById } from './utils';
import { SMS_TYPE } from './ConversationsV2';
import parseQueryParams from 'src/utils/query';
import { formatPhone } from './MailDetailsToolbar';
import Label from '../../components/Label';
import StarIcon from '@mui/icons-material/Star';
import { getMemberFullName } from '../Compliance/Certification/Household/HouseholdUtils';

const MAX_CHARACTERS_PER_SEGMENT = 160;
const MAX_PAGES_PER_MESSAGE = 10;
const MAX_CHARACTERS_PER_MESSAGE =
  MAX_CHARACTERS_PER_SEGMENT * MAX_PAGES_PER_MESSAGE;
// ----------------------------------------------------------------------

const CONTACTS_LABEL = 'Contacts List';
const MEMBERS_LABEL = 'Household Members';

const SMSCompose = ({
  onCloseCompose,
  isReply,
  setIsReply,
  reject,
  setFullScreen,
  isProperty = false,
}) => {
  const dispatch = useDispatch();
  const { certification_id } = useParams();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { infoToReject } = useSelector((state) => state.certification);
  const { pathname } = useLocation();
  const isUserDashboard =
    pathname.startsWith('/workbench/user') ||
    pathname.startsWith('/workbench/my-renters');
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const certification = useSelector(
    ({ certification }) => certification?.certification,
    shallowEqual
  );
  const { user_certification } = useSelector(
    ({ userDashboard: { communication } }) => ({
      user_certification: communication.user_certification,
    }),
    shallowEqual
  );

  const [message, setMessage] = useState(
    reject && infoToReject ? infoToReject?.message?.text : ''
  );
  const [validMessage, setValidMessage] = useState(false);
  const [householdMembersList, setHouseholdMembersList] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [templateIds, setTemplateIds] = useState([]);

  const schemaShapes = {
    contacts: Yup.array(),
    certification: Yup.number(),
    subject: Yup.string().nullable(),
    household_members: Yup.array().test('household_members', (value, data) => {
      if (value?.length < 1) {
        return data.createError({
          message: 'At least 1 Household Member is required.',
          path: `household_members`,
        });
      }
      return true;
    }),
  };
  const [schemaShape, setSchemaShape] = useState(schemaShapes);

  const segmets = useMemo(
    () => Math.floor((message?.length - 1) / MAX_CHARACTERS_PER_SEGMENT) + 1,
    [message?.length]
  );

  const certId = useMemo(() => {
    return (
      certification_id ||
      selectedConversation?.certification?.id ||
      user_certification?.id ||
      certification?.id
    );
  }, [
    certification_id,
    selectedConversation,
    user_certification,
    certification,
  ]);

  const emailSchema = Yup.object().shape(schemaShape);
  const formik = useFormik({
    initialValues: {
      subject: reject && infoToReject ? infoToReject?.subject : '',
      household_members: reject && infoToReject ? [infoToReject?.member] : [],
      contacts: [],
      certification: certId,
      files: [],
      message_type: SMS_TYPE,
      emails: [],
    },
    validationSchema: emailSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // if the user is on a selected conversation and is a reply use the selected conversation ID
        let conversationId = selectedConversation?.id || null;
        let conversationResponse = null;
        const destinations = [
          ...(values?.household_members || []),
          ...(values?.contacts || []),
        ];

        if (!values?.household_members[0]?.mobile_phone?.is_reachable) {
          enqueueSnackbar(
            'The mobile phone for that household member is not reachable.',
            { variant: 'error' }
          );
          return;
        }
        const conversationPayload = {
          subject: `${destinations[0]?.name} SMS Conversation`,
          hhm_mobile_phone: destinations[0]?.mobile_phone?.id,
          contact_mobile_phone: null,
          contacts: values.contacts.map((m) => m?.id),
          certification: values.certification,
          compliance_users: [user.id],
          conversation_type: values.message_type,
          property: certification?.property,
        };

        // check if the selected household member has an existing conversation with the SMS type
        // to use that conversation ID
        if (!conversationId && values?.household_members[0]?.hhmId) {
          const { data } = await axios.get(
            `communication/new_conversation/conversation/?conversation_type=2&household_members=${values?.household_members[0]?.hhmId}&fields=id,mobile_phone.is_reachable&expand=mobile_phone`
          );
          conversationResponse = data;

          if (data?.length >= 1) {
            conversationId = data[0]?.id;
          }
        }

        // if at this point the conversation ID has not been defined it means that is not
        // a reply and is not an existing SMS conversation
        if (!conversationId) {
          const { data } = await axios.post(
            'communication/new_conversation/conversation/',
            conversationPayload
          );
          conversationResponse = data;
          conversationId = data?.id;
        }
        // if the conversation was creatd correctly or the user is on a selected conversation
        if (conversationId) {
          const messagePayload = {
            conversation: conversationId,
            content: xss(message),
            message_type: values?.message_type,
            sender_user: user.id,
          };

          if (templateIds?.length) {
            messagePayload.templates = templateIds;
          }

          const newMessage = await axios.post(
            `communication/new_conversation/message/`,
            buildMessageFormData(messagePayload)
          );

          if (isReply) {
            const finalMessage = await getMessageById(newMessage?.data?.id);
            dispatch(
              sendMessageSuccess({
                conversationId: newMessage?.data?.conversation,
                message: finalMessage,
              })
            );
          }

          enqueueSnackbar('Message sent successfully.', { variant: 'success' });
          handleClose();
          resetForm();
          setMessage('');
          setSubmitting(false);
          dispatch(setShouldReload(true));
          setTemplateIds([]);
        } else {
          throw new Error(conversationResponse.toString());
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong sending the message.', {
          variant: 'error',
        });
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    setValues,
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
    isValid,
  } = formik;

  useEffect(() => {
    setSchemaShape((st) => ({
      ...st,
      ...schemaShapes,
    }));
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!certId) {
      return [];
    }

    fetchContactInformation();
  }, [certId]);

  const fetchContactInformation = async () => {
    const params = parseQueryParams({
      expand: [
        'household.household_members',
        'household.household_members.id',
        'household.household_members.user',
        'household.household_members.user.email_addresses',
        'household.household_members.member_type',
        'household.household_members.mobile_phones',
        'property.id',
        'property.portfolio',
        'property.portfolio.id',
        'property.portfolio.property_owner_company',
      ],
    });

    const { data } = await axiosInstance.get(
      `certification/certification/${certId}/?${params}`
    );
    const membersList = data?.household?.household_members;
    setHouseholdMembersList(membersList);

    const contactParams = {
      property: data?.property?.id,
      portfolio: data?.property?.portfolio?.id,
      company: data?.property?.portfolio?.property_owner_company?.id,
      for_household: data?.household?.id,
      expand: ['email_addresses', 'mobile_phones', 'fax_number', 'household'],
      fields: [
        'email_addresses',
        'mobile_phones',
        'fax_number',
        'first_name',
        'last_name',
        'household.id',
        'household.name',
      ],
    };

    const { data: contacts } = await axios.get(
      `contacts/?${parseQueryParams(contactParams)}`
    );
    const flatContactPhoneNumbers = [];
    contacts.forEach((contact) => {
      contact?.mobile_phones?.forEach((phone) => {
        if (phone?.phone_number) {
          flatContactPhoneNumbers.push({
            first_name: contact?.first_name,
            last_name: contact?.last_name,
            name: getMemberFullName(contact),
            household: contact?.household,
            categoryLabel: CONTACTS_LABEL,
            mobile_phone: { ...phone },
          });
        }
      });
    });
    setContactsOptions(flatContactPhoneNumbers);
  };

  const memberOptions = useMemo(() => {
    if (!certId || householdMembersList?.length === 0) {
      return [];
    }
    let options = [];
    householdMembersList.forEach((member) => {
      member?.mobile_phones.forEach((number) => {
        options.push({
          first_name: member?.first_name,
          last_name: member?.last_name,
          user: member?.user,
          hhmId: member?.id,
          email: member?.email,
          household: member.household,
          // email_address_id: member?.email_address_id,
          mobile_phone: number,
        });
      });
    });
    options = options.map((el, idx) => ({
      name: `${el.first_name} ${el.last_name}`,
      user_id: el?.user?.id,
      id: el?.id,
      hhmId: el?.id,
      email: el?.email,
      household: el.household,
      email_address_id: el?.email_address_id,
      mobile_phone: el?.mobile_phone,
      is_primary_email: el?.is_primary_email,
      is_email_verified: el?.verified,
      isHHM: true,
      categoryLabel: MEMBERS_LABEL,
      user: {
        id: el?.user?.id,
        first_name: el.first_name,
        last_name: el.last_name,
        email: el?.email,
        email_addresses: el?.user?.email_addresses,
      },
    }));

    return options;
  }, [certId, householdMembersList]);

  const handleClose = useCallback(() => {
    resetForm();
    setMessage('');
    onCloseCompose();
    setFullScreen(false);
    setTemplateIds([]);
  }, []); // eslint-disable-line

  const resetFormikValues = useCallback(
    (value = {}) => {
      const newValues = { ...formik.values, ...value };
      resetForm();
      setTimeout(() => {
        setValues(newValues);
      }, 100);
    },
    [formik.values, resetForm, setValues]
  );

  const resetFormData = useCallback(
    (overrides = {}) => {
      setMessage('');
      setValidMessage(false);
      resetFormikValues(overrides);
    },
    [resetFormikValues]
  );

  const resetConversationSchema = (type, overrides = {}) => {
    if ([SMS_TYPE].includes(type)) {
      setSchemaShape(schemaShapes);
    }
    resetFormData(overrides);
  };

  const handleTemplateSelection = useCallback(
    (value, templateId) => {
      setTemplateIds((t) => {
        return [...t, templateId];
      });

      const newMessage = `${message}${value || ''}`;
      setMessage(newMessage);
      if (
        !validMessage &&
        newMessage?.length > 0 &&
        newMessage?.length <= 1600
      ) {
        setValidMessage(true);
      }
    },
    [message, validMessage]
  );

  const getChipLabel = (option) => {
    if (!option) {
      return null;
    }
    return `${option.name} - ${formatPhone(option.mobile_phone.phone_number)}`;
  };

  // handle reply action
  useEffect(() => {
    if (selectedConversation && isReply) {
      const household_members = (selectedConversation?.from || [])
        .filter((hhmu) => !!hhmu.isHHM)
        .map((hhmu) => ({
          id: hhmu?.id,
          name: `${hhmu?.displayName}`,
          email: `${hhmu?.email}`,
          hhmId: hhmu?.id,
          mobile_phone: hhmu?.mobile_phone,
        }));
      const contacts = (selectedConversation?.from || [])
        .filter((contact) => !contact.isHHM)
        .map((contact) => ({
          id: contact?.id,
          name: `${contact?.displayName}`,
          email: `${contact?.email}`,
          mobile_phone: contact?.mobile_phone,
        }));
      resetConversationSchema(selectedConversation?.conversation_type, {
        household_members,
        contacts,
        message_type: selectedConversation?.conversation_type,
      });
    }

    if (!selectedConversation?.id && isReply) {
      handleClose();
      setIsReply(false);
    }
  }, [
    selectedConversation?.id,
    selectedConversation?.conversationType,
    selectedConversation?.from,
    isReply,
    setIsReply,
  ]); //eslint-disable-line

  const handleHouseholdMembersChange = async (value) => {
    const members = value.filter((v) => Boolean(v.isHHM));
    const selectedContacts = value.filter((v) => !Boolean(v.isHHM));
    const conversationId = selectedConversation?.id || null;
    const selectedConversationApplicantPool =
      selectedConversation?.certification?.applicant_pool?.id;

    const certificationApplicantPool = certification?.applicant_pool?.id;

    const payloadEmails = value
      .map((e) => e?.email_address_id)
      .filter((el) => el !== undefined);
    await setFieldValue('household_members', members);
    await setFieldValue('contacts', selectedContacts);
    await setFieldValue('emails', payloadEmails);
    if (
      selectedConversationApplicantPool !== certificationApplicantPool ||
      (!conversationId && (members.length >= 1 || selectedContacts.length >= 1))
    ) {
      await createEmptyConversation(members, selectedContacts, payloadEmails);
    } else if (
      conversationId &&
      (members.length >= 1 || selectedContacts.length >= 1)
    ) {
      await updateEmptyConversationRecipients(
        members,
        selectedContacts,
        conversationId,
        payloadEmails
      );
    }
  };

  const createEmptyConversation = async (members, contacts, emails) => {
    const destinations = [...(members || []), ...(contacts || [])];
    const conversationPayload = {
      subject: `${destinations?.[0]?.name} SMS Conversation`,
      household_members_users: uniq(members.map((member) => member?.user_id)),
      contacts: contacts.map((contact) => contact?.id),
      members_emails: emails,
      hhm_mobile_phone: members[0]?.mobile_phone?.id,
      property: selectedConversation?.certification?.property.id,
      certification: selectedConversation?.id
        ? selectedConversation?.certification?.id
        : isUserDashboard || isProperty
        ? user_certification?.id
        : certification?.id,
      compliance_users: [user.id],
      conversation_type: values.message_type,
    };
    const selectedPhoneNumber = destinations[0]?.mobile_phone?.phone_number;
    let existingSmsConversationId = null;

    if (
      selectedPhoneNumber &&
      certification?.property &&
      certification?.applicant_pool
    ) {
      const res = await axios.get(
        `communication/new_conversation/conversation/?fields=from_to_pair,id&conversation_type=${SMS_TYPE}&phone_number=${selectedPhoneNumber}&property=${certification.property}&certification__applicant_pool=${certification.applicant_pool.id}`
      );
      if (res?.data?.length) {
        existingSmsConversationId = res?.data[0]?.id;
      }
    }

    let createdConversationId = null;
    if (!existingSmsConversationId) {
      const response = await axios.post(
        'communication/new_conversation/conversation/',
        conversationPayload
      );
      createdConversationId = response?.data?.id;
    }

    const finalId = createdConversationId || existingSmsConversationId;

    if (finalId) {
      dispatch(
        getConversation(finalId, {
          expand: [
            'certification',
            'certification.property',
            'certification.applicant_pool',
            'certification.unit',
            'mobile_phone',
            'hhm_mobile_phone',
            'household_members_users.household_member.mobile_phone',
            'last_message.attachments',
            'last_message.message_type',
            'contacts',
            'contacts.mobile_phone',
          ],
          fields: [
            'from_to_pair',
            'certification.property',
            'certification.applicant_pool.id',
            'conversation_type',
            'mobile_phone.id',
            'mobile_phone.phone_number',
            'mobile_phone.is_reachable',
            'mobile_phone.is_valid',
            'hhm_mobile_phone.id',
            'hhm_mobile_phone.phone_number',
            'hhm_mobile_phone.is_reachable',
            'hhm_mobile_phone.is_valid',
            'id',
            'certification.id',
            'certification.is_done',
            'certification.unit.name',
            'certification.log_number',
            'certification.display_name',
            'add_to_certification_file',
            'last_message_date',
            'created',
            'has_new_message',
            'subject',
            'household_members_users.id',
            'household_members_users.email',
            'household_members_users.first_name',
            'household_members_users.last_name',
            'household_members_users.household_member.id',
            'household_members_users.household_member.mobile_phone',
            'last_message',
            'contacts.id',
            'contacts.first_name',
            'contacts.last_name',
            'contacts.email',
            'contacts.mobile_phone.id',
            'contacts.mobile_phone.phone_number',
            'contacts.mobile_phone.is_reachable',
            'contacts.mobile_phone.is_valid',
          ],
        })
      );
    }
  };

  const updateEmptyConversationRecipients = async (
    members,
    contacts,
    conversationId,
    emails
  ) => {
    axios
      .patch(`communication/new_conversation/conversation/${conversationId}/`, {
        household_members_users: uniq(members.map((member) => member?.user_id)),
        contacts: contacts.map((contact) => contact?.id),
        members_emails: emails,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            updateConversation({
              value: {
                household_members_users: members.map((member) => member.user),
                contacts: contacts,
              },
              conversationId,
            })
          );
        }
      });
  };

  return (
    <>
      <Autocomplete
        fullWidth
        filterSelectedOptions
        multiple
        disablePortal
        disabled={isReply}
        isOptionEqualToValue={(opt, val) => {
          return +val?.id === +opt?.id;
        }}
        options={[...memberOptions, ...contactsOptions]}
        getOptionLabel={(opt) => {
          return opt?.name || '';
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start !important',
            }}
            {...props}
          >
            {option.name}
            <Typography>
              {!option?.household && (
                <Label sx={{ ml: 1 }} variant="ghost" color="default">
                  Property
                </Label>
              )}
            </Typography>
            <Box
              component="span"
              sx={{
                color: 'text.secondary',
                display: 'flex',
                alignItems: 'center',
                ml: 1,
              }}
            >
              {`<${option.mobile_phone?.phone_number}>`}
              <Label
                sx={{ ml: 1 }}
                variant="ghost"
                color={
                  option.mobile_phone?.is_reachable ? 'primary' : 'warning'
                }
              >
                {option.mobile_phone?.is_reachable
                  ? 'Reachable'
                  : 'Not reachable'}
              </Label>
              {option?.mobile_phone?.is_primary && (
                <Tooltip
                  title={
                    'This is the primary phone number for the household member.'
                  }
                >
                  <StarIcon
                    color={'secondary'}
                    sx={{ width: '17px', height: '17px', ml: 1 }}
                  />
                </Tooltip>
              )}
            </Box>
          </Box>
        )}
        value={[...(values?.household_members || [])]}
        getOptionDisabled={(option) => {
          if (values?.household_members?.length === 1) {
            return true;
          } else {
            return !option.mobile_phone?.is_reachable;
          }
        }}
        groupBy={(option) => option.categoryLabel}
        sx={{
          '& .MuiAutocomplete-inputRoot': { pl: 3, py: 1 },
          '& .MuiAutocomplete-input': { pl: 3, py: 1 },
          '& .MuiAutocomplete-inputRoot::before': { borderColor: 'grey.300' },
          '& .MuiAutocomplete-endAdornment': { right: 16 },
        }}
        onChange={(event, value) => {
          handleHouseholdMembersChange(value);
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={getChipLabel(option)}
              disabled={isReply}
              sx={{
                '&.MuiChip-root.Mui-disabled': {
                  opacity: 1,
                },
              }}
              {...getTagProps({ index })}
              {...(isReply ? { onDelete: null } : {})}
            />
          ))
        }
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              size="small"
              variant="standard"
              placeholder={
                isReply
                  ? ''
                  : values?.contacts?.length +
                      values?.household_members?.length ===
                    0
                  ? 'To'
                  : ''
              }
            />
            {Boolean(touched.household_members && errors.household_members) && (
              <FormHelperText sx={{ pl: 3 }} error>
                {errors.household_members}
              </FormHelperText>
            )}
          </>
        )}
      />

      {!memberOptions?.length && !isReply && (
        <Typography
          variant="subtitle1"
          sx={{ textAlign: 'center', py: 4, mb: 'auto' }}
        >
          This household does not have a member with a reachable mobile phone.
        </Typography>
      )}

      {(memberOptions?.length > 0 || isReply) && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflowY: 'auto',
            }}
          >
            <TextField
              fullWidth
              multiline
              value={message}
              minRows={5}
              onChange={(e) => {
                setMessage(e?.target?.value || '');
                setValidMessage(
                  e?.target?.value?.length > 0 &&
                    e?.target?.value?.length <= MAX_CHARACTERS_PER_MESSAGE
                );
              }}
              placeholder="Type a message"
              inputProps={{ maxLength: MAX_CHARACTERS_PER_MESSAGE }}
              sx={{
                mb: 'auto',
                '& fieldset': {
                  border: 'none !important',
                },
              }}
            />
          </Box>

          <FormHelperText sx={{ pr: 3, fontSize: 16, pb: 1, display: 'flex' }}>
            {message?.length > MAX_CHARACTERS_PER_MESSAGE && (
              <Box
                component="span"
                sx={{
                  color: 'error.main',
                  pl: 3,
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                The message is too big.
              </Box>
            )}

            <Box component="span" sx={{ ml: 'auto' }}>
              {message?.length} characters / {segmets} segment
              {segmets === 0 || segmets > 1 ? 's' : ''}
            </Box>
          </FormHelperText>
        </>
      )}

      <Divider />

      <Box sx={{ py: 2, px: 3, display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          onClick={handleSubmit}
          disabled={!validMessage || !isValid}
          sx={{ mr: 2 }}
        >
          Send
        </LoadingButton>

        {selectedConversation?.id && !!values?.household_members?.length && (
          <MessageTemplates
            messageType={SMS_TYPE}
            conversation={selectedConversation}
            conversationId={selectedConversation?.id}
            onselectTemplate={handleTemplateSelection}
          />
        )}
      </Box>
    </>
  );
};

export default memo(SMSCompose);
