import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// material
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// icons
import LockIcon from '@mui/icons-material/Lock';
// routes
import { COMPLIANCE_PATHS, HOUSEHOLD_ID, UNIT_ID } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';
// components
import HouseholdLanguage from '../../../Household/HouseholdLanguage';

export const SelectedMemberDetails = ({
  selectedMember,
  currentCertification,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);

  const getPropertyViewPath = (propertySlug) => {
    return COMPLIANCE_PATHS.general.property.replace(
      ':property_slug',
      propertySlug
    );
  };

  const getCertificationViewPath = (id, propertySlug) => {
    return COMPLIANCE_PATHS.certification.view
      .replace(':property_slug', propertySlug)
      .replace(':certification_id', id);
  };

  const getHouseholdViewPath = (id, propertySlug) => {
    return COMPLIANCE_PATHS.household.view
      .replace(':property_slug', propertySlug)
      .replace(HOUSEHOLD_ID, id);
  };

  const getUnitViewPath = (id, propertySlug) => {
    return COMPLIANCE_PATHS.unit.view
      .replace(':property_slug', propertySlug)
      .replace(UNIT_ID, id);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const resendCredentials = () => {
    const dataToSend = { household_member_id: selectedMember.id };

    axios
      .post('user_profile/regenerate-member-password/', dataToSend)
      .then((response) => {
        if (response && (response.status === 200 || response.status)) {
          enqueueSnackbar('Credentials sent.', { variant: 'success' });
        } else {
          enqueueSnackbar(response.data.error, { variant: 'error' });
        }
      });
    handleClose();
  };

  return (
    <>
      <Grid item xs={12} md={8}>
        <Box sx={{ mt: 2 }}>
          <Stack
            direction="row"
            gap={0.5}
            whiteSpace="nowrap"
            alignItems="center"
          >
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ lineHeight: '18px', textTransform: 'capitalize' }}
            >
              {`${selectedMember.first_name} ${selectedMember.last_name}` ||
                selectedMember.email}
            </Typography>
            <HouseholdLanguage
              preferredLanguage={selectedMember?.preferred_language?.name}
            />
          </Stack>
          <Typography
            variant="caption"
            color="text.disabled"
            style={{ textTransform: 'capitalize' }}
          >
            {`${
              selectedMember.member_type.display_name ||
              selectedMember.member_type.name
            }`}
          </Typography>
          {currentCertification && (
            <Box mt={1}>
              <Typography
                variant="body2"
                color="text.disabled"
                style={{ textTransform: 'capitalize' }}
              >
                <b>Household: </b>
                <RouterLink
                  to={getHouseholdViewPath(
                    selectedMember.household.id,
                    currentCertification?.property.slug
                  )}
                >
                  {`${selectedMember.household?.name || '-'}`}
                </RouterLink>
              </Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2" color="text.disabled">
                  <b>Certification: </b>
                  <RouterLink
                    to={getCertificationViewPath(
                      currentCertification.id,
                      currentCertification?.property.slug
                    )}
                  >
                    {currentCertification.display_name}
                  </RouterLink>
                </Typography>
                {!currentCertification?.is_active &&
                  currentCertification.is_done && (
                    <Tooltip
                      title="This certification is done."
                      placement="top"
                      arrow
                    >
                      <LockIcon
                        sx={{ color: theme.palette.grey[400], fontSize: 20 }}
                      />
                    </Tooltip>
                  )}
              </Stack>
              {currentCertification?.log_number && (
                <Typography
                  variant="caption"
                  color="text.disabled"
                  style={{ textTransform: 'capitalize' }}
                >
                  {`Log Number: ${
                    String(currentCertification?.log_number) || '-'
                  }`}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="text.disabled"
                style={{ textTransform: 'capitalize' }}
              >
                <b>Property: </b>
                <RouterLink
                  to={getPropertyViewPath(currentCertification?.property.slug)}
                >
                  {`${currentCertification?.property?.name || '-'}`}
                </RouterLink>
              </Typography>
            </Box>
          )}
          {selectedMember.household?.unit?.id && (
            <Typography
              variant="body2"
              color="text.disabled"
              style={{ textTransform: 'capitalize' }}
            >
              <b>Unit: </b>
              <RouterLink
                to={getUnitViewPath(
                  selectedMember.household?.unit?.id,
                  selectedMember?.household?.unit?.building?.property?.slug
                )}
              >
                {`${selectedMember.household?.unit?.name || '-'}`}
              </RouterLink>
            </Typography>
          )}
          {selectedMember.household?.unit?.id && (
            <Typography
              paragraph={true}
              my={0}
              variant="caption"
              color="text.disabled"
              style={{ textTransform: 'capitalize' }}
            >
              {`Building Name: ${
                !!selectedMember.household?.unit?.building?.name
                  ? selectedMember.household?.unit?.building?.name
                  : '-'
              }`}
            </Typography>
          )}
          {selectedMember.household?.unit?.id && (
            <Typography
              paragraph={true}
              my={0}
              variant="caption"
              color="text.disabled"
              style={{ textTransform: 'capitalize' }}
            >
              {`Applicant Pool: ${
                !!selectedMember.household?.unit?.building?.applicant_pool?.name
                  ? selectedMember.household?.unit?.building?.applicant_pool
                      ?.name
                  : '-'
              }`}
            </Typography>
          )}
        </Box>
      </Grid>
      {currentCertification &&
        currentCertification.is_active &&
        !currentCertification.is_done && (
          <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
            <Stack mt={2} direction="column" spacing={1}>
              <Button
                component={RouterLink}
                to={getCertificationViewPath(
                  currentCertification.id,
                  currentCertification?.property.slug,
                  '?tab=communiations'
                )}
                variant="contained"
                size="small"
                color="info"
              >
                Start Conversation
              </Button>
              <Button
                variant="contained"
                size="small"
                color="info"
                onClick={() => setOpenDialog(true)}
              >
                Resend Credentials
              </Button>
            </Stack>

            {openDialog && (
              <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>Resend Credentials</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    An email will be send to the household member with new
                    credentials to access the application.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    onClick={resendCredentials}
                    color="primary"
                    variant="contained"
                  >
                    Resend
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Grid>
        )}
    </>
  );
};
