import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  building: {},
};

const slice = createSlice({
  name: 'Building',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET single building
    getBuildingSuccess(state, action) {
      state.isLoading = false;
      state.building = action.payload;
    },

    // PATCH single building
    updateBuildingSuccess(state, action) {
      state.isLoading = false;
      state.building = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBuilding(buildingId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `property_portfolio/buildings/${buildingId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getBuildingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBuilding(buildingId, buildingData, queryParams) {
  return async (dispatch) => {
    let responseStatus = 'success';
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `property_portfolio/buildings/${buildingId}/?${parseQueryParams(
          queryParams
        )}`,
        buildingData
      );
      dispatch(slice.actions.updateBuildingSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      responseStatus = 'error';
    }
    return responseStatus;
  };
}
