import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import xss from 'xss';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import axios from 'src/utils/axios';
import axiosInstance from 'src/utils/axios';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarIcon from '@mui/icons-material/Star';
import { uniq } from 'lodash';
// material
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
//
import { QuillEditor } from '../editor';
import { useSnackbar } from 'notistack';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  getConversation,
  sendMessageSuccess,
  setShouldReload,
  updateConversation,
  updateSelectedConversation,
} from 'src/redux/slices/conversationsV2';
import MailDetailsAttachments from './MailDetailsAttachments';
import MessageTemplates from './Templates';
import TemplatesWarning from './TemplatesWarning';
import { buildMessageFormData, getMessageById, InputStyle } from './utils';
import { EMAIL_TYPE } from './ConversationsV2';
import parseQueryParams from 'src/utils/query';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AttachFileManager from './AttachFileManager';
import Label from '../Label';
// ----------------------------------------------------------------------

const CONTACTS_LABEL = 'Contacts List';
const MEMBERS_LABEL = 'Household Members';
const EMPTY_CONVERSATION_LABEL = 'Empty Communication';

const getUserFromEmail = (email, user) => {
  let name = '';
  name += user?.first_name ? user.first_name : '';
  name += user?.last_name ? ' ' + user.last_name : '';
  if (user?.first_name !== email?.email) {
    name += email.email;
  }
  name = name.trim();
  return name;
};

const MailComposeV2 = ({
  onCloseCompose,
  isReply,
  setIsReply,
  reject,
  setFullScreen,
  isProperty = false,
  isForward = false,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { certification_id } = useParams();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { infoToReject } = useSelector((state) => state.certification);
  const { pathname } = useLocation();
  const isUserDashboard =
    pathname.startsWith('/workbench/user') ||
    pathname.startsWith('/workbench/my-renters');
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const certification = useSelector(
    ({ certification }) => certification?.certification,
    shallowEqual
  );
  const { user_certification } = useSelector(
    ({ userDashboard: { communication } }) => ({
      user_certification: communication.user_certification,
    }),
    shallowEqual
  );

  const _getMsgAndSubject = (subject = false) => {
    if (reject && infoToReject) {
      return subject ? infoToReject?.subject : infoToReject?.message?.text;
    }
    if (isForward) {
      return subject ? isForward.subject : isForward.message;
    }
    return '';
  };

  const [message, setMessage] = useState(_getMsgAndSubject());
  const [validMessage, setValidMessage] = useState(false);
  const [contactFilter, setContactFilter] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [householdMembersList, setHouseholdMembersList] = useState([]);
  const [templateIds, setTemplateIds] = useState([]);
  const [attachFileManager, setAttachFileManager] = useState(false);
  const [updateRecipients, setUpdateRecipients] = useState(false);

  const schemaShapes = {
    contacts: Yup.array(),
    certification: Yup.number(),
    subject:
      (isReply && selectedConversation?.messages?.length === 0) || !isReply
        ? Yup.string().required('Subject is required.')
        : Yup.string().nullable(),
    household_members: Yup.array().test('household_members', (value, data) => {
      if (
        value?.length < 1 &&
        data?.parent?.contacts?.length < 1 &&
        data?.parent?.emails < 1
      ) {
        return data.createError({
          message: 'At least 1 Household Member or Contact is required.',
          path: `household_members`,
        });
      }
      return true;
    }),
  };

  const certId = useMemo(() => {
    return (
      certification_id ||
      selectedConversation?.certification?.id ||
      user_certification?.id ||
      certification?.id
    );
  }, [
    certification_id,
    selectedConversation,
    user_certification,
    certification,
  ]);

  const certFile = useMemo(() => {
    return (
      selectedConversation?.certification?.certification_file ||
      user_certification?.certification_file ||
      certification?.certification_file
    );
  }, [selectedConversation, user_certification, certification]);

  const formik = useFormik({
    initialValues: {
      subject: _getMsgAndSubject(true),
      household_members: reject && infoToReject ? [infoToReject?.member] : [],
      contacts: [],
      certification: certId,
      files: isForward?.attachments || [],
      message_type: EMAIL_TYPE,
      emails: [],
    },
    validationSchema: Yup.object().shape(schemaShapes),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // if the user is on a selected conversation and is a reply use the selected conversation ID
        let conversationId = selectedConversation?.id || null;
        let conversationResponse = null;

        const conversationPayload = {
          subject: values.subject,
          household_members_users: uniq(
            values.household_members.map((m) => m?.id)
          ),
          members_emails: values.emails,
          contact_emails: values.contacts.map((m) => m?.id),
          certification: values.certification,
          compliance_users: [user.id],
          conversation_type: values.message_type,
          property: selectedConversation?.certification?.property?.id,
        };

        // if at this point the conversation ID has not been defined it means that is not
        // a reply and is not an existing SMS conversation
        if (!conversationId) {
          const { data } = await axios.post(
            'communication/new_conversation/conversation/',
            conversationPayload
          );
          conversationResponse = data;
          conversationId = data?.id;
        }
        // if the conversation was creatd correctly or the user is on a selected conversation
        if (conversationId) {
          if (
            selectedConversation?.subject === EMPTY_CONVERSATION_LABEL &&
            selectedConversation?.messages?.length === 0
          ) {
            const response = await axios.patch(
              `communication/new_conversation/conversation/${selectedConversation?.id}/`,
              {
                subject: values.subject,
              }
            );
            if (response.status === 200) {
              dispatch(
                updateConversation({
                  value: { subject: values.subject },
                  conversationId,
                })
              );
              dispatch(
                updateSelectedConversation({
                  value: { subject: values.subject },
                  conversationId,
                })
              );
            }
          }

          if (isReply && updateRecipients) {
            const updateRecipientsResponse = await axios.patch(
              `communication/new_conversation/conversation/${selectedConversation?.id}/`,
              {
                hhm_emails: uniq(values.household_members.map((m) => m?.id)),
                contacts: values.contacts.map((c) => c?.id),
              }
            );

            if (updateRecipientsResponse.status === 200) {
              dispatch(
                updateConversation({
                  value: {
                    household_members_users: values.household_members.map(
                      (member) => (member?.user ? member.user : member)
                    ),
                    contacts: values.contacts,
                  },
                  conversationId,
                })
              );

              const fromList = [
                ...values.household_members.map((m) => {
                  if (m) {
                    return {
                      name: m?.first_name || '',
                      displayName: `${m?.first_name || ''} ${
                        m?.last_name || ''
                      }`,
                      lastName: m?.last_name || '',
                      email: m?.email,
                      id: m?.id,
                      mobile_phone: m?.mobile_phone || null,
                      hhmId: m?.hhmId || null,
                      isHHM: true,
                    };
                  }
                  return m;
                }),
                ...values.contacts,
              ];

              dispatch(
                updateSelectedConversation({
                  value: {
                    from: fromList,
                  },
                  conversationId,
                })
              );
            }
          }

          const finalFiles = values?.files.map((_file) => {
            if (_file.title) {
              return _file.file;
            } else {
              return _file;
            }
          });

          const messagePayload = {
            conversation: conversationId,
            content: xss(message),
            message_type: values?.message_type,
            sender_user: user.id,
            files: finalFiles,
          };

          if (templateIds?.length) {
            messagePayload.templates = templateIds;
          }

          const newMessage = await axios.post(
            `communication/new_conversation/message/`,
            buildMessageFormData(messagePayload)
          );

          if (isReply) {
            const finalMessage = await getMessageById(newMessage?.data?.id);
            dispatch(
              sendMessageSuccess({
                conversationId: newMessage?.data?.conversation,
                message: finalMessage,
              })
            );
          }

          enqueueSnackbar('Message sent successfully.', { variant: 'success' });
          handleClose();
          resetForm();
          setMessage('');
          setSubmitting(false);
          dispatch(setShouldReload(true));
          setTemplateIds([]);
        } else {
          throw new Error(conversationResponse.toString());
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong sending the message.', {
          variant: 'error',
        });
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    setValues,
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
    isValid,
  } = formik;

  useEffect(() => {
    if (!certId) {
      return;
    }

    const params = parseQueryParams({
      expand: [
        'household.household_members',
        'household.household_members.user',
        'household.household_members.email_addresses',
        'household.household_members.member_type',
        'household.household_members.mobile_phone',
      ],
    });

    axiosInstance
      .get(`certification/certification/${certId}/?${params}`)
      .then(({ data }) => {
        const membersList = data?.household?.household_members;
        setHouseholdMembersList(membersList);
      });
  }, [certId]);

  const memberOptions = useMemo(() => {
    if (!certId || householdMembersList?.length === 0) {
      return [];
    }
    let options = householdMembersList.filter((member) => {
      return member?.email_addresses?.length > 0;
    });
    options = options.reduce((accumulator, member) => {
      const result = member?.email_addresses
        .filter(
          (memberEmail) =>
            memberEmail.verified &&
            !memberEmail.email.endsWith('prontohousing.io')
        )
        .map((memberEmail) => ({
          ...member,
          email: memberEmail.email,
          email_address_id: memberEmail.id,
          is_primary_email: memberEmail.is_primary,
          verified: memberEmail.verified,
        }));
      return accumulator.concat(result);
    }, []);

    options = options.map((el, idx) => ({
      name: `${el.first_name} ${el.last_name}`,
      user_id: el?.user?.id,
      id: el?.email_address_id,
      household: el?.household,
      hhmId: el?.id,
      email: el?.email,
      email_address_id: el?.email_address_id,
      mobile_phone: el?.mobile_phone,
      is_primary_email: el?.is_primary_email,
      is_email_verified: el?.verified,
      isHHM: true,
      categoryLabel: MEMBERS_LABEL,
      user: {
        id: el?.id,
        first_name: el.first_name,
        last_name: el.last_name,
        email: el?.email,
        email_addresses: el?.email_addresses,
      },
    }));
    return options;
  }, [certId, householdMembersList]);

  const findOrCreateContact = async (contactEmail) => {
    try {
      if (!contactEmail) {
        return;
      }
      const schema = Yup.string().email().required();
      await schema.validate(contactEmail);
      const found = contactsOptions
        .map((contact) =>
          contact?.email_addresses?.filter(
            (email) => email?.email === contactEmail
          )
        )
        .flat();
      if (
        !contactsOptions?.filter(
          (email) => email.email_address === contactEmail
        ).length
      ) {
        const payload = {
          ...contactFilter,
          email_addresses: [{ email: contactEmail }],
        };
        const { data } = await axios.post(
          `contacts/create_or_update_contact/`,
          payload
        );
        const newContact = {
          ...data,
          name: data.first_name
            ? `${data.first_name} ${data.last_name}`
            : `${data?.email_addresses[0]?.email}`,
          categoryLabel: CONTACTS_LABEL,
          email: data?.email_addresses[0]?.email,
          id: data?.email_addresses[0]?.id,
        };
        const newContacts = [...values.contacts, newContact];
        setFieldValue('contacts', newContacts);
        setContactsOptions([...contactsOptions, newContact]);
        if (!selectedConversation?.id && newContacts?.length >= 1) {
          createEmptyConversation(
            values.household_members,
            newContacts,
            values?.emails
          );
        } else if (
          selectedConversation.id &&
          (values.household_members.length >= 1 ||
            values.contacts.length >= 1 ||
            newContacts?.length >= 1)
        ) {
          updateEmptyConversationRecipients(
            values.household_members,
            [...values.contacts, newContact],
            selectedConversation?.id,
            values?.emails
          );
        }
      } else if (
        values.contacts.find((c) => c.email === found.email) === undefined
      ) {
        setFieldValue('contacts', [...values.contacts, found]);
      }
      setEmailError(null);
    } catch (error) {
      setEmailError(`The email is not valid`);
      console.error(error);
    }
  };

  const removeFile = useCallback(
    (name) => {
      setFieldValue(
        'files',
        values.files.filter((f) => f.name !== name)
      );
    },
    [setFieldValue, values]
  );

  const handleAttach = () => fileInputRef.current.click();

  const handleClose = useCallback(() => {
    resetForm();
    setMessage('');
    onCloseCompose();
    setFullScreen(false);
    setTemplateIds([]);
  }, []); // eslint-disable-line

  const resetFormikValues = useCallback(
    (value = {}) => {
      const newValues = { ...formik.values, ...value };
      resetForm();
      setTimeout(() => {
        setValues(newValues, true);
      }, 100);
    },
    [formik.values, resetForm, setValues]
  );

  const handleTemplateSelection = useCallback(
    (value, templateId) => {
      setTemplateIds((t) => {
        return [...t, templateId];
      });

      const newMessage = `${message}${value || ''}`;
      setMessage(newMessage);
    },
    [message]
  );

  const resetFormData = useCallback(
    (overrides = {}) => {
      setMessage('');
      setValidMessage(false);
      resetFormikValues(overrides);
    },
    [resetFormikValues]
  );

  // handle reply action
  useEffect(() => {
    if (selectedConversation && isReply) {
      const household_members = selectedConversation?.hhm_emails?.map(
        (email) => {
          return {
            ...email,
            name: `${email?.household_member?.first_name} ${email?.household_member?.last_name}`,
          };
        }
      );
      const contacts = selectedConversation?.contact_emails?.map((email) => {
        const name = getUserFromEmail(email, email.contact);
        return {
          ...email,
          name: name,
        };
      });
      resetFormData({
        household_members,
        contacts,
        message_type: selectedConversation?.conversation_type,
      });
    }

    if (!selectedConversation?.id && isReply) {
      handleClose();
      setIsReply(false);
    }
  }, [
    selectedConversation?.id,
    selectedConversation?.conversationType,
    selectedConversation?.from,
    isReply,
    setIsReply,
  ]); //eslint-disable-line

  // fetch contacts when the component is mounted
  useEffect(() => {
    const fetchContacts = async () => {
      if (!certId) {
        setContactsOptions([]);
        return;
      }

      try {
        const certParams = parseQueryParams({
          expand: ['household', 'property', 'property.portfolio'],
          fields: [
            'household.id',
            'property.id',
            'property.portfolio',
            'property.portfolio.id',
            'property.portfolio.property_owner_company',
          ],
        });

        const { data } = await axiosInstance.get(
          `certification/certification/${certId}/?${certParams}`
        );

        const contactParams = {
          property: data?.property?.id,
          portfolio: data?.property?.portfolio?.id,
          company: data?.property?.portfolio?.property_owner_company,
          for_household: data?.household?.id,
          expand: [
            'email_addresses',
            'mobile_phones',
            'fax_number',
            'household',
          ],
          fields: [
            'email_addresses',
            'mobile_phones',
            'fax_number',
            'first_name',
            'last_name',
            'household.id',
            'household.name',
          ],
        };
        setContactFilter({
          property: data?.property?.id,
          portfolio: data?.property?.portfolio?.id,
          company: data?.property?.portfolio?.property_owner_company,
          household: data?.household?.id,
        });
        // const params = parseQueryParams(filter);
        const { data: contacts } = await axios.get(
          `contacts/?${parseQueryParams(contactParams)}`
        );
        let flatContactEmailAddresses = [];
        contacts.forEach((contact) => {
          contact?.email_addresses?.forEach((email) => {
            if (email?.email && !email?.email.endsWith('prontohousing.io')) {
              flatContactEmailAddresses.push({
                first_name: contact?.first_name,
                last_name: contact?.last_name,
                household: contact?.household,
                ...email,
              });
            }
          });
        });
        let updatedContacts = flatContactEmailAddresses.map((c) => ({
          ...c,
          name: c.first_name ? `${c.first_name} ${c.last_name}` : `${c.email}`,
          categoryLabel: CONTACTS_LABEL,
        }));
        setContactsOptions(updatedContacts);
      } catch (error) {
        console.error(error);
      }
    };

    fetchContacts();
  }, [certId]);

  const handleHouseholdMembersChange = async (value) => {
    const members = value.filter((v) => Boolean(v.isHHM));
    const selectedContacts = value.filter((v) => !Boolean(v.isHHM));
    const conversationId = selectedConversation?.id || null;

    const payloadEmails = value
      .map((e) => e?.email_address_id)
      .filter((el) => el !== undefined);
    await setFieldValue('household_members', members);
    await setFieldValue('contacts', selectedContacts);
    await setFieldValue('emails', payloadEmails);
    setEmailError(null);

    if (isReply) {
      return;
    }

    if (
      !conversationId &&
      (members.length >= 1 || selectedContacts.length >= 1)
    ) {
      createEmptyConversation(members, selectedContacts, payloadEmails);
    } else if (
      conversationId &&
      (members.length >= 1 || selectedContacts.length >= 1)
    ) {
      updateEmptyConversationRecipients(
        members,
        selectedContacts,
        conversationId,
        payloadEmails
      );
    }
  };

  const createEmptyConversation = async (members, contacts, emails) => {
    const conversationPayload = {
      subject: EMPTY_CONVERSATION_LABEL,
      household_members_users: uniq(members.map((member) => member?.user_id)),
      hhm_emails: emails,
      contact_emails: contacts.map((contact) => contact?.id),
      property: selectedConversation?.certification?.property.id,
      certification: selectedConversation?.id
        ? selectedConversation?.certification?.id
        : isUserDashboard || isProperty
        ? user_certification?.id
        : certification?.id,
      compliance_users: [user.id],
      conversation_type: values.message_type,
    };

    const response = await axios.post(
      'communication/new_conversation/conversation/',
      conversationPayload
    );
    let finalId = response?.data?.id;

    if (finalId) {
      dispatch(
        getConversation(finalId, {
          expand: [
            'certification',
            'certification.property',
            'certification.unit',
            'mobile_phone',
            'hhm_mobile_phone',
            'hhm_emails.household_member',
            'contact_emails.contact',
            'household_members_users.household_member.mobile_phone',
            'last_message.attachments',
            'last_message.message_type',
            'contacts',
            'contacts.mobile_phone',
          ],
          fields: [
            'from_to_pair',
            'certification.property',
            'conversation_type',
            'mobile_phone.id',
            'mobile_phone.phone_number',
            'mobile_phone.is_reachable',
            'mobile_phone.is_valid',
            'hhm_mobile_phone.id',
            'hhm_mobile_phone.phone_number',
            'hhm_mobile_phone.is_reachable',
            'hhm_mobile_phone.is_valid',
            'id',
            'certification.id',
            'certification.is_done',
            'certification.unit.name',
            'certification.log_number',
            'certification.display_name',
            'add_to_certification_file',
            'last_message_date',
            'created',
            'has_new_message',
            'subject',
            'household_members_users.id',
            'household_members_users.email',
            'household_members_users.first_name',
            'household_members_users.last_name',
            'household_members_users.household_member.id',
            'household_members_users.household_member.mobile_phone',
            'last_message',
            'contacts.id',
            'contacts.first_name',
            'contacts.last_name',
            'contacts.email',
            'contacts.mobile_phone.id',
            'contacts.mobile_phone.phone_number',
            'contacts.mobile_phone.is_reachable',
            'contacts.mobile_phone.is_valid',
            'hhm_emails',
            'contact_emails.contact',
          ],
        })
      );
    }
  };

  const updateEmptyConversationRecipients = async (
    members,
    contacts,
    conversationId,
    emails
  ) => {
    axios
      .patch(`communication/new_conversation/conversation/${conversationId}/`, {
        household_members_users: uniq(members.map((member) => member?.user_id)),
        contact_emails: contacts.map((contact) => contact?.id),
        hhm_emails: emails,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            updateConversation({
              value: {
                household_members_users: members.map((member) => member.user),
                contact_emails: contacts.map((contact) => contact?.id),
                hhm_emails: emails,
              },
              conversationId,
            })
          );
        }
      });
  };

  const getFileNameAndExtension = (file) => {
    const nameParts = file.name?.split('.');
    const extension = nameParts.pop();
    const name = nameParts.join('.');

    return { name, extension };
  };

  const getNormalizedFileName = (file) => {
    const { name, extension } = getFileNameAndExtension(file);
    return `${name.trim()}.${extension}`;
  };

  const replaceInvalidFiles = (file) => {
    const normalizedFileName = getNormalizedFileName(file);
    if (normalizedFileName === file.name) {
      return file;
    }

    return new File([file], normalizedFileName, {
      type: file.type,
    });
  };

  const onFileUpload = (event) => {
    let newFiles = Object.values(event.target.files).filter(
      (newFile) =>
        !values.files.some((vf) => vf.name === getNormalizedFileName(newFile))
    );
    newFiles = newFiles.map(replaceInvalidFiles);

    if (newFiles.length > 0) {
      const finalValue = [...values.files, ...newFiles];
      setFieldValue('files', finalValue);
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (isForward) {
      setMessage(isForward.message || '');
      setFieldValue('subject', `${isForward.subject}`);
      setFieldValue('files', isForward.attachments);
      setFieldValue('household_members', []);
    } else {
      setMessage('');
      setFieldValue('subject', '');
      setFieldValue('files', []);
    }
  }, [isForward]); //eslint-disable-line

  return (
    <>
      <Autocomplete
        onBlur={(e) => findOrCreateContact(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            findOrCreateContact(e.target.value);
          }
        }}
        fullWidth
        filterSelectedOptions
        multiple
        disablePortal
        // disabled={isReply}
        disableClearable={isReply}
        isOptionEqualToValue={(opt, val) => {
          return +val?.id === +opt?.id;
        }}
        options={[...memberOptions, ...contactsOptions]}
        getOptionLabel={(opt) => {
          return opt?.name || '';
        }}
        filterOptions={(options, state) => {
          const searchTerm = state.inputValue;
          return options.filter((option) => {
            const nameMatch = option?.name
              ?.toLowerCase()
              ?.includes(searchTerm.toLowerCase());
            const emailMatch = option.email
              .toLowerCase()
              .includes(searchTerm.toLowerCase());
            return nameMatch || emailMatch;
          });
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'flex-start !important',
            }}
            {...props}
          >
            {option.name}
            <Typography>
              {!option?.household && (
                <Label sx={{ ml: 1 }} variant="ghost" color="default">
                  Property
                </Label>
              )}
            </Typography>
            {option.name !== option.email && (
              <Box
                component="span"
                sx={{
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  ml: 1,
                }}
              >
                {`<${option.email}>`}
                {option?.is_primary_email && (
                  <Tooltip
                    title={
                      'This is the primary email address for the household member.'
                    }
                  >
                    <StarIcon
                      color={'secondary'}
                      sx={{ width: '17px', height: '17px', ml: 1 }}
                    />
                  </Tooltip>
                )}
                {option.is_email_verified && (
                  <Tooltip title={'This email address has been verified.'}>
                    <VerifiedIcon
                      color={'primary'}
                      sx={{ width: '17px', height: '17px', ml: 1 }}
                    />
                  </Tooltip>
                )}
                {!option.is_email_verified &&
                  option?.is_email_verified !== undefined && (
                    <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                      &nbsp;(Unverified)
                    </Typography>
                  )}
              </Box>
            )}
          </Box>
        )}
        value={[
          ...(values?.household_members || []),
          ...(values?.contacts || []),
        ]}
        getOptionDisabled={(option) => {
          return (
            !option?.is_email_verified &&
            option?.is_email_verified !== undefined
          );
        }}
        groupBy={(option) => option.categoryLabel}
        sx={{
          '& .MuiAutocomplete-inputRoot': { pl: 3, py: 1 },
          '& .MuiAutocomplete-input': { pl: 3, py: 1 },
          '& .MuiAutocomplete-inputRoot::before': { borderColor: 'grey.300' },
          '& .MuiAutocomplete-endAdornment': { right: 16 },
        }}
        onChange={(event, value) => {
          handleHouseholdMembersChange(value);
          if (isReply) {
            setUpdateRecipients(true);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const used =
              isReply &&
              (selectedConversation?.hhm_emails?.some(
                (from) => from.id === option.id
              ) ||
                selectedConversation?.contact_emails?.some(
                  (from) => from.id === option.id
                ));
            return (
              <Chip
                size="small"
                label={
                  option.name !== option.email
                    ? `${option?.displayName || option?.name || ''} <${
                        option.email
                      }>`
                    : option.email
                }
                disabled={used}
                sx={{
                  '&.MuiChip-root.Mui-disabled': {
                    opacity: 1,
                  },
                }}
                {...getTagProps({ index })}
                {...(used ? { onDelete: null } : {})}
              />
            );
          })
        }
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              size="small"
              variant="standard"
              placeholder={
                isReply
                  ? ''
                  : values?.contacts?.length +
                      values?.household_members?.length ===
                    0
                  ? 'To'
                  : ''
              }
            />
            {Boolean(touched.household_members && errors.household_members) && (
              <FormHelperText sx={{ pl: 3 }} error>
                {errors.household_members}
              </FormHelperText>
            )}
            {emailError && (
              <FormHelperText sx={{ pl: 3 }} error>
                {emailError}
              </FormHelperText>
            )}
          </>
        )}
      />

      {/* enable subject field for email type */}
      {(!isReply ||
        (isReply && selectedConversation?.messages?.length === 0)) && (
        <>
          <InputStyle
            disableUnderline
            placeholder="Subject"
            {...getFieldProps('subject')}
          />
          {Boolean(touched.subject && errors.subject) && (
            <FormHelperText sx={{ pl: 3 }} error>
              {errors.subject}
            </FormHelperText>
          )}
        </>
      )}

      <Grid container sx={{ maxHeight: 'calc(100% - 200px)', flex: 1 }}>
        <Grid
          item
          xs={attachFileManager ? 6 : 12}
          sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <QuillEditor
            simple
            id="compose-mail"
            value={message}
            onChange={(value, delta, source, editor) => {
              setMessage(value.replace(/<img .*?>/g, '') || '');
              setValidMessage(editor?.getText()?.trim()?.length > 0);
              if (!value) {
                setTemplateIds([]);
              }
            }}
            placeholder="Type a message"
            handleAttach={handleAttach}
            sx={{
              borderColor: 'transparent',
              height: 'calc(-100%)',
              flexGrow: 1,
              '& .quill ': {
                height: 'calc(100% - 45px)',
              },
              '& .ql-editor': {
                maxHeight: '100%',
                minHeight: '100%',
              },
              '& .ql-snow .ql-editor pre.ql-syntax': {
                backgroundColor: 'transparent',
                color: 'inherit',
              },
            }}
          />

          {values?.files?.length > 0 && !attachFileManager && (
            <MailDetailsAttachments
              files={values.files.filter((_file) => !_file.title)}
              showDelete
              removeFile={removeFile}
            />
          )}

          <input
            multiple
            hidden
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onFileUpload}
          />

          {Boolean(touched.message && errors.message) && (
            <FormHelperText sx={{ pl: 3 }} error>
              {errors.message}
            </FormHelperText>
          )}
        </Grid>
        {attachFileManager && (
          <Grid item xs={6}>
            <AttachFileManager
              certificationFile={certFile}
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              errors={errors}
            />
          </Grid>
        )}
      </Grid>
      <Divider />

      <Box sx={{ py: 2, px: 3, display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          onClick={handleSubmit}
          disabled={!validMessage || !isValid}
          sx={{ mr: 2 }}
        >
          Send
        </LoadingButton>

        {!selectedConversation?.certification && <TemplatesWarning />}

        {selectedConversation?.id &&
          selectedConversation?.certification &&
          !!values?.household_members?.length && (
            <MessageTemplates
              messageType={EMAIL_TYPE}
              conversation={selectedConversation}
              conversationId={selectedConversation?.id}
              onselectTemplate={handleTemplateSelection}
            />
          )}
        {certFile && (
          <ToggleButtonGroup
            color="primary"
            exclusive
            value={attachFileManager}
            sx={{ mr: 2 }}
            onChange={() => {
              if (!!values.files.length) {
                setFieldValue('files', [
                  ...values.files.filter((_file) => !_file.title),
                ]);
              }
              setAttachFileManager((prev) => !prev);
            }}
          >
            <ToggleButton size="small" value={true}>
              {attachFileManager ? (
                <CheckBoxIcon sx={{ height: '20px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon sx={{ height: '20px' }} />
              )}
              Attach File from File Manager
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
    </>
  );
};

export default memo(MailComposeV2);
