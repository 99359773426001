import { Box, Grid, IconButton, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function FCFileItem({ title, index, handleDelete, file }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.id,
    data: file,
    // disabled: disabled,
  });

  const style = {
    position: 'relative',
    display: 'inline-block',
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} ref={setNodeRef} style={style}>
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.grey[200],
          borderRadius: '8px',
          p: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant={'body2'}
          sx={{
            color: (theme) => theme.palette.info.main,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <DragIndicatorIcon
            {...attributes}
            {...listeners}
            sx={{
              outline: 'none',
              height: '15px',
              width: '15px',
              // color: selected ? saved ? '#FFF' : '#7A4F01' : 'text.secondary',
              cursor: 'move',
            }}
          />
          <b>{index + 1}</b> &nbsp;
          {title}
        </Typography>
        <IconButton
          onClick={() => {
            handleDelete(index);
          }}
          size={'small'}
        >
          <CloseRoundedIcon sx={{ height: '15px', width: '15px' }} />
        </IconButton>
      </Box>
    </Grid>
  );
}
