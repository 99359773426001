import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import collapseFill from '@iconify/icons-eva/collapse-fill';
// import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TablePagination,
  Tooltip,
} from '@mui/material';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//
import { MHidden } from '../@material-extend';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { setParams } from 'src/redux/slices/conversationsV2';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  // height: 84,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: theme.spacing(2),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.up('md')]: {
    width: 240,
    flexDirection: 'row',
    '&.Mui-focused': { width: 280 },
  },
}));

// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: { xs: 'auto', md: 2 } }}>
      <IconButton
        sx={{ p: { xs: 0, sm: 1 } }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <IconButton
        sx={{ p: { xs: 0, sm: 1 } }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

MailToolbar.propTypes = {
  mails: PropTypes.number.isRequired,
  selectedMails: PropTypes.number.isRequired,
  onOpenSidebar: PropTypes.func,
  onToggleDense: PropTypes.func,
  onSelectAll: PropTypes.func,
  onDeselectAll: PropTypes.func,
};

export default function MailToolbar({
  mails,
  selectedMails,
  onOpenSidebar,
  onToggleDense,
  onSelectAll,
  onDeselectAll,
  fetchConversations,
  ...other
}) {
  /*
   const handleSelectChange = (event) => (event.target.checked ? onSelectAll() : onDeselectAll());
   const selectedAllMails = selectedMails === mails && mails > 0;
   const selectedSomeMails = selectedMails > 0 && selectedMails < mails;
   */

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const { params, count } = useSelector(
    ({ conversationsV2 }) => ({
      params: conversationsV2?.params || {},
      count: conversationsV2?.count,
      isLoading: conversationsV2.isLoading,
    }),
    shallowEqual
  );

  const updateParams = useCallback(
    (params = {}) => dispatch(setParams(params)),
    [dispatch]
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      updateParams({ page: newPage + 1 });
    },
    [updateParams]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const newSize = event.target.value;
      updateParams({ page: 1, page_size: newSize });
    },
    [updateParams]
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => updateParams({ search, page: 1 }), 350);
    return () => clearTimeout(timeOutId);
  }, [search, updateParams]);

  return (
    <RootStyle {...other}>
      <MHidden width="mdUp">
        <IconButton onClick={onOpenSidebar} size="large">
          <Icon icon={menuFill} />
        </IconButton>
      </MHidden>

      {/* <Checkbox checked={selectedAllMails} indeterminate={selectedSomeMails} onChange={handleSelectChange} /> */}
      <Tooltip title="Refresh">
        <IconButton onClick={fetchConversations} size="large">
          <Icon icon={refreshFill} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Dense">
        <IconButton onClick={onToggleDense} size="large">
          <Icon icon={collapseFill} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <Box sx={{ flexGrow: 1 }} />

      <FormControl size="small">
        <SearchStyle
          onChange={(e) => setSearch(e?.target?.value || '')}
          placeholder="Search message…"
          startAdornment={
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          }
        />
      </FormControl>

      <TablePagination
        sx={{
          border: 0,
          width: { xs: '100%', sm: 'auto' },
          '& .MuiTablePagination-select': {
            mr: 2,
          },
          '& .MuiTablePagination-displayedRows': {
            display: { xs: 'none', sm: 'block' },
          },
          '& .MuiTablePagination-spacer': {
            display: { xs: 'none', sm: 'block' },
          },
          '& .MuiToolbar-gutters': {
            pl: { xs: 0, sm: 2 },
            height: 'auto',
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, { label: 'All', value: -1 }]}
        component="div"
        count={count}
        rowsPerPage={params.page_size}
        page={params.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </RootStyle>
  );
}
