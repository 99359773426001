import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { memo, useCallback, useEffect, useState } from 'react';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
//
import Scrollbar from '../Scrollbar';
import EmptyContent from '../EmptyContent';
import MailItem from './MailItem';
import MailToolbar from './MailToolbar';
import ConversationItemSkeleton from './Skeleton';
import { shallowEqual } from 'react-redux';
import {
  FILTER_IDS,
  getConversations,
  setShouldReload,
} from 'src/redux/slices/conversationsV2';
import { mapConversation } from './utils';
import useAuth from '../../hooks/useAuth';
import FaxItem from './FaxItem';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
});

// ----------------------------------------------------------------------

const MailList = ({ onOpenSidebar, isUserDashboard, composeIsOpen }) => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );
  const { certification_id } = useParams();
  const { user } = useAuth();

  const propertyId = useSelector(
    (st) => st?.property?.property?.id,
    shallowEqual
  );

  const conversations = useSelector(
    ({ conversationsV2 }) => conversationsV2?.list,
    shallowEqual
  );
  const isLoading = useSelector(
    ({ conversationsV2 }) => conversationsV2?.isLoading,
    shallowEqual
  );
  const params = useSelector(
    ({ conversationsV2 }) =>
      ({
        ...conversationsV2?.params,
        ...(conversationsV2?.selectedFilter?.filters || {}),
      } || {}),
    shallowEqual
  );
  const shouldReload = useSelector(
    ({ conversationsV2 }) => conversationsV2?.shouldReload,
    shallowEqual
  );

  const [selectedMails, setSelectedMails] = useState([]);
  const [dense, setDense] = useState(false);
  const isEmpty = conversations.length < 1;

  const fetchConversations = useCallback(() => {
    let filterValue = !isUserDashboard
      ? certification_id
        ? `certification=${certification_id}&`
        : `property=${propertyId}&`
      : undefined;

    if (isUserDashboard) {
      if (!filterValue) {
        filterValue = `as_specialist=true&file_owner=${user?.id}&`;
      } else {
        filterValue += `as_specialist=true&compliance_user=${user?.id}&`;
      }
    }

    dispatch(
      getConversations(filterValue, {
        expand: [
          'mobile_phone',
          'contacts',
          'contacts.mobile_phones',
          'contacts.email_addresses',
          'documo_metadata',
          'certification',
          'certification.unit',
          'certification.property',
          'certification.household',
          'certification.household.unit',
          'contact_fax_number.contact',
          'household_members_users.household_member.mobile_phone',
          'hhm_emails.household_member.mobile_phones',
          'hhm_mobile_phone.household_member',
          'hhm_emails.household_member',
          'contact_emails.contact',
          'contact_mobile_phone.contact',
          'last_message',
          'last_message.sender_contact',
          'last_message.sender_user',
          'last_message.attachments',
          'last_message.message_type',
          'last_message.sender_user.household_members',
          'last_message.sender_user.household_members.member_type',
        ],
        fields: [
          'id',
          'from_to_pair',
          'conversation_type',
          'subject',
          'has_new_message',
          'created',
          'status',
          'documo_metadata',
          'hhm_mobile_phone.id',
          'hhm_mobile_phone.phone_number',
          'hhm_mobile_phone.is_reachable',
          'hhm_mobile_phone.is_valid',
          'hhm_mobile_phone.household_member.id',
          'hhm_mobile_phone.household_member.first_name',
          'hhm_mobile_phone.household_member.last_name',
          'mobile_phone.id',
          'mobile_phone.phone_number',
          'mobile_phone.is_reachable',
          'mobile_phone.is_valid',
          'certification.id',
          'certification.display_name',
          'certification.log_number',
          'certification.is_done',
          'certification.property',
          'certification.household.name',
          'certification.household.unit.id',
          'certification.household.unit.name',
          'last_message.content',
          'last_message.from_email',
          'last_message.created',
          'last_message_date',
          'last_message.sender_user.first_name',
          'last_message.sender_user.last_name',
          'last_message.sender_user.household_member',
          'last_message.sender_user.household_member.member_type.display_name',
          'household_members_users.id',
          'household_members_users.email',
          'household_members_users.first_name',
          'household_members_users.last_name',
          'household_members_users.household_member.id',
          'household_members_users.household_member.mobile_phone',
          'contacts.id',
          'contacts.first_name',
          'contacts.last_name',
          'contacts.email_addresses',
          'contacts.fax_number',
          'contacts.mobile_phones.id',
          'contacts.mobile_phones.phone_number',
          'contacts.mobile_phones.is_reachable',
          'contacts.mobile_phones.is_valid',
          'add_to_certification_file',
          'contact_fax_number.contact',
          'contact_fax_number.fax_number',
          'hhm_emails',
          'contact_emails',
        ],
        ordering: ['-has_new_message', '-last_message_date'],
        ...params,
      })
    );
  }, [
    certification_id,
    dispatch,
    isUserDashboard,
    params,
    propertyId,
    user?.id,
  ]);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    if (shouldReload) {
      fetchConversations();
      dispatch(setShouldReload(false));
    }
  }, [dispatch, fetchConversations, shouldReload]);

  const handleSelectAllMails = () => {
    setSelectedMails(conversations.allIds.map((mailId) => mailId));
  };

  const handleToggleDense = () => {
    setDense((prev) => !prev);
  };

  const handleDeselectAllMails = () => {
    setSelectedMails([]);
  };

  return (
    <RootStyle>
      <MailToolbar
        fetchConversations={fetchConversations}
        mails={conversations.length}
        selectedMails={selectedMails.length}
        onSelectAll={handleSelectAllMails}
        onOpenSidebar={onOpenSidebar}
        onDeselectAll={handleDeselectAllMails}
        onToggleDense={handleToggleDense}
      />

      <Divider />

      {isLoading && (
        <>
          <ConversationItemSkeleton />
          <ConversationItemSkeleton />
          <ConversationItemSkeleton />
          <ConversationItemSkeleton />
          <ConversationItemSkeleton />
        </>
      )}

      {!isEmpty && !isLoading && (
        <Scrollbar>
          {conversations.map((conversation) =>
            selectedFilter.id !== 'fax' ? (
              <MailItem
                key={conversation?.id}
                propertyName={conversation?.certification?.property?.name}
                isDense={dense}
                mail={mapConversation(conversation)}
                created={conversation?.created}
                isUserDashboard={isUserDashboard}
                isSelected={false}
              />
            ) : (
              <FaxItem
                key={conversation?.id}
                propertyName={conversation?.certification?.property?.name}
                isDense={dense}
                fax={mapConversation(conversation)}
                created={conversation?.created}
                isUserDashboard={isUserDashboard}
                isSelected={false}
              />
            )
          )}
        </Scrollbar>
      )}
      {isEmpty && !isLoading && (
        <EmptyContent
          title={
            params.filterBy === FILTER_IDS.all
              ? 'There is no conversation.'
              : 'There are no unread messages.'
          }
          img="/static/illustrations/illustration_empty_mail.svg"
          sx={{ flexGrow: 1, height: 'auto' }}
        />
      )}
    </RootStyle>
  );
};

export default memo(MailList);

MailList.propTypes = {
  onOpenSidebar: PropTypes.func,
};
