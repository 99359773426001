import {
  extractPDFPages,
  isAStoredElement,
  isAValidIndex,
} from '../../../DocumenManagerV2/utils';

export const filterByString = (value = '', search) => {
  return value?.toLowerCase().includes(search.toLowerCase());
};

/**
 * Build an array with N pages starting from N number
 * e.g. getPagesToInsert(4, 6) returns [{ number: 7, order: 6 }, { number: 8, order: 7 }, { number: 9, order: 8 }]
 *
 * @param {number} pagesQty Number of pages to generate
 * @param {number} insertBeforeThisPage The numbers on the array start's with this number
 * @returns {Array}
 */
export const getPagesToInsert = (pagesQty, insertBeforeThisPage) => {
  if (!pagesQty) {
    return [];
  }
  return [...Array(pagesQty).keys()].map((i) => ({
    id: null,
    number: insertBeforeThisPage + i,
    order: insertBeforeThisPage + i - 1,
    isNew: true,
    hasChanges: false,
    wasDeleted: false,
  }));
};

/**
 * contentChanged is a numbers Array with the pages that have changed
 */
export const onContentPageChange = (pages, contentChanged = []) => {
  const newPages = JSON.parse(JSON.stringify(pages));

  for (let i = 0; i < contentChanged.length; i++) {
    const pageNumberWithChanges = contentChanged[i];

    // look's for the answer that contains the page with changes
    const pageIndex = newPages.findIndex(
      (page) => page.number === pageNumberWithChanges
    );

    if (isAValidIndex(pageIndex)) {
      const page = { ...newPages[pageIndex] }; // clone the answer
      // set as edited the page if is not new
      page.hasChanges = isAStoredElement(page);
      newPages[pageIndex] = page;
    }
  }

  return newPages;
};

export const reorderPages = (pages, moved) => {
  return [...pages]
    .map((page) =>
      page.wasDeleted
        ? page
        : {
            ...page,
            // set the pages as edited if the page was moved and is not a new page
            ...(Boolean(moved[page?.number]) && isAStoredElement(page)
              ? { hasChanges: true }
              : {}),
            order: (moved[page?.number] || page.number) - 1,
            number: moved[page?.number] || page.number,
          }
    )
    .sort((a, b) => a.number - b.number);
};

export const onRemovePages = (pages, removedPages, moved) => {
  let newPages = JSON.parse(JSON.stringify(pages));

  // iterate over the array of removed pages
  // this is an array with the number of the pages e.g. [1, 2, 3]
  for (let i = 0; i < removedPages.length; i++) {
    const removedPage = removedPages[i];
    // find the page to remove
    const pageIdx = newPages.findIndex((p) => p?.number === removedPage);

    if (isAValidIndex(pageIdx)) {
      // mark as deleted the page only if is not a new page
      if (isAStoredElement(pages[pageIdx])) {
        newPages[pageIdx].number = -1;
        newPages[pageIdx].wasDeleted = true;
        newPages[pageIdx].hasChanges = true;
      }

      // remove the page if is not a saved page
      if (pages[pageIdx].isNew) {
        newPages.splice(pageIdx, 1);
      }
    }
  }

  return reorderPages(newPages, moved);
};

export const onMovePages = (pages, moved) => {
  const newPages = JSON.parse(JSON.stringify(pages));
  return reorderPages(newPages, moved);
};

export const onAddPages = (pages, added, moved = {}) => {
  let newPages = JSON.parse(JSON.stringify(pages));
  newPages = reorderPages(newPages, moved);

  for (let i = 0; i < added.length; i++) {
    newPages.push({
      id: null,
      number: added[i],
      order: added[i] - 1,
      isNew: true,
      hasChanges: false,
      wasDeleted: false,
    });
  }

  return newPages.sort((a, b) => a.number - b.number);
};

export const extractAllDocumentPages = async (
  doc,
  annotManager,
  fileName = ''
) => {
  if (!doc || !annotManager) {
    return {};
  }
  let pagesBlobs = {};

  try {
    const pagesToExtract = [...Array(doc.getPageCount()).keys()].map(
      (i) => i + 1
    );

    for (const pageNumber of pagesToExtract) {
      const newPage = await extractPDFPages({
        annotManager,
        doc,
        key: pageNumber,
        name: 'page',
        pagesToExtract: [pageNumber],
        prefix: fileName,
      });
      pagesBlobs = { ...pagesBlobs, ...newPage };
    }
  } catch (error) {
    console.error(error);
  }

  return pagesBlobs;
};

export const getFlattenedArray = (documentsList) => {
  let newData = [];

  let orderCounter = 0;
  documentsList.forEach((parent) => {
    newData.push({
      document: parent.id,
      order: orderCounter++,
      sub_order: null,
      position: null,
      parent_id: null,
      has_order_changed: parent.has_order_changed,
    });

    if (parent.upper_children_certification_documents.length !== 0) {
      parent.upper_children_certification_documents.forEach(
        (upperChild, index) => {
          newData.push({
            document: upperChild.id,
            order: orderCounter++,
            sub_order: index,
            position: 'upper',
            parent_id: parent.id,
            has_order_changed: upperChild.has_order_changed,
          });
        }
      );
    }

    if (parent.lower_children_certification_documents.length !== 0) {
      parent.lower_children_certification_documents.forEach(
        (lowerChild, index) => {
          newData.push({
            document: lowerChild.id,
            order: orderCounter++,
            sub_order: index,
            position: 'lower',
            parent_id: parent.id,
            has_order_changed: lowerChild.has_order_changed,
          });
        }
      );
    }
  });

  return newData;
};

export const getFlattenedArrayByOrderOfAppearance = (documentsList) => {
  let newData = [];

  documentsList.forEach((parent) => {
    parent.upper_children_certification_documents?.forEach(
      (upperChild, index) => newData.push(upperChild)
    );

    newData.push(parent);

    parent.lower_children_certification_documents?.forEach(
      (lowerChild, index) => newData.push(lowerChild)
    );
  });
  return newData;
};
