import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------

ContactListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onRequestSort: PropTypes.func,
};

export default function ContactListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{ boxShadow: 'none' }}
            align="left"
            sortDirection={orderBy === headCell.sort ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.sort}
                direction={orderBy === headCell.sort ? order : 'asc'}
                onClick={createSortHandler(headCell.sort)}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  noWrap={true}
                  sx={{ fontWeight: 600 }}
                  dangerouslySetInnerHTML={{ __html: headCell.label }}
                />
                {orderBy === headCell.sort && (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap={true}
                sx={{ fontWeight: 600 }}
                dangerouslySetInnerHTML={{ __html: headCell.label }}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
