import { Box, Typography } from '@mui/material';

export default function FaxDetailsBody({
  subject,
  details,
  onRetry,
  onDelete,
  onRefresh,
}) {
  return (
    <>
      <Box sx={{ px: { xs: 3, md: 5 } }}>
        <Typography variant="h3" gutterBottom>
          {subject}
        </Typography>

        {details.map(({ title, value }) =>
          value ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Typography
                variant={'caption'}
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: (theme) => theme.palette.text.disabled,
                }}
              >
                {title}:
              </Typography>
              <Typography variant={'body2'}>{value}</Typography>
            </Box>
          ) : (
            <></>
          )
        )}
      </Box>
    </>
  );
}
