import axios from 'axios';

export const toFormData = (obj, form = null, namespace = '') => {
  const formDataPayload = form || new FormData();
  let formKey;

  for (const property in obj) {
    if (obj.hasOwnProperty(property) && obj[property]) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        formDataPayload.append(formKey, obj[property].toISOString());
      } else if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        toFormData(obj[property], formDataPayload, formKey);
      } else {
        // if it's a string or a File object
        formDataPayload.append(formKey, obj[property]);
      }
    }
  }

  return formDataPayload;
};

let lastUpdated = 0;
let refreshPromise = null;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT || 'http://localhost:8000/api/',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error?.response?.status === 401 || error?.response?.status === 400) &&
      error?.response?.config?.url === 'token/access/'
    ) {
      localStorage.removeItem('refresh');
      localStorage.removeItem('accessToken');
      sessionStorage.clear();
      window.location.pathname = '/';
    }

    if (
      error?.response?.status === 401 &&
      error?.response?.config?.url !== 'token/both/?validate' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const refresh = localStorage.getItem('refresh');
      try {
        if (lastUpdated + 1000 * 60 < Date.now()) {
          refreshPromise = axiosInstance.post('token/access/', {
            refresh,
          });
          refreshPromise.then((result) => {
            localStorage.setItem('accessToken', result.data.access);
          });
        }
        lastUpdated = Date.now();
        const response = await refreshPromise;
        console.log(new Date(lastUpdated));
        const { data } = response;
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.access}`;
        originalRequest.headers.Authorization = `Bearer ${data.access}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(
      (error.response && error.response.data) ||
        error.message ||
        'Something went wrong'
    );
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    // Start session whenever a request is called
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
