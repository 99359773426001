export const DEFAULT_ROWS_PER_PAGE = 25;
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 50, 100];
export const ROWS_PER_PAGE_OPTIONS_ALL = [
  5,
  10,
  15,
  20,
  25,
  { label: 'All', value: -1 },
];
