import React, { useCallback, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Button, Container, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { ResetPasswordForm } from '../../components/authentication/reset-password';
//
import { SentIcon } from '../../assets';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import CopyrightMessage from 'src/components/authentication/login/V2/CopyrightMessage';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------
ResetPassword.defaultProps = {
  userEmail: '',
  onSent: PropTypes.func,
  showLogo: true,
  extendSession: false,
};

export default function ResetPassword({
  userEmail,
  onBack,
  showLogo = true,
  showCopyright = true,
  extendSession = false,
}) {
  const [email, setEmail] = useState(userEmail);
  const [sent, setSent] = useState(false);

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    if (onBack && typeof onBack === 'function') {
      onBack();
      return;
    }
    navigate(PATH_AUTH.login);
  }, [navigate, onBack]);

  return (
    <RootStyle title="Reset Password">
      {showLogo && (
        <HeaderStyle>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </HeaderStyle>
      )}

      <Container>
        <Box sx={{ maxWidth: 480, mx: 'auto' }}>
          {!sent ? (
            <>
              <Typography variant="h3" paragraph>
                Forgot your password?
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please enter the email address associated with your account and
                we will email you a link to reset your password.
              </Typography>

              <ResetPasswordForm
                email={userEmail}
                onSent={() => setSent(true)}
                onGetEmail={(value) => setEmail(value)}
                extendSession={extendSession}
              />

              <Button
                fullWidth
                size="large"
                onClick={handleBack}
                sx={{ mt: 1 }}
              >
                Back
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h3" gutterBottom>
                Request sent successfully.
              </Typography>
              <SentIcon sx={{ mb: 5, mx: 'auto', height: 160, mt: 5 }} />
              <Typography variant="body1" fontSize="16px" gutterBottom>
                An email will be sent to <strong>{email}</strong> if it is found
                in our system.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please check your inbox and spam folder.
              </Typography>

              <Button
                size="large"
                variant="contained"
                onClick={handleBack}
                sx={{ mt: 5 }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
      </Container>
      {showCopyright && <CopyrightMessage />}
    </RootStyle>
  );
}
