import { createSlice } from '@reduxjs/toolkit';
import { parseJson } from 'src/components/ProntoHousingAdministration/QuestionMapping/utils';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isTableReady: false,
  error: false,
  ruleTemplateList: [],
  propertyRuleList: [],
  ruleTemplate: {},
  propertyRule: {},
};

const slice = createSlice({
  name: 'DynamicRuleTemplates',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Form List
    getRuleTemplateListSuccess(state, action) {
      state.isLoading = false;
      state.isTableReady = true;
      state.ruleTemplateList = action.payload;
    },

    getRuleTemplateSuccess(state, action) {
      state.isLoading = false;
      state.ruleTemplate = {
        ...action.payload,
        additional_event_data: action.payload.additional_event_data
          ? JSON.parse(action.payload.additional_event_data)
          : {},
      };
    },

    resetRuleTemplateList(state) {
      state.isTableReady = false;
      state.ruleTemplateList = [];
    },

    // GET Form List
    getPropertyRuleListSuccess(state, action) {
      state.isLoading = false;
      state.isTableReady = true;
      state.propertyRuleList = action.payload;
    },

    getPropertyRuleSuccess(state, action) {
      state.isLoading = false;
      let additional_event_data = action?.payload?.additional_event_data || {};

      if (typeof additional_event_data === 'string') {
        additional_event_data = parseJson(additional_event_data);
      }

      state.propertyRule = {
        ...action.payload,
        additional_event_data: additional_event_data || {},
      };
    },

    resetPropertyRuleList(state) {
      state.isTableReady = false;
      state.propertyRuleList = [];
    },

    resetPropertyRule(state) {
      state.isTableReady = false;
      state.propertyRule = {};
    },

    resetRuleTemplate(state) {
      state.isTableReady = false;
      state.ruleTemplate = {};
    },
  },
});

//Actions
export const {
  resetRuleTemplateList,
  resetPropertyRuleList,
  resetPropertyRule,
  resetRuleTemplate,
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRuleTemplateList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `events_system/dynamic_rules/dynamic_rule_template/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getRuleTemplateListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRuleTemplate(ruleTemplateId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `events_system/dynamic_rules/dynamic_rule_template/${ruleTemplateId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getRuleTemplateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPropertyRuleList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `events_system/dynamic_rules/dynamic_rule/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getPropertyRuleListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPropertyRule(ruleTemplateId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `events_system/dynamic_rules/dynamic_rule/${ruleTemplateId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getPropertyRuleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
