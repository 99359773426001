import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { MODES } from './utils';

// -----------------------------------------------------------------------

const CDMTreeHeader = ({ actionMode, pagesInput }) => {
  const title = useMemo(() => {
    switch (actionMode) {
      case MODES.toFileManager:
        return 'Add To File Manager';
      case MODES.toQuestionAnswer:
        return 'Add To Question Answer';
      default:
        return null;
    }
  }, [actionMode]);

  return (
    <>
      {title ? (
        <Box sx={{ mb: 3 }}>
          <Box component="h3" sx={{ lineHeight: 1 }}>
            {`${title}`}
          </Box>
          {pagesInput && (
            <Box
              component="p"
              sx={{ lineHeight: 1, color: 'text.secondary', mt: 1 }}
            >
              {`Selected page number(s): ${pagesInput}`}
            </Box>
          )}
        </Box>
      ) : null}
    </>
  );
};

// -----------------------------------------------------------------------

CDMTreeHeader.propTypes = {
  actionMode: PropTypes.string,
  pagesInput: PropTypes.string,
};

CDMTreeHeader.defaultProps = {
  actionMode: null,
  pagesInput: null,
};

export default React.memo(CDMTreeHeader);
