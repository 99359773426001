// import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { MCircularProgress } from 'src/components/@material-extend';
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Toolbar,
} from '@mui/material';
// icons
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
// routes
// import { PATH_DASHBOARD } from 'src/routes/paths';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(() => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

export default function ContactListToolbar({
  search,
  onSearch,
  isLoading,
  openModalDialog,
}) {
  return (
    <>
      <RootStyle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SearchStyle
            value={search}
            onChange={onSearch}
            placeholder="Search contact..."
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: 'text.disabled' }}
                />
              </InputAdornment>
            }
          />
          {isLoading && (
            <MCircularProgress sx={{ ml: 2 }} size={24} color="primary" />
          )}
        </Box>
        <Box sx={{ ml: 'auto', display: 'flex' }}>
          {/*<Button
           variant="contained"
           component={RouterLink}
           to={PATH_DASHBOARD.prontoHousingAdministration.contacts.new}
           startIcon={<Icon icon={plusFill} />}
           > */}
          <Button
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={openModalDialog}
          >
            Add New Contact
          </Button>
        </Box>
      </RootStyle>
    </>
  );
}
