import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import DescriptionIcon from '@mui/icons-material/Description';
import { initialState } from '../../ConversationManager/ConversationDocumentManager';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ConversationDocumentManagerDialog from '../ConversationDocumentManagerDialog';

const AttachmentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  display: 'flex',
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.primary['light']}`,
  color: theme.palette.primary.main,
  wordBreak: 'break-all',
  cursor: 'pointer',
}));

const MessageImgStyle = styled('img')(({ theme }) => ({
  height: 200,
  maxWidth: 296,
  width: '100%',
  cursor: 'pointer',
  objectFit: 'cover',
  paddingTop: '15px',
  borderRadius: theme.shape.borderRadius,
}));

const trim = (str, length = 50) => {
  return str.length > length ? `${str.slice(0, length)}...` : str;
};

const isPdf = (f) => f?.ext?.toLowerCase()?.includes('pdf');

const SmsPdfAttachments = ({
  conversationId,
  certificationId,
  certificationConfig,
  files,
  members,
  subject,
}) => {
  const [dmState, setDmState] = useState(initialState);
  const [attachments, setAttachments] = useState([]);

  const updateDmState = useCallback(
    (obj = {}) => setDmState((st) => ({ ...st, ...obj })),
    []
  );
  const memberOptions = useMemo(
    () =>
      members.filter(
        (member) =>
          member.member_type.name.toLowerCase() !==
          'non-household member emergency contact'
      ),
    [members]
  );

  const handleOpen = (file) => {
    setAttachments([file]);

    updateDmState({
      visible: true,
      conversationId: conversationId,
      certificationConfig: certificationConfig,
      certificationId: certificationId,
      initialFile: file.url,
      initialFileName: 'conversation_document',
      attachments: [file],
      memberOptions: memberOptions,
      subject: subject,
    });
  };

  useEffect(() => {
    const updatedFile = files.find((file) => file?.id === attachments[0]?.id);
    if (updatedFile) {
      updateDmState({ attachments: [updatedFile] });
    }
  }, [attachments, files, updateDmState]);

  return (
    <>
      {files.map((file) => {
        if (isPdf(file)) {
          return (
            <AttachmentStyle key={file?.id} onClick={() => handleOpen(file)}>
              <DescriptionIcon
                sx={{ color: 'inherit' }}
                style={{ fontSize: '50', pr: 1 }}
              />
              <Typography variant="body2" color="text.primary">
                Document Name: <br />
                <strong style={{ wordBreak: 'break-all' }}>
                  {trim(file?.name || '')}
                </strong>
              </Typography>
            </AttachmentStyle>
          );
        }

        if (['.jpg', '.jpeg', '.png'].includes(file?.ext)) {
          return (
            <a
              key={file?.id}
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MessageImgStyle alt={file.name} src={file.url} />
            </a>
          );
        }

        return null;
      })}

      <ConversationDocumentManagerDialog
        dmState={dmState}
        setDmState={setDmState}
        updateDmState={updateDmState}
        attachments={attachments || []}
      />
    </>
  );
};

SmsPdfAttachments.propTypes = {
  conversationId: PropTypes.number,
  certificationId: PropTypes.number,
  files: PropTypes.array,
  members: PropTypes.array,
  subject: PropTypes.string,
};

SmsPdfAttachments.defaultProps = {
  conversationId: null,
  certificationId: null,
  files: [],
  members: [],
  subject: '',
};

export default React.memo(SmsPdfAttachments);
