import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

const GeneralDialog = ({
  maxWidth,
  fullWidth,
  visible,
  loading,
  title,
  message,
  acceptVariant,
  acceptColor,
  acceptButtonText,
  secondActionVariant,
  secondActionColor,
  secondActionButtonText,
  cancelVariant,
  cancelColor,
  cancelButtonText,
  hideAccept,
  showSecondAction,
  secondActionLoading,
  invertActionButtons,
  children,
  onAccept,
  onSecondAction,
  onCancel,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Dialog open={visible} fullWidth={fullWidth} maxWidth={maxWidth || false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!!message && <DialogContentText>{message}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions
        sx={
          invertActionButtons
            ? {
                flexDirection: 'row-reverse',
                justifyContent: 'flex-start',
                gap: 1,
              }
            : {}
        }
      >
        {!hideAccept && (
          <LoadingButton
            color={acceptColor}
            variant={acceptVariant}
            loading={loading}
            disabled={secondActionLoading}
            onClick={onAccept}
          >
            {acceptButtonText}
          </LoadingButton>
        )}
        {showSecondAction && (
          <LoadingButton
            color={secondActionColor}
            variant={secondActionVariant}
            loading={secondActionLoading}
            disabled={loading}
            onClick={onSecondAction}
          >
            {secondActionButtonText}
          </LoadingButton>
        )}
        <Button
          color={cancelColor}
          variant={cancelVariant}
          disabled={loading || secondActionLoading}
          onClick={onCancel}
        >
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GeneralDialog.propTypes = {
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  acceptVariant: PropTypes.string,
  acceptColor: PropTypes.string,
  acceptButtonText: PropTypes.string,
  secondActionVariant: PropTypes.string,
  secondActionColor: PropTypes.string,
  secondActionButtonText: PropTypes.string,
  cancelVariant: PropTypes.string,
  cancelColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  hideAccept: PropTypes.bool,
  showSecondAction: PropTypes.bool,
  secondActionLoading: PropTypes.bool,
  invertActionButtons: PropTypes.bool,
  children: PropTypes.element,
  onAccept: PropTypes.func,
  onSecondAction: PropTypes.func,
  onCancel: PropTypes.func,
};

GeneralDialog.defaultProps = {
  maxWidth: '',
  fullWidth: false,
  visible: false,
  loading: false,
  title: '',
  message: '',
  acceptVariant: 'contained',
  acceptColor: 'error',
  acceptButtonText: 'Accept',
  secondActionVariant: 'text',
  secondActionColor: 'primary',
  secondActionButtonText: 'Action',
  cancelVariant: 'text',
  cancelColor: 'primary',
  cancelButtonText: 'Cancel',
  hideAccept: false,
  showSecondAction: false,
  secondActionLoading: false,
  invertActionButtons: false,
  children: null,
  onAccept: () => null,
  onSecondAction: () => null,
  onCancel: () => null,
};

export default GeneralDialog;
