import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// material
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import makeStyles from '@mui/styles/makeStyles';
// utils
import TemplatesEngineService from 'src/services/TemplatesEngine.service';
import templateList from './TemplateList';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    height: '48vh',
    maxHeight: '48vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  inputMultiline: {
    '& .MuiInputBase-root': {
      padding: '20px',
    },
    '& .MuiInputBase-input': {
      height: 'calc(30vh - 40px)',
    },
  },
}));

// ----------------------------------------------------------------------

const TemplateForm = ({
  template,
  propertyId,
  isEdit,
  onSuccess,
  onRestore,
  onCancel,
  setUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const [save, setSave] = useState(false);

  const TemplateSchema = Yup.object().shape({
    name: Yup.string().required('Template name is required'),
    text: Yup.string().required('Template is required'),
    sms_template: Yup.string().required('SMS Template is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: template?.name || '',
      text: isEdit && template?.text ? template.text : '',
      sms_template:
        isEdit && template?.sms_template ? template.sms_template : '',
      description: isEdit && template?.description ? template.description : '',
    },
    validationSchema: TemplateSchema,
    onSubmit: async (values) => {
      if (!isEdit) {
        return;
      }
      setSubmitting(true);
      let request;

      const body = {
        name: values.name,
        text: values.text,
        sms_template: values.sms_template,
        description: values.description,
      };

      // This triggers when we edit a base template
      // a copy will be created to prevent the generic one from being modified
      // as it is used in multiple properties.
      if (propertyId && !template?.property) {
        body['property'] = propertyId || null;
        body['type'] = template?.type?.id;
        body['category'] = template?.category?.id;
        body['description'] = template?.description;
        request = TemplatesEngineService.createCommunicationTemplate(body);
      } else {
        request = TemplatesEngineService.updateCommunicationTemplate(
          template.id,
          body
        );
      }

      request
        .then(() => {
          onSubmitSuccess();
        })
        .catch((error) => {
          onSubmitError(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const onSubmitSuccess = () => {
    if (isEdit) {
      setUpdate(true);
    }
    const message = `Template ${isEdit ? 'updated' : 'created'} successfully.`;
    enqueueSnackbar(message, { variant: 'success' });
    onSuccess();
    setSave(false);
  };

  const onSubmitError = (error) => {
    const message = `Error ${isEdit ? 'editing' : 'creating'} the template.`;
    enqueueSnackbar(message, { variant: 'error' });
    console.error(error);
  };

  // Weird that this is called restore default, but it only deletes it
  // and doesn't actually restore it
  const handleRestoreDefault = async () => {
    // very few templates have a property
    // condition might be beneficial to change to id
    if (template?.property) {
      setRestoring(true);
      TemplatesEngineService.deleteCommunicationTemplate(template.id)
        .then(() => {
          enqueueSnackbar('Template restored successfully.', {
            variant: 'success',
          });
          onRestore(template?.type?.id || null);
          onSuccess();
          setSave(false);
        })
        .catch(() => {
          enqueueSnackbar('Error restoring template.', { variant: 'error' });
        })
        .finally(() => {
          setRestoring(false);
        });
    }
  };

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    templateList && (
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ marginTop: -10 }}>
            <Grid item xs={12} md={8} mb={2}>
              <Stack spacing={3} sx={{ width: '100%' }}>
                <Stack spacing={{ xs: 3, sm: 2 }}>
                  <Box className={classes.root}>
                    <TextField
                      fullWidth
                      multiline
                      label="Template"
                      rows={17}
                      className={classes.inputMultiline}
                      {...getFieldProps('text')}
                      onChange={(event) => {
                        setFieldValue('text', event.target.value);
                        setSave(true);
                      }}
                      error={Boolean(touched.text && errors.text)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      multiline
                      label="SMS Template"
                      rows={3}
                      className={classes.inputMultiline}
                      {...getFieldProps('sms_template')}
                      onChange={(event) => {
                        setFieldValue('sms_template', event.target.value);
                        setSave(true);
                      }}
                      error={Boolean(
                        touched.sms_template && errors.sms_template
                      )}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Name"
                sx={{ mb: 2 }}
                {...getFieldProps('name')}
                onChange={(event) => {
                  setFieldValue('name', event.target.value);
                  setSave(true);
                }}
              />

              <TextField
                fullWidth
                multiline
                label="Description"
                minRows={3}
                // className={classes.inputMultiline}
                sx={{ mb: 2 }}
                {...getFieldProps('description')}
                onChange={(event) => {
                  setFieldValue('description', event.target.value);
                  setSave(true);
                }}
              />
              <Box
                className={classes.root}
                sx={{
                  overflowY: 'auto',
                  borderRadius: 1,
                  bgcolor: 'background.neutral',
                  p: 3,
                }}
              >
                {/*<Markdown children={template?.type?.usage || ''} />*/}
                <Typography
                  component="pre"
                  style={{
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {template?.type?.usage || ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ pt: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent:
                    template?.property || errors ? 'space-between' : 'flex-end',
                }}
              >
                {template?.property && (
                  <LoadingButton
                    color="info"
                    variant="contained"
                    loading={restoring}
                    onClick={handleRestoreDefault}
                  >
                    Restore Default
                  </LoadingButton>
                )}
                {errors && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormHelperText error={true} sx={{ m: 0 }}>
                      {errors[Object.keys(errors)[0]]}
                    </FormHelperText>
                  </Box>
                )}
                <Box>
                  <Button color="inherit" sx={{ mr: 3 }} onClick={onCancel}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={submitting}
                    disabled={!save}
                  >
                    Save Changes
                  </LoadingButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    )
  );
};

TemplateForm.propTypes = {
  template: PropTypes.object,
  propertyId: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSuccess: PropTypes.func,
  onRestore: PropTypes.func,
  onCancel: PropTypes.func,
};

export default TemplateForm;
