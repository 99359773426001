import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import linkFill from '@iconify/icons-eva/link-fill';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import { MAvatar } from '../@material-extend';
import createAvatar from 'src/utils/createAvatar';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import { htmlToPlainText } from './utils';
import { EMAIL_TYPE } from './ConversationsV2';

const MailDetailsRow = ({
  msg,
  updateCollapsedItems,
  isOpen,
  selectMessage,
  isDone,
  conversation,
}) => {
  const isEmail = useMemo(
    () => conversation.conversation_type === EMAIL_TYPE,
    [conversation.conversation_type]
  );

  const getSenderName = (message) => {
    if (message?.sender_user) {
      return `${message?.sender_user?.first_name} ${message?.sender_user?.last_name}`;
    } else if (message?.sender_contact) {
      return `${message?.sender_contact?.first_name} ${message?.sender_contact?.last_name}`;
    }
    return 'Unknown Contact';
  };
  const getSenderEmail = (message) => {
    if (message?.sender_user) {
      return `${message?.sender_user?.email}`;
    } else if (message?.sender_contact) {
      return `${message?.sender_contact?.email}`;
    } else if (message?.from_email) {
      return message.from_email;
    }
    return 'Unknown Contact';
  };

  const name = useMemo(() => getSenderName(msg), [msg]);
  const email = useMemo(() => getSenderEmail(msg), [msg]);

  if (!msg) {
    return null;
  }

  return (
    <Box
      onClick={() => updateCollapsedItems(msg?.id)}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'flex-start',
        px: { xs: 3, md: 5 },
        pb: 1,
      }}
    >
      <MAvatar
        sx={{ width: 30, height: 30 }}
        alt={`${name}`}
        src={''}
        color={createAvatar(name).color}
      >
        {createAvatar(name).name}
      </MAvatar>

      <Box sx={{ ml: 2, width: 'calc(100% - 74px)' }}>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography display="inline" variant="subtitle2">
            {`${name}`}
          </Typography>

          <Link variant="caption" sx={{ color: 'text.secondary' }}>
            &nbsp; {isEmail && `<${email}>`}
          </Link>

          <Typography
            display="inline"
            variant="caption"
            color="text.secondary"
            sx={{ ml: 'auto' }}
          >
            {msg?.created && fDateTimeSuffix(msg?.created)}
          </Typography>
        </Box>

        {msg?.files?.length > 0 && !isOpen && (
          <Box
            component={Icon}
            icon={linkFill}
            sx={{
              height: 16,
              width: 16,
              position: 'absolute',
              right: { xs: 25, md: 41 },
              bottom: 5,
            }}
          />
        )}

        {!isOpen && (
          <Typography
            color="text.secondary"
            variant="caption"
            display="block"
            sx={{ width: 'calc(100% - 50px)' }}
            noWrap
          >
            {htmlToPlainText(msg?.content)}
          </Typography>
        )}
      </Box>

      {!isDone && (
        <Tooltip
          title="Select message to include in File Manager."
          placement="top"
        >
          <Box
            onClick={(e) => selectMessage(e, msg)}
            component={
              msg?.add_to_certification_file
                ? CheckBoxIcon
                : CheckBoxOutlineBlankIcon
            }
            sx={{
              fontSize: 20,
              color: msg?.add_to_certification_file
                ? 'primary.main'
                : 'text.secondary',
              cursor: 'pointer',
              ml: 1,
            }}
          ></Box>
        </Tooltip>
      )}
    </Box>
  );
};

MailDetailsRow.propTypes = {
  msg: PropTypes.object,
  conversation: PropTypes.object,
  updateCollapsedItems: PropTypes.func,
  selectMessage: PropTypes.func,
  isOpen: PropTypes.bool,
};

MailDetailsRow.defaultProps = {
  conversation: null,
  msg: null,
  updateCollapsedItems: () => null,
  selectMessage: () => null,
  isOpen: false,
};

export default memo(MailDetailsRow);
