import axios from './axios';
// ----------------------------------------------------------------------

const removeTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refresh');
  delete axios.defaults.headers.common.Authorization;
  window.location.href = '/';
};

const setSession = (accessToken, refresh = localStorage.getItem('refresh')) => {
  if (accessToken && refresh && refresh !== 'null') {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refresh', refresh);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
};

export { setSession, removeTokens };
