import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
// material
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
// utils
import { fDateTimeSuffix } from '../../utils/formatTime';
import { COMPLIANCE_PATHS } from 'src/routes/paths';
import { memo, useMemo, useState } from 'react';
import { fPhone } from 'src/utils/formatNumber';
import { useParams } from 'react-router';
import FaxOutInIcon from './FaxOutInIcon';
import { Icon } from '@iconify/react';
import roundSend from '@iconify/icons-ic/round-send';
import documentLink24Filled from '@iconify/icons-fluent/document-link-24-filled';
import { fFaxNumber, getCertificationPagePath, getUnitPagePath } from './utils';
import { RootStyle } from './MailDetailsToolbar';
import FTReleteCertification from './FTReleteCertification';
import documentArrowLeft24Filled from '@iconify/icons-fluent/document-arrow-left-24-filled';
// ----------------------------------------------------------------------

const FaxDetailsToolbar = ({
  fax,
  faxMessage,
  allAttachments,
  retry,
  ...other
}) => {
  const { search } = useLocation();
  const { pathname } = useLocation();
  const { property_slug, certification_id } = useParams();
  const isUserDashboard = pathname.startsWith('/workbench/user');

  const [openCertifications, setOpenCertifications] = useState(false);

  const returnPath = useMemo(
    () =>
      !isUserDashboard
        ? certification_id
          ? `${COMPLIANCE_PATHS.root}/${fax?.certification?.property?.slug}/${fax?.certification?.id}${search}`
          : `${COMPLIANCE_PATHS.root}/${
              fax?.certification?.property?.slug || property_slug
            }${search}`
        : `${COMPLIANCE_PATHS.root}/user/communications/?${search}`,
    [search, isUserDashboard, fax] //eslint-disable-line
  );

  const getContactName = (message) => {
    const userTarget = message?.sender_user || message?.sender_contact || {};
    const faxNumber =
      message?.sender_contact?.fax_number ||
      fax?.certification?.property?.fax_number ||
      null;
    let displayName = `${userTarget?.first_name || ''} ${
      userTarget?.last_name || ''
    }`;
    if (faxNumber) {
      displayName += ` • ${
        fPhone(faxNumber.replace('+1', '')) || faxNumber.replace('+1', '')
      }`;
    }

    return displayName;
  };

  const getSenderUserLabel = (contact_fax_number) => {
    const faxNumber = contact_fax_number?.fax_number
      ? `• ${fPhone(contact_fax_number.fax_number)}`
      : '';
    if (contact_fax_number?.contact?.displayName) {
      return `${contact_fax_number?.contact?.displayName} ${faxNumber}`.trim();
    } else {
      return `${contact_fax_number?.contact?.first_name} ${contact_fax_number?.contact?.last_name} ${faxNumber}`.trim();
    }
  };

  return (
    <>
      <RootStyle {...other}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Tooltip title="Back">
            <IconButton
              sx={{ pl: 0, '&:hover': { backgroundColor: 'transparent' } }}
              component={RouterLink}
              to={returnPath}
              size="large"
            >
              <Icon icon={arrowIosBackFill} width={20} height={20} />
            </IconButton>
          </Tooltip>

          {faxMessage && (
            <FaxOutInIcon
              isIncoming={!faxMessage.sender_user}
              status={fax.status}
            />
          )}

          {faxMessage && (
            <Box sx={{ ml: 2, width: { xs: 'calc(100% - 88px)', md: 'auto' } }}>
              <Typography
                sx={{
                  display: { xs: 'block', md: 'inline' },
                  width: { xs: '100%', md: 'auto' },
                }}
                variant="subtitle2"
                noWrap
              >
                {getContactName(faxMessage)}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                <Typography variant="caption" color="text.secondary">
                  To: &nbsp;
                </Typography>
                <Typography variant="caption" color="text.secondary" noWrap>
                  {/*is incoming*/}
                  {!faxMessage.sender_user && (
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {fax.property.name} •{' '}
                      {fFaxNumber(fax.property.fax_number)}
                    </Typography>
                  )}

                  {/*is outgoing*/}
                  {!!faxMessage.sender_user && (
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {getSenderUserLabel(fax.contact_fax_number)}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            width: { xs: '100%', md: 'auto' },
            justifyContent: { xs: 'flex-end', md: 'flex-start' },
          }}
        >
          {!certification_id && allAttachments.length ? (
            <>
              {!Boolean(fax.certification) ? (
                <Button
                  sx={{ ml: 2 }}
                  color={'info'}
                  startIcon={<Icon icon={documentLink24Filled} />}
                  onClick={() => setOpenCertifications(true)}
                >
                  Relate Certification
                </Button>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'caption'}>
                    <RouterLink to={getCertificationPagePath(fax)}>
                      {fax?.certification?.display_name} <br />{' '}
                      {fax?.certification?.household?.name}
                    </RouterLink>

                    {fax?.certification?.household?.unit && (
                      <>
                        ·
                        <RouterLink to={getUnitPagePath(fax)}>
                          {fax?.certification?.household?.unit?.name}
                        </RouterLink>
                      </>
                    )}
                  </Typography>

                  {!Boolean(
                    faxMessage?.files[0]?.survey_file ||
                      faxMessage?.files[0]?.certification_document
                  ) && (
                    <Tooltip title={'Change Certification'}>
                      <IconButton
                        onClick={() => setOpenCertifications(true)}
                        size="large"
                      >
                        <Icon
                          icon={documentArrowLeft24Filled}
                          style={{ color: '#161C24' }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )}
            </>
          ) : (
            ''
          )}
          {fax.status === 'failed' && (
            <Button
              variant="text"
              endIcon={<Icon icon={roundSend} width={15} height={15} />}
              onClick={retry}
            >
              Try to send again
            </Button>
          )}

          {faxMessage && (
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {fDateTimeSuffix(fax?.createdAt)}
            </Typography>
          )}
        </Box>
      </RootStyle>

      <FTReleteCertification
        open={openCertifications}
        setOpen={setOpenCertifications}
        certificatiob={fax.certification}
        property={fax?.property?.id}
        allAttachments={allAttachments}
        isReassign={Boolean(fax.certification)}
      />
    </>
  );
};

FaxDetailsToolbar.propTypes = {
  mail: PropTypes.object,
};

export default memo(FaxDetailsToolbar);
