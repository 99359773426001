/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: false,
  currentFile: {},
  selectedAnswer: {},
  selectedFile: {},
  selectedIndex: {},
  selectedFiles: [],
  actionType: null,
  objectToReject: null,
  objectToRename: null,
  objectToDelete: null,
  objectToMove: null,
  openRejectionModal: false,
  openConfirmationModal: false,
  openDeleteConfirmationModal: false,
  openReassignModal: false,
  openRenameModal: false,
  editOrder: false,
  cloneCurrentRecordData: null,
};

const slice = createSlice({
  name: 'DocumentManager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single certification
    getCurrentFileSuccess(state, action) {
      state.isLoading = false;
      state.currentFile = action.payload;
    },

    // SET File
    setSelectedFileSuccess(state, action) {
      state.isLoading = false;
      state.selectedFile = action.payload;
    },

    // SET Answer
    setSelectedAnswerSuccess(state, action) {
      state.isLoading = false;
      state.selectedAnswer = action.payload;
    },

    setOpenConfirmation(state, action) {
      state.openConfirmationModal = action.payload;
    },

    setOpenDeleteConfirmation(state, action) {
      state.openDeleteConfirmationModal = action.payload;
    },

    setOpenRejectionModal(state, action) {
      state.openRejectionModal = action.payload;
    },

    setOpenReassignModal(state, action) {
      state.openReassignModal = action.payload;
    },

    setOpenRenameModal(state, action) {
      state.openRenameModal = action.payload;
    },

    setSelectedFiles(state, action) {
      state.selectedFiles = action.payload;
    },

    setActionType(state, action) {
      state.actionType = action.payload;
    },

    setObjectToReject(state, action) {
      state.objectToReject = action.payload;
    },

    setObjectToRename(state, action) {
      state.objectToRename = action.payload;
    },

    setObjectToDelete(state, action) {
      state.objectToDelete = action.payload;
    },

    setObjectToMove(state, action) {
      state.objectToMove = action.payload;
    },

    setEditOrder(state, action) {
      state.editOrder = action.payload;
    },

    setCloneCurrentRecordData(state, action) {
      state.cloneCurrentRecordData = action.payload;
    },

    // GET certification category statuses
    getSelectedIndexSuccess(state, action) {
      state.isLoading = false;
      state.selectedIndex = action.payload;
    },

    resetDocumentManager(state, action) {
      state.isLoading = false;
      state.error = false;
      state.selectedAnswer = {};
      state.selectedFile = {};
      state.editOrder = false;
    },

    resetSelectedFile(state, action) {
      state.isLoading = false;
      state.error = false;
      state.selectedFile = {};
    },

    resetSelectedAnswer(state, action) {
      state.isLoading = false;
      state.error = false;
      state.selectedAnswer = {};
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  resetDocumentManager,
  resetSelectedFile,
  resetSelectedAnswer,
  setOpenConfirmation,
  setOpenDeleteConfirmation,
  setSelectedFiles,
  setOpenRejectionModal,
  setActionType,
  setObjectToReject,
  setObjectToRename,
  setObjectToDelete,
  setOpenReassignModal,
  setOpenRenameModal,
  setObjectToMove,
  setCloneCurrentRecordData,
  setEditOrder,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCurrentFile(pages, index) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getCurrentFileSuccess(pages[index]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedFile(file) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSelectedFileSuccess(file));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedAnswer(answer) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setSelectedAnswerSuccess(answer));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
