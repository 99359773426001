import React, { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
// material
import { Box, Button, Divider, Drawer, List } from '@mui/material';
import { MHidden } from '../@material-extend';
// icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// redux
import { useSelector } from '../../redux/store';
// utils
import { EMAIL_TYPE, FAX_TYPE, SMS_TYPE } from './ConversationsV2';
// components
import Scrollbar from '../Scrollbar';
import MailSidebarItem from './MailSidebarItem';

// ----------------------------------------------------------------------

const MailSidebar = ({
  isOpenSidebar,
  onOpenCompose,
  onOpenFaxCompose,
  onCloseSidebar,
  toggleContact,
  isProperty,
  isUserDashboard,
  handleSelectCertification,
}) => {
  const { pathname } = useLocation();
  const selectedFilter = useSelector(
    ({ conversationsV2: { selectedFilter } }) => selectedFilter,
    shallowEqual
  );

  const labels = useSelector(
    ({ conversationsV2: { labels } }) => labels,
    shallowEqual
  );

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenCompose = () => {
    if (isUserDashboard || isProperty) {
      handleSelectCertification();
    } else {
      onCloseSidebar();
      onOpenCompose();
    }
  };

  const handleOpenFaxCompose = () => {
    if (isUserDashboard || isProperty) {
      handleSelectCertification();
    } else {
      onCloseSidebar();
      onOpenFaxCompose();
    }
  };

  const handleContacts = (status) => {
    toggleContact(status);
  };

  const renderContent = (
    <Scrollbar>
      <Box sx={{ p: 3 }}>
        {selectedFilter.filters.conversation_type === EMAIL_TYPE && (
          <Button
            fullWidth
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpenCompose}
          >
            New Conversation
          </Button>
        )}
        {selectedFilter.filters.conversation_type === SMS_TYPE && (
          <Button
            fullWidth
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpenCompose}
          >
            New SMS
          </Button>
        )}
        {selectedFilter.filters.conversation_type === FAX_TYPE && (
          <Button
            fullWidth
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
            onClick={handleOpenFaxCompose}
          >
            New Fax
          </Button>
        )}
      </Box>

      <Divider />

      <List disablePadding>
        {Object.keys(labels).map((key) => (
          <React.Fragment key={labels[key]?.id}>
            {!(key === 'unassigned') ? (
              <MailSidebarItem
                label={labels[key]}
                hideContacts={() => handleContacts(false)}
              />
            ) : (
              isProperty && (
                <MailSidebarItem
                  label={labels[key]}
                  hideContacts={() => handleContacts(false)}
                />
              )
            )}
          </React.Fragment>
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <MHidden width="mdUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: 280 } }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="mdDown">
        <Drawer
          variant="permanent"
          PaperProps={{ sx: { width: 280, position: 'relative' } }}
        >
          {renderContent}
          <Box sx={{ mt: 'auto', pb: '30px' }}>
            <List disablePadding>
              {!isUserDashboard && (
                <MailSidebarItem
                  label={{ id: 'contacts', name: 'contacts' }}
                  hideContacts={() => handleContacts(true)}
                />
              )}
            </List>
          </Box>
        </Drawer>
      </MHidden>
    </>
  );
};

MailSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenCompose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
  isProperty: PropTypes.bool,
};

export default memo(MailSidebar);
