import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const MessageTemplateItem = ({ name, onClick }) => {
  return (
    <Typography
      onClick={onClick}
      noWrap
      variant="body1"
      sx={{
        cursor: 'pointer',
        textTransform: 'capitalize',
        fontSize: 13,
        mb: 0.25,
        transition: (theme) => theme.transitions.create('all'),
        '&:hover': { color: 'primary.main' },
      }}
    >
      {name.replaceAll('_', ' ').toLowerCase()}
    </Typography>
  );
};

MessageTemplateItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default MessageTemplateItem;
