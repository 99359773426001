import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  pageRecordsMap: {},
  recordsLoaded: false,
  questionnaireLocation: null,
  pageGroups: {},
};

const slice = createSlice({
  name: 'Tenant Questionnaire',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getQuestionnairePageSurveyRecordsSuccess(state, action) {
      state.isLoading = false;
      state.pageRecordsMap = action.payload;
      state.recordsLoaded = true;
    },

    setQuestionnaireLocation(state, action) {
      state.isLoading = false;
      state.questionnaireLocation = action.payload;
    },

    setPageGroups(state, action) {
      state.isLoading = false;
      state.pageGroups = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const { setQuestionnaireLocation, setPageGroups } = slice.actions;

// ----------------------------------------------------------------------

export function getQuestionnairePageSurveyRecords(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/survey_record/?${parseQueryParams(queryParams)}`
      );

      const recordsMap = {};
      response.data.forEach((r) => {
        recordsMap[r.id] = r;
      });

      dispatch(
        slice.actions.getQuestionnairePageSurveyRecordsSuccess(recordsMap)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
