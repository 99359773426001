import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
// material
import { Box, Dialog, DialogContent } from '@mui/material';
// icons
// redux
import { updateSelectedConversation } from '../../redux/slices/conversationsV2';
// utils
// components
import GeneralDialog from '../ProntoHousingAdministration/QuestionMapping/Dialog';
import CDMPdfPreview from '../ConversationManager/CDMPdfPreview';
import CDMTree from '../ConversationManager/CDMTree';

// -----------------------------------------------------------------------

const initialActionMode = {
  actionMode: null,
  actionFile: null,
  pagesToExtract: [],
  pagesInput: null,
};

export const initialState = {
  // initial data
  visible: false,
  conversationId: null,
  certificationConfig: null,
  certificationId: null,
  initialFile: null,
  initialFileName: '',
  attachments: [],
  memberOptions: [],
  subject: '',
  // pdfFile data
  ready: false,
  viewerRef: null,
  fileBlob: null,
  core: null,
  annotationManager: null,
  pageCount: null,
  newPdfFileUrl: null,
  isDirty: false,
  // actionMode data
  ...initialActionMode,
};

const sidebarStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: { xs: 'auto', md: '90vh' },
  maxWidth: { xs: '100%', md: '400px' },
  overflow: { xs: 'initial', md: 'auto' },
  py: 2,
  px: 3,
};
// -----------------------------------------------------------------------

const ConversationDocumentManagerDialog = ({
  dmState,
  setDmState,
  updateDmState,
  attachments,
  shouldSelectCertification,
}) => {
  const dispatch = useDispatch();
  const [pdfInstance, setPdfInstance] = useState(null);

  const [selectedPages, setSelectedPages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const resetDmState = useCallback(() => {
    if (dmState.newPdfFileUrl) {
      dispatch(
        updateSelectedConversation({
          value: { pdf_file_url: dmState.newPdfFileUrl },
          conversationId: dmState.conversationId,
        })
      );
    }
    setPdfInstance(null);
    setSelectedPages([]);
    setShowAlert(false);
    setDmState(initialState);
  }, [dispatch, dmState.conversationId, dmState.newPdfFileUrl, setDmState]);

  const resetActionMode = useCallback(() => {
    if (pdfInstance) {
      pdfInstance.UI.enableFeatures([
        'ThumbnailMultiselect',
        'ThumbnailReordering',
        'ThumbnailMerging',
      ]);
      pdfInstance.UI.unselectThumbnailPages(
        pdfInstance.UI.getSelectedThumbnailPageNumbers()
      );
      pdfInstance.Core.documentViewer.disableReadOnlyMode();
      pdfInstance.Core.annotationManager.disableReadOnlyMode();
    }
    updateDmState(initialActionMode);
  }, [pdfInstance, updateDmState]);

  const handleClose = () => {
    if (dmState.isDirty || dmState.actionMode) {
      setShowAlert(true);
    } else {
      resetDmState();
      if (shouldSelectCertification) {
        shouldSelectCertification.onClose();
      }
    }
  };

  useEffect(() => {
    updateDmState({
      attachments: attachments,
    });
  }, [attachments, updateDmState]);

  return (
    <>
      {dmState.visible && (
        <Dialog
          open={Boolean(dmState.visible)}
          fullWidth
          maxWidth="lg"
          PaperProps={{ square: true }}
          onClose={() => handleClose()}
        >
          <DialogContent
            sx={{
              padding: '0px !important',
              display: 'flex',
              flexDirection: { xs: 'column-reverse', md: 'row' },
            }}
          >
            <CDMPdfPreview
              readOnly
              pdfInstance={pdfInstance}
              initialFile={dmState.initialFile}
              initialFileName={dmState.initialFileName}
              setPdfInstance={setPdfInstance}
              setSelectedPages={setSelectedPages}
              updateDmState={updateDmState}
            />

            <Box sx={sidebarStyle}>
              <CDMTree
                readOnly
                pdfInstance={pdfInstance}
                dmState={dmState}
                selectedPages={selectedPages}
                resetActionMode={resetActionMode}
                updateDmState={updateDmState}
                handleClose={handleClose}
                shouldSelectCertification={shouldSelectCertification}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {showAlert && (
        <GeneralDialog
          title="Discard changes"
          visible={showAlert}
          message="All the entered data will be discarded, this action can't be undone."
          cancelButtonText="Cancel"
          acceptButtonText="Yes, discard"
          onCancel={() => {
            setShowAlert(false);
          }}
          onAccept={() => {
            resetDmState();
            if (shouldSelectCertification) {
              shouldSelectCertification.onClose();
            }
          }}
        />
      )}
    </>
  );
};

// -----------------------------------------------------------------------

ConversationDocumentManagerDialog.propTypes = {
  dmS: PropTypes.number,
  certificationId: PropTypes.number,
  pdfFileUrl: PropTypes.string,
  attachments: PropTypes.array,
  members: PropTypes.array,
  subject: PropTypes.string,
};

ConversationDocumentManagerDialog.defaultProps = {
  conversationId: null,
  certificationId: null,
  pdfFileUrl: null,
  attachments: [],
  members: [],
  subject: '',
};

export default React.memo(ConversationDocumentManagerDialog);
