import { Box, Collapse, IconButton, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { collapseStyles } from '../ProntoHousingAdministration/QuestionMapping/Questionnaire/utils';

const TreeTraceback = ({ traceback, fileUrl }) => {
  const [open, setOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);

  const classes = collapseStyles();

  return (
    <Box sx={{ pl: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography
          variant="caption"
          color="text.secondary"
          component="p"
          mb={1}
          pr={1}
        >
          We could not process the attachment to add it to the document.
        </Typography>

        <IconButton
          aria-label="open"
          size="medium"
          onClick={() => setOpen((st) => !st)}
          sx={{ p: 0 }}
        >
          <ExpandMoreIcon
            className={clsx(classes.expand, { [classes.expandOpen]: open })}
            fontSize="inherit"
          />
        </IconButton>
      </Box>

      <Collapse in={open} unmountOnExit>
        <Box
          component="pre"
          sx={{
            fontSize: 12,
            backgroundColor: 'rgba(239, 83, 80, .1)',
            py: 1,
            px: 2,
            mb: 1,
            border: '1px solid',
            borderColor: 'error.main',
            borderRadius: 1,
            color: 'error.dark',
            textWrap: 'wrap',
          }}
        >
          <p>Something went wrong processing the attachment.</p>
          <Link
            color={'#194da2'}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenDetails((value) => !value)}
          >
            Show details
          </Link>
          <Collapse in={openDetails} unmountOnExit>
            <p>{traceback}</p>
          </Collapse>
        </Box>

        {fileUrl && (
          <a href={fileUrl} download rel="noopener noreferrer" target="_blank">
            Download attachment
          </a>
        )}
      </Collapse>
    </Box>
  );
};

export default TreeTraceback;
