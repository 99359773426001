import { useEffect, useState } from 'react';
// material
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// redux
import { useDispatch } from '../../redux/store';
import { setCertification } from '../../redux/slices/UserDashboard/userDashboard';
// utils
import useAuth from '../../hooks/useAuth';
import axiosInstance from 'src/utils/axios';
import parseQueryParams from 'src/utils/query';
import { fDateUTC } from 'src/utils/formatTime';
import { FAX_TYPE } from './ConversationsV2';

export default function SelectCertificationsDialog({
  open,
  setOpen,
  onOpenCompose,
  onOpenFaxCompose,
  type,
  isProperty = false,
  propertyId = null,
}) {
  const dispatch = useDispatch();
  const [certifications, setCertifications] = useState([]);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const { user } = useAuth();

  const getCertifications = async (search) => {
    if (!search) {
      setCertifications([]);
      return;
    }

    try {
      setLoading(true);
      const queryParams = {
        expand: ['household.household_members.user'],
        is_done: false,
        ...(search ? { search } : {}),
      };

      if (isProperty && propertyId) {
        queryParams.property = propertyId;
      } else {
        queryParams.as_specialist = true;
        queryParams.compliance_user = user?.id;
      }

      const response = await axiosInstance.get(
        `certification/certification/?${parseQueryParams(queryParams)}`
      );
      if (response.status === 200) {
        setCertifications(response.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSendCertification = () => {
    dispatch(setCertification(selectedCertification));
    setOpen(false);
    if (!!type && type === FAX_TYPE) {
      onOpenFaxCompose();
    } else {
      onOpenCompose();
    }
  };

  useEffect(() => {
    let timeOutId = null;
    if (search !== null) {
      timeOutId = setTimeout(() => {
        getCertifications(search);
      }, 500);
    }
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId);
      }
    };
  }, [search]); //eslint-disable-line

  useEffect(() => {
    if (open) {
      setSearch('');
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Start a new conversation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please select a certification to start a new conversation
        </DialogContentText>
        <Autocomplete
          fullWidth
          freeSolo
          disabled={loading}
          blurOnSelect={'touch'}
          options={certifications || []}
          getOptionLabel={(option) =>
            `${option.household.name} | ${option.display_name}`
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedCertification(newValue);
          }}
          onInputChange={(event, value) => {
            if (event.type !== 'click') {
              setSearch(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search certification..."
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              {...props}
              key={option.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, mb: 0, width: '100%' }}
              >
                {`${option.household.name} | ${option.display_name}`}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ width: '100%' }}
              >
                <b>Effective Date: </b>{' '}
                {option?.effective_date
                  ? fDateUTC(option.effective_date)
                  : 'N/A'}
              </Typography>
            </Box>
          )}
        />
        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'} mt={2}>
          <Button
            color={'error'}
            variant={'text'}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            disabled={Boolean(!selectedCertification)}
            onClick={() => handleSendCertification()}
          >
            Start a new {!!type && type === FAX_TYPE ? 'fax' : 'conversation'}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
