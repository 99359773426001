import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';

import useAuth from '../../../hooks/useAuth';
// material
import { Alert, OutlinedInput, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import axios from '../../../utils/axios';
import TermsOfServiceDialogV2 from 'src/components/authentication/login/V2/TermsOfServiceDialogV2';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

// eslint-disable-next-line consistent-return
function maxLength(object) {
  if (object.target.value.length > object.target.maxLength) {
    return (object.target.value = object.target.value.slice(
      0,
      object.target.maxLength
    ));
  }
}

export default function VerifyCodeForm({
  settings,
  handleSave,
  onExtendSessionSuccess,
}) {
  const { login, extendCurrentSession, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const termsOfServiceRef = useRef(null);

  const [sessionDetails, setSessionDetails] = useState(null);

  const VerifyCodeSchema = Yup.object().shape({
    code0: Yup.number().required('Code is required'),
    code1: Yup.number().required('Code is required'),
    code2: Yup.number().required('Code is required'),
    code3: Yup.number().required('Code is required'),
    code4: Yup.number().required('Code is required'),
    code5: Yup.number().required('Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      code0: '',
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
    },
    validationSchema: VerifyCodeSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const code = Object.values(values)
          .map((item) => item)
          .join('');
        const { data } = await axios.post(
          !settings
            ? 'mobile_phone/mobile_phone_login/verify_code/'
            : 'phones/mobile_phone_verification/verify_mobile/',
          { code }
        );

        if (!settings) {
          const { access, refresh } = data;

          if (isAuthenticated) {
            extendCurrentSession(data);
            if (onExtendSessionSuccess) {
              onExtendSessionSuccess(data);
            }
            closeDialog();
            return;
          }

          const { data: user } = await axios.get(
            'user_profile/user/current_profile/',
            { headers: { Authorization: `Bearer ${access}` } }
          );

          if (!user.terms_of_service_agreement) {
            setSessionDetails({
              user: user.id,
              access: access,
              refresh: refresh,
            });
            termsOfServiceRef.current?.toggleOpenDialog();
            return;
          }
          await login(access, refresh);
          navigate('/');
        } else {
          handleSave();
        }

        if (isMountedRef.current) {
          setSubmitting(false);
          resetForm();
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error?.detail });
          setSubmitting(false);
        }
        setErrors({
          afterSubmit:
            'Invalid code, review the information or request another code.',
        });
      }
    },
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setErrors,
    setFieldValue,
    resetForm,
  } = formik;

  const handlePaste = (e) => {
    const copiedCode = e.clipboardData.getData('Text');
    [...copiedCode].forEach((c, idx) => {
      if (idx >= 6) {
        return true;
      }
      setFieldValue(`code${idx}`, c);
    });
  };

  const isValidForm = useMemo(() => {
    return Object.values(values).every((v) => Boolean(v) || v === 0);
  }, [values]);

  useEffect(() => {
    document.getElementById('code0').focus();
  }, []);

  const closeDialog = () => {
    localStorage.removeItem('temporalSession');
    termsOfServiceRef.current?.toggleOpenDialog();
  };

  const onAccept = async () => {
    try {
      await axios.patch(
        `user_profile/user/${sessionDetails?.user}/`,
        { terms_of_service_agreement: true },
        { headers: { Authorization: `Bearer ${sessionDetails.access}` } }
      );

      await login(sessionDetails.access, sessionDetails.refresh);
      closeDialog();
      navigate('/');
    } catch (error) {
      console.error(error);
      setErrors({ afterSubmit: 'Something went wrong.' });
      closeDialog();
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        {errors.afterSubmit && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {errors.afterSubmit}
          </Alert>
        )}
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction="row" spacing={2} justifyContent="center">
            {Object.keys(values).map((item, idx) => {
              return (
                <OutlinedInput
                  key={item}
                  id={item}
                  {...getFieldProps(item)}
                  onPaste={(e) => handlePaste(e)}
                  onChange={(e) => {
                    if (isNaN(e.target.value)) {
                      return;
                    }
                    if (
                      idx < Object.keys(values).length - 1 &&
                      e.target.value !== ''
                    ) {
                      document.getElementById('code' + (idx + 1)).focus();
                    }
                    setFieldValue(item, e.target.value);
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Backspace' && idx > 0) {
                      document.getElementById('code' + (idx - 1)).focus();
                    }
                  }}
                  type="number"
                  placeholder="-"
                  onInput={maxLength}
                  error={Boolean(touched[item] && errors[item])}
                  inputProps={{
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 },
                    },
                  }}
                />
              );
            })}
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!isValidForm}
            loading={isSubmitting}
            sx={{ mt: 3 }}
          >
            {!settings ? 'Login' : 'Verify Code'}
          </LoadingButton>
        </Form>
      </FormikProvider>
      <TermsOfServiceDialogV2
        onCancel={closeDialog}
        onAccept={onAccept}
        ref={termsOfServiceRef}
      />
    </>
  );
}
