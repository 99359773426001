import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import AccountPopover from './AccountPopover';
import { StatusNotesBubble } from '../../components/Compliance/Certification/StatusNotes/StatusNotesBubble';
import { alpha, experimentalStyled as styled } from '@mui/material/styles';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  pointerEvents: 'none',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const AccountPopoverHolder = ({ compliance, dashboard }) => (
  <>
    {compliance ? (
      <Box marginBottom={dashboard ? '15px' : '50px'} justifyContent="flex-end">
        <AccountPopover />
        <StatusNotesBubble />
      </Box>
    ) : (
      <RootStyle sx={{ height: 23 }}>
        <ToolbarStyle>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }} marginTop="2em">
            <Box>
              <Box sx={{ pointerEvents: 'initial' }}>
                <AccountPopover bigIcon />
              </Box>

              <Box sx={{ pointerEvents: 'initial' }}>
                <StatusNotesBubble bigIcon />
              </Box>
            </Box>
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    )}
  </>
);

export default AccountPopoverHolder;
