import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingCertificationFile: false,
  isLoadingCertificationDocuments: false,
  isLoadingTemplates: false,
  isLoadingDeletedDocuments: false,
  error: false,
  certificationFile: {},
  certificationPages: [],
  certificationDocuments: [],
  templates: [],
  deletedDocuments: [],
  updated: false,
  rotatedPages: [],
  isHouseholdReachable: true,
  isSavingRotatedPages: false,
  unassignedSits: {
    selectedDocument: null,
    selectedSitMapping: null,
    documentLoaded: false,
    hoverElement: null,
  },
  previewSelectedDocument: null,
  previewPagesWithMappings: [],
  fileManagerPreviewDocumentData: null,
  documentPreview: {
    selectedAnnotation: null,
  },
  // Document Manager
  dmLoading: false,
  dmPages: [],
  isReordering: false,
};

const slice = createSlice({
  name: 'FileManager',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // UPDATE DOCUMENT PREVIEW
    setDocumentPreviewState(state, action) {
      state.documentPreview = { ...state.documentPreview, ...action.payload };
    },

    setFileManagerPreviewDocumentData(state, action) {
      state.fileManagerPreviewDocumentData = action.payload;
    },

    startLoadingCertificationFile(state) {
      state.isLoading = true;
      state.isLoadingCertificationFile = true;
    },

    startLoadingCertificationDocuments(state) {
      state.isLoading = true;
      state.isLoadingCertificationDocuments = true;
    },

    startLoadingTemplates(state) {
      state.isLoadingTemplates = true;
    },

    startLoadingDeletedDocuments(state) {
      state.isLoadingDeletedDocuments = true;
    },

    startReordering(state) {
      state.isReordering = true;
    },

    reorderingSuccess(state) {
      state.isReordering = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCertificationFileSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingCertificationFile = false;
      state.updated = false;
      state.certificationFile = action.payload;
    },

    getCertificationPagesSuccess(state, action) {
      state.isLoading = false;
      state.updated = false;
      state.certificationPages = action.payload;
    },

    getCertificationDocumentsSuccess(state, action) {
      state.certificationDocuments = action.payload?.documents?.results?.sort(
        (a, b) => a.order - b.order
      );
      state.deletedDocuments = action.payload?.deleted_documents?.results;
      state.isHouseholdReachable = action.payload?.household_is_reachable;
      state.isLoading = false;
      state.isLoadingCertificationDocuments = false;
      state.updated = false;
    },

    setCertificationDocuments(state, action) {
      state.certificationDocuments = action.payload.sort(
        (a, b) => a.order - b.order
      );
    },

    updateCertificationDocument(state, action) {
      const document = action.payload;
      const documentIndex = state.certificationDocuments.findIndex(
        (doc) => doc.id === document.id
      );

      if (documentIndex !== -1) {
        state.certificationDocuments[documentIndex] = {
          ...state.certificationDocuments[documentIndex],
          ...document,
        };
      }
    },

    getTemplatesSuccess(state, action) {
      state.isLoadingTemplates = false;
      state.updated = false;
      state.templates = action.payload;
    },

    getDeletedDocumentsSuccess(state, action) {
      state.isLoadingDeletedDocuments = false;
      state.updated = false;
      state.deletedDocuments = action.payload;
    },

    setUpdatedSuccess(state, action) {
      state.updated = action.payload;
    },

    clearCertificationPagesSuccess(state, action) {
      state.certificationPages = [];
    },

    clearCertificationDocumentsSuccess(state, action) {
      state.certificationDocuments = [];
    },

    resetCertificationFileData(state, action) {
      state.certificationDocuments = [];
      state.certificationPages = [];
      state.certificationFile = {};
    },

    setPreviewPagesWithMappings(state, action) {
      state.previewPagesWithMappings = action.payload;
    },

    setPreviewSelectedDocument(state, action) {
      state.previewSelectedDocument = action.payload;
    },

    setRotatedPages(state, action) {
      state.rotatedPages = action.payload;
    },

    setIsSavingRotatedPages(state, action) {
      state.isSavingRotatedPages = action.payload;
    },

    updateUnassignedSits(state, action) {
      state.unassignedSits = {
        ...state.unassignedSits,
        ...action.payload,
      };
    },

    resetUnassignedSits(state) {
      state.unassignedSits = {
        selectedDocument: null,
        selectedSitMapping: null,
        documentLoaded: false,
      };
    },

    setDMPages(state, action) {
      state.dmPages = action.payload;
    },

    startDMLoading(state) {
      state.dmLoading = true;
    },

    endDMLoading(state) {
      state.dmLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  resetCertificationFileData,
  setCertificationDocuments,
  updateCertificationDocument,
  updateUnassignedSits,
  resetUnassignedSits,
  startDMLoading,
  startReordering,
  endDMLoading,
  setDMPages,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCertificationFile(certificationId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCertificationFile());
    try {
      const response = await axios.get(
        `certification/file/${certificationId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getCertificationFileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getCertificationDocuments(
  certificationID,
  enableLoading = true,
  reOrder = false
) {
  return async (dispatch) => {
    if (enableLoading) {
      dispatch(slice.actions.startLoadingCertificationDocuments());
    }
    try {
      const response = await axios.get(
        `certification/file_manager/${certificationID}/`
      );
      if (reOrder) {
        dispatch(slice.actions.reorderingSuccess());
      }
      dispatch(slice.actions.getCertificationDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTemplates(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingTemplates());
    try {
      const response = await axios.get(
        `questionnaire/form_library/form_template/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getTemplatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDeletedDocuments(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingDeletedDocuments());
    try {
      const response = await axios.get(
        `certification/document?${parseQueryParams(queryParams)}`
      );

      dispatch(slice.actions.getDeletedDocumentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUpdated(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setUpdatedSuccess(value));
  };
}

export function clearCertificationPages() {
  return async (dispatch) => {
    dispatch(slice.actions.clearCertificationPagesSuccess());
  };
}

export function clearCertificationDocuments() {
  return async (dispatch) => {
    dispatch(slice.actions.clearCertificationDocumentsSuccess());
  };
}
