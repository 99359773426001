import React, { useCallback, useMemo, useState } from 'react';
import xss from 'xss';
import useAuth from '../../../hooks/useAuth';
import roundSend from '@iconify/icons-ic/round-send';

import { experimentalStyled as styled } from '@mui/material/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SMS_TYPE } from '../ConversationsV2';
import {
  Box,
  Divider,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import MessageTemplates from '../Templates';
import axiosInstance from '../../../utils/axios';
import { buildMessageFormData, getMessageById } from '../utils';
import { useSnackbar } from 'notistack';
import { sendMessageSuccess } from '../../../redux/slices/conversationsV2';
import { LoadingButton } from '@mui/lab';
import { SMS_LIST_SCROLL_NODE_ID } from './SmsDetailList';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
}));

const MAX_CHARACTERS_PER_SEGMENT = 160;
const MAX_PAGES_PER_MESSAGE = 10;
const MAX_CHARACTERS_PER_MESSAGE =
  MAX_CHARACTERS_PER_SEGMENT * MAX_PAGES_PER_MESSAGE;

const SmsMessageInput = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );

  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const [messagePayload, setMessagePayload] = useState({
    conversation: selectedConversation?.id,
    content: '',
    message_type: SMS_TYPE,
    sender_user: user.id,
    templates: [],
  });

  const segments = useMemo(
    () =>
      Math.floor(
        (messagePayload?.content?.length - 1) / MAX_CHARACTERS_PER_SEGMENT
      ) + 1,
    [messagePayload?.content?.length]
  );

  const handleTemplateSelection = useCallback(
    (value, templateId) => {
      const updatedMessage = `${messagePayload.content}${value || ''}`;
      setMessagePayload((st) => ({
        ...st,
        content: xss(updatedMessage),
        templates: [...st.templates, templateId],
      }));

      if (
        !validMessage &&
        updatedMessage?.length > 0 &&
        updatedMessage?.length <= 1600
      ) {
        setValidMessage(true);
      }
    },
    [messagePayload.content, validMessage]
  );

  const handleSend = useCallback(async () => {
    setIsSendingMessage(true);

    try {
      const payload = { ...messagePayload };

      if (!payload.templates?.length) {
        delete payload.templates;
      }

      const newMessage = await axiosInstance.post(
        `communication/new_conversation/message/`,
        buildMessageFormData(payload)
      );
      const finalMessage = await getMessageById(newMessage?.data?.id);

      dispatch(
        sendMessageSuccess({
          conversationId: newMessage?.data?.conversation,
          message: finalMessage,
        })
      );

      enqueueSnackbar('Message sent successfully.', { variant: 'success' });
      setMessagePayload((st) => ({ ...st, content: '', templates: [] }));
      setValidMessage(false);
    } catch (error) {
      enqueueSnackbar('Something went wrong sending the message.', {
        variant: 'error',
      });
      console.error(error);
    } finally {
      setIsSendingMessage(false);
      setTimeout(() => {
        const elNode = document.querySelector(
          `#${SMS_LIST_SCROLL_NODE_ID} .simplebar-content-wrapper`
        );
        if (elNode) {
          elNode.scrollTo({ top: elNode.scrollHeight, behavior: 'smooth' });
        }
      }, 500);
    }
  }, [dispatch, enqueueSnackbar, messagePayload]);

  const handleChangeMessage = useCallback(
    (event) => {
      setMessagePayload((st) => ({ ...st, content: xss(event.target.value) }));
      const messageIsValid =
        event?.target?.value?.length > 0 &&
        event?.target?.value?.length <= MAX_CHARACTERS_PER_MESSAGE;
      setValidMessage(messageIsValid);

      if (messageIsValid && event.keyCode === 13) {
        handleSend();
      }
    },
    [handleSend]
  );

  const is_reachable =
    (selectedConversation.hhm_mobile_phone &&
      selectedConversation.hhm_mobile_phone.is_reachable) ||
    (selectedConversation.contact_mobile_phone &&
      selectedConversation.contact_mobile_phone.is_reachable);

  return (
    <>
      <Divider />

      <RootStyle>
        <TextField
          fullWidth
          multiline
          value={messagePayload.content}
          minRows={2}
          maxRows={6}
          onChange={handleChangeMessage}
          onKeyUp={(e) => {
            if (validMessage && e?.code === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
          placeholder="Type a message"
          sx={{
            mb: 'auto',
            '& fieldset': {
              border: 'none !important',
            },
          }}
          inputProps={{
            maxLength: MAX_CHARACTERS_PER_MESSAGE,
          }}
          autoFocus
          disabled={isSendingMessage || !is_reachable}
        />

        <Divider orientation="vertical" flexItem />

        <LoadingButton
          loading={isSendingMessage}
          color="primary"
          disabled={
            !messagePayload.content || isSendingMessage || !is_reachable
          }
          onClick={handleSend}
        >
          <Icon icon={roundSend} width={24} height={24} />
        </LoadingButton>
      </RootStyle>

      <Divider />

      {!is_reachable && (
        <Box sx={{ p: 1, display: 'flex' }}>
          <Typography fontWeight="bold" color="text.secondary">
            This phone number is not reachable.
          </Typography>
        </Box>
      )}

      {is_reachable && (
        <Box sx={{ p: 1, display: 'flex' }}>
          {selectedConversation?.id && (
            <MessageTemplates
              messageType={SMS_TYPE}
              conversation={selectedConversation}
              conversationId={selectedConversation?.id}
              onselectTemplate={handleTemplateSelection}
            />
          )}

          <FormHelperText
            sx={{ pr: 3, fontSize: 16, pb: 1, display: 'flex', ml: 'auto' }}
          >
            {messagePayload?.content?.length > MAX_CHARACTERS_PER_MESSAGE && (
              <Box
                component="span"
                sx={{
                  color: 'error.main',
                  pl: 3,
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                The message is too big.
              </Box>
            )}

            <Box component="span" sx={{ ml: 'auto' }}>
              {messagePayload?.content?.length} characters / {segments} segment
              {segments === 0 || segments > 1 ? 's' : ''}
            </Box>
          </FormHelperText>
        </Box>
      )}
    </>
  );
};

export default React.memo(SmsMessageInput);
