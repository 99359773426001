import axiosInstance from 'src/utils/axios';
import parseQueryParams from '../../utils/query';
import { nanoid } from 'nanoid';
import { datadogRum } from '@datadog/browser-rum';
import {
  CUSTOM_DATA_KEY,
  parseJson,
} from '../ProntoHousingAdministration/QuestionMapping/utils';
import { QUESTIONS_TYPES_WITH_CHOICES } from '../CertificationWorkbench/utils';
import { getRecordResponseKey } from 'src/pages/tenant/QuestionnaireV4/utils';
import { AnswerGroup } from './models';

const RECORD_FIELDS = [
  'id',
  'answers',
  'qit.id',
  'qit.order',
  'qit.calculation',
  'qit.necessity',
  'qit.question.id',
  'qit.question.name',
  'qit.question_choices',
  'qit.question.help_text',
  'qit.question.question_type',
  'qit.question.question_type_display',
];

const RECORD_EXPAND = [
  'answers',
  'answers.calculation_result',
  'answers.question_choices',
  'qit.question',
  'qit.question_choices',
  'qit.calculation',
];

export async function getChildSchema(parentQitId, surveyId) {
  const queryParams = {
    survey: surveyId,
    qit__parent_qit: parentQitId,
    expand: RECORD_EXPAND,
    fields: RECORD_FIELDS,
  };

  const { data } = await axiosInstance.get(
    `questionnaire/survey_record/?${parseQueryParams(queryParams)}`
  );

  return data
    .filter((record) => record?.qit?.question?.question_type !== 'file')
    .sort((a, b) => a.qit.order - b.qit.order);
}

export async function getGroupSchema(questionGroupId, surveyId) {
  const queryParams = {
    survey: surveyId,
    visible_for_survey: surveyId,
    question_group: questionGroupId,
    not_parent_qit: true,
    exclude_file_childrens: true,
    exclude_calculations: true,
    expand: RECORD_EXPAND,
    fields: RECORD_FIELDS,
  };

  const { data } = await axiosInstance.get(
    `questionnaire/survey_record/?${parseQueryParams(queryParams)}`
  );

  return data
    .filter((record) => record?.qit?.question?.question_type !== 'file')
    .sort((a, b) => a.qit.order - b.qit.order);
}

export const objToFormData = (obj) => {
  const formData = new FormData();

  for (const key in obj) {
    if (Boolean(obj[key]) || typeof obj[key] === 'number') {
      formData.append(key, obj[key]);
    }
  }

  return formData;
};

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    // create a file reader to read the File Object
    const fr = new FileReader();

    // file reader onload event
    fr.onload = () => {
      resolve(fr.result);
    };

    // file reader error event
    fr.onerror = reject;

    // read file as data url
    fr.readAsDataURL(file);
  });
};

export const readImageDimensions = (imageSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image(); // create and ampty image
    const imgDimensions = { width: 0, height: 0 }; // initialize image dimensions

    img.onload = () => {
      imgDimensions.width = img.width;
      imgDimensions.height = img.height;
      resolve(imgDimensions);
    };

    img.onerror = reject;

    img.src = imageSrc;
  });
};

export const imageToPDFDoc = async (file, instance, isBase64 = false) => {
  const { PDFNet } = instance.Core;
  await PDFNet.initialize();

  // create and empty DOCUMENT
  const doc = await PDFNet.PDFDoc.create();

  await PDFNet.runWithCleanup(async () => {
    // lock the document before a write operation
    // runWithCleanup will auto unlock when complete
    doc.lock();

    // ElementBuilder is used to build new Element objects
    const builder = await PDFNet.ElementBuilder.create();

    // ElementWriter is used to write Elements to the page
    const writer = await PDFNet.ElementWriter.create();
    const readerFile = isBase64 ? file : await readFile(file);
    const imgDimensions = await readImageDimensions(readerFile);

    // Start a new page with the image dimensions
    const pageRect = await PDFNet.Rect.init(
      0,
      0,
      imgDimensions.width,
      imgDimensions.height
    );
    const page = await doc.pageCreate(pageRect);

    // begin writing to the page
    writer.beginOnPage(page);

    // Create an Image that can be reused multiple times in the document or multiple on the same page.
    const pdfNetImage = await PDFNet.Image.createFromURL(doc, readerFile);
    const element = await builder.createImageFromMatrix(
      pdfNetImage,
      await PDFNet.Matrix2D.create(
        imgDimensions.width,
        0,
        0,
        imgDimensions.height,
        0,
        0
      )
    );

    writer.writePlacedElement(element);
    writer.end();

    // Add the new page to the document sequence
    doc.pagePushBack(page);
  });

  return doc;
};

export const getPdfFileObject = async (
  doc,
  annotManager,
  fileName = 'file.pdf',
  filterProntoAnnots = false
) => {
  let xfdfString = await annotManager.exportAnnotations();

  if (filterProntoAnnots) {
    const annotList = annotManager
      .getAnnotationsList()
      .filter(
        (annot) =>
          !parseJson(annot.getCustomData(CUSTOM_DATA_KEY))?.isProntoAnnotation
      );
    xfdfString = await annotManager.exportAnnotations({ annotList });
  }

  // extracting the pages instead use getFileData we avoid rendering issues with the annotations
  // looks like this method doesn't affect the viewer live PDF
  const pages = [...Array(doc?.getPageCount()).keys()].map((n) => n + 1); // all document pages [1, 2, 3...]
  const data = await doc.extractPages(pages, xfdfString);

  // const data = await doc.getFileData({ xfdfString });
  return new File([new Uint8Array(data)], `${fileName}.pdf`, {
    type: 'application/pdf',
    lastModified: new Date().getTime(),
  });
};

export const isAValidIndex = (i) => i >= 0;

export const isAStoredElement = (element) => {
  // the element status is not new and has valid id
  return !element?.isNew && typeof element?.id === 'number';
};

export const enumFileAnswerPagesOnMove = (answer) => {
  const newFileAnswers = [...(answer?.files || [])];
  let pageOrder = 0;

  for (let i = 0; i < newFileAnswers.length; i++) {
    const fileAnswer = { ...newFileAnswers[i], order: i };

    if (!fileAnswer.wasDeleted && isAStoredElement(fileAnswer)) {
      fileAnswer.hasChanges = true;
    }

    for (let i = 0; i < fileAnswer.pages.length; i++) {
      const page = fileAnswer.pages[i];
      if (page.wasDeleted) {
        continue;
      }

      page.number = pageOrder + 1;
      page.order = pageOrder;
      pageOrder += 1;

      if (isAStoredElement(page)) {
        page.hasChanges = true;
      }
    }

    newFileAnswers[i] = fileAnswer;
  }

  return newFileAnswers;
};

const markFileAnswerPagesAsDeleted = (answer, removedPages = []) => {
  const newFileAnswers = [...(answer?.files || [])];
  const deletedAnswers = [];

  for (let i = 0; i < newFileAnswers.length; i++) {
    const fileAnswer = { ...newFileAnswers[i] };
    // remove all the new pages deleted
    fileAnswer.pages = fileAnswer.pages.filter(
      (p) => !(removedPages.includes(p?.number) && p?.isNew)
    );

    // iterate over the pages to remove or mark as deleted the items
    for (let x = 0; x < fileAnswer.pages.length; x++) {
      const page = fileAnswer.pages[x];
      if (!removedPages.includes(page.number)) {
        continue;
      }

      // mark as deleted the page
      if (isAStoredElement(page)) {
        page.wasDeleted = true;
        page.number = null;
        page.order = null;
      }
    }

    const shouldDeleteFileAnswer =
      isAStoredElement(fileAnswer) &&
      !fileAnswer?.pages?.filter((page) => !page?.wasDeleted).length;
    if (shouldDeleteFileAnswer && !fileAnswer.wasDeleted) {
      fileAnswer.wasDeleted = shouldDeleteFileAnswer;
      fileAnswer.hasChanges = false;

      const newAnswer = buildFileAnswer({
        id: `${
          answer?.answer_group ? `${answer.answer_group}__` : ''
        }file-${i}__${nanoid()}`,
        isNew: true,
        order: fileAnswer.order,
      });

      deletedAnswers.push({ ...fileAnswer });
      newFileAnswers[i] = newAnswer;
    } else {
      newFileAnswers[i] = fileAnswer;
    }
  }

  return enumFileAnswerPagesOnMove({
    ...answer,
    files: [...newFileAnswers, ...deletedAnswers],
  });
};

export const removePagesEvent = (answer, removedPages = []) => {
  if (!answer?.files?.length) {
    return [];
  }
  return markFileAnswerPagesAsDeleted(answer, removedPages);
};

export const movePagesEvent = (answer) => {
  if (!answer?.files?.length) {
    return [];
  }
  return enumFileAnswerPagesOnMove(answer);
};

/**
 * contentChanged is a numbers Array with the pages that have changed
 */
export const onContentPageChange = (answer, contentChanged = []) => {
  const newFileAnswers = [...(answer?.files || [])];

  for (let i = 0; i < contentChanged.length; i++) {
    const pageNumberWithChanges = contentChanged[i];
    // look's for the file answer that contains the page with changes
    const fileAnswerIndex = newFileAnswers.findIndex((fileAnswer) =>
      fileAnswer.pages.some((page) => page.number === pageNumberWithChanges)
    );

    if (isAValidIndex(fileAnswerIndex)) {
      const fileAnswer = newFileAnswers[fileAnswerIndex];

      // looks for the file answer page with changes
      const pageIndex = fileAnswer.pages.findIndex(
        (page) => page.number === pageNumberWithChanges
      );
      if (isAValidIndex(pageIndex)) {
        // set as edited the page and the file answer if are existing elements on the DB
        fileAnswer.pages[pageIndex].hasChanges = isAStoredElement(
          fileAnswer.pages[pageIndex]
        );
        fileAnswer.hasChanges = isAStoredElement(fileAnswer);
      }
    }
  }

  return newFileAnswers;
};

export const addPagesEvent = (answer) => {
  const finalAnswer = { ...answer };

  for (let i = 0; i < finalAnswer.files.length; i++) {
    const fileAnswer = finalAnswer.files[i];

    // if the answer was changed to wasDeleted previusly but new pages are inserted
    // that flag needs to be removed in order to update the answer correctly
    if (
      fileAnswer?.wasDeleted &&
      fileAnswer?.pages?.filter((p) => !p.wasDeleted)?.length
    ) {
      fileAnswer.wasDeleted = false;
    }
  }

  return enumFileAnswerPagesOnMove(finalAnswer);
};

export const sortByOrder = (a, b) => a?.order - b?.order;

export const buildFileAnswer = ({
  id,
  order,
  file_date,
  name,
  pages,
  pdf_file_url,
  status,
  isNew,
  hasChanges,
  wasDeleted,
}) => ({
  id: id || nanoid(),
  order: order || 0,
  file_date: file_date || null,
  name: name || null,
  pages: pages || [],
  pdf_file_url: pdf_file_url || '',
  status: status || 3,
  isNew: isNew || true,
  hasChanges: hasChanges || false,
  wasDeleted: wasDeleted || false,
});

export const extractPDFPages = async ({
  annotManager,
  doc,
  key = '',
  name = '',
  pagesToExtract = [],
  prefix = '',
  index = 0,
}) => {
  if (!annotManager || !doc || !pagesToExtract.length) {
    return {};
  }

  // only include annotations on the pages to extract
  const annotList = annotManager
    .getAnnotationsList()
    .filter((annot) => pagesToExtract.indexOf(annot.PageNumber) > -1);
  const xfdfString = await annotManager.exportAnnotations({ annotList });
  const data = await doc.extractPages(pagesToExtract, xfdfString);
  const file = new File(
    [new Uint8Array(data)],
    `${prefix}__${name}-${index}.pdf`,
    { type: 'application/pdf', lastModified: new Date().getTime() }
  );

  return { [key]: file };
};

export const STATUS_CODES = {
  temporal: 0,
  accepted: 1,
  rejected: 2,
  unanswered: 3,
  answered: 4,
  corrupted: 5,
};

// consider previusly rejected
export const getPageStatus = (page) => {
  if ([STATUS_CODES.accepted].includes(page?.status)) {
    return page.status;
  }

  return STATUS_CODES.answered;
};

export const getFileAnswerStatus = (fileAnswer) => {
  if ([STATUS_CODES.accepted].includes(fileAnswer?.status)) {
    return fileAnswer.status;
  }

  const validPages = fileAnswer?.pages?.filter((p) => !p.wasDeleted)?.length;

  if (!validPages) {
    return STATUS_CODES.unanswered;
  }

  return STATUS_CODES.answered;
};

export const getAnswerStatus = (answer) => {
  if (STATUS_CODES.accepted === answer?.status) {
    return answer.status;
  }

  const pages = answer?.files?.reduce(
    (acc, a) => acc.concat(a?.pages?.filter((p) => !p?.wasDeleted) || []),
    []
  );

  if (!pages.length) {
    return STATUS_CODES.unanswered;
  }

  return STATUS_CODES.answered;
};

export async function patchFileAnswersStatus(fileAnswerIds, status, answer) {
  for (const fileID of fileAnswerIds) {
    const body = { compliance_status: status };
    await axiosInstance.patch(
      `questionnaire/survey_file/${fileID}/update_compliance_status/`,
      body
    );
  }

  const { data: recordAnswers } = await axiosInstance.get(
    `questionnaire/survey_answer_ordered_survey_files/?survey_record=${answer.survey_record}&answer_group=${answer.answer_group}&fields=${ANSWER_FIELDS}&expand=files`
  );
  const newTopLevelAnswer = recordAnswers?.[0];

  return newTopLevelAnswer;
}

export const ANSWER_FIELDS = [
  'id',
  'answer_group',
  'name',
  'file_date',
  'status',
  'survey_record',
  'order',
  'files.id',
  'pdf_file_url',
  'pdf_file',
  'files.status',
  'files.name',
  'files.file_date',
  'files.pdf_file_url',
  'files.order',
  'files.status',
  'files.id',
  'survey_file_date',
  'files.pages.survey_file',
].join(',');

export const SIDBAR_STYLES = {
  height: { xs: 'auto', md: '90vh' },
  width: '100%',
  maxWidth: { xs: '100%', md: '500px' },
  overflow: { xs: 'initial', md: 'auto' },
  display: 'flex',
  flexDirection: 'column',
};

export async function buildInitialDataForQuestionnaireDM({
  answerGroupID,
  recordId,
  incomingPayload = {},
  answerGroupIndex,
}) {
  const answerGroup = new AnswerGroup();
  answerGroup.position = answerGroupIndex ?? null;

  if (typeof answerGroup?.position === 'number') {
    answerGroup.position += 1;
  }

  // get record answers
  const answerGroupFilter = answerGroupID
    ? `&answer_group=${answerGroupID}`
    : '';
  const { data: recordAnswers } = await axiosInstance.get(
    `questionnaire/survey_answer_ordered_survey_files/?survey_record=${recordId}${answerGroupFilter}&fields=${ANSWER_FIELDS}&expand=files`
  );

  const topLevelAnswer = recordAnswers?.[0] || {};
  if (Object.keys(topLevelAnswer).length) {
    answerGroupID = topLevelAnswer?.answer_group;
  }

  const result = {
    ...incomingPayload,
    answerGroup,
    topLevelAnswer,
    answerGroupID,
  };
  return result;
}

const buildRecordsSchema = (questionnaireRecords = []) => {
  const result = {};
  for (const record of questionnaireRecords) {
    result[record.id] = record;
  }

  return result;
};

const getAnswerValue = (initialSchema = [], answer) => {
  if (!answer) {
    return null;
  }

  const surveyRecord = initialSchema.find(
    (record) => record?.id === answer?.survey_record
  );
  if (!surveyRecord?.answers) {
    return null;
  }

  const answerValue = surveyRecord.answers.find(
    (recordAnswer) =>
      recordAnswer?.parent_file === answer?.parent_file &&
      recordAnswer?.survey_record === answer?.survey_record
  );
  if (!answerValue) {
    return null;
  }

  return answerValue;
};

export const buildAnswerParentFile = (
  answerParentFile,
  initialSchema = [],
  answerGroup
) => {
  if (!answerParentFile || !answerGroup?.id) {
    return null;
  }

  const newParentFile = {
    id: answerParentFile.id,
    order: answerParentFile.order,
    answer_group: answerGroup.id,
    childAnswers: initialSchema.map((record) => {
      const exisingAnswer = getAnswerValue(initialSchema, {
        survey_record: record?.id,
        parent_file: answerParentFile?.id,
      });

      return {
        id: exisingAnswer?.id || nanoid(),
        survey_record: record.id,
        value: exisingAnswer?.[getRecordResponseKey(record)] || '',
        parent_file: answerParentFile?.id,
        calculation_result: exisingAnswer?.calculation_result,
      };
    }),
    errors: null,
  };

  return newParentFile;
};

export const buildParentFileAnswers = (
  answerGroup,
  initialSchema,
  buildSchema = true
) => {
  const result = {};

  if (buildSchema) {
    result.childSchema = buildRecordsSchema(initialSchema);
  }
  result.parentFileAnswers = {};

  if (!answerGroup?.answer?.files?.length) {
    return result;
  }

  for (const fileAnswer of answerGroup.answer.files) {
    // if (typeof fileAnswer?.id !== 'number') {
    //   continue;
    // }
    result.parentFileAnswers[fileAnswer.id] = buildAnswerParentFile(
      fileAnswer,
      initialSchema,
      answerGroup
    );
  }

  return result;
};

export const getRecordAnswerValue = (answerGroup, surveyRecord) => {
  if (!answerGroup) {
    return null;
  }

  if (!surveyRecord?.answers) {
    return null;
  }

  const answerValue = surveyRecord.answers.find(
    (recordAnswer) => recordAnswer?.answer_group === answerGroup
  );
  if (!answerValue) {
    return null;
  }

  return answerValue;
};

export const getGroupAnswers = (answerGroupID, groupSchema) => {
  const result = [];

  for (const surveyRecord of groupSchema) {
    let exisingAnswer = null;

    if (typeof answerGroupID === 'number') {
      exisingAnswer = getRecordAnswerValue(answerGroupID, surveyRecord);
    }

    result.push({
      id: exisingAnswer ? exisingAnswer?.id || nanoid() : nanoid(),
      survey_record: surveyRecord.id,
      value: exisingAnswer
        ? exisingAnswer[getRecordResponseKey(surveyRecord)] || ''
        : '',
      answer_group: answerGroupID,
    });
  }

  return result;
};

export const buildGroupAnswers = (
  answerGroupID,
  initialSchema,
  buildSchema = true
) => {
  const result = {};
  if (buildSchema) {
    result.groupSchema = buildRecordsSchema(initialSchema);
  }
  result.groupAnswers = getGroupAnswers(answerGroupID, initialSchema);
  return result;
};

const mapChildAnswer = (answer, childSchema) => {
  const answerKey = getRecordResponseKey(childSchema[answer?.survey_record]);
  let value = answer?.value;

  if (
    QUESTIONS_TYPES_WITH_CHOICES.includes(
      childSchema[answer?.survey_record]?.qit?.question?.question_type
    )
  ) {
    value = answer?.value?.map((v) => v?.id) || [];
  }
  if (
    childSchema[answer?.survey_record]?.qit?.question?.question_type ===
    'currency'
  ) {
    value = answer?.value.replace(/[^\d.-]/g, '');
  }

  const finalAnswer = {
    survey_answer_id: typeof answer?.id === 'number' ? answer.id : null,
    parent_file: answer?.parent_file,
    survey_record: answer?.survey_record,
    [answerKey]: value,
  };

  return finalAnswer;
};

export const buildChildAnswersPayload = ({
  parentFileAnswers = {},
  childSchema,
}) => {
  // first we get all the child answers list
  const allChildAnswers = parentFileAnswers.flatMap((file) => {
    const childrenAnswers = file.children_answers?.filter(
      (answer) => answer?.dirty
    );
    return childrenAnswers;
  });
  const finalAnswers = allChildAnswers.map((answer) =>
    mapChildAnswer(answer, childSchema)
  );

  return finalAnswers;
};

export const buildGroupAnswersPayload = ({
  finalGroupAnswers = [],
  groupSchema = {},
}) => {
  /* Filtering the answers to only those that have a value and then mapping them to a new object. */
  const answers = finalGroupAnswers
    .filter(
      (answer) =>
        answer?.dirty &&
        (typeof answer?.id === 'number' || Boolean(answer.value))
    )
    .map((answer) => ({
      survey_answer_id: typeof answer?.id === 'number' ? answer.id : null,
      survey_record: answer?.survey_record,
      [getRecordResponseKey(groupSchema[answer?.survey_record])]:
        QUESTIONS_TYPES_WITH_CHOICES.includes(
          groupSchema[answer?.survey_record]?.qit?.question?.question_type
        )
          ? answer?.value?.map((v) => v?.id) || []
          : groupSchema[answer?.survey_record]?.qit?.question?.question_type ===
            'currency'
          ? answer?.value.replace(/[^\d.-]/g, '')
          : answer?.value,
    }));

  return answers;
};

export function rebuildAnswersStatus({ answerGroup, shouldRecalculateStatus }) {
  const finalAnswerGroup = JSON.parse(JSON.stringify(answerGroup));
  if (
    finalAnswerGroup?.answer?.status === STATUS_CODES.accepted ||
    !shouldRecalculateStatus
  ) {
    return finalAnswerGroup;
  }

  finalAnswerGroup.answer.status = getAnswerStatus(finalAnswerGroup.answer);

  for (let x = 0; x < finalAnswerGroup.answer.files.length; x++) {
    const fileAnswer = finalAnswerGroup.answer.files[x];
    fileAnswer.status = getFileAnswerStatus(fileAnswer);

    for (let y = 0; y < fileAnswer.pages.length; y++) {
      const page = fileAnswer.pages[y];
      page.status = getPageStatus(page);
    }
  }

  return finalAnswerGroup;
}

export function emitDatadogEvent(payload = {}) {
  // send event to data dog to track document manager changes
  const emitChanges = Object.keys(payload || {}).some((key) =>
    ['answerGroup'].includes(key)
  );
  if (
    process?.env?.REACT_APP_DATADOG_ID &&
    process?.env?.REACT_APP_DATADOG_TOKEN &&
    emitChanges
  ) {
    datadogRum.addAction(
      `DocManagerEvent: ${payload?.eventFrom || ''}`,
      payload
    );
  }
}

export const mergeDocuments = async (pdfInstance, filesArray) => {
  const { Core } = pdfInstance;
  const promises = [];
  let pageCounter = 1;
  const error = [];
  for (const file of filesArray) {
    try {
      const fileURL = file.pdf_file_url || file.file_url;
      const doc = await Core.createDocument(fileURL);
      const pageCount = doc.getPageCount();
      file.pages = Array.from({ length: pageCount }, () => {
        const page = {
          number: pageCounter,
          surveyFileId: file.id,
        };
        pageCounter += 1;
        return page;
      });
      promises.push({ PdfFile: doc, id: file.id });
    } catch (err) {
      file.status = STATUS_CODES.corrupted;
      const corruptFileIndex =
        filesArray.findIndex((f) => f.id === file.id) + 1;
      error.push(`Answer ${corruptFileIndex}`);
    }
  }
  return Promise.all(promises)
    .catch((error) => {
      return { error: error?.serverResponse?.url || 'Unknown error' };
    })
    .then(async (files) => {
      const pdfFile = await generatePdfFile(Core, files);
      return {
        pdfFile,
        filesArray,
        error,
      };
    });
};

export const generatePdfFile = async (Core, files) => {
  if (files.error) {
    return files;
  }
  if (files.length < 1) {
    return null;
  }
  const { PDFNet } = Core;
  await PDFNet.initialize();
  const firstDocument = await files[0].PdfFile.getPDFDoc();
  const copyPages = [];
  for (
    const itr = await firstDocument.getPageIterator();
    await itr.hasNext();
    await itr.next()
  ) {
    copyPages.push(await itr.current());
  }
  const temporalDocument = await PDFNet.PDFDoc.create();
  for (let i = 0; i < copyPages.length; ++i) {
    await temporalDocument.pagePushBack(copyPages[i]);
  }
  const baseDoc = await Core.createDocument(temporalDocument);
  for (let i = 1; i < files.length; i++) {
    const newDoc = files[i].PdfFile;
    const newDocPageCount = newDoc.getPageCount();
    const pages = Array.from({ length: newDocPageCount }, (v, k) => k + 1);
    const pageIndexToInsert = baseDoc.getPageCount() + 1;
    await baseDoc.insertPages(newDoc, pages, pageIndexToInsert);
  }
  return baseDoc;
};

export async function movePages({
  answerFiles,
  pagesTreeSelection,
  targetIndex,
}) {
  const finalAnswerFiles = JSON.parse(
    JSON.stringify(answerFiles.filter((file) => !file?.wasDeleted))
  );
  const deletedFileAnswers = [];
  const movedPages = [];

  /* Filtering the pages of the file answer. */
  for (let i = 0; i < finalAnswerFiles.length; i++) {
    const fileAnswer = finalAnswerFiles[i];

    fileAnswer.pages = fileAnswer.pages.filter((p) => {
      const wasMoved = pagesTreeSelection.includes(p.number);
      // if is a moved page, is pushed to the moved pages array
      if (wasMoved) {
        movedPages.push(p);
      }
      return !wasMoved;
    });

    // if the file answer doesn't have pages is removed
    if (isAStoredElement(fileAnswer) && !fileAnswer?.pages?.length) {
      fileAnswer.wasDeleted = true;

      const newAnswer = buildFileAnswer({
        id: `${
          fileAnswer?.answer_group ? `${fileAnswer.answer_group}__` : ''
        }file-${i}__${nanoid()}`,
        isNew: true,
        order: fileAnswer.order,
      });

      deletedFileAnswers.push({ ...fileAnswer });
      finalAnswerFiles[i] = newAnswer;
    }
  }

  finalAnswerFiles[targetIndex].pages = [
    ...finalAnswerFiles[targetIndex].pages,
    ...movedPages.map((p) => {
      const markAsNew = deletedFileAnswers.some(
        (file) => file?.id === p?.survey_file
      );
      const result = {
        ...p,
        survey_file: finalAnswerFiles[targetIndex].id,
      };

      if (markAsNew) {
        result.isNew = true;
        result.pdf_file_url = '';
        result.id = null;
        result.survey_file = finalAnswerFiles[targetIndex].id;
      }

      return result;
    }),
  ];

  return [...finalAnswerFiles, ...deletedFileAnswers];
}

export async function getCertificationMembers(id) {
  const params = {
    fields: [
      'household.household_members.id',
      'household.household_members.first_name',
      'household.household_members.last_name',
      'household.household_members.email',
    ],
    expand: ['household.household_members'],
  };
  try {
    const res = await axiosInstance.get(
      `certification/certification/${id}/?${parseQueryParams(params)}`
    );
    return res?.data?.household?.household_members || [];
  } catch (error) {
    console.error(error);
  }
}

export async function completeTodo(id) {
  if (!id) {
    return;
  }
  const { data: todo } = await axiosInstance.get(
    `todos/todo/${id}/?fields=completed,id`
  );

  if (Boolean(todo?.id) && !todo?.completed) {
    await axiosInstance.patch(`todos/todo/${id}/`, { completed: true });
  }
}

export const sortFilesByValidID = (a, b) => {
  if (typeof a.id !== 'number' && typeof b.id !== 'number') {
    return 0;
  }
  if (typeof a.id !== 'number') {
    return 1;
  }
  if (typeof b.id !== 'number') {
    return -1;
  }
  return 0;
};

export const countFilePages = async (pdfInstance, files = []) => {
  let pageCounter = 1;
  for (const file of files) {
    try {
      const doc = await pdfInstance.Core.createDocument(file.pdf_file_url);
      const pageCount = doc.getPageCount();
      file.pages = Array.from({ length: pageCount }, () => {
        const page = {
          number: pageCounter,
          surveyFileId: file.id,
        };
        pageCounter += 1;
        return page;
      });
    } catch (err) {
      file.status = STATUS_CODES.corrupted;
    }
  }
  return files;
};

export const initialValuesBuilder = (childAnswers = []) => {
  const initialValues = {};
  for (const answer of childAnswers) {
    initialValues[answer.survey_record] = '';
    if (answer.text) {
      initialValues[answer.survey_record] = answer.text;
    } else if (answer.question_choices.length) {
      initialValues[answer.survey_record] = answer.question_choices.map(
        (id) => ({
          id,
        })
      );
    }
  }
  return initialValues;
};

export const mapSurveyAnswers = (answers = []) => {
  const surveyAnswers = {};
  for (const answer of answers) {
    surveyAnswers[answer.survey_record] = answer;
  }
  return surveyAnswers;
};

export const areObjectListsEqualByKey = (list1 = [], list2 = [], key) => {
  // Extract values of the specified key from each list
  const values1 = list1.map((item) => item[key]).sort();
  const values2 = list2.map((item) => item[key]).sort();

  // Check if both lists have the same elements
  if (values1.length !== values2.length) {
    return false;
  }

  for (let i = 0; i < values1.length; i++) {
    if (values1[i] !== values2[i]) {
      return false;
    }
  }

  return true;
};

export const updateTopLevelAnswer = async (pdfInstance, topLevelAnswer) => {
  await pdfInstance.closeDocument();
  const { pdfFile, filesArray, error } = await mergeDocuments(
    pdfInstance,
    topLevelAnswer.files
  );

  topLevelAnswer.files = filesArray;

  await pdfInstance.loadDocument(pdfFile, {
    filename: `document.pdf`,
  });

  return {
    topLevelAnswer,
    error,
  };
};
