import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useRef } from 'react';
import { useSicky } from 'src/hooks/useSticky';
import { useLocation } from 'react-router';

// ----------------------------------------------------------------------

TemplateListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
};

// ----------------------------------------------------------------------

export default function TemplateListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) {
  const { pathname } = useLocation();
  const isCompliance = pathname.includes('/workbench');
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headerRef = useRef();
  useSicky({ nodeRef: headerRef, offsetTop: isCompliance ? 170 : 23 });

  return (
    <TableHead ref={headerRef}>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{ boxShadow: 'none' }}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.sort ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.sort}
                direction={orderBy === headCell.sort ? order : 'asc'}
                onClick={createSortHandler(headCell.sort)}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  noWrap={true}
                  sx={{ fontWeight: 600 }}
                >
                  {headCell.label}
                </Typography>
                {orderBy === headCell.sort && (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap={true}
                sx={{ fontWeight: 600 }}
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
