import { array, bool, lazy, mixed, number, object, string } from 'yup';
import {
  DEFAULT_GROUP,
  DEFAULT_PAGE,
  DEFAULT_QUESTION,
  DEFAULT_SECTION,
} from '../constants';
import makeStyles from '@mui/styles/makeStyles';
import { reduceQits } from 'src/components/ProntoHousingAdministration/QuestionMapping/utils';

const filterQitControlOptions = (forbidden, options) => {
  if (!forbidden || forbidden.length === 0) {
    return options;
  }
  return options.filter((option) => !forbidden.includes(option.id));
};

const grouHasMappings = (group) =>
  group?.questions?.some((q) => q?.qit?.is_mapped);

const qitSchema = object({
  id: number()
    .typeError('Question field is required.')
    .required('Question field is required.'),
  necessity: string().required('Necessity type is required.'),
  for_compliance: bool(),
  question_context: number().nullable(),
  number_of_answers_required: number()
    .min(1, 'The minimum value is 1.')
    .required('Number of Answers is required.'),
  number_of_files_required_per_answer: number().min(
    0,
    'Negative numbers are not allowed.'
  ),
  number_of_pages_required_per_file: number().min(
    0,
    'Negative numbers are not allowed.'
  ),
  help_text: string().nullable(),
  question_choices: array().test('question_choices', (value, data) => {
    switch (data?.parent?.question_type) {
      case 'bool':
        return value?.length !== 2
          ? data.createError({
              message: 'Question needs 2 answers',
              path: data.path,
            })
          : true;
      case 'multiselect':
      case 'choices':
      case 'frequency':
        return value?.length < 2
          ? data.createError({
              message: 'Question needs at least 2 answers',
              path: data.path,
            })
          : true;
      case undefined:
      case null:
      case '':
      default:
        return true;
    }
  }),
});

const schema = object().shape({
  id: string()
    .typeError('Section is required.')
    .required('Section is required.'),
  pages: array()
    .of(
      object().shape({
        id: number()
          .typeError('Page field is required.')
          .required('Page field is required.'),
        question_groups: array()
          .of(
            object().shape({
              type: string().required('Group type is required.'),
              name: string().when('type', {
                is: 'multi',
                then: string().required('Group name is required.'),
              }),
              question: mixed(),
              householdmember_type: mixed(),
              age_min: number()
                .min(0, 'Minimum Age 0.')
                .transform((value) => (isNaN(value) ? undefined : value))
                .max(150, 'Maximum Age 150.'),
              age_max: number()
                .min(0, 'Minimum Age 0.')
                .transform((value) => (isNaN(value) ? undefined : value))
                .max(150, 'Maximum Age 150.'),
              question_choice: mixed().when('question', {
                is: (val) => Boolean(val),
                then: mixed()
                  .nullable(true)
                  .required('Question choice is required.'),
              }),
              questions: array()
                .of(
                  lazy((item) => {
                    if (item.contextId) {
                      return object({
                        contextId: number()
                          .typeError('Context is required.')
                          .required('Context is required.'),
                        order: number(),
                        qits: array().of(qitSchema),
                      });
                    } else {
                      return qitSchema;
                    }
                  })
                )
                .min(1, 'At least 1 question is required.'),
            })
          )
          .min(1, 'At least one group is required.'),
      })
    )
    .min(1, 'At least 1 page is required.'),
});
const PropertySchema = object().shape({
  description: string(),
});

export const NEW_SECTION = {
  ...DEFAULT_SECTION,
  pages: [
    {
      ...DEFAULT_PAGE,
      question_groups: [
        {
          ...DEFAULT_GROUP,
          questions: [{ ...DEFAULT_QUESTION }],
        },
      ],
    },
  ],
};

const NEW_PAGE = {
  ...DEFAULT_PAGE,
  question_groups: [
    {
      ...DEFAULT_GROUP,
      questions: [{ ...DEFAULT_QUESTION }],
    },
  ],
};

const NEW_GROUP = {
  ...DEFAULT_GROUP,
  questions: [{ ...DEFAULT_QUESTION }],
};

export const GROUP_TYPES = {
  single: { id: 'single', label: 'Single' },
  multi: { id: 'multi', label: 'Multi' },
};

const NEW_QUESTION = { ...DEFAULT_QUESTION };
const NECESSITIES = [
  { id: 3, name: 'Not Required' },
  { id: 1, name: 'Required' },
  { id: 2, name: 'Desired' },
];

const collapseStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 2,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export function extractQits(
  sectionIndex,
  pageIndex,
  groupIndex,
  sectionsTree = []
) {
  let finalQits = [];
  const sections = sectionsTree.slice(0, sectionIndex + 1);

  for (let x = 0; x < sections?.length; x++) {
    const section = sectionsTree[x];
    let pages = section.pages;
    if (sectionIndex === x) {
      pages = pages.slice(0, pageIndex + 1);
    }

    for (let y = 0; y < pages.length; y++) {
      const page = section.pages[y];
      let groups = page.question_groups;
      if (sectionIndex === x && pageIndex === y) {
        groups = groups.slice(0, groupIndex);
      }

      for (let z = 0; z < groups.length; z++) {
        const group = page.question_groups[z];
        const resultQits = group?.questions
          .reduce(reduceQits, [])
          .filter((el) => el?.qit?.question?.question_choices?.length);
        finalQits = finalQits.concat(resultQits);
      }
    }
  }

  return finalQits.map((el) => el?.qit?.question?.id);
}

export function extractQitsDeleteParent(sectionsTree = [], questions) {
  let groupsIds = [];
  const sections = sectionsTree;
  for (let x = 0; x < sections?.length; x++) {
    const section = sectionsTree[x];
    let pages = section.pages;

    for (let y = 0; y < pages.length; y++) {
      const page = section.pages[y];
      let groups = page.question_groups;

      for (let z = 0; z < groups.length; z++) {
        const group = page.question_groups[z];
        if (questions.includes(group?.question?.id)) {
          groupsIds.push({
            group: group.id,
            section: x,
            page: y,
            groupIndex: z,
          });
        }
      }
    }
  }

  return groupsIds;
}

export function patchPlainQits(groupsToPatch, qitsList) {
  const groups = groupsToPatch.map((g) => g.group);
  const newPlainQits = [...qitsList];
  return newPlainQits.map((pq) => ({
    ...pq,
    question_group: {
      ...pq.question_group,
      ...(groups.includes(pq.question_group.id)
        ? {
            question: null,
            question_choice: null,
          }
        : {}),
    },
  }));
}

export {
  filterQitControlOptions,
  schema,
  PropertySchema,
  NEW_PAGE,
  collapseStyles,
  grouHasMappings,
  NEW_GROUP,
  NEW_QUESTION,
  NECESSITIES,
};
