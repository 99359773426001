export const getMemberFullName = (member) => {
  let fullName = member.first_name;
  if (member.middle_name) {
    fullName += ' ' + member.middle_name;
  }
  fullName += ' ' + member.last_name;
  return fullName;
};

export const findHouseholdMemberById = (
  householdMemberID,
  householdMembers
) => {
  const newMembers = JSON.parse(JSON.stringify(householdMembers));
  const index = newMembers.findIndex(
    (householdMember) => householdMember.id === householdMemberID
  );
  if (index === -1) {
    return newMembers;
  }
  newMembers.splice(index, 1);
  return newMembers;
};
