export const MODES = {
  toFileManager: 'toFileManager',
  toQuestionAnswer: 'toQuestionAnswer',
};

export const extractPDFPages = async ({
  annotationManager,
  coreDocument,
  pagesToExtract,
}) => {
  // only include annotations on the pages to extract
  const annotList = annotationManager
    .getAnnotationsList()
    .filter((annot) => pagesToExtract.indexOf(annot.PageNumber) > -1);
  const xfdfString = await annotationManager.exportAnnotations({ annotList });
  const data = await coreDocument.extractPages(pagesToExtract, xfdfString);
  const date = new Date().getTime();
  return new File([new Uint8Array(data)], `${date}.pdf`, {
    type: 'application/pdf',
    lastModified: date,
  });
};
