import React, { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/webviewer';
import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
// utils
import { getPdfFileObject } from '../DocumenManagerV2/utils';
import docManagerConfig, {
  initializeDocumentManager,
} from '../DocumenManagerV2/docManagerConfig';
import useAuth from 'src/hooks/useAuth';

// -----------------------------------------------------------------------

const CDMPdfPreview = ({
  pdfInstance,
  initialFile,
  initialFileName,
  setPdfInstance,
  setSelectedPages,
  updateDmState,
  readOnly = false,
}) => {
  const viewer = useRef(null);
  const { user } = useAuth();

  // buildInstance instance on mount
  useEffect(() => {
    const buildInstance = async () => {
      localStorage.removeItem('persist:viewer'); // remove previous UI settings

      const instance = await WebViewer(docManagerConfig, viewer.current);
      const { Core, UI } = instance;
      const { annotationManager, documentViewer } = Core;

      initializeDocumentManager(instance, { user, disableAllTools: readOnly });

      // listen layout changes on the pdf document
      Core.documentViewer.addEventListener('layoutChanged', async (changes) => {
        if (
          !changes?.contentChanged?.length &&
          !changes?.annotationsChanged?.length &&
          !changes?.removed?.length &&
          !changes?.added?.length &&
          !Object.keys(changes?.moved || {}).length
        ) {
          return;
        }

        const coreDocument = documentViewer.getDocument();
        const pageCount = coreDocument.getPageCount();
        const file = await getPdfFileObject(
          coreDocument,
          annotationManager,
          initialFileName
        );

        const newState = {
          isDirty: true,
          fileBlob: file,
          pageCount: pageCount,
        };

        updateDmState(newState);
      });

      Core.annotationManager.addEventListener(
        'annotationChanged',
        (annotations, action, { imported }) => {
          // when a page is removed the annotations are removed too but the annotationChanged event
          // is triggered first, but in that case we need to wait until the layoutChanged event
          // finish whe the updates on the redux store to get the latest version of the answers
          // in order to update the state correctly
          setTimeout(async () => {
            if (imported) {
              return;
            }

            const coreDocument = documentViewer.getDocument();
            const file = await getPdfFileObject(
              coreDocument,
              annotationManager,
              initialFileName
            );

            const newState = {
              isDirty: true,
              fileBlob: file,
            };

            updateDmState(newState);
          }, 250);
        }
      );

      // this code is triggered when the document is loaded
      Core.documentViewer.addEventListener('documentLoaded', async () => {
        const coreDocument = documentViewer.getDocument();
        const pageCount = coreDocument.getPageCount();
        const file = await getPdfFileObject(
          coreDocument,
          annotationManager,
          initialFileName
        );

        const newState = {
          ready: true,
          fileBlob: file,
          pageCount: pageCount,
          viewerRef: viewer,
        };

        updateDmState(newState);
      });

      // this code is triggered when the selected thumbnail changed
      UI.addEventListener(
        'selectedThumbnailChanged',
        (selectedThumbnailPageIndexes) => {
          if (selectedThumbnailPageIndexes?.detail?.length > 0) {
            setSelectedPages(
              selectedThumbnailPageIndexes.detail.map((index) => index + 1)
            );
          } else {
            setSelectedPages([]);
          }
        }
      );

      if (Core && annotationManager) {
        updateDmState({
          core: Core,
          annotationManager: annotationManager,
        });
      }

      if (readOnly) {
        UI.disableFeatures(['ThumbnailReordering', 'ThumbnailMerging']);
        UI.disableElements([
          'thumbnailControl',
          'thumbRotateClockwise',
          'thumbDelete',
          'pageManipulationOverlayButton',
          'thumbnailsControlRotatePopupTrigger',
          'thumbnailsControlInsertPopupTrigger',
          'thumbnailsControlReplace',
          'thumbnailsControlExtract',
          'thumbnailsControlDelete',
          'thumbnailsControlManipulatePopupTrigger',
        ]);
      }

      UI.enableElements(['documentControl']);

      setPdfInstance(instance);
    };

    if (!pdfInstance && viewer.current) {
      buildInstance();
    }

    return () => {
      if (pdfInstance) {
        pdfInstance.closeDocument();
        pdfInstance.dispose();
        setPdfInstance(null);
      }
    };
  }, [
    initialFileName,
    updateDmState,
    pdfInstance,
    setPdfInstance,
    setSelectedPages,
    user,
    readOnly,
  ]);

  // load PDF file and draw PDF annotations when a file is selected
  useEffect(() => {
    if (initialFile && pdfInstance) {
      pdfInstance.loadDocument(decodeURIComponent(initialFile), {
        filename: initialFileName,
      });
    }
  }, [initialFile, initialFileName, pdfInstance]);

  return (
    <Box
      variant="div"
      ref={viewer}
      id="cdm-viewer"
      sx={{ height: '90vh', width: '100%' }}
    />
  );
};

// -----------------------------------------------------------------------

CDMPdfPreview.propTypes = {
  pdfInstance: PropTypes.object,
  initialFile: PropTypes.string,
  initialFileName: PropTypes.string,
  setPdfInstance: PropTypes.func,
  setSelectedPages: PropTypes.func,
  updateDmState: PropTypes.func,
};

CDMPdfPreview.defaultProps = {
  pdfInstance: null,
  initialFile: null,
  initialFileName: '',
  setPdfInstance: () => null,
  setSelectedPages: () => null,
  updateDmState: () => null,
};

export default React.memo(CDMPdfPreview);
