import { nanoid } from 'nanoid';
import axiosInstance from '../../../utils/axios';
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';
import { array, string } from 'yup';
import { QUESTIONS_TYPES_WITH_CHOICES } from '../../../components/CertificationWorkbench/utils';
import { fDatePost } from 'src/utils/formatTime';
import { datadogRum } from '@datadog/browser-rum';

export const NECESSITIES = {
  REQUIRED: 1,
  DESIRED: 2,
  NOT_REQUIRED: 3,
};

export const SURVEY_PARAMS = parseQueryParams({
  expand: ['household_member'],
  fields: [
    'id',
    'household_member',
    'household',
    'household_member.first_name',
    'household_member.last_name',
    'household_member.email',
    'household_member.user',
    'certification',
  ],
});

export const isValidId = (x) => !isNaN(Number(x));

export const getRecordsParams = (pageId, sectionId, surveyId) =>
  parseQueryParams({
    page: pageId,
    section: sectionId,
    survey: surveyId,
    expand: [
      'answers',
      'answers.answer_group',
      'answer.files.satus',
      'answer.files.name',
      'answer.files.file_date',
      'answers.files.pdf_file_url',
      'answers.files.order',
      'answers.files.status',
      'answers.files.id',
      'answers.files.rejection_reasons',
      'answers.question_choices',
      'qit',
      'qit.question_choices',
      'qit.member_types',
      'qit.parent_qit',
      'qit.parent_choice',
      'qit.question',
      'qit.question.question_choices',
      'qit.parent_qit.question',
      'qit.question_group',
      'qit.question_group.parent_qit',
      'qit.question_group.parent_choice',
    ],
  });

export const getSchemaForRecord = (record, calculation) => {
  let requiredField = 'This field is required.';
  const question = record?.qit?.question || calculation?.name;
  const questionType =
    record?.qit?.question?.question_type || calculation?.format;

  switch (questionType) {
    case 'log_number':
    case 'text':
      if (
        question.name === 'first_name' ||
        question.name === 'last_name' ||
        question.name === 'date_of_birth'
      ) {
        requiredField = `${question.title} is required.`;
      }

      return string().required(
        question.help_text ? question.help_text : requiredField
      );
    case 'date':
      return string().required(
        question.help_text ? question.help_text : 'This field is required.'
      );
    case 'email':
      return string()
        .email('Must be a valid email.')
        .required('Email is required.');
    case 'bool':
      return array()
        .min(1, 'Please select one option.')
        .required('Please select one option.')
        .nullable();
    case 'choices':
      return array()
        .min(1, 'Please select one option.')
        .required('Please select one option.')
        .nullable();
    case 'multiselect':
      return array()
        .min(1, 'Please select at least one option.')
        .required('Please select at least one option.')
        .nullable();
    case 'ssn':
      return string()
        .trim()
        .matches(
          new RegExp('^(\\d{3})-(\\d{2})-(\\d{4})$'),
          'Please enter a valid social security number.'
        )
        .required('Please enter a valid social security number.');
    case 'phone':
      return string()
        .trim()
        .matches(
          new RegExp('^\\(?([0-9]{3})?\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$'),
          'Please enter a valid phone number.'
        )
        .required('Please enter a valid phone number.');
    case 'currency':
      return string().test(
        'calc-value',
        'This number is too large for calculations',
        (val) => !val || parseInt(val.replace(/[^\d.-]/g, '')) < 10000000
      );
    case 'file':
      return array().min(1).required(`A files is required.`);
    case 'zip_code':
      return string()
        .trim()
        .matches(
          new RegExp('^(\\d{5})$'),
          'Please enter a valid ZIP Code matches.'
        )
        .required('Please enter a valid ZIP Code.');
    case 'name':
      return string().required('Please enter a valid Name.');
    case 'last_SSN':
      return string().required(requiredField).nullable();
    case 'number':
      return string()
        .trim()
        .matches(
          new RegExp('^(\\$\\s)?(([1-9]\\d{0,2}(,\\d{3})*)|0)+(\\.\\d{1,6})?$'),
          'Please enter a valid number.'
        )
        .required('Please enter a valid number.')
        .nullable();
    case 'percentage':
      return string()
        .trim()
        .matches(
          new RegExp('^(\\d)+(\\.\\d{1,4})?'),
          'Please enter a valid percentage.'
        )
        .required('Please enter a valid percentage.')
        .nullable();
    case 'frequency':
      return array()
        .min(1, 'Please select one.')
        .required('Please select one.')
        .nullable();
    default:
      return string();
  }
};

export const getRecordResponseKey = (record) => {
  if (record?.qit) {
    switch (record.qit.question.question_type) {
      case 'log_number':
      case 'date':
      case 'text':
      case 'email':
      case 'ssn':
      case 'last_SSN':
      case 'currency':
      case 'account':
      case 'phone':
      case 'zip_code':
      case 'name':
      case 'number':
      case 'percentage':
      case 'calculation':
        return 'text';
      case 'bool':
      case 'multiselect':
      case 'choices':
      case 'frequency':
        return 'question_choices';
      case 'text_area':
        return 'text_area';
      case 'file':
        return 'file';
      default:
        return null;
    }
  }
  return null;
};

export const buildInitialValues = (records) => {
  const questionGroups = {};
  const orderedRecords = Object.values(records).sort(
    (a, b) => a.qit.order - b.qit.order
  );
  orderedRecords.forEach((record) => {
    if (
      record.qit.for_compliance ||
      record.qit.question.question_type === 'calculation'
    ) {
      return true;
    }

    // Generate the base form of questions by group
    if (!questionGroups[record.qit.question_group.id]) {
      questionGroups[record.qit.question_group.id] = {
        ...record.qit.question_group,
        formTemplate: {},
        answerGroups: {},
        hasAnswers: false,
        order: record.qit.order,
      };
    }

    if (
      !questionGroups[record.qit.question_group.id].formTemplate[
        'id_' + record.id
      ]
    ) {
      questionGroups[record.qit.question_group.id].formTemplate[
        'id_' + record.id
      ] = {
        id: null,
        value: record.qit.question.question_type !== 'file' ? '' : [],
      };
    }
  });

  const valuesByQIT_ = {};
  orderedRecords.forEach((record) => {
    valuesByQIT_[record.qit?.id] = null;

    if (record.qit.question.question_type === 'file') {
      valuesByQIT_[record.qit?.id] = [];
    }

    record.answers.forEach((answer) => {
      const aG = answer.answer_group;

      if (
        record.qit.for_compliance ||
        record.qit.question.question_type === 'calculation'
      ) {
        return true;
      }

      if (!questionGroups[aG.question_group].answerGroups[aG.id]) {
        questionGroups[aG.question_group].answerGroups[aG.id] = {
          id: aG.id,
          records: { ...questionGroups[aG.question_group].formTemplate },
        };

        questionGroups[aG.question_group].hasAnswers = true;
      }

      const answerValue = extractAnswerValue(
        answer,
        record.qit?.question?.question_type || 'text'
      );

      valuesByQIT_[record.qit?.id] = answerValue;
      questionGroups[aG.question_group].answerGroups[aG.id].records[
        'id_' + record.id
      ] = {
        id: answer.id,
        value: answerValue,
      };
    });
  });

  const initialValues_ = {};
  Object.values(questionGroups).forEach((questionGroup) => {
    initialValues_[questionGroup.id] = questionGroup.hasAnswers
      ? Object.values(questionGroup.answerGroups)
      : // this is adding the extra array to the formTemplate that we see in seasonal employment
        // removing this causes the seasonal employment selector to not even show
        [
          {
            id: nanoid(),
            records: { ...questionGroup.formTemplate },
          },
        ];
  });

  return [questionGroups, initialValues_, valuesByQIT_];
};

const removeListDuplicates = (array) => {
  return [...new Set(array)];
};

export const getExternalParentsValues = async (
  recordsMap,
  sectionId,
  pageId,
  surveyId
) => {
  const questionGroupsWithParents = Object.values(recordsMap)
    .map((record) => record.qit.question_group)
    .filter(
      (qg) =>
        qg?.parent_choice?.id &&
        qg?.parent_qit?.id &&
        qg?.parent_qit?.section !== Number(sectionId) &&
        qg?.parent_qit?.page !== Number(pageId)
    );

  let parentQITsIds = questionGroupsWithParents.map((qg) => qg.parent_qit?.id);
  parentQITsIds = removeListDuplicates(parentQITsIds);
  const params = parseQueryParams({
    expand: ['question_choices', 'survey_record'],
    fields: [
      'id',
      'question_choices.id',
      'question_choices.name',
      'survey_record.qit',
    ],
    survey_record__qit__in: parentQITsIds.join(','),
    survey_record__survey: surveyId,
  });

  const { data } = await axiosInstance.get(
    `questionnaire/survey_answer/?${params}`
  );

  const answersByQIT = {};
  parentQITsIds.forEach((QITid) => (answersByQIT[QITid] = null));

  data.forEach((a) => {
    if (!answersByQIT[a.survey_record.qit]) {
      answersByQIT[a.survey_record.qit] = [];
    }

    if (a.question_choices?.length > 0) {
      const choicesId = answersByQIT[a.survey_record.qit].map((c) => c.id);
      const notIncludedChoices = a.question_choices.filter(
        (choice) => !choicesId.includes(choice.id)
      );
      answersByQIT[a.survey_record.qit] = [
        ...answersByQIT[a.survey_record.qit],
        ...notIncludedChoices,
      ];
    }
  });

  return answersByQIT;
};

export const extractAnswerValue = (answer, questionType) => {
  if (!answer || (answer && answer.is_rejected && questionType !== 'file')) {
    return null;
  }

  switch (questionType) {
    case 'log_number':
    case 'date':
    case 'text':
    case 'email':
    case 'ssn':
    case 'last_SSN':
    case 'currency':
    case 'account':
    case 'phone':
    case 'zip_code':
    case 'name':
    case 'number':
    case 'percentage':
    case 'calculation':
      return answer.text;
    case 'frequency':
    case 'multiselect':
    case 'choices':
    case 'bool':
      return answer.question_choices;
    case 'text_area':
      return answer.text_area;
    case 'file':
      return answer.files ? answer.files : [];
    default:
      return null;
  }
};

export const recordShouldRender = (
  surveyRecord,
  recordsByQit,
  values,
  questionGroupId,
  answerGroupIndex
) => {
  if (surveyRecord?.qit?.parent_qit?.question?.question_type === 'file') {
    return false;
  }

  if (
    surveyRecord?.qit?.parent_qit?.id &&
    surveyRecord?.qit?.parent_choice?.id
  ) {
    const parentRecordId = recordsByQit[surveyRecord?.qit?.parent_qit?.id]?.id;
    let parentSelectedChoicesId;

    try {
      const value =
        values[questionGroupId][answerGroupIndex].records[
          'id_' + parentRecordId
        ]?.value;
      const choicesId = value?.length ? value.map((v) => v.id) : null;
      parentSelectedChoicesId =
        choicesId === null || choicesId === undefined ? null : choicesId;
    } catch (e) {
      parentSelectedChoicesId = null;
    }

    const parentChoiceId = surveyRecord?.qit?.parent_choice?.id;

    return Boolean(
      parentChoiceId &&
        parentSelectedChoicesId &&
        parentSelectedChoicesId?.length > 0 &&
        parentSelectedChoicesId.includes(parentChoiceId)
    );
  }

  return true;
};

export const groupShouldRender = (
  questionGroup,
  flatValuesByQIT_,
  externalParentQITsValues
) => {
  const parentQITid = questionGroup?.parent_qit?.id;
  const parentChoiceId = questionGroup?.parent_choice?.id;

  if (parentQITid && parentChoiceId) {
    const value = (
      flatValuesByQIT_[parentQITid] ||
      externalParentQITsValues[parentQITid] ||
      []
    ).filter(Boolean);
    const parentAnswersId = value?.length > 0 ? value.map((v) => v.id) : null;

    // false parent is not answered
    return Boolean(
      parentChoiceId &&
        parentAnswersId &&
        parentAnswersId?.length > 0 &&
        parentAnswersId.includes(parentChoiceId)
    );
  }

  return true;
};

const getGroupsToDeleteAndFilterVisibleGroups = (
  values,
  pageGroups,
  flatValuesByQIT,
  externalParentQITsValues
) => {
  const groupsToDelete = [];
  const visibleQuestionGroups = Object.entries(values).filter(
    ([questionGroupId, answerGroups]) => {
      const questionGroup = pageGroups[questionGroupId];
      const isVisible = groupShouldRender(
        questionGroup,
        flatValuesByQIT,
        externalParentQITsValues
      );

      if (!isVisible) {
        answerGroups.forEach((answerGroup) => {
          if (isValidId(answerGroup.id)) {
            groupsToDelete.push(answerGroup.id);
          }
        });
      }
      return isVisible;
    }
  );
  return { groupsToDelete, visibleQuestionGroups };
};

const getAnswerGroupsToDeleteAndFilterVisibleRecords = (
  records,
  pageRecordsMap,
  recordsByQit,
  questionGroupId,
  values,
  answerGroupIndex
) => {
  const recordsToDelete = [];

  const visibleRecords = Object.entries(records).filter(
    ([recordId, answer]) => {
      const record = pageRecordsMap[recordId.replace('id_', '')];

      const isVisible = recordShouldRender(
        record,
        recordsByQit,
        values,
        questionGroupId,
        answerGroupIndex
      );

      if (!isVisible) {
        if (isValidId(answer?.id)) {
          recordsToDelete.push(Number(answer.id));
        }
      }

      return isVisible;
    }
  );
  return { recordsToDelete, visibleRecords };
};

const createFilePayloadData = (record, answerGroup, answer) => {
  const answerGroupID = isNaN(answerGroup?.id) ? null : answerGroup?.id;

  const answerID =
    record.answers.find((answer) => answer.answer_group.id === answerGroupID)
      ?.id || null;

  const filePayloadData = {
    survey_record: record.id,
    answer_group: answerGroupID,
    survey_answer_id: answerID,
    files: [],
  };

  answer.value.forEach((newFile) => {
    if (isValidId(newFile.id) && !newFile.file_blob) {
      return true;
    }
    const payloadData = {
      file_blob: newFile.file_blob,
    };
    if (isValidId(newFile.id)) {
      payloadData.id = newFile.id;
    }

    filePayloadData.files.push(payloadData);
  });
  return filePayloadData.files.length > 0 ? filePayloadData : null;
};

export const buildAnswersPayload = (
  values,
  flatValuesByQIT,
  externalParentQITsValues,
  recordsByQit,
  pageGroups,
  pageRecordsMap
) => {
  const newPayload = [];
  let hasFileAnswers = false;
  const answersToDelete = [];
  const { groupsToDelete, visibleQuestionGroups } =
    getGroupsToDeleteAndFilterVisibleGroups(
      values,
      pageGroups,
      flatValuesByQIT,
      externalParentQITsValues
    );

  (visibleQuestionGroups || []).forEach(([questionGroupId, answerGroups]) => {
    answerGroups.forEach((answerGroup, agIx) => {
      const questionPayload = {
        answer_group: isValidId(answerGroup.id) ? Number(answerGroup.id) : null,
        answers: [],
      };
      newPayload.push(questionPayload);

      const { recordsToDelete, visibleRecords } =
        getAnswerGroupsToDeleteAndFilterVisibleRecords(
          answerGroup.records,
          pageRecordsMap,
          recordsByQit,
          questionGroupId,
          values,
          agIx
        );

      if (answersToDelete.length > 0) {
        answersToDelete.push(...recordsToDelete);
      }

      (visibleRecords || []).forEach(([recordId, answer]) => {
        const record = pageRecordsMap[recordId.replace('id_', '')];

        if (record.qit.question.question_type === 'file') {
          if (!answer.value?.length > 0) {
            return;
          }
          const fileAnswerData = createFilePayloadData(
            record,
            answerGroup,
            answer
          );

          if (!fileAnswerData) {
            return;
          }
          hasFileAnswers = true;
          return questionPayload.answers.push(fileAnswerData);
        }

        const responseKey = getRecordResponseKey(record);
        let answerValue = answer.value;
        if (
          QUESTIONS_TYPES_WITH_CHOICES.includes(
            record.qit.question.question_type
          )
        ) {
          answerValue = answer.value ? answer.value.map((v) => v.id) : [];
        } else if (record.qit.question.question_type === 'currency') {
          answerValue = answerValue.replace(/[^\d.-]/g, '');
        } else if (!answerValue) {
          return true; // For questions that are not choice based, if there is no value, don't save it.
        }

        questionPayload.answers.push({
          survey_answer_id: answer?.id,
          id: answer.id,
          survey_record: record.id,
          [responseKey]: answerValue,
        });
      });

      // Remove empty objects questions from the payload
      if (questionPayload.answers.length === 0) {
        newPayload.pop();
      }
    });
  });

  return [newPayload, hasFileAnswers, answersToDelete, groupsToDelete];
};

const isInvalidAnswer = (value, questionType) => {
  if (questionType === 'file') {
    return value.length < 1;
  }
  return !value && value !== 0;
};

export const validateFormValues = async (
  values,
  errors,
  pageGroups,
  flatValuesByQIT,
  externalParentQITsValues,
  pageRecordsMap,
  recordsByQit
) => {
  const nagRecords_ = [];
  let newErrors = { ...errors };

  for (const [groupId, answerGroups] of Object.entries(values)) {
    const questionGroup = pageGroups[groupId];
    const groupIsVisible = groupShouldRender(
      questionGroup,
      flatValuesByQIT,
      externalParentQITsValues
    );

    if (!groupIsVisible) {
      continue;
    }

    let agIx = 0;

    for (const answerGroup of answerGroups) {
      for (const [recordId, answer] of Object.entries(answerGroup.records)) {
        const record = pageRecordsMap[recordId.replace('id_', '')];
        const schema = getSchemaForRecord(record);
        const recordKey = `${groupId}.${agIx}.records.${recordId}.value`;

        try {
          const isVisible = recordShouldRender(
            record,
            recordsByQit,
            values,
            groupId,
            agIx
          );

          if (isVisible && record.qit.necessity === NECESSITIES.REQUIRED) {
            await schema.validate(answer?.value);
          }

          delete newErrors[recordKey];

          const isValid = isInvalidAnswer(
            answer.value,
            record.qit.question.question_type
          );

          if (
            isVisible &&
            isValid &&
            record.qit.necessity === NECESSITIES.DESIRED
          ) {
            nagRecords_.push(record);
          }
        } catch (err) {
          newErrors = { ...newErrors, [recordKey]: err.message };
        }
      }

      agIx += 1;
    }
  }

  return [newErrors, nagRecords_];
};

export const patchCustomProntoFields = (
  flatValuesByQIT,
  recordsByQit,
  certificationId,
  memberId
) => {
  if (!certificationId || !memberId) {
    return;
  }

  Object.values(recordsByQit).forEach((r) => {
    if (
      r?.qit?.question?.question_type === 'date' &&
      r?.qit?.question?.name?.toLowerCase().includes('date of birth') &&
      flatValuesByQIT[r.qit.id]
    ) {
      const dateOfBirth = fDatePost(flatValuesByQIT[r.qit.id]);
      axios.patch(`household/household_member/${memberId}/`, {
        date_of_birth: dateOfBirth,
      });
    } else if (
      r?.qit?.question?.question_type === 'log_number' &&
      flatValuesByQIT[r.qit.id]
    ) {
      axios.patch(`certification/certification/${certificationId}/`, {
        log_number: flatValuesByQIT[r.qit.id],
      });
    }
  });
};

export function datadogAddAction(
  payload = {},
  eventName = 'save_answers_error'
) {
  // send event to data dog to track the errors on the questionnaire
  if (
    process?.env?.REACT_APP_DATADOG_ID &&
    process?.env?.REACT_APP_DATADOG_TOKEN
  ) {
    datadogRum.addAction(eventName, payload);
  }
}
