import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  form: {},
  formList: [],
  formQuestions: [],
  currentSearch: '',
};

const slice = createSlice({
  name: 'Form',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Single Form
    getFormSuccess(state, action) {
      state.isLoading = false;
      state.form = action.payload;
    },

    // GET Form List
    getFormListSuccess(state, action) {
      state.isLoading = false;
      state.formList = action.payload;
    },

    getFormQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.formQuestions = action.payload;
    },

    setDocumentTemplate(state, action) {
      const docs = [...state.formList.results];
      const idx = docs.findIndex((element) => element.id === action.payload.id);
      docs[idx].template = action.payload.template;
      state.formList.results = docs;
    },
    // Set currentSearch
    setCurrentSearch(state, action) {
      state.currentSearch = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setCurrentSearch, setDocumentTemplate } = slice.actions;

// ----------------------------------------------------------------------

export function getFormList(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `form/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getFormListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFormQuestions(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `survey/question/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getFormQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getForm(formId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `form/${formId}/?${parseQueryParams(queryParams)}`
      );
      dispatch(slice.actions.getFormSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createForm(formData, callbackSuccess, callbackError) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`form/`, formData);
      if (response.status === 201) {
        callbackSuccess(response);
      }
    } catch (error) {
      callbackError(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateForm(formId, formData, callbackSuccess, callbackError) {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`form/${formId}/`, formData);
      if (response.status === 200) {
        callbackSuccess(response);
      }
    } catch (error) {
      callbackError(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
