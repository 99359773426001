import { useNavigate } from 'react-router';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';

const NewHeaderBreadcrumbs = ({ links }) => {
  const linksLength = links.length;
  const navigate = useNavigate();

  const handleLinkClick = (e, href) => {
    e.preventDefault();
    navigate(href);
  };

  return (
    <Breadcrumbs sx={{ fontSize: '0.875rem' }}>
      {links.map((link, i) => {
        const last = i === linksLength - 1;

        return last ? (
          <Typography
            color="text.primary"
            key={link.href}
            sx={{ fontSize: '0.875rem' }}
          >
            {link.name}
          </Typography>
        ) : (
          <Link
            underline="hover"
            color="inherit"
            href={link.href}
            key={link.href}
            onClick={(e) => handleLinkClick(e, link.href)}
            sx={{ fontSize: '0.875rem', color: '#919EAB' }}
          >
            {link.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default NewHeaderBreadcrumbs;
