import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import axios from 'src/utils/axios';
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function StatusNotesForm({
  isEdit,
  statusNote,
  certification,
  onAddSuccess,
  onCancel,
  fromBubble,
  isDashboardNote = false,
}) {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const response = await axios.get('status_notes/status_note_category/');
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const StatusNoteSchema = Yup.object().shape({
    note: Yup.string().required('Note is required.'),
    category: Yup.object().nullable().required('Category is required.'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      note: isEdit ? statusNote?.note || '' : '',
      category: isEdit
        ? statusNote?.category || {}
        : categories.length > 0
        ? categories.find(
            (category) => category?.name?.toLowerCase() === 'status'
          ) || categories[0]
        : {},
      certification: certification.id,
      author: user.id,
    },
    validationSchema: StatusNoteSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit) {
          // Build data to send
          const noteData = {
            note: values.note,
            category: values.category.id,
          };
          // Send data
          await axios.patch(
            `status_notes/status_note/${statusNote.id}/`,
            noteData
          );
          onAddSuccess();
        } else {
          // Build data to send
          const noteData = {
            note: values.note,
            category: values.category.id,
            certification: certification.id,
            author: user.id,
          };
          // Send data
          const response = await axios.post(
            `status_notes/status_note/?expand=author`,
            noteData
          );
          resetForm();
          onAddSuccess(response);
        }
        setSubmitting(false);
        enqueueSnackbar(isEdit ? 'Note edited.' : 'Note created.', {
          variant: 'success',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Something went wrong.', { variant: 'error' });
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {isEdit || isDashboardNote ? (
            <>
              {!isDashboardNote && (
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    {...getFieldProps('category')}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      setFieldValue('category', newValue);
                    }}
                    options={categories || []}
                    getOptionLabel={(option) => option.name || ''}
                    disabled={isDashboardNote}
                    renderInput={(params) => (
                      <TextField
                        label="Category"
                        {...params}
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  autoFocus={true}
                  minRows={4}
                  maxRows={4}
                  placeholder="Write a note here..."
                  label="Note"
                  {...getFieldProps('note')}
                  error={Boolean(errors.note && touched.note)}
                  helperText={errors.note}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  autoFocus={fromBubble ? true : false}
                  minRows={1}
                  maxRows={2}
                  placeholder="Write a note here..."
                  label="Note"
                  {...getFieldProps('note')}
                  error={Boolean(errors.note && touched.note)}
                  helperText={errors.note}
                />
              </Grid>
              <Grid item xs={12} md={8} pr={{ md: 2 }}>
                <Autocomplete
                  fullWidth
                  {...getFieldProps('category')}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('category', newValue);
                  }}
                  options={categories || []}
                  getOptionLabel={(option) => option.name || ''}
                  disabled={isDashboardNote}
                  renderInput={(params) => (
                    <TextField
                      label="Category"
                      {...params}
                      error={Boolean(touched.category && errors.category)}
                      helperText={touched.category && errors.category}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} md={isEdit || isDashboardNote ? 12 : 4}>
            {isEdit || isDashboardNote ? (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button color="inherit" sx={{ mr: 2 }} onClick={onCancel}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="medium"
                  loading={isSubmitting}
                >
                  {isDashboardNote ? 'Add Note' : 'Save Changes'}
                </LoadingButton>
              </Box>
            ) : (
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="medium"
                loading={isSubmitting}
              >
                Add Note
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
