import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
// icons
import EditIcon from '@mui/icons-material/Edit';
// utils
import parseQueryParams from '../../utils/query';
import { ROWS_PER_PAGE_OPTIONS } from '../../utils/tables';
// components
import Label from '../Label';
import Scrollbar from '../Scrollbar';
import EmptyTable from '../EmptyTable';
import SearchNotFound from '../SearchNotFound';
import TemplateListToolbar from './TemplateListToolbar';
import TemplateListHead from './TemplateListHead';
import TemplateForm from './TemplateForm';
import usePaginationLS, { getLsValue } from 'src/hooks/usePaginationLS';
import TemplatesEngineService from 'src/services/TemplatesEngine.service';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false, sort: 'name' },
  { id: 'type_name', label: 'Type', alignRight: false, sort: 'type__name' },
  {
    id: 'category',
    label: 'Category',
    alignRight: false,
    sort: 'category__name',
  },
  {
    id: 'description',
    label: 'Description',
    alignRight: false,
    sort: 'description',
  },
  { id: 'actions', label: '', alignRight: true },
];

// ----------------------------------------------------------------------
const LS_ROWS_PER_PAGE_KEY = 'templates-list-pagination-value';

const TemplateList = ({ propertyId, categories, setDisplayConfiguration }) => {
  // const { templateList } = useSelector(state => state.template);

  const [templateList, setTemplateList] = useState([]);
  const [editTemplate, setEditTemplate] = useState(null);
  const [search, setSearch] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('type__name');
  const [rowsPerPage, setRowsPerPage] = useState(
    getLsValue(LS_ROWS_PER_PAGE_KEY)
  );
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [update, setUpdate] = useState(false);

  usePaginationLS({
    key: LS_ROWS_PER_PAGE_KEY,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    rowsPerPage,
  });

  const getTemplates = async (_page, _rowsPerPage, _order, _orderBy) => {
    const currentPage = _page !== undefined ? _page : page;
    const currentPageSize = _rowsPerPage ? _rowsPerPage : rowsPerPage;
    const currentOrder = _order ? _order : order;
    const currentOrderBy = _orderBy ? _orderBy : orderBy;

    try {
      setLoading(true);
      const queryParams = {
        expand: ['type', 'category'],
        page_size: currentPageSize,
        page: currentPage + 1,
      };

      if (propertyId) {
        queryParams.for_property = propertyId;
      } else {
        queryParams.property__isnull = true;
      }
      if (categories && Array.isArray(categories)) {
        queryParams['category__name__in'] = categories;
      }
      if (search) {
        queryParams.search = search;
      }
      if (currentOrderBy) {
        const modifier = currentOrder === 'desc' ? '-' : '';
        queryParams.ordering = `${modifier}${currentOrderBy}`;
      }

      TemplatesEngineService.getCommunicationTemplates(
        parseQueryParams(queryParams)
      )
        .then((response) => {
          setTemplateList(response.data);
          setReady(true);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTemplates(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(newSize);
    getTemplates(0, newSize);
  };

  const handleRequestSort = (event, sortBy) => {
    const isAsc = orderBy === sortBy && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(sortBy);
    setPage(0);
    getTemplates(0, rowsPerPage, newOrder, sortBy);
  };

  const handleEditTemplate = (template) => {
    setEditTemplate(template);
  };

  const handleCancelEditTemplate = () => {
    setEditTemplate(null);
  };

  const handleSuccess = () => {
    setEditTemplate(null);
    getTemplates();
  };

  const handleRestore = async (typeId) => {
    if (typeId) {
      const queryParams = parseQueryParams({
        expand: ['type', 'category'],
        property__isnull: true,
        type: typeId,
      });
      TemplatesEngineService.getCommunicationTemplates(queryParams)
        .then((response) => {
          setEditTemplate(response.data[0]);
          getTemplates();
        })
        .catch((error) => {
          handleSuccess();
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (search !== null) {
      const timeOutId = setTimeout(() => {
        setPage(0);
        setCurrentSearch(search);
        getTemplates(0);
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getTemplates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (update) {
      getTemplates(0);
      setUpdate(false);
    }
  }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredTemplates =
    'results' in templateList ? templateList.results : [];
  const templatesNotFound = filteredTemplates.length === 0;

  return (
    <>
      <TemplateListToolbar
        search={search}
        onSearch={setSearch}
        isLoading={loading}
      />
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TemplateListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            {ready && !templatesNotFound && (
              <TableBody>
                {filteredTemplates.map((template) => (
                  <TableRow hover key={template?.id}>
                    <TableCell
                      align="left"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {template?.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {template.type.name.replaceAll('_', ' ').toLowerCase()}
                    </TableCell>
                    <TableCell align="left">
                      <Label
                        variant="ghost"
                        color={template.property ? 'info' : 'default'}
                      >
                        {template.category.name || 'N/A'}
                      </Label>
                    </TableCell>
                    <TableCell align="left">
                      {/*<TemplateDescriptionEdit*/}
                      {/*  description={template.description}*/}
                      {/*  templateId={template.id}*/}
                      {/*  setUpdate={setUpdate}*/}
                      {/*  update={update}*/}
                      {/*/>*/}
                      {template.description || '-'}
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        color="primary"
                        size="small"
                        variant="text"
                        endIcon={<EditIcon />}
                        onClick={() => handleEditTemplate(template)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
            {ready && templatesNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                    {!currentSearch ? (
                      <EmptyTable objectName="Templates" />
                    ) : (
                      <SearchNotFound searchQuery={currentSearch} />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!ready && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={5} style={{ padding: 0 }}>
                    <Skeleton variant="text" height={80} width={'100%'} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={templateList.count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ mt: 3 }}
      />
      {setDisplayConfiguration && (
        <Button
          sx={{ mb: 3 }}
          color="secondary"
          type="button"
          variant="contained"
          onClick={() => setDisplayConfiguration(false)}
        >
          Return
        </Button>
      )}

      {/*Edit Modal*/}
      <Dialog
        open={!!editTemplate}
        maxWidth="lg"
        fullWidth
        onClose={handleCancelEditTemplate}
      >
        <DialogTitle>Edit Template</DialogTitle>
        <DialogContent>
          <TemplateForm
            isEdit
            template={editTemplate}
            propertyId={propertyId}
            onSuccess={handleSuccess}
            onRestore={handleRestore}
            onCancel={handleCancelEditTemplate}
            setUpdate={setUpdate}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

TemplateList.propTypes = {
  propertyId: PropTypes.number,
  categories: PropTypes.array,
};

export default TemplateList;
