import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import roundReply from '@iconify/icons-ic/round-reply';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import {
  Box,
  Button,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
// utils
import createAvatar from '../../utils/createAvatar';
import { fDateTimeSuffix } from '../../utils/formatTime';
import { MAvatar } from '../@material-extend';
import { memo, useMemo, useState } from 'react';
import { EMAIL_TYPE, SMS_TYPE } from './ConversationsV2';
import { fPhone } from 'src/utils/formatNumber';
import CertificationsDialog from './CertificationDialog';
import { useParams } from 'react-router';

// ----------------------------------------------------------------------

export const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  justifyContent: 'space-between',
  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const formatPhone = (number = '') => {
  if (!number || !number?.length >= 10) {
    return null;
  }
  return fPhone(number.slice(-10));
};

// If anyone but the user in the app is part of the last message, fill To: as the name of the conversation in the app
const getAssetName = (conversation) => {
  return conversation?.certification?.id
    ? `${conversation?.certification?.household?.name} | ${conversation?.certification?.display_name}`
    : conversation?.property?.name;
};

const buildEmailToField = (conversation, message, inMsg = false) => {
  // If the most recent message was sent by someone outside the app -> To: <conversation-based name>
  if (message?.sender_contact_email?.id || message?.sender_hhm_email?.id) {
    return getAssetName(conversation);
  } else {
    let toRecipients = [];
    conversation?.hhm_emails?.forEach((email) => {
      toRecipients.push(getUserFromEmail(email, email.household_member, inMsg));
    });
    conversation?.contact_emails?.forEach((email) => {
      toRecipients.push(getUserFromEmail(email, email.contact, inMsg));
    });
    return toRecipients.join(', ');
  }
};

const getUserFromEmail = (email, user, inMsg) => {
  let name = '';
  name += user?.first_name ? user.first_name : '';
  name += user?.last_name ? ' ' + user.last_name : '';
  if (name === '') {
    name = email.email;
  } else if (user?.first_name !== email?.email) {
    name += inMsg ? ` - ${email.email}` : ` <${email.email}>`;
  }
  name = name.trim();
  return name;
};

const getContactName = (message, conversation) => {
  let displayName = [];
  if (message?.sender_user) {
    if (message?.sender_user.first_name) {
      displayName.push(message?.sender_user.first_name);
    }
    if (message?.sender_user.last_name) {
      displayName.push(message?.sender_user.last_name);
    }
    return displayName.join(' ');
  } else if (message?.sender_contact) {
    if (message?.sender_contact.first_name) {
      displayName.push(message?.sender_contact.first_name);
    }
    if (message?.sender_contact.last_name) {
      displayName.push(message?.sender_contact.last_name);
    }
    return displayName.join(' ');
  } else {
    if (message.message_type === 2 && !message?.sender_user) {
      if (conversation?.hhm_mobile_phone?.household_member?.first_name) {
        displayName.push(
          conversation.hhm_mobile_phone.household_member.first_name
        );
      }
      if (conversation?.hhm_mobile_phone?.household_member?.last_name) {
        displayName.push(
          conversation.hhm_mobile_phone.household_member.last_name
        );
      }
      return displayName.join(' ');
    }
    displayName.push(message?.from_email);
    return displayName.join(' ');
  }
};

// ----------------------------------------------------------------------

const MailDetailsToolbar = ({
  conversation,
  handleOpenCompose,
  firstMessage,
  selectMessage,
  isDone,
  ...other
}) => {
  const { pathname, search } = useLocation();
  const [openCertifications, setOpenCertifications] = useState(false);
  const isUserDashboard = pathname.startsWith('/workbench/user');
  const { conversationKey } = useParams();
  const returnPath = useMemo(
    () => (pathname || '').replace(`/communications/${conversationKey}`, ''),
    [conversationKey, pathname]
  );
  const isEmail = useMemo(
    () => conversation?.conversation_type === EMAIL_TYPE,
    [conversation?.conversation_type]
  );
  const isSMS = useMemo(
    () => conversation?.conversation_type === SMS_TYPE,
    [conversation?.conversation_type]
  );
  const [fromNumber, toNumber] = (conversation?.from_to_pair || '').split('/');

  if (!conversation) {
    return null;
  }

  const forwardMessage = async () => {
    const _forward = {
      message: ``,
      attachments: [],
      subject: `FW: ${conversation.subject}`,
    };

    conversation.messages.forEach((msg) => {
      _forward.attachments = [..._forward.attachments, ...msg.files];
      _forward.message =
        _forward.message +
        `
---------- Forwarded message ---------
From: ${getContactName(msg, conversation)} - ${
          msg?.sender_user?.email || msg?.sender_contact?.email || ''
        }
Date: ${fDateTimeSuffix(msg?.created)}
Subject: ${conversation.subject}
To: ${buildEmailToField(conversation, msg, true)}

${msg.content}

${
  !!msg.files.length
    ? `Attachments
${String(msg.files.map((attc) => `${attc.name}`)).replace(',', ', ')}
`
    : ''
}
`;
    });

    const uniqueIds = [];

    const unique = _forward.attachments.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);

      if (!isDuplicate) {
        uniqueIds.push(element.id);

        return true;
      }

      return false;
    });

    const promises = unique.map(
      async (_attch) => await getAttachedFiles(_attch)
    );
    _forward.attachments = await Promise.all(promises);
    return _forward;
  };

  const getAttachedFiles = async (value) => {
    try {
      const blob = await fetch(value.url).then((resp) => {
        if (resp.ok) {
          return resp.blob();
        } else {
          return resp.json();
        }
      });

      return new File([blob], value.name);
    } catch (e) {
      console.error(e);
    }
  };

  const handleForward = async () => {
    const data = await forwardMessage();
    handleOpenCompose(false, data);
  };

  return (
    <>
      <RootStyle {...other}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', md: 'auto' },
          }}
        >
          <Tooltip title="Back">
            <IconButton
              sx={{ pl: 0, '&:hover': { backgroundColor: 'transparent' } }}
              component={RouterLink}
              to={`${returnPath}${search}`}
              size="large"
            >
              <Icon icon={arrowIosBackFill} width={20} height={20} />
            </IconButton>
          </Tooltip>

          {firstMessage && (
            <MAvatar
              alt={getContactName(firstMessage, conversation)}
              src={null}
              color={
                createAvatar(getContactName(firstMessage, conversation)).color
              }
            >
              {createAvatar(getContactName(firstMessage, conversation)).name}
            </MAvatar>
          )}

          {firstMessage && (
            <Box sx={{ ml: 2, width: { xs: 'calc(100% - 88px)', md: 'auto' } }}>
              <Typography
                variant="subtitle2"
                sx={{
                  display: { xs: 'block', md: 'inline' },
                  width: { xs: '100%', md: 'auto' },
                }}
                noWrap
              >
                {getContactName(firstMessage, conversation)}
              </Typography>

              <Link
                variant="caption"
                sx={{
                  color: 'text.secondary',
                  display: { xs: 'inline-block', md: 'inline' },
                  width: { xs: '100%', md: 'auto' },
                }}
              >
                &nbsp;{' '}
                {isEmail &&
                  (firstMessage?.sender_user?.email ||
                    firstMessage?.sender_contact?.email) &&
                  `<${
                    firstMessage?.sender_user?.email ||
                    firstMessage?.sender_contact?.email ||
                    ''
                  }>`}
                {isSMS && formatPhone(fromNumber)}
              </Link>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mr: '5px' }}
                >
                  To:
                </Typography>
                <>
                  {isEmail && (
                    <>
                      <Typography variant="caption" color="text.secondary">
                        {buildEmailToField(conversation, firstMessage)}
                        {conversation?.certification?.id && isUserDashboard && (
                          <>
                            &nbsp; - &nbsp;
                            {
                              conversation?.certification?.household?.name
                            } | {conversation?.certification?.display_name}
                          </>
                        )}
                        {conversation?.certification?.property?.id &&
                          isUserDashboard && (
                            <>
                              &nbsp; - &nbsp;
                              {conversation?.certification?.property?.name}
                            </>
                          )}
                      </Typography>
                      {!Boolean(
                        firstMessage?.sender_user ||
                          firstMessage?.sender_contact
                      ) && (
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          noWrap
                        >
                          {conversation?.certification?.property?.name}
                        </Typography>
                      )}
                      {conversation?.compliance_users?.length === 0 && ( // Inbound conversation
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          noWrap
                        >
                          {getAssetName(conversation)}
                        </Typography>
                      )}
                    </>
                  )}
                  {isSMS && (
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {conversation?.last_message?.sender_user
                        ? `${conversation?.from[0]?.name || ''} ${
                            conversation?.from[0]?.lastName || ''
                          }`
                        : conversation?.hhm_mobile_phone
                        ? `${
                            conversation?.hhm_mobile_phone?.household_member
                              ?.first_name || ''
                          } ${
                            conversation?.hhm_mobile_phone?.household_member
                              ?.last_name || ''
                          }`
                        : ''}{' '}
                      {formatPhone(toNumber)}
                    </Typography>
                  )}
                </>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: { xs: '100%', md: 'auto' },
            justifyContent: { xs: 'flex-end', md: 'flex-start' },
          }}
        >
          {firstMessage && (
            <Typography
              variant="caption"
              sx={{ color: 'text.secondary', minWidth: 120 }}
            >
              {fDateTimeSuffix(conversation?.createdAt)}
            </Typography>
          )}

          {isEmail && (
            <Tooltip
              title={!Boolean(conversation.certification) ? '' : 'Reply'}
            >
              <Box>
                <IconButton
                  onClick={() => handleOpenCompose(true)}
                  size="large"
                >
                  <Icon icon={roundReply} width={20} height={20} />
                </IconButton>
              </Box>
            </Tooltip>
          )}

          {isEmail && Boolean(conversation.certification) && (
            <Tooltip
              title={!Boolean(conversation.certification) ? '' : 'Forward'}
            >
              <Box>
                <IconButton
                  onClick={handleForward}
                  disabled={!Boolean(conversation.certification)}
                  size="large"
                >
                  <Icon
                    icon={roundReply}
                    width={20}
                    height={20}
                    style={{ transform: 'scaleX(-1)' }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          )}

          {!Boolean(conversation.certification) && (
            <Button onClick={() => setOpenCertifications(true)} sx={{ ml: 2 }}>
              Add to Certification
            </Button>
          )}

          {!isDone && firstMessage && (
            <Tooltip
              title="Select message to include in File Manager."
              placement="top"
            >
              <Box
                onClick={(e) => selectMessage(e, firstMessage)}
                component={
                  firstMessage?.add_to_certification_file
                    ? CheckBoxIcon
                    : CheckBoxOutlineBlankIcon
                }
                sx={{
                  fontSize: 20,
                  color: firstMessage?.add_to_certification_file
                    ? 'primary.main'
                    : 'text.secondary',
                  cursor: 'pointer',
                  ml: 1,
                }}
              />
            </Tooltip>
          )}
        </Box>
      </RootStyle>
      <CertificationsDialog
        open={openCertifications}
        setOpen={setOpenCertifications}
        email={firstMessage?.from_email}
        certification={conversation.certification}
      />
    </>
  );
};

MailDetailsToolbar.propTypes = {
  conversation: PropTypes.object,
};

export default memo(MailDetailsToolbar);
