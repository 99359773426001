import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ConversationDetailItemSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          borderBottom: '1px solid rgba(165, 158, 171, 0.2 sx={{ mb: 1 }}4)',
          p: 3,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Skeleton sx={{ mr: 2 }} variant="circular" height={40} width={40} />

          <Box sx={{ mr: 'auto' }}>
            <Skeleton variant="text" height={16} width={160} />
            <Skeleton variant="text" height={14} width={120} />
          </Box>

          <Skeleton variant="text" height={14} width={80} />
        </Box>
      </Box>

      <Box sx={{ p: 3 }}>
        <Skeleton variant="text" height={45} width={200} />
        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="60%" sx={{ mb: 1 }} />

        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="30%" sx={{ mb: 1 }} />

        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="70%" sx={{ mb: 1 }} />

        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="100%" sx={{ mb: 1 }} />
        <Skeleton variant="text" height={18} width="90%" sx={{ mb: 1 }} />
      </Box>
    </>
  );
};

export default ConversationDetailItemSkeleton;
