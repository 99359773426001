import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import xss from 'xss';
import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import closeFill from '@iconify/icons-eva/close-fill';
import expandFill from '@iconify/icons-eva/expand-fill';
import collapseFill from '@iconify/icons-eva/collapse-fill';
import axios from 'src/utils/axios';
import axiosInstance from 'src/utils/axios';
import VerifiedIcon from '@mui/icons-material/Verified';
import { uniq } from 'lodash';
// material
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Backdrop,
  Box,
  Chip,
  Divider,
  FormHelperText,
  IconButton,
  Input,
  Portal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
//
import { QuillEditor } from '../editor';
import { useSnackbar } from 'notistack';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  FILTER_IDS,
  getConversation,
  sendMessageSuccess,
  setShouldReload,
  updateConversation,
  updateSelectedConversation,
} from 'src/redux/slices/conversationsV2';
import MailDetailsAttachments from './MailDetailsAttachments';
import MessageTemplates from './Templates';
import { buildMessageFormData, getMessageById } from './utils';
import { EMAIL_TYPE, FAX_TYPE, SMS_TYPE } from './ConversationsV2';
import parseQueryParams from 'src/utils/query';
import { formatPhone } from './MailDetailsToolbar';
import Label from '../../components/Label';

const FALLBACK_TYPES = [
  { display_name: 'Email', value: 1 },
  { display_name: 'SMS', value: 2 },
];
const MAX_CHARACTERS_PER_SEGMENT = 160;
const MAX_PAGES_PER_MESSAGE = 10;
const MAX_CHARACTERS_PER_MESSAGE =
  MAX_CHARACTERS_PER_SEGMENT * MAX_PAGES_PER_MESSAGE;

// ----------------------------------------------------------------------

export const RootStyle = styled('div')(({ theme }) => ({
  right: 70,
  bottom: 0,
  zIndex: 1200,
  height: '100%',
  minHeight: '70vh',
  maxHeight: 'calc(100vh - 200px) ',
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  overflow: 'hidden',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor: theme.palette.background.paper,
  width: '60vw',
}));

export const InputStyle = styled(Input)(({ theme }) => ({
  padding: theme.spacing(0.5, 3),
  borderBottom: `solid 1px ${theme.palette.divider}`,
}));

// ----------------------------------------------------------------------

const CONTACTS_LABEL = 'Contacts List';
const MEMBERS_LABEL = 'Household Members';
const EMPTY_CONVERSATION_LABEL = 'Empty Communication';

const MailCompose = ({
  isOpenCompose,
  onCloseCompose,
  isReply,
  setIsReply,
  reject,
  isProperty,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { certification_id } = useParams();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { infoToReject } = useSelector((state) => state.certification);
  const { pathname } = useLocation();
  const isUserDashboard =
    pathname.startsWith('/workbench/user') ||
    pathname.startsWith('/workbench/my-renters');
  const selectedConversation = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedConversation,
    shallowEqual
  );
  const certification = useSelector(
    ({ certification }) => certification?.certification,
    shallowEqual
  );
  const { user_certification } = useSelector(
    ({ userDashboard: { communication } }) => ({
      user_certification: communication.user_certification,
    }),
    shallowEqual
  );
  const selectedFilter = useSelector(
    ({ conversationsV2 }) => conversationsV2?.selectedFilter,
    shallowEqual
  );
  // const smsConversations = useSelector(({ conversationsV2 }) => conversationsV2?.smsConversations, shallowEqual);
  // const propertyId = useSelector((st) => st?.property?.property?.id, shallowEqual);

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [fullScreen, setFullScreen] = useState(false);
  const [messaTypeOptions, setMessaTypeOptions] = useState(FALLBACK_TYPES);
  const [message, setMessage] = useState(
    reject && infoToReject ? infoToReject?.message?.text : ''
  );
  const [validMessage, setValidMessage] = useState(false);
  const [contactFilter, setContactFilter] = useState([]);
  const [contactsOptions, setContactsOptions] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const [householdMembersList, setHouseholdMembersList] = useState([]);
  const [templateIds, setTemplateIds] = useState([]);

  const schemaShapes = {
    BASE: {
      household_members: Yup.array(),
      contacts: Yup.array(),
      certification: Yup.number(),
    },
    [EMAIL_TYPE]: {
      subject:
        (isReply && selectedConversation?.messages?.length === 0) || !isReply
          ? Yup.string().required('Subject is required.')
          : Yup.string().nullable(),
      household_members: Yup.array().test(
        'household_members',
        (value, data) => {
          if (
            value?.length < 1 &&
            data?.parent?.contacts?.length < 1 &&
            data?.parent?.emails < 1
          ) {
            return data.createError({
              message: 'At least 1 Household Member or Contact is required.',
              path: `household_members`,
            });
          }
          return true;
        }
      ),
    },
    [SMS_TYPE]: {
      subject: Yup.string().nullable(),
      household_members: Yup.array().test(
        'household_members',
        (value, data) => {
          if (value?.length < 1) {
            return data.createError({
              message: 'At least 1 Household Member is required.',
              path: `household_members`,
            });
          }
          return true;
        }
      ),
    },
  };
  const [schemaShape, setSchemaShape] = useState(schemaShapes['BASE']);

  const segmets = useMemo(
    () => Math.floor((message?.length - 1) / MAX_CHARACTERS_PER_SEGMENT) + 1,
    [message?.length]
  );

  const certId = useMemo(() => {
    return (
      certification_id ||
      selectedConversation?.certification?.id ||
      user_certification?.id ||
      certification?.id
    );
  }, [
    certification_id,
    selectedConversation,
    user_certification,
    certification,
  ]);

  /*
   const _getSMSConversations = useCallback(() => {
   if (!certId) return;
   const filterBy = 'certification';
   dispatch(getSMSConversations(certId, filterBy, { fields: ['from_to_pair'] }));
   }, [dispatch, certId]);
   */

  const emailSchema = Yup.object().shape(schemaShape);
  const formik = useFormik({
    initialValues: {
      subject: reject && infoToReject ? infoToReject?.subject : '',
      household_members: reject && infoToReject ? [infoToReject?.member] : [],
      contacts: [],
      certification: certId,
      files: [],
      message_type: EMAIL_TYPE,
      emails: [],
    },
    validationSchema: emailSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // if the user is on a selected conversation and is a reply use the selected conversation ID
        let conversationId = selectedConversation?.id || null;
        let conversationResponse = null;
        const destinations = [
          ...(values?.household_members || []),
          ...(values?.contacts || []),
        ];

        if (
          isSMS &&
          !values?.household_members[0]?.mobile_phone?.is_reachable
        ) {
          enqueueSnackbar(
            'The mobile phone for that household member is not reachable.',
            { variant: 'error' }
          );
          return;
        }

        const conversationPayload = {
          subject:
            values.message_type === EMAIL_TYPE
              ? values.subject
              : `${destinations[0]?.name} SMS Conversation`,
          mobile_phone: isSMS ? destinations[0]?.mobile_phone?.id : undefined,
          household_members_users: uniq(
            values.household_members.map((m) => m?.user_id)
          ),
          members_emails: values.emails,
          contacts: values.contacts.map((m) => m?.id),
          certification: values.certification,
          compliance_users: [user.id],
          conversation_type: values.message_type,
          property: selectedConversation?.certification?.property?.id,
        };

        // check if the selected household member has an existing conversation with the SMS type
        // to use that conversation ID
        if (!conversationId && isSMS && values?.household_members[0]?.hhmId) {
          const { data } = await axios.get(
            `communication/new_conversation/conversation/?conversation_type=2&household_members=${values?.household_members[0]?.hhmId}&fields=id,mobile_phone.is_reachable&expand=mobile_phone`
          );
          conversationResponse = data;

          if (data?.length >= 1) {
            conversationId = data[0]?.id;
          }
        }

        // if at this point the conversation ID has not been defined it means that is not
        // a reply and is not an existing SMS conversation
        if (!conversationId) {
          const { data } = await axios.post(
            'communication/new_conversation/conversation/',
            conversationPayload
          );
          conversationResponse = data;
          conversationId = data?.id;
        }
        // if the conversation was creatd correctly or the user is on a selected conversation
        if (conversationId) {
          if (
            isEmail &&
            selectedConversation?.subject === EMPTY_CONVERSATION_LABEL &&
            selectedConversation?.messages?.length === 0
          ) {
            const response = await axios.patch(
              `communication/new_conversation/conversation/${selectedConversation?.id}/`,
              {
                subject: values.subject,
              }
            );
            if (response.status === 200) {
              dispatch(
                updateConversation({
                  value: { subject: values.subject },
                  conversationId,
                })
              );
              dispatch(
                updateSelectedConversation({
                  value: { subject: values.subject },
                  conversationId,
                })
              );
            }
          }

          const messagePayload = {
            conversation: conversationId,
            content: xss(message),
            message_type: values?.message_type,
            sender_user: user.id,
            files: isSMS ? undefined : values?.files,
          };

          if (templateIds?.length) {
            messagePayload.templates = templateIds;
          }

          const newMessage = await axios.post(
            `communication/new_conversation/message/`,
            buildMessageFormData(messagePayload)
          );

          // _getSMSConversations();

          if (isReply) {
            const finalMessage = await getMessageById(newMessage?.data?.id);
            dispatch(
              sendMessageSuccess({
                conversationId: newMessage?.data?.conversation,
                message: finalMessage,
              })
            );
          }

          enqueueSnackbar('Message sent successfully.', { variant: 'success' });
          handleClose();
          resetForm();
          setMessage('');
          setSubmitting(false);
          dispatch(setShouldReload(true));
          setTemplateIds([]);
        } else {
          throw new Error(conversationResponse.toString());
        }
      } catch (error) {
        enqueueSnackbar('Something went wrong sending the message.', {
          variant: 'error',
        });
        console.error(error);
        setSubmitting(false);
      }
    },
  });

  const {
    setValues,
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
    isValid,
  } = formik;

  const isEmail = useMemo(
    () => values.message_type === EMAIL_TYPE,
    [values.message_type]
  );
  const isSMS = useMemo(
    () => values.message_type === SMS_TYPE,
    [values.message_type]
  );

  useEffect(() => {
    setSchemaShape((st) => ({
      ...st,
      ...schemaShapes[values.message_type],
    }));
  }, []); //eslint-disable-line

  // const filteredSMSConversations = smsConversations?.length > 0 ? smsConversations.filter(conversation =>
  // conversation.from_to_pair) : smsConversations;

  useEffect(() => {
    if (!certId) {
      return [];
    }

    const params = parseQueryParams({
      expand: [
        'household.household_members',
        'household.household_members.user',
        'household.household_members.user.email_addresses',
        'household.household_members.member_type',
        'household.household_members.mobile_phone',
      ],
    });

    axiosInstance
      .get(`certification/certification/${certId}/?${params}`)
      .then(({ data }) => {
        const membersList = data?.household?.household_members;
        setHouseholdMembersList(membersList);
      });
  }, [certId]);

  const memberOptions = useMemo(() => {
    if (!certId || householdMembersList?.length === 0) {
      return [];
    }
    let options = householdMembersList.filter((member) => {
      if (isEmail) {
        return member?.user?.email_addresses?.length > 0;
      }
      if (isSMS) {
        return member?.mobile_phone?.is_reachable;
      }
      return false;
    });
    if (!isSMS) {
      options = options.reduce((accumulator, member) => {
        const result = member?.user?.email_addresses
          .filter(
            (memberEmail) =>
              memberEmail.verified &&
              !memberEmail.email.endsWith('prontohousing.io')
          )
          .map((memberEmail) => ({
            ...member,
            email: memberEmail.email,
            email_address_id: memberEmail.id,
            is_primary_email: memberEmail.is_primary,
            verified: memberEmail.verified,
          }));
        return accumulator.concat(result);
      }, []);
    }
    options = options.map((el, idx) => ({
      name: `${el.first_name} ${el.last_name}`,
      user_id: el?.user?.id,
      id: idx,
      hhmId: el?.id,
      email: el?.email,
      email_address_id: el?.email_address_id,
      mobile_phone: el?.mobile_phone,
      is_primary_email: el?.is_primary_email,
      is_email_verified: el?.verified,
      isHHM: true,
      categoryLabel: MEMBERS_LABEL,
      user: {
        id: el?.user?.id,
        first_name: el.first_name,
        last_name: el.last_name,
        email: el?.email,
        email_addresses: el?.user?.email_addresses,
      },
    }));
    return options;
  }, [certId, isEmail, isSMS, householdMembersList]);

  const findOrCreateContact = async (contactEmail) => {
    try {
      if (!contactEmail) {
        return;
      }
      const schema = Yup.string().email().required();
      await schema.validate(contactEmail);
      const found = contactsOptions.find((c) => c.email === contactEmail);
      if (found === undefined) {
        const payload = {
          ...contactFilter,
          email: contactEmail,
        };
        const { data } = await axios.post(`contacts/`, payload);
        const newContact = {
          ...data,
          name: data.first_name
            ? `${data.first_name} ${data.last_name}`
            : `${data.email}`,
          categoryLabel: CONTACTS_LABEL,
        };
        const newContacts = [...values.contacts, newContact];
        setFieldValue('contacts', newContacts);
        setContactsOptions([...contactsOptions, newContact]);
        if (!selectedConversation?.id && newContacts?.length >= 1) {
          createEmptyConversation(
            values.household_members,
            newContacts,
            values?.emails
          );
        } else if (
          selectedConversation.id &&
          (values.household_members.length >= 1 || values.contacts.length >= 1)
        ) {
          updateEmptyConversationRecipients(
            values.household_members,
            [...values.contacts, newContact],
            selectedConversation?.id,
            values?.emails
          );
        }
      } else if (
        values.contacts.find((c) => c.email === found.email) === undefined
      ) {
        setFieldValue('contacts', [...values.contacts, found]);
      }
      setEmailError(null);
    } catch (error) {
      setEmailError(`The email is not valid`);
      console.error(error);
    }
  };

  const removeFile = useCallback(
    (name) => {
      setFieldValue(
        'files',
        values.files.filter((f) => f.name !== name)
      );
    },
    [setFieldValue, values]
  );

  const handleExitFullScreen = () => setFullScreen(false);
  const handleEnterFullScreen = () => setFullScreen(true);
  const handleAttach = () => fileInputRef.current.click();

  const handleClose = useCallback(() => {
    resetForm();
    setMessage('');
    onCloseCompose();
    setFullScreen(false);
    setTemplateIds([]);
  }, []); // eslint-disable-line

  const resetFormikValues = useCallback(
    (value = {}) => {
      const newValues = { ...formik.values, ...value };
      resetForm();
      setTimeout(() => {
        setValues(newValues);
      }, 100);
    },
    [formik.values, resetForm, setValues]
  );

  const resetFormData = useCallback(
    (overrides = {}) => {
      setMessage('');
      setValidMessage(false);
      resetFormikValues(overrides);
    },
    [resetFormikValues]
  );

  const resetConversationSchema = (type, overrides = {}) => {
    if ([SMS_TYPE, EMAIL_TYPE].includes(type)) {
      setSchemaShape({ ...schemaShapes['BASE'], ...schemaShapes[type] });
    }
    resetFormData(overrides);
  };

  const handleConversationTypeChange = useCallback(
    (e, value) => {
      if (!value) {
        return;
      }

      const type = +value;
      const overrides = {
        household_members: [],
        message_type: type,
        subject: '',
        files: [],
        contacts: [],
      };

      if (type === SMS_TYPE && values.message_type !== type) {
        resetConversationSchema(type, overrides);
      } else if (type === EMAIL_TYPE && values.message_type !== type) {
        resetConversationSchema(type, overrides);
      }

      setFieldValue('message_type', type);
      setTemplateIds([]);
    },
    [setFieldValue, values.message_type] //eslint-disable-line
  );

  const handleTemplateSelection = useCallback(
    (value, templateId) => {
      setTemplateIds((t) => {
        return [...t, templateId];
      });

      const newMessage = `${message}${value || ''}`;
      setMessage(newMessage);
      if (
        isSMS &&
        !validMessage &&
        newMessage?.length > 0 &&
        newMessage?.length <= 1600
      ) {
        setValidMessage(true);
      }
    },
    [isSMS, message, validMessage]
  );

  const getChipLabel = (option) => {
    if (!option) {
      return null;
    }
    if (isSMS) {
      return `${option.name} - ${formatPhone(
        option.mobile_phone.phone_number
      )}`;
    } else {
      return option.name !== option.email
        ? `${option.name} <${option.email}>`
        : option.email;
    }
  };

  // handle reply action
  useEffect(() => {
    if (isOpenCompose && selectedConversation && isReply) {
      const household_members = (selectedConversation?.from || [])
        .filter((hhmu) => !!hhmu.isHHM)
        .map((hhmu) => ({
          id: hhmu?.id,
          name: `${hhmu?.displayName}`,
          email: `${hhmu?.email}`,
          hhmId: hhmu?.id,
          mobile_phone: hhmu?.mobile_phone,
        }));
      const contacts = (selectedConversation?.from || [])
        .filter((contact) => !contact.isHHM)
        .map((contact) => ({
          id: contact?.id,
          name: `${contact?.displayName}`,
          email: `${contact?.email}`,
          mobile_phone: contact?.mobile_phone,
        }));
      resetConversationSchema(selectedConversation?.conversation_type, {
        household_members,
        contacts,
        message_type: selectedConversation?.conversation_type,
      });
    }

    if (!selectedConversation?.id && isReply) {
      handleClose();
      setIsReply(false);
    }
  }, [
    selectedConversation?.id,
    selectedConversation?.conversationType,
    selectedConversation?.from,
    isOpenCompose,
    isReply,
    setIsReply,
  ]); //eslint-disable-line

  // fetch contacts when the component is mounted
  useEffect(() => {
    if (!isOpenCompose) {
      return;
    }

    const fetchContacts = async () => {
      if (!certId) {
        setContactsOptions([]);
        return;
      }

      try {
        const certParams = parseQueryParams({
          expand: ['household', 'property', 'property.portfolio'],
          fields: [
            'household.id',
            'property.id',
            'property.portfolio',
            'property.portfolio.id',
            'property.portfolio.property_owner_company',
          ],
        });

        const { data } = await axiosInstance.get(
          `certification/certification/${certId}/?${certParams}`
        );

        const filter = {
          property: data?.property?.id,
          portfolio: data?.property?.portfolio?.id,
          company: data?.property?.portfolio?.property_owner_company,
          household: data?.household?.id,
        };
        setContactFilter(filter);
        const params = parseQueryParams(filter);
        const { data: contacts } = await axios.get(`contacts/?${params}`);
        setContactsOptions(
          contacts
            .filter(
              (c) => Boolean(c.email) && !c.email.endsWith('prontohousing.io')
            )
            .map((c) => ({
              ...c,
              name: c.first_name
                ? `${c.first_name} ${c.last_name}`
                : `${c.email}`,
              categoryLabel: CONTACTS_LABEL,
            }))
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchContacts();
  }, [certId, isOpenCompose]);

  useEffect(() => {
    const fetchMessageTypes = async () => {
      try {
        const { data } = await axios.options(
          'communication/new_conversation/conversation/'
        );
        if (data?.actions?.POST?.conversation_type?.choices?.length) {
          setMessaTypeOptions(data.actions.POST.conversation_type.choices);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMessageTypes();
  }, [selectedConversation?.certification?.property]);

  useEffect(() => {
    if (isOpenCompose && selectedFilter.id === FILTER_IDS.sms && !isReply) {
      handleConversationTypeChange(null, SMS_TYPE);
    }
  }, [isOpenCompose, isReply, selectedFilter.id]); // eslint-disable-line

  if (!isOpenCompose) {
    return null;
  }

  const handleHouseholdMembersChange = async (value) => {
    const members = value.filter((v) => Boolean(v.isHHM));
    const selectedContacts = value.filter((v) => !Boolean(v.isHHM));
    const conversationId = selectedConversation?.id || null;

    const payloadEmails = value
      .map((e) => e?.email_address_id)
      .filter((el) => el !== undefined);
    await setFieldValue('household_members', members);
    await setFieldValue('contacts', selectedContacts);
    await setFieldValue('emails', payloadEmails);
    setEmailError(null);
    if (
      !conversationId &&
      (members.length >= 1 || selectedContacts.length >= 1)
    ) {
      createEmptyConversation(members, selectedContacts, payloadEmails);
    } else if (
      conversationId &&
      (members.length >= 1 || selectedContacts.length >= 1)
    ) {
      updateEmptyConversationRecipients(
        members,
        selectedContacts,
        conversationId,
        payloadEmails
      );
    }
  };

  const createEmptyConversation = async (members, contacts, emails) => {
    const destinations = [...(members || []), ...(contacts || [])];
    const conversationPayload = {
      subject:
        values.message_type === EMAIL_TYPE
          ? EMPTY_CONVERSATION_LABEL
          : `${destinations?.[0]?.name} SMS Conversation`,
      household_members_users: uniq(members.map((member) => member?.user_id)),
      contacts: contacts.map((contact) => contact?.id),
      members_emails: emails,
      property: selectedConversation?.certification?.property?.id,
      certification: selectedConversation?.id
        ? selectedConversation?.certification?.id
        : isUserDashboard || isProperty
        ? user_certification?.id
        : certification?.id,
      compliance_users: [user.id],
      conversation_type: values.message_type,
    };
    const selectedPhoneNumber = destinations[0]?.mobile_phone?.phone_number;
    let existingSmsConversationId = null;

    if (isSMS && selectedPhoneNumber && certification?.property) {
      const res = await axios.get(
        `communication/new_conversation/conversation/?fields=from_to_pair,id&conversation_type=${SMS_TYPE}&phone_number=${selectedPhoneNumber}&property=${certification.property}`
      );
      if (res?.data?.length) {
        existingSmsConversationId = res?.data[0]?.id;
      }
    }

    let createdConversationId = null;
    if (!isSMS || (isSMS && !existingSmsConversationId)) {
      const response = await axios.post(
        'communication/new_conversation/conversation/',
        conversationPayload
      );
      createdConversationId = response?.data?.id;
    }

    const finalId = createdConversationId || existingSmsConversationId;

    if (finalId) {
      dispatch(
        getConversation(finalId, {
          expand: [
            'certification',
            'certification.property',
            'certification.unit',
            'mobile_phone',
            'hhm_mobile_phone',
            'hhm_emails.household_member',
            'contact_emails.contact',
            'household_members_users.household_member.mobile_phone',
            'last_message.attachments',
            'last_message.message_type',
            'contacts',
            'contacts.mobile_phone',
          ],
          fields: [
            'from_to_pair',
            'certification.property',
            'conversation_type',
            'mobile_phone.id',
            'mobile_phone.phone_number',
            'mobile_phone.is_reachable',
            'mobile_phone.is_valid',
            'hhm_mobile_phone.id',
            'hhm_mobile_phone.phone_number',
            'hhm_mobile_phone.is_reachable',
            'hhm_mobile_phone.is_valid',
            'id',
            'certification.id',
            'certification.is_done',
            'certification.unit.name',
            'certification.log_number',
            'certification.display_name',
            'add_to_certification_file',
            'last_message_date',
            'created',
            'has_new_message',
            'subject',
            'household_members_users.id',
            'household_members_users.email',
            'household_members_users.first_name',
            'household_members_users.last_name',
            'household_members_users.household_member.id',
            'household_members_users.household_member.mobile_phone',
            'last_message',
            'contacts.id',
            'contacts.first_name',
            'contacts.last_name',
            'contacts.email',
            'contacts.mobile_phone.id',
            'contacts.mobile_phone.phone_number',
            'contacts.mobile_phone.is_reachable',
            'contacts.mobile_phone.is_valid',
            'hhm_emails',
            'contact_emails',
          ],
        })
      );
      // _getSMSConversations();
    }
  };

  const updateEmptyConversationRecipients = async (
    members,
    contacts,
    conversationId,
    emails
  ) => {
    axios
      .patch(`communication/new_conversation/conversation/${conversationId}/`, {
        household_members_users: uniq(members.map((member) => member?.user_id)),
        contacts: contacts.map((contact) => contact?.id),
        members_emails: emails,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            updateConversation({
              value: {
                household_members_users: members.map((member) => member.user),
                contacts: contacts,
              },
              conversationId,
            })
          );
        }
      });
  };

  return (
    <Portal>
      <Backdrop open={fullScreen || isMobile} sx={{ zIndex: 1998 }} />
      <RootStyle
        sx={{
          ...((fullScreen || isMobile) && {
            top: 0,
            right: { xs: 12, md: 40 },
            zIndex: 1999,
            margin: 'auto',
            maxHeight: '95vh',
            width: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
            height: { xs: `calc(100% - 24px)`, md: `calc(100% - 80px)` },
          }),
        }}
      >
        <Box
          sx={{
            pl: 3,
            pr: 1,
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: 'calc(100% - 88px)' }}>
            <Typography variant="h6">
              {isReply ? 'Reply message' : 'New Message'}
            </Typography>

            {isReply && isEmail && (
              <Typography
                variant="caption"
                display="block"
                noWrap
                sx={{ width: '100%' }}
              >
                <strong>Subject:</strong> {selectedConversation?.subject}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <ToggleButtonGroup
            color="primary"
            exclusive
            value={values.message_type}
            sx={{ mr: 2 }}
            onChange={handleConversationTypeChange}
          >
            {messaTypeOptions
              .filter((opt) =>
                !isReply ? true : opt.value === values.message_type
              )
              .map(
                (opt) =>
                  opt.value !== FAX_TYPE && (
                    <ToggleButton
                      sx={{ color: '#919EAB !important' }}
                      disabled={isReply}
                      key={opt.value}
                      size="small"
                      value={opt.value}
                    >
                      {opt.display_name}
                    </ToggleButton>
                  )
              )}
          </ToggleButtonGroup>

          {!isMobile && (
            <IconButton
              onClick={
                fullScreen ? handleExitFullScreen : handleEnterFullScreen
              }
              size="large"
            >
              <Icon
                icon={fullScreen ? collapseFill : expandFill}
                width={20}
                height={20}
              />
            </IconButton>
          )}

          <IconButton onClick={handleClose} size="large">
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Box>

        <Divider />
        <Autocomplete
          onBlur={(e) => findOrCreateContact(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              findOrCreateContact(e.target.value);
            }
          }}
          fullWidth
          filterSelectedOptions
          multiple
          disablePortal
          disabled={isReply}
          isOptionEqualToValue={(opt, val) => {
            return +val?.id === +opt?.id;
          }}
          options={
            isSMS ? memberOptions : [...memberOptions, ...contactsOptions]
          }
          getOptionLabel={(opt) => {
            return opt?.name || '';
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={
                !isSMS
                  ? {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start !important',
                    }
                  : {}
              }
              {...props}
            >
              {option.name}
              {isSMS ? (
                <Label
                  sx={{ ml: 1 }}
                  variant="ghost"
                  color={option.mobile_phone?.verified ? 'primary' : 'warning'}
                >
                  {option.mobile_phone?.verified ? 'Verified' : 'Not verified'}
                </Label>
              ) : (
                option.name !== option.email && (
                  <Box
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      display: 'flex',
                      alignItems: 'center',
                      ml: 1,
                    }}
                  >
                    {`<${option.email}>`}
                    {option.is_email_verified && (
                      <Tooltip title={'This email address has been verified.'}>
                        <VerifiedIcon
                          color={'primary'}
                          sx={{ width: '17px', height: '17px', ml: 1 }}
                        />
                      </Tooltip>
                    )}
                    {!option.is_email_verified &&
                      option?.is_email_verified !== undefined && (
                        <Typography
                          variant="caption"
                          sx={{ fontStyle: 'italic' }}
                        >
                          &nbsp;(Unverified)
                        </Typography>
                      )}
                  </Box>
                )
              )}
            </Box>
          )}
          value={[
            ...(values?.household_members || []),
            ...(values?.contacts || []),
          ]}
          getOptionDisabled={(option) => {
            if (isSMS && values?.household_members?.length === 1) {
              return true;
            }
            if (isSMS) {
              return !option.mobile_phone?.verified;
            }
            return (
              !option?.is_email_verified &&
              option?.is_email_verified !== undefined
            );
          }}
          groupBy={(option) => option.categoryLabel}
          sx={{
            '& .MuiAutocomplete-inputRoot': { pl: 3, py: 1 },
            '& .MuiAutocomplete-input': { pl: 3, py: 1 },
            '& .MuiAutocomplete-inputRoot::before': { borderColor: 'grey.300' },
            '& .MuiAutocomplete-endAdornment': { right: 16 },
          }}
          onChange={(event, value) => {
            handleHouseholdMembersChange(value);
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                size="small"
                label={getChipLabel(option)}
                disabled={isReply}
                sx={{
                  '&.MuiChip-root.Mui-disabled': {
                    opacity: 1,
                  },
                }}
                {...getTagProps({ index })}
                {...(isReply ? { onDelete: null } : {})}
              />
            ))
          }
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                size="small"
                variant="standard"
                placeholder={
                  isReply
                    ? ''
                    : values?.contacts?.length +
                        values?.household_members?.length ===
                      0
                    ? 'To'
                    : ''
                }
              />
              {Boolean(
                touched.household_members && errors.household_members
              ) && (
                <FormHelperText sx={{ pl: 3 }} error>
                  {errors.household_members}
                </FormHelperText>
              )}
              {isEmail && emailError && (
                <FormHelperText sx={{ pl: 3 }} error>
                  {emailError}
                </FormHelperText>
              )}
            </>
          )}
        />

        {/* enable subject field for email type */}
        {((isEmail && !isReply) ||
          (isEmail &&
            isReply &&
            selectedConversation?.messages?.length === 0)) && (
          <>
            <InputStyle
              disableUnderline
              placeholder="Subject"
              {...getFieldProps('subject')}
            />
            {Boolean(touched.subject && errors.subject) && (
              <FormHelperText sx={{ pl: 3 }} error>
                {errors.subject}
              </FormHelperText>
            )}
          </>
        )}

        {isEmail && (
          <QuillEditor
            simple
            id="compose-mail"
            value={message}
            onChange={(value, delta, source, editor) => {
              setMessage(value.replace(/<img .*?>/g, '') || '');
              setValidMessage(editor?.getText()?.trim()?.length > 0);
              if (!value) {
                setTemplateIds([]);
              }
            }}
            placeholder="Type a message"
            handleAttach={handleAttach}
            sx={{
              borderColor: 'transparent',
              height: 'calc(-100%)',
              flexGrow: 1,
              '& .quill ': {
                height: 'calc(100% - 45px)',
              },
              '& .ql-editor': {
                maxHeight: '100%',
                minHeight: '100%',
              },
              '& .ql-snow .ql-editor pre.ql-syntax': {
                backgroundColor: 'transparent',
                color: 'inherit',
              },
            }}
          />
        )}

        {!memberOptions?.length && !isReply && isSMS && (
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'center', py: 4, mb: 'auto' }}
          >
            This household does not have a member with a reachable mobile phone.
          </Typography>
        )}

        {((isSMS && memberOptions?.length > 0) || (isSMS && isReply)) && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflowY: 'auto',
              }}
            >
              <TextField
                fullWidth
                multiline
                value={message}
                minRows={5}
                onChange={(e) => {
                  setMessage(e?.target?.value || '');
                  setValidMessage(
                    e?.target?.value?.length > 0 &&
                      e?.target?.value?.length <= MAX_CHARACTERS_PER_MESSAGE
                  );
                }}
                placeholder="Type a message"
                inputProps={{ maxLength: MAX_CHARACTERS_PER_MESSAGE }}
                sx={{
                  mb: 'auto',
                  '& fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            </Box>

            <FormHelperText
              sx={{ pr: 3, fontSize: 16, pb: 1, display: 'flex' }}
            >
              {message?.length > MAX_CHARACTERS_PER_MESSAGE && (
                <Box
                  component="span"
                  sx={{
                    color: 'error.main',
                    pl: 3,
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                >
                  The message is too big.
                </Box>
              )}

              <Box component="span" sx={{ ml: 'auto' }}>
                {message?.length} characters / {segmets} segment
                {segmets === 0 || segmets > 1 ? 's' : ''}
              </Box>
            </FormHelperText>
          </>
        )}

        {values?.files?.length > 0 && (
          <MailDetailsAttachments
            files={values.files}
            showDelete
            removeFile={removeFile}
          />
        )}

        <input
          multiple
          hidden
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={(e) => {
            const newValues = Object.values(e.target.files).filter(
              (nf) => !values.files.some((vf) => vf.name === nf.name)
            );
            const finalValue = [...values.files, ...newValues];

            if (newValues.length > 0) {
              setFieldValue('files', finalValue);
              fileInputRef.current.value = null;
            }
          }}
        />

        {Boolean(touched.message && errors.message) && (
          <FormHelperText sx={{ pl: 3 }} error>
            {errors.message}
          </FormHelperText>
        )}

        <Divider />

        <Box sx={{ py: 2, px: 3, display: 'flex', alignItems: 'center' }}>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
            disabled={!validMessage || !isValid}
            sx={{ mr: 2 }}
          >
            Send
          </LoadingButton>

          {selectedConversation?.id && !!values?.household_members?.length && (
            <MessageTemplates
              messageType={values.message_type}
              conversation={selectedConversation}
              conversationId={selectedConversation?.id}
              onselectTemplate={handleTemplateSelection}
            />
          )}
        </Box>
      </RootStyle>
    </Portal>
  );
};

export default memo(MailCompose);

MailCompose.propTypes = {
  isOpenCompose: PropTypes.bool,
  onCloseCompose: PropTypes.func,
};
