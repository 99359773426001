import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import VerifyCode from 'src/pages/authentication/VerifyCode';
import ResetPassword from 'src/pages/authentication/ResetPassword';
import LoginFormV2, {
  phoneRegex,
  emailRegex,
  LOGIN_FORM_STEP,
} from './LoginFormV2';
import LoginLinkSent from './LoginLinkSent';
import TermsOfServiceDialogV2 from './TermsOfServiceDialogV2';
import axios from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required('This field is required')
    .test(
      'is-email-or-phone',
      'Please enter a valid phone number or email address.',
      (value) => {
        if (!value) return false;
        return emailRegex.test(value) || phoneRegex.test(value);
      }
    ),
  password: Yup.string().required('Password is required.'),
});

interface LoginV2Props {
  extendSession?: boolean;
  onExtendSessionSuccess?: ({ refresh }) => void;
}

interface SessionDetails {
  user: number;
  access: string;
  refresh: string;
}

const LoginStateManager: FC<LoginV2Props> = ({
  extendSession = false,
  onExtendSessionSuccess,
}) => {
  const { login, isAuthenticated, extendCurrentSession } = useAuth();
  const termsOfServiceRef = useRef(null);
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState<null | string>(null);
  const [sessionDetails, setSessionDetails] = useState<SessionDetails>(null);

  const [loginFormStep, setLoginFormStep] = useState<LOGIN_FORM_STEP>(
    LOGIN_FORM_STEP.LOGIN
  );

  const formik = useFormik({
    initialValues: { username: '', password: '' },
    validationSchema,
    onSubmit: () => {
      setLoginError(null);
      void onLogin();
    },
  });

  const { values, resetForm, setSubmitting } = formik;
  const { username, password } = values;

  // TODO: Remove this further down the line
  //  this is just to cleanup exposed data
  useEffect(() => {
    localStorage.removeItem('temporalSession');
    localStorage.removeItem('email');
  }, []);

  const onBack = (): void => {
    setLoginFormStep(LOGIN_FORM_STEP.LOGIN);
  };

  const onTermsOfServiceAccept = async (): Promise<void> => {
    await axios.patch(
      `user_profile/user/${sessionDetails?.user}/`,
      { terms_of_service_agreement: true },
      { headers: { Authorization: `Bearer ${sessionDetails.access}` } }
    );

    await login(sessionDetails.access, sessionDetails.refresh);
    if (!isAuthenticated) {
      navigate('/');
    }
  };

  const onLogin = async (): Promise<void> => {
    try {
      const response = await axios.post('token/both/?validate', {
        email: username,
        password: password,
      });
      const { access, refresh } = response.data;

      if (isAuthenticated) {
        await extendCurrentSession(response.data);
        onExtendSessionSuccess?.(response.data);
        setSubmitting(false);
        setLoginError(null);
        resetForm();
        return;
      }

      const { data: user } = await axios.get(
        'user_profile/user/current_profile/',
        { headers: { Authorization: `Bearer ${access}` } }
      );

      if (!user.terms_of_service_agreement) {
        setSessionDetails({
          user: user.id,
          access: access,
          refresh: refresh,
        });
        termsOfServiceRef.current?.toggleOpenDialog();
        return;
      }

      await login(access, refresh);
      navigate('/');

      setSubmitting(false);
      resetForm();
    } catch (error) {
      setLoginError('Invalid credentials. Please verify them and try again.');
      setSubmitting(false);
    }
  };

  const getMainContent = useCallback((): React.ReactNode => {
    switch (loginFormStep) {
      case LOGIN_FORM_STEP.LOGIN:
        return (
          <LoginFormV2
            formik={formik}
            formStep={loginFormStep}
            setLoginFormStep={setLoginFormStep}
            extendSession={extendSession}
            loginError={loginError}
          />
        );
      case LOGIN_FORM_STEP.VERIFY_CODE:
        return (
          <VerifyCode
            phone={username}
            onBack={onBack}
            onExtendSessionSuccess={onExtendSessionSuccess}
          />
        );
      case LOGIN_FORM_STEP.LOGIN_LINK_SENT:
        return <LoginLinkSent onBack={onBack} />;
      case LOGIN_FORM_STEP.RESET_PASSWORD:
        return (
          <ResetPassword
            userEmail={username}
            onBack={() => setLoginFormStep(LOGIN_FORM_STEP.LOGIN)}
            extendSession={extendSession}
            showLogo={!extendSession}
            showCopyright={false}
          />
        );
    }
  }, [
    extendSession,
    formik,
    loginError,
    loginFormStep,
    onExtendSessionSuccess,
    username,
  ]);

  return (
    <>
      {getMainContent()}
      <TermsOfServiceDialogV2
        onAccept={onTermsOfServiceAccept}
        ref={termsOfServiceRef}
      />
    </>
  );
};

export default LoginStateManager;
