import React from 'react';
import { Box, Skeleton } from '@mui/material';

const ConversationItemSkeleton = () => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        p: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Skeleton sx={{ mr: 2 }} variant="circular" height={32} width={32} />
      <Skeleton sx={{ mr: 10 }} variant="text" height={22} width="15%" />
      <Skeleton variant="text" height={16} width="40%" />
      <Skeleton sx={{ ml: 'auto' }} variant="text" height={22} width={120} />
    </Box>
  );
};

export default ConversationItemSkeleton;
