import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import archiveFill from '@iconify/icons-eva/archive-fill';
import roundMarkEmailRead from '@iconify/icons-ic/round-mark-email-read';
import roundMarkEmailUnread from '@iconify/icons-ic/round-mark-email-unread';

// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { IconButton, Tooltip } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteItemFromConversationsList,
  toggleConversationReadStatus,
} from 'src/redux/slices/conversationsV2';
import { useSnackbar } from 'notistack';
import { deleteEmptyConversations } from './utils';
import TrashBin from '../../icons/TrashBin';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0, 0, 1),
}));

// ----------------------------------------------------------------------

const MailItemAction = ({ conversation, deleteConversation, ...other }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isEmpty = !conversation?.last_message && Boolean(conversation?.id);

  const showSnackbar = useCallback(
    (msg, variant = 'success') => {
      closeSnackbar();
      enqueueSnackbar(msg, { variant });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  const cleanEmptyConversation = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    await deleteEmptyConversations([conversation]);
    dispatch(deleteItemFromConversationsList(conversation));
  };

  const actions = useMemo(
    () => [
      {
        name: `Mark ${
          conversation?.conversation_type === 1
            ? 'Email'
            : conversation?.conversation_type === 2
            ? 'Text'
            : 'Fax'
        } ${conversation?.isUnread ? 'Read' : 'Unread'}`,
        icon: conversation?.isUnread
          ? roundMarkEmailUnread
          : roundMarkEmailRead,
        action: (event) => {
          event.preventDefault();
          event.stopPropagation();
          dispatch(
            toggleConversationReadStatus({
              conversationId: conversation?.id,
              readedValue: !conversation?.isUnread,
              errFunc: () => showSnackbar('Something went wrong.', 'error'),
            })
          );
        },
      },
    ],
    [
      conversation?.id,
      conversation?.isUnread,
      conversation?.conversation_type,
      dispatch,
      showSnackbar,
    ]
  );

  return (
    <RootStyle {...other}>
      {actions.map((action) => (
        <Tooltip key={action.name} title={action.name}>
          <IconButton
            size="small"
            onClick={action.action}
            sx={{
              mx: 0.25,
              '&:hover': {
                color: 'text.primary',
              },
            }}
          >
            <Icon icon={action.icon} width={24} height={24} />
          </IconButton>
        </Tooltip>
      ))}
      {isEmpty && (
        <Tooltip
          key={'delete item:' + conversation.id}
          title={'Delete Empty Conversation'}
        >
          <IconButton
            size="small"
            onClick={cleanEmptyConversation}
            sx={{
              mx: 0.25,
              '&:hover': {
                color: 'text.primary',
              },
            }}
          >
            <TrashBin />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
};

MailItemAction.propTypes = {
  conversation: PropTypes.object,
};

export default memo(MailItemAction);
