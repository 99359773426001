import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
// icons
import DescriptionIcon from '@mui/icons-material/Description';
// redux
// utils
import { getFileName, getFileType } from '../../utils/getFileFormat';
// components
import Scrollbar from '../Scrollbar';
import ConversationDocumentManagerDialog from '../ConversationsV2/ConversationDocumentManagerDialog';

// -----------------------------------------------------------------------

export const initialActionMode = {
  actionMode: null,
  actionFile: null,
  pagesToExtract: [],
  pagesInput: null,
};

export const initialState = {
  // initial data
  visible: false,
  conversationId: null,
  certificationId: null,
  initialFile: null,
  initialFileName: '',
  attachments: [],
  memberOptions: [],
  subject: '',
  // pdfFile data
  ready: false,
  viewerRef: null,
  fileBlob: null,
  core: null,
  annotationManager: null,
  pageCount: null,
  newPdfFileUrl: null,
  isDirty: false,
  // actionMode data
  ...initialActionMode,
};

export const sidebarStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: { xs: 'auto', md: '90vh' },
  maxWidth: { xs: '100%', md: '400px' },
  overflow: { xs: 'initial', md: 'auto' },
  py: 2,
  px: 3,
};

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `solid 1px ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.neutral,
}));

const ThumbStyle = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  color: theme.palette.text.disabled,
  '& img': { width: '100%', height: '100%' },
}));

function downloadFile(url) {
  window.open(url, '_blank');
}

// -----------------------------------------------------------------------

const ConversationDocumentManager = ({
  conversationId,
  certificationId,
  certificationConfig,
  pdfFileUrl,
  files,
  msg,
  attachments,
  members,
  subject,
}) => {
  const [dmState, setDmState] = useState(initialState);

  const updateDmState = useCallback((obj = {}) => {
    setDmState((st) => ({ ...st, ...obj }));
  }, []);

  const memberOptions = useMemo(() => {
    let options = [];
    members.forEach((member) => {
      if (
        member.member_type.name.toLowerCase() !==
        'non-household member emergency contact'
      ) {
        options.push(member);
      }
    });
    return options;
  }, [members]);

  const handleOpen = (file, msg) => {
    if (!msg?.sender_user) {
      // Message was sent by renter or non-compliance
      if (file.ext !== '.pdf' && !pdfFileUrl) {
        downloadFile(file.url);
      } else {
        updateDmState({
          visible: true,
          conversationId: conversationId,
          certificationConfig: certificationConfig,
          certificationId: certificationId,
          initialFile: pdfFileUrl || file.url,
          initialFileName: 'conversation_document',
          attachments: attachments,
          memberOptions: memberOptions,
          subject: subject,
        });
      }
    } else {
      // Message was sent by compliance user, so we shouldn't invoke the pdfFileUrl, which is built out of files that
      // renters or non-compliance send to the app
      if (file.ext === '.pdf') {
        updateDmState({
          visible: true,
          conversationId: conversationId,
          certificationConfig: certificationConfig,
          certificationId: certificationId,
          initialFile: file.url,
          initialFileName: 'conversation_document',
          attachments: attachments,
          memberOptions: memberOptions,
          subject: subject,
        });
      } else {
        downloadFile(file.url);
      }
    }
  };

  useEffect(() => {
    updateDmState({
      attachments: attachments,
    });
  }, [attachments, updateDmState]);

  return (
    <>
      <RootStyle sx={{ border: 0, py: 1, px: { xs: 3, md: 5 } }}>
        <Scrollbar>
          <Stack direction="row" spacing={5}>
            {files.map((file) => (
              <Box
                key={file.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 1,
                }}
              >
                <ThumbStyle
                  onClick={() => handleOpen(file, msg)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Box sx={{ height: 28 }}>
                    <DescriptionIcon style={{ fontSize: 28 }} />
                  </Box>
                </ThumbStyle>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: 56,
                    mt: 0.5,
                  }}
                >
                  <Typography variant="caption" noWrap>
                    {getFileName(file?.name)}
                  </Typography>
                  <Typography variant="caption">
                    .{getFileType(file?.name)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Stack>
        </Scrollbar>
      </RootStyle>

      <ConversationDocumentManagerDialog
        dmState={dmState}
        setDmState={setDmState}
        updateDmState={updateDmState}
        attachments={attachments || []}
      />
    </>
  );
};

// -----------------------------------------------------------------------

ConversationDocumentManager.propTypes = {
  conversationId: PropTypes.number,
  certificationId: PropTypes.number,
  pdfFileUrl: PropTypes.string,
  files: PropTypes.array,
  attachments: PropTypes.array,
  members: PropTypes.array,
  subject: PropTypes.string,
};

ConversationDocumentManager.defaultProps = {
  conversationId: null,
  certificationId: null,
  pdfFileUrl: null,
  files: [],
  attachments: [],
  members: [],
  subject: '',
};

export default React.memo(ConversationDocumentManager);
