import { memo, useCallback, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import { MAvatar, MHidden } from '../@material-extend';
// icons
import { Icon } from '@iconify/react';
import linkFill from '@iconify/icons-eva/link-fill';
// utils
import { fDateTimeSuffix } from '../../utils/formatTime';
import createAvatar from '../../utils/createAvatar';
// redux
import { setSelectedConversation } from 'src/redux/slices/conversationsV2';
// components
import MailItemAction from './MailItemAction';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 2),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    zIndex: 999,
    position: 'relative',
    boxShadow: theme.customShadows.z24,
  },
}));

const WrapStyle = styled(Link)(({ theme }) => ({
  minWidth: 0,
  display: 'flex',
  paddingTop: theme.spacing(2),
  // padding: theme.spacing(2, 0),
  transition: theme.transitions.create('padding'),
}));

// ----------------------------------------------------------------------

const MailItem = ({
  mail: conversation,
  isDense,
  isSelected,
  onSelect,
  onDeselect,
  created,
  createdAt,
  propertyName,
  isUserDashboard,
  ...other
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let from =
    conversation?.conversation_type === 2 &&
    !conversation?.last_message?.sender_user
      ? conversation?.hhm_mobile_phone?.household_member
      : conversation?.last_message?.sender_contact ||
        conversation?.last_message?.sender_user;

  const isAttached = useMemo(
    () => conversation?.last_message?.attachments?.length > 0,
    [conversation?.last_message?.attachments?.length]
  );
  const selectConversation = useCallback(() => {
    dispatch(
      setSelectedConversation({
        conversation,
        // the unread field is ignored here until the action setSelectedConversation is trigered
        // on the MailDetails COMPONENT
        conversationHadNewMessages: false,
      })
    );
  }, [conversation, dispatch]);

  return (
    <RootStyle
      sx={{
        ...(conversation.isUnread && {
          color: 'text.primary',
          backgroundColor: 'background.paper',
        }),
        ...(isSelected && { bgcolor: 'action.selected' }),
      }}
      {...other}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          pb: isUserDashboard && propertyName ? 1 : 2,
        }}
      >
        <WrapStyle
          color="inherit"
          underline="none"
          component={RouterLink}
          to={`communications/${conversation?.id}${search}`}
          onClick={selectConversation}
          sx={{
            display: 'flex',
            // if more actions are added  the SM width needs to bee updated
            width: '100%',
            flex: 1,
            ...(isDense && { py: 1 }),
          }}
        >
          <MAvatar
            alt={`${from?.first_name} ${from?.last_name}`}
            src={null}
            color={
              createAvatar(
                from?.first_name || conversation?.last_message?.from_email
              ).color
            }
            sx={{ width: 32, height: 32 }}
          >
            {
              createAvatar(
                from?.first_name || conversation?.last_message?.from_email
              ).name
            }
          </MAvatar>

          <Box
            sx={{
              ml: 2,
              minWidth: 0,
              width: '100%',
              alignItems: 'center',
              display: { md: 'flex' },
            }}
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                pr: 2,
                minWidth: 200,
                ...(conversation.isUnread && { fontWeight: 'fontWeightBold' }),
              }}
            >
              {`${from?.first_name || ''} ${from?.last_name || ''}`.trim() ||
                conversation?.last_message?.from_email ||
                'Empty Communication'}
            </Typography>

            <Typography noWrap variant="body2" sx={{ pr: 2 }}>
              <Box
                component="span"
                sx={{
                  ...(conversation.isUnread && {
                    fontWeight: 'fontWeightBold',
                  }),
                }}
              >
                {conversation.subject}
              </Box>
              &nbsp;-&nbsp;
              <Box
                component="span"
                sx={{
                  ...(conversation.isUnread && { color: 'text.secondary' }),
                }}
              >
                {conversation.message}
              </Box>
            </Typography>

            <MHidden width="mdDown">
              {isAttached && (
                <Box
                  component={Icon}
                  icon={linkFill}
                  sx={{
                    mx: 2,
                    width: 20,
                    height: 20,
                    flexShrink: 0,
                  }}
                />
              )}
            </MHidden>

            <Typography
              variant="caption"
              sx={{
                flexShrink: 0,
                minWidth: 120,
                ml: 'auto',
                textAlign: 'right',
                ...(conversation.isUnread && { fontWeight: 'fontWeightBold' }),
              }}
            >
              {fDateTimeSuffix(conversation?.createdAt || created)}
            </Typography>
          </Box>
          <MailItemAction conversation={conversation} />
        </WrapStyle>
        {propertyName && isUserDashboard && (
          <Typography
            variant="caption"
            sx={{
              flexShrink: 0,
              minWidth: 120,
              ml: 'auto',
              textAlign: 'right',
              display: 'flex',
              flexDirection: 'column',
              ...(conversation.isUnread && { fontWeight: 'fontWeightBold' }),
            }}
          >
            {propertyName && isUserDashboard ? propertyName : ''}
          </Typography>
        )}
      </Box>
    </RootStyle>
  );
};

export default memo(MailItem);

MailItem.propTypes = {
  mail: PropTypes.object.isRequired,
  isDense: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func,
  onSelect: PropTypes.func,
};
