import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isDesktop: true,
  error: false,
  household: {},
  certificationConfig: {},
  missingFiles: null,
  missingResponses: null,
  rejectedAnswers: [],
  requestedSignatures: [],
  completedSignatures: [],
  householdForms: [],
  actualForm: {},
  groupsVisibility: {},
  membersShowAnswers: {},
  uploadingFile: null,
  selectedRecordData: null,
  isFetchingRecordData: false,
  currentRecordData: null,
  QSFilters: [],
  folderMissingAnswers: [],
  foldersToRefresh: [],
  filterHash: '',
  answerGroups: {},
  updateAllUsers: false,
  shouldReload: false,
  isCompliance: false,
  loadingCalculation: false,
  esign: {
    createData: null,
    sitMappings: [],
    isSaving: false,
  },
  selectedRecordToUpdate: null,

  refreshQsTable: false,
  refreshRejectTable: false,
};

const slice = createSlice({
  name: 'Questionnaire Summary',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setShouldReload(state, action) {
      state.shouldReload = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setEsignOptions(state, action) {
      state.esign = { ...state.esign, ...action.payload };
    },

    resetEsignOptions(state) {
      state.esign = {
        createData: null,
        sitMappings: [],
      };
    },

    getHouseholdSuccess(state, action) {
      state.household = action.payload;
      state.isLoading = false;
    },

    getCertificationConfigSuccess(state, action) {
      state.certificationConfig = action.payload;
    },

    getMissingFilesSuccess(state, action) {
      state.missingFiles = action.payload;
      state.isLoading = false;
    },

    setFolderMissingAnswers(state, action) {
      const _array = [...state.folderMissingAnswers, action.payload];
      state.folderMissingAnswers = [...new Set(_array)];
    },

    setFoldersToRefresh(state, action) {
      state.foldersToRefresh = action.payload;
    },

    clearMissingFilesSuccess(state, action) {
      state.missingFiles = [];
      state.isLoading = false;
    },

    getMissingResponsesSuccess(state, action) {
      state.missingResponses = action.payload;
      state.isLoading = false;
    },

    getRequestedSignaturesSuccess(state, action) {
      state.requestedSignatures = action.payload;
      state.isLoading = false;
    },

    getCompletedSignaturesSuccess(state, action) {
      state.completedSignatures = action.payload;
      state.isLoading = false;
    },

    getHouseholdFormsSuccess(state, action) {
      state.householdForms = action.payload;
      state.isLoading = false;
    },

    getActualFormSuccess(state, action) {
      state.actualForm = action.payload;
      state.isLoading = false;
    },

    resetActualForm(state) {
      state.actualForm = {};
    },

    clearMissingResponsesSuccess(state, action) {
      state.missingResponses = [];
      state.isLoading = false;
    },

    getRejectedAnswersSuccess(state, action) {
      state.rejectedAnswers = action.payload;
      state.isLoading = false;
    },

    clearRejectedAnswersSuccess(state, action) {
      state.rejectedAnswers = [];
      state.isLoading = false;
    },

    setIsCompliance(state, action) {
      state.isCompliance = action.payload;
    },

    setLoadingCalculation(state, action) {
      state.loadingCalculation = action.payload;
    },

    storeVisibleGroupsIdSuccess(state, action) {
      state.groupsVisibility = { ...state.groupsVisibility, ...action.payload };
    },

    setUpdateAllUsers(state, action) {
      state.updateAllUsers = action.payload;
    },

    setSelectedRecordData(state, action) {
      state.selectedRecordData = action.payload;
      state.isLoading = false;
    },

    setCurrentRecordData(state, action) {
      state.currentRecordData = action.payload;
    },

    setQSFilters(state, action) {
      state.QSFilters = action.payload;
      state.filterHash = action.payload.join(',');
    },

    setMembersShowAnswers(state, action) {
      state.membersShowAnswers = action.payload;
    },

    setIsDesktop(state, action) {
      state.isDesktop = action.payload;
    },

    setSelectedRecordToUpdate(state, action) {
      state.selectedRecordToUpdate = action.payload;
    },

    resetQuestionnaireData(state, action) {
      state.isLoading = false;
      state.household = {};
      state.missingFiles = null;
      state.missingResponses = null;
      state.rejectedAnswers = [];
      state.questionSurveyRecords = {};
    },

    updateSelectedRecordStatusSuccess(state, action) {
      state.selectedRecordData = action.payload;
    },

    setUploadingFile(state, action) {
      state.uploadingFile = action.payload;
    },

    setIsFetchingRecordData(state, action) {
      state.isFetchingRecordData = action?.payload || false;
    },

    setAnswerGroups(state, action) {
      state.answerGroups = { ...state.answerGroups, ...action.payload };
    },

    setChangeAnswerGroups(state, action) {
      state.answerGroups = { ...action.payload };
    },

    setRefreshQsTable(state, action) {
      state.refreshQsTable = action.payload;
    },

    setRefreshRejectTable(state, action) {
      state.refreshRejectTable = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  resetQuestionnaireData,
  setEsignOptions,
  resetEsignOptions,
  setUpdateAllUsers,
  setSelectedRecordData,
  setQSFilters,
  setMembersShowAnswers,
  setIsDesktop,
  setShouldReload,
  setCurrentRecordData,
  setSelectedRecordToUpdate,
  setUploadingFile,
  resetActualForm,
  setIsFetchingRecordData,
  setAnswerGroups,
  setChangeAnswerGroups,
  setRefreshQsTable,
  setRefreshRejectTable,
  setFolderMissingAnswers,
  setFoldersToRefresh,
  setIsCompliance,
  setLoadingCalculation,
} = slice.actions;

// ----------------------------------------------------------------------

export function getHousehold(householdId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `household/all_household/${householdId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getHouseholdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCertificationConfig(certificationConfigId, queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `certification/certification_config/${certificationConfigId}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getCertificationConfigSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMissingFiles(surveysId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/missing_files/?surveys_id=${surveysId.join(',')}`
      );
      dispatch(slice.actions.getMissingFilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearMissingFiles() {
  return async (dispatch) => {
    dispatch(slice.actions.clearMissingFilesSuccess());
  };
}

export function getMissingResponses(surveysId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/missing_responses/?surveys_id=${surveysId.join(',')}`
      );
      dispatch(slice.actions.getMissingResponsesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearMissingResponses() {
  return async (dispatch) => {
    dispatch(slice.actions.clearMissingResponsesSuccess());
  };
}

export function getRequestedSignatures(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `signature/signature_request_individual/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getRequestedSignaturesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getHouseholdForms(queryParams) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `signature/signature_request_form/?${parseQueryParams(queryParams)}`
      );
      const validSignaturesData = (response.data || []).filter(
        (requestedSignature) =>
          !requestedSignature.certification_document?.is_deleted
      );
      dispatch(slice.actions.getHouseholdFormsSuccess(validSignaturesData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getActualForm(form_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        expand: [
          'certification_document.pages',
          'needed_signatures.household_member',
          'needed_signatures.certification_page',
          'needed_signatures.signature_mapping',
          'needed_signatures.signature_mapping.sit',
        ],
        certification_document__is_deleted: false,
      };

      const response = await axios.get(
        `signature/signature_request_form/${form_id}/?${parseQueryParams(
          params
        )}`
      );
      dispatch(slice.actions.getActualFormSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRejectedAnswers(surveysId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `questionnaire/rejected_answers/?surveys_id=${surveysId.join(',')}`
      );
      dispatch(slice.actions.getRejectedAnswersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearRejectedAnswers() {
  return async (dispatch) => {
    dispatch(slice.actions.clearRejectedAnswersSuccess());
  };
}

export function storeVisibleGroupsId(questionGroupsVisibility) {
  return async (dispatch) => {
    dispatch(
      slice.actions.storeVisibleGroupsIdSuccess(questionGroupsVisibility)
    );
  };
}

export function updateSelectedRecordStatus(recordData) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.updateSelectedRecordStatusSuccess(recordData));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
