import { Avatar, Box, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import fax16Filled from '@iconify/icons-fluent/fax-16-filled';
import arrowRight12Filled from '@iconify/icons-fluent/arrow-right-12-filled';
import arrowLeft12Filled from '@iconify/icons-fluent/arrow-left-12-filled';
import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

export default function FaxOutInIcon({ isIncoming, status }) {
  const theme = useTheme();

  const statusColor = useMemo(() => {
    if (isIncoming) {
      return theme.palette.primary.main;
    } else {
      if (status === 'success') {
        return theme.palette.info.main;
      }
      if (status === 'pending' || !status) {
        return theme.palette.grey[600];
      }
      if (status === 'failed') {
        return theme.palette.error.main;
      }
      return theme.palette.info.main;
    }
  }, [status, isIncoming]); // eslint-disable-line

  return (
    <Box>
      <Tooltip
        title={isIncoming ? 'Inbound Fax' : 'Outbound Fax'}
        arrow
        placement="top"
      >
        <Avatar sx={{ bgcolor: statusColor }}>
          <Icon icon={fax16Filled} style={{ color: '#FFF' }} />
          <Avatar
            sx={{
              bgcolor: statusColor,
              width: '10px',
              height: '10px',
              position: 'absolute',
              right: '20%',
              top: '50%',
            }}
          >
            {isIncoming ? (
              <Icon icon={arrowLeft12Filled} style={{ color: '#FFF' }} />
            ) : (
              <Icon icon={arrowRight12Filled} style={{ color: '#FFF' }} />
            )}
          </Avatar>
        </Avatar>
      </Tooltip>
    </Box>
  );
}
