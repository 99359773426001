import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
// icons
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import CloseIcon from '@mui/icons-material/Close';
// redux
import {
  clearHouseholdMembersList,
  getHouseholdMembersList,
} from 'src/redux/slices/Compliance/Property';
// utils
import axiosInstance from 'src/utils/axios';
// components
import StatusNotesForm from './StatusNotesForm';
import { SelectedMemberDetails } from './SelectedMemberDetails';
import { StatusNotesRecentList } from './StatusNotesRecentList';
import SearchNotFound from '../../../SearchNotFound';
import Scrollbar from '../../../Scrollbar';
import HouseholdLanguage from '../../../Household/HouseholdLanguage';
import useAuth from '../../../../hooks/useAuth';

const HouseholdMemberBoxStyle = styled(Box)(({ theme }) => ({
  borderLeft: `3px solid ${theme.palette.background.default}`,
  '&:hover': {
    backgroundColor: `${theme.palette.action.hover} !important`,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
}));

const HouseholdMemberLabel = ({ member, handleClick }) => {
  const theme = useTheme();
  return (
    <HouseholdMemberBoxStyle
      style={{ cursor: 'pointer' }}
      px={2}
      py={1}
      onClick={() => handleClick(member)}
    >
      <Typography
        variant="body2"
        style={{ lineHeight: '10px', textTransform: 'capitalize' }}
        mt={1}
        color={theme.palette.text.primary}
      >
        <Stack
          direction="row"
          gap={0.5}
          whiteSpace="nowrap"
          alignItems="center"
        >
          <>{`${member.first_name} ${member.last_name}` || member.email}</>
          <HouseholdLanguage
            preferredLanguage={member?.preferred_language?.name}
          />
          <Box component="span" color="text.secondary">
            {' '}
            {`(${member.member_type.display_name || member.member_type.name})`}
          </Box>
        </Stack>
      </Typography>

      <Typography variant="caption" color="text.disabled">
        {`${member.household?.name || '-'}`}
        {member.active_certification?.property?.name
          ? ` at ${member.active_certification?.property.name} `
          : ''}
        {member.household?.unit?.name
          ? 'Unit: ' + member.household?.unit?.name || '-'
          : ''}
      </Typography>
    </HouseholdMemberBoxStyle>
  );
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  maxHeight: 56,
}));

export const StatusNotesBubbleForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const { householdMembersList, isLoadingBubble } = useSelector(
    (state) => state.property
  );
  const { user } = useAuth();
  const propertyIDs = user.properties?.map((property) => property.id);

  const [search, setSearch] = useState(null);
  const [currentSearch, setCurrentSearch] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [currentCertification, setCurrentCertification] = useState(null);
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [editNote, setEditNote] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectingProperty, setSelectingProperty] = useState({
    id: 0,
    name: 'All Properties',
  });

  const _getProperties = async () => {
    let _properties = [];
    const response = await axiosInstance.get(
      `property_portfolio/property/?id__in=${propertyIDs?.join(
        ','
      )}&fields=id,name`
    );
    if (response.status === 200) {
      _properties = response.data;
      _properties.push({ id: 0, name: 'All Properties' });
      setProperties(_properties);
    }
  };

  const _getHouseholdMembers = () => {
    let logNumberFilter = null;
    if (!isNaN(search)) {
      logNumberFilter = search;
    }
    dispatch(
      getHouseholdMembersList({
        expand: [
          'member_type',
          'household.unit.building',
          'household.unit.building.applicant_pool',
          'household.unit.building.property',
          'household.certifications.property',
        ],
        fields: [
          // HouseholdMember fields
          'id',
          'active_certification',
          'content_type_id',
          'email',
          'first_name',
          'last_name',
          'slug',
          'middle_name',
          'member_type.id',
          'member_type.name',
          'preferred_language',
          // Household fields
          'household.id',
          'household.name',
          'household.slug',
          // Unit fields
          'household.unit.id',
          'household.unit.active_certification',
          'household.unit.name',
          // Building fields
          'household.unit.building.id',
          'household.unit.building.name',
          // Applicant pool fields
          'household.unit.building.applicant_pool.id',
          'household.unit.building.applicant_pool.name',
          // Property fields
          'household.unit.building.property.id',
          'household.unit.building.property.name',
          'household.unit.building.property.slug',
          // Certifications fields
          'household.certifications.id',
          'household.certifications.log_number',
          'household.certifications.display_name',
          'household.certifications.is_active',
          'household.certifications.is_done',
          'household.certifications.property',
          'household.certifications.created',
          //Certifications Property fields,
          'household.certifications.property.id',
          'household.certifications.property.slug',
          'household.certifications.property.name',
        ],
        page_size: 10,
        ...(selectingProperty?.name !== 'All Properties'
          ? {
              household__certifications__property__in: [selectingProperty?.id],
            }
          : { household__certifications__property__in: propertyIDs }),
        ...(logNumberFilter
          ? { household__certifications__log_number: logNumberFilter }
          : {}),
        ...(search ? { search: search } : {}),
      })
    );
  };

  useEffect(() => {
    if (properties.length === 0) {
      _getProperties();
    }
  });

  useEffect(() => {
    if (search !== null) {
      if (search !== '') {
        const timeOutId = setTimeout(() => {
          setCurrentSearch(search);
          _getHouseholdMembers();
        }, 500);
        return () => clearTimeout(timeOutId);
      } else {
        setCurrentSearch(null);
        dispatch(clearHouseholdMembersList());
      }
    }
  }, [search, selectingProperty]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccess = () => {
    setRefreshNotes(true);
    handleCancelEditNote();
  };

  const handleEditNote = (statusNote) => {
    setEditNote(statusNote);
  };

  const handleCancelEditNote = () => {
    setEditNote(null);
  };

  const handleClick = (member) => {
    setSelectedMember(member);
    setSearch('');
  };

  const handleUpdateSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (currentCertification) {
      setRefreshNotes(true);
    }
  }, [currentCertification]);

  useEffect(() => {
    if (selectedMember) {
      const inactiveCertifications = selectedMember?.household?.certifications
        .filter((cert) => !cert.is_active && cert.is_done)
        .sort(
          (a, b) =>
            new Date(b.created).getTime() - new Date(a.created).getTime()
        );

      setCurrentCertification(
        selectedMember.active_certification || inactiveCertifications?.[0]
      );
    }
  }, [selectedMember]);

  return (
    <>
      <Box>
        <Grid container p={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SearchStyle
                autoFocus
                value={search}
                onChange={handleUpdateSearch}
                placeholder="Household Member Name or Email"
                startAdornment={
                  <InputAdornment position="start">
                    <Box
                      component={Icon}
                      icon={searchFill}
                      sx={{ color: 'text.disabled' }}
                    />
                  </InputAdornment>
                }
              />
              {properties.length > 0 && (
                <Autocomplete
                  limitTags={1}
                  disableClearable
                  options={properties || []}
                  getOptionLabel={(option) => option.name}
                  defaultValue={{ id: 0, name: 'All Properties' }}
                  sx={{ minWidth: '40%', maxWidth: '50%', ml: 2 }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, newValue) => {
                    setSelectingProperty(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Property" />
                  )}
                />
              )}
              <Box
                minWidth={selectedMember ? '155px' : 'none'}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                {selectedMember && (
                  <Button
                    color="info"
                    variant="text"
                    onClick={() => setSelectedMember(null)}
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    Clear Search
                  </Button>
                )}
                <IconButton onClick={onClose} size="large">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            mt={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <Typography variant="caption" color="text.secondary">
              You are searching for household members within the properties you
              have access to.
            </Typography>
          </Grid>
          {selectedMember && (
            <SelectedMemberDetails
              selectedMember={selectedMember}
              currentCertification={currentCertification}
            />
          )}
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>
          {selectedMember && !currentSearch ? (
            <Grid item xs={12} sx={{ height: 'calc(100vh - 440px)' }}>
              <Scrollbar style={{ paddingRight: '16px', paddingTop: '6px' }}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  Status Notes
                </Typography>
                {currentCertification ? (
                  <>
                    {currentCertification.is_active &&
                    !currentCertification.is_done ? (
                      <>
                        <StatusNotesForm
                          certification={currentCertification}
                          onAddSuccess={handleSuccess}
                          fromBubble={true}
                        />
                        <Divider sx={{ my: 2 }} />
                        <StatusNotesRecentList
                          certificationId={currentCertification.id}
                          refreshNotes={refreshNotes}
                          setRefreshNotes={setRefreshNotes}
                          onEditNote={handleEditNote}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}
                      >
                        <Typography
                          variant="body1"
                          align="center"
                          my={2}
                          color="text.disabled"
                        >
                          You cannot add a Status Note because current
                          certification is done or inactive.
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      align="center"
                      my={2}
                      color="text.disabled"
                    >
                      You cannot add a Status Note because no certification was
                      found.
                    </Typography>
                  </Box>
                )}
              </Scrollbar>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: selectedMember
                  ? 'calc(100vh - 440px)'
                  : 'calc(100vh - 310px)',
              }}
            >
              <Scrollbar style={{ paddingRight: '16px' }}>
                {isLoadingBubble ? (
                  <Box mt={2} sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </Box>
                ) : currentSearch ? (
                  householdMembersList?.results &&
                  householdMembersList?.count > 0 ? (
                    <Box>
                      {householdMembersList.results.map((member) => {
                        return (
                          <HouseholdMemberLabel
                            key={member.id}
                            member={member}
                            handleClick={handleClick}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <Box sx={{ py: 2, width: '100%' }}>
                      <SearchNotFound searchQuery={currentSearch} />
                    </Box>
                  )
                ) : (
                  <Typography
                    variant="body1"
                    mt={2}
                    color="text.secondary"
                    textAlign="center"
                  >
                    Type in the search box to see household members.
                  </Typography>
                )}
              </Scrollbar>
            </Grid>
          )}
        </Grid>
      </Box>

      <Dialog
        open={editNote ? true : false}
        onClose={handleCancelEditNote}
        maxWidth="sm"
      >
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          {editNote && (
            <StatusNotesForm
              isEdit={true}
              statusNote={editNote}
              certification={currentCertification}
              onAddSuccess={handleSuccess}
              onCancel={handleCancelEditNote}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
