import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import parseQueryParams from 'src/utils/query';
import { shallowEqual, useSelector } from 'react-redux';

const extractTree = (records = []) => {
  if (!records?.length) {
    return [];
  }

  return records.reduce((acc, record) => {
    const result = [...acc];
    let sectionIdx = result?.findIndex(
      (sect) => sect?.id === record.qit.section?.id
    );

    if (sectionIdx === -1) {
      result.push({ ...record.qit.section, pages: [] });
      sectionIdx = result.findIndex(
        (sect) => sect?.id === record.qit.section?.id
      );
    }

    let pageIdx = result[sectionIdx]?.pages?.findIndex(
      (page) => page?.id === record?.qit?.page?.id
    );
    if (pageIdx === -1 && result[sectionIdx]?.pages) {
      result[sectionIdx].pages.push({ ...record?.qit?.page, questions: [] });
      pageIdx = result[sectionIdx].pages.findIndex(
        (page) => page?.id === record?.qit?.page?.id
      );
    }

    result[sectionIdx].pages[pageIdx].questions = [
      ...(result[sectionIdx].pages[pageIdx]?.questions || []),
      {
        ...record.qit,
        recordId: record.id,
      },
    ];
    return result;
  }, []);
};

const filterTree = (sections, filter = '') => {
  const result = [];

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    let validSection = null;

    for (let pageIndex = 0; pageIndex < section.pages.length; pageIndex++) {
      const page = section.pages[pageIndex];
      const questions = page.questions.filter((q) =>
        q?.question?.name?.toLowerCase().includes(filter.toLowerCase())
      );

      if (questions.length && !validSection) {
        validSection = { ...section, pages: [] };
      }
      if (questions?.length && Boolean(validSection)) {
        validSection.pages.push({ ...page, questions });
      }
    }

    if (Boolean(validSection)) {
      result.push(validSection);
    }
  }

  return result;
};

const useStyles = makeStyles((theme) => ({
  list: {
    listStyle: 'none',
  },
  childList: {
    paddingLeft: theme.spacing(2),
    borderLeft: '1px dashed rgba(33, 43, 54, 0.4)',
    ml: 1,
    pl: 1,
  },
  loader: {
    display: 'flex',
  },
  loaderContainer: {
    margin: 'auto',
  },
  header: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  bigHeader: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: 20,
  },
  subHeader: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    cursor: 'pointer',
  },
  hover: {
    lineHeight: '20px',
    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
}));

const SELECTION_TYPES = {
  questionGroup: 'questionGroup',
  answerGroup: 'answerGroup',
};

const RecordQuestionnaireTree = ({
  hhmId,
  disableSelection,
  onSelect,
  ignoredAnswerGroups = [],
  ignoredQuestions = [],
}) => {
  const css = useStyles();
  const [state, setState] = useState({
    list: [],
    tree: [],
    loading: false,
    answers: [],
    answerGroups: [],
  });
  const [selection, setSelection] = useState({
    type: null,
    id: null,
    recordId: null,
    questionName: '',
  });

  const reduxStoreCertification = useSelector(
    (st) => st?.certification?.certification,
    shallowEqual
  );

  const [search, setSearch] = useState({ loading: false, value: '' });
  const [filteredTree, setFilteredTree] = useState([]);

  const handleSelection = ({
    type,
    qit,
    id,
    recordId,
    isRecord,
    questionName = '',
    answerGroupID,
    answerID,
  }) => {
    if (!disableSelection) {
      if (
        (Boolean(selection.type) &&
          Boolean(selection.id) &&
          selection.type === type &&
          selection.id === id &&
          !selection.selectedRecord) ||
        selection.selectedRecord === recordId
      ) {
        setSelection({
          type: null,
          id: null,
          recordId: null,
          selectedRecord: null,
          questionName,
          qit,
          answerGroupID,
          answerID,
        });
      } else {
        setSelection({
          type,
          id,
          recordId,
          questionName,
          ...(isRecord && type === 'answerGroup'
            ? { selectedRecord: recordId }
            : {}),
          qit,
          answerGroupID,
          answerID,
        });
      }
    }
  };

  useEffect(() => {
    onSelect(selection);
    return () => {
      onSelect({ type: null, id: null, recordId: null, questionName: '' });
    };
  }, [onSelect, selection]);

  useEffect(() => {
    const fetchRecords = async () => {
      setState((st) => ({ ...st, loading: true }));

      try {
        const recordsParams = {
          question_type: 'file',
          survey__household_member: hhmId,
          survey__certification: reduxStoreCertification.id,
          survey__certification__is_active: true,
          survey__certification__is_done: false,
          expand: [
            'qit.question',
            'qit.question_group',
            'qit.section',
            'qit.page',
            'answers.answer_group',
            'answers.survey_record',
          ],
          fields: [
            'id',
            'answers',
            'order',
            'qit',
            'qit.id',
            'qit.question_group',
            'qit.question',
            'qit.order',
            'qit.section',
            'qit.page',
          ],
          ordering: 'qit__order',
        };

        const answerGroupsParams = {
          survey__household_member: hhmId,
          survey__certification__is_active: true,
          survey__certification__is_done: false,
          survey__certification: reduxStoreCertification.id,
        };

        const records = await axiosInstance.get(
          `questionnaire/survey_record/?${parseQueryParams(recordsParams)}`
        );
        const answerGroups = await axiosInstance.get(
          `questionnaire/answer_group/?${parseQueryParams(answerGroupsParams)}`
        );
        const filterRecords = records.data;
        const answers = filterRecords.reduce(
          (acc, curr) => acc.concat(curr.answers),
          []
        );

        setState((st) => ({
          ...st,
          loading: false,
          list: filterRecords,
          tree: extractTree(filterRecords),
          answerGroups: answerGroups.data,
          answers,
        }));
      } catch (error) {
        console.error(error);
        setState((st) => ({ ...st, loading: false }));
      }
    };

    setSelection({ type: null, id: null, recordId: null });
    if (hhmId) {
      fetchRecords();
    } else {
      setState((st) => ({
        ...st,
        list: [],
        tree: [],
        loading: false,
        answers: [],
        answerGroups: [],
      }));
    }
  }, [hhmId]);

  useEffect(() => {
    let timeOutId = null;

    if (search.value.trim()) {
      setSearch((st) => ({ ...st, loading: true }));

      timeOutId = setTimeout(() => {
        setFilteredTree(filterTree(state.tree, search.value));
        setSearch((st) => ({ ...st, loading: false }));
      }, 500);
    } else {
      setFilteredTree([]);
    }

    return () => clearTimeout(timeOutId);
  }, [search.value, state.tree]);

  const shouldRenderQuestion = (answerGroup, qitID) => {
    const shouldIgnoreAnswerGroup = ignoredAnswerGroups.find(
      (iag) => iag === answerGroup
    );

    const shouldIgnoreQuestion = ignoredQuestions.find((iq) => iq === qitID);

    if (shouldIgnoreAnswerGroup && shouldIgnoreQuestion) {
      return false;
    } else {
      return true;
    }
  };

  const renderQuestionAnswerGroups = (pageId, qits, prefix = '') => {
    return qits
      .filter((qit) => qit.page.id === pageId)
      .map((qit, i) => {
        let qitAnswerGroups = state.answerGroups
          .filter((ag) => ag.question_group === qit.question_group.id)
          .map((ag, i) => ({ ...ag, position: i + 1 }));
        return (
          <Box
            key={qit.id}
            component="ul"
            className={`${css.list} ${css.childList}`}
          >
            {qitAnswerGroups.length === 0 ||
            (qitAnswerGroups.length === 1 &&
              qit.question_group.type === 'single') ? (
              <Box component="li" className={css.hover}>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      sx={{ p: 0 }}
                      disabled={disableSelection}
                      checked={
                        (selection.id === qit.question_group.id &&
                          selection.recordId === qit.recordId &&
                          selection.type === SELECTION_TYPES.questionGroup) ||
                        selection.selectedRecord === qit.recordId
                      }
                      onChange={() => {
                        if (
                          qit.question_group.type === 'single' &&
                          qitAnswerGroups.length > 0
                        ) {
                          const ag = qitAnswerGroups[0];
                          handleSelection({
                            type: SELECTION_TYPES.answerGroup,
                            qit: qit,
                            id: ag.id,
                            recordId: qit.recordId,
                            isRecord: true,
                            questionName: qit?.question?.name,
                            answerGroupID: ag.id,
                            answerID: state.answers.find(
                              (answer) =>
                                answer?.answer_group?.id === ag?.id &&
                                answer?.survey_record?.id === qit.recordId
                            )?.id,
                          });
                        } else {
                          handleSelection({
                            type: SELECTION_TYPES.questionGroup,
                            qit: qit,
                            id: qit.question_group.id,
                            recordId: qit.recordId,
                            isRecord: false,
                            questionName: qit?.question?.name,
                            answerGroupID: null,
                            answerID: null,
                          });
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      className={css.subHeader}
                      sx={{ lineHeight: '20px', pl: 0.5 }}
                    >
                      {`${prefix}.${i + 1}. ${qit.question.name}`}
                    </Typography>
                  }
                  sx={{ m: 0, p: 0, alignItems: 'flex-start' }}
                />
              </Box>
            ) : (
              <Box component="li">
                <Typography
                  variant="body2"
                  className={css.header}
                  sx={{ lineHeight: '20px' }}
                >
                  {`${prefix}.${i + 1}. ${qit.question.name}`}
                </Typography>
              </Box>
            )}

            {(qitAnswerGroups.length > 1 ||
              qit.question_group.type === 'multi') &&
              qitAnswerGroups.map((ag, i) =>
                shouldRenderQuestion(ag?.id, qit?.question?.id) ? (
                  <Box
                    key={ag.id}
                    component="ul"
                    className={`${css.list} ${css.childList}`}
                  >
                    <Box component="li" className={css.hover}>
                      <FormControlLabel
                        control={
                          <Radio
                            size="small"
                            sx={{ p: 0 }}
                            disabled={disableSelection}
                            checked={
                              selection.id === ag.id &&
                              selection.recordId === qit.recordId &&
                              selection.type === SELECTION_TYPES.answerGroup
                            }
                            onChange={() =>
                              handleSelection({
                                type: SELECTION_TYPES.answerGroup,
                                qit: qit,
                                id: ag.id,
                                recordId: qit.recordId,
                                isRecord: false,
                                questionName: qit?.question?.name,
                                answerGroupID: ag.id,
                                answerID: state?.answers?.find(
                                  (answer) =>
                                    answer?.answer_group?.id === ag?.id &&
                                    answer?.survey_record?.id === qit.recordId
                                )?.id,
                              })
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="body2"
                            className={css.subHeader}
                            sx={{ lineHeight: '20px', pl: 0.5 }}
                          >
                            {ag?.name || qit.question_group?.type === 'multi'
                              ? `${qit.question_group?.name} ${
                                  ag?.position || i + 1
                                }`
                              : `Answer group ${i + 1}`}
                          </Typography>
                        }
                        sx={{ m: 0, p: 0, alignItems: 'flex-start' }}
                      />
                    </Box>

                    <Box
                      component="ul"
                      className={`${css.list} ${css.childList}`}
                    >
                      {state.answers
                        .filter(
                          (answer) =>
                            answer.answer_group.id === ag.id &&
                            answer.survey_record === qit.recordId
                        )
                        .map((answer, i) => (
                          <Box key={answer.id} component="li">
                            <Typography
                              variant="body2"
                              className={css.header}
                              sx={{ lineHeight: '20px' }}
                            >
                              {answer?.name || `Answer ${i + 1}`}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ) : (
                  <></>
                )
              )}
          </Box>
        );
      });
  };

  if (state?.loading) {
    return (
      <Box className={css.loader}>
        <CircularProgress sx={{ m: 'auto' }} color="primary" size={20} />
      </Box>
    );
  }

  if (!state?.tree?.length) {
    return null;
  }

  return (
    <Box sx={{ height: '100%' }}>
      <TextField
        placeholder="Search"
        fullWidth
        value={search.value}
        sx={{ mb: 3 }}
        size="small"
        onChange={(e) => {
          setSearch((st) => ({ ...st, value: e?.target?.value || '' }));
        }}
      />

      {search.value.trim() && !search.loading && !filteredTree?.length && (
        <Typography align="center">No results found</Typography>
      )}

      {(search.value.trim() && !search.loading ? filteredTree : state.tree).map(
        (section, sectionIndex) => (
          <Box key={section?.id} component="ul" className={css.list}>
            <Box component="li">
              <Typography variant="body2" className={css.bigHeader}>
                {sectionIndex + 1}. {section?.name}
              </Typography>
            </Box>

            {section?.pages?.map((page, pageIndex) => (
              <Box
                key={page?.id}
                component="ul"
                className={`${css.list} ${css.childList}`}
              >
                <Box component="li">
                  <Typography variant="body2" className={css.header}>
                    {sectionIndex + 1}.{pageIndex + 1}. {page?.name}
                  </Typography>
                </Box>
                {renderQuestionAnswerGroups(
                  page.id,
                  page.questions,
                  `${sectionIndex + 1}.${pageIndex + 1}`
                )}
              </Box>
            ))}
          </Box>
        )
      )}
    </Box>
  );
};

export default RecordQuestionnaireTree;
