import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Menu, Typography } from '@mui/material';
import MessageTemplateItem from './TemplateItem';
import { LoadingButton } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import TemplateModal from '../templates/TemplateModal';
import { getCommunicationTemplates } from 'src/utils/CommunicationTemplateUtils';
import TemplatesEngineService from 'src/services/TemplatesEngine.service';

const MessageTemplates = ({
  onselectTemplate,
  conversationId,
  messageType,
  conversation,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const [templateList, setTemplateList] = useState({
    initialOutreach: [],
    followUp: [],
    complianceSpecialist: [],
    certificationNotice: [],
  });

  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [loading, setLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const renderTemplate = useCallback(
    (template) => {
      setGeneratingTemplate(true);
      const body = {
        template_id: template.id,
        template_type: template.type.name,
        user_id: user.id,
        conversation_id: conversationId,
        message_type: messageType,
        property_id:
          conversation?.certification?.property?.id ||
          conversation?.property?.id,
      };

      TemplatesEngineService.renderTemplate(body)
        .then((response) => {
          onselectTemplate(response?.data?.text, template.id);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Something went wrong.', { variant: 'error' });
        })
        .finally(() => {
          setGeneratingTemplate(false);
        });
    },
    [
      conversationId,
      enqueueSnackbar,
      messageType,
      onselectTemplate,
      conversation?.certification?.property?.id,
      user.id,
    ]
  );

  const handleSelect = useCallback(
    (template) => {
      renderTemplate(template);
      handleClose();
    },
    [handleClose, renderTemplate]
  );

  const getTemplates = useCallback(() => {
    setLoading(true);
    const queryParams = {
      expand: ['type'],
    };

    if (conversation?.certification?.property?.id) {
      queryParams.for_property = conversation?.certification?.property?.id;
    } else {
      queryParams.property__isnull = true;
    }

    getCommunicationTemplates(queryParams, setTemplateList)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [conversation?.certification?.property?.id]);

  useEffect(() => {
    getTemplates();
  }, [getTemplates]);

  return (
    <>
      <LoadingButton
        loading={generatingTemplate}
        size="small"
        id="templates-menu"
        sx={{ mr: 2 }}
        onClick={handleClick}
      >
        Templates
      </LoadingButton>

      <Menu
        disablePortal
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box sx={{ minWidth: { sm: 500 }, py: 1, px: 2 }}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}
          >
            <Typography noWrap variant="subtitle3" sx={{ mr: 2 }}>
              Templates
            </Typography>

            <TemplateModal
              propertyId={conversation?.certification?.property?.id || null}
              categories={[
                'Initial Outreach',
                'Follow Up',
                'Certification Notice',
              ]}
              title="Conversation Templates"
              onCloseFunc={getTemplates}
            />
          </Box>

          {loading && (
            <Typography variant="caption" noWrap color="text.secondary">
              Fetching templates...
            </Typography>
          )}
          {!loading && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box sx={{ pr: 4, width: '50%' }}>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ fontWeight: 'fontWeightBold' }}
                  mb={1}
                >
                  Initial Outreach
                </Typography>

                {!templateList?.initialOutreach?.length && (
                  <Typography variant="caption" noWrap color="text.secondary">
                    Templates not available.
                  </Typography>
                )}

                {templateList.initialOutreach.map((template) => (
                  <MessageTemplateItem
                    key={template?.id}
                    onClick={() => handleSelect(template)}
                    name={template?.name}
                  />
                ))}
              </Box>

              <Box sx={{ width: '50%' }}>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ fontWeight: 'fontWeightBold' }}
                  mb={1}
                >
                  Certification Notice
                </Typography>

                {!templateList?.certificationNotice?.length && (
                  <Typography variant="caption" noWrap color="text.secondary">
                    Templates not available.
                  </Typography>
                )}

                {(templateList?.certificationNotice || []).map((template) => (
                  <MessageTemplateItem
                    key={template?.id}
                    onClick={() => handleSelect(template)}
                    name={template?.name}
                  />
                ))}
              </Box>

              <Box sx={{ width: '50%', mt: 2 }}>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ fontWeight: 'fontWeightBold' }}
                  mb={1}
                >
                  Follow Up
                </Typography>

                {!templateList?.followUp?.length && (
                  <Typography variant="caption" noWrap color="text.secondary">
                    Templates not available.
                  </Typography>
                )}

                {templateList.followUp.map((template) => (
                  <MessageTemplateItem
                    key={template?.id}
                    onClick={() => handleSelect(template)}
                    name={template?.name}
                  />
                ))}
              </Box>

              <Box sx={{ width: '50%', mt: 2 }}>
                <Typography
                  variant="subtitle2"
                  noWrap
                  sx={{ fontWeight: 'fontWeightBold' }}
                  mb={1}
                >
                  Compliance Specialist
                </Typography>

                {!templateList?.complianceSpecialist?.length && (
                  <Typography variant="caption" noWrap color="text.secondary">
                    Templates not available.
                  </Typography>
                )}

                {templateList.complianceSpecialist.map((template) => (
                  <MessageTemplateItem
                    key={template?.id}
                    onClick={() => handleSelect(template)}
                    name={template?.name}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
};

MessageTemplates.propTypes = {
  onselectTemplate: PropTypes.func,
  messageType: PropTypes.number,
  conversationId: PropTypes.number,
};

MessageTemplates.defaultProps = {
  onselectTemplate: () => null,
  conversationId: null,
  messageType: null,
};

export default memo(MessageTemplates);
