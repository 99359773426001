import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@mui/material/styles';
import { Box, Link, Stack, Tooltip, Typography } from '@mui/material';
// utils
import { getFileName, getFileType } from '../../utils/getFileFormat';
//
import Scrollbar from '../Scrollbar';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `solid 1px ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.neutral,
}));

const ThumbStyle = styled('div')(({ theme }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${theme.palette.divider}`,
  color: theme.palette.text.disabled,
  '& img': { width: '100%', height: '100%' },
}));
// ----------------------------------------------------------------------

FileItem.propTypes = {
  file: PropTypes.object,
  showDelete: PropTypes.bool,
  removeFile: PropTypes.func,
  setSelectedAttachment: PropTypes.func,
};

FileItem.defaultProps = {
  file: null,
  showDelete: false,
  removeFile: () => null,
  setSelectedAttachment: () => null,
};

function FileItem({
  file,
  showDelete,
  removeFile,
  removeByID,
  showTooltipName,
  selectable,
  setSelectedAttachment,
  isDone,
}) {
  const fileName = useMemo(
    () => (
      <>
        <Typography noWrap variant="caption">
          {getFileName(file?.name)}
        </Typography>
        <Typography variant="caption">.{getFileType(file?.name)}</Typography>
      </>
    ),
    [file?.name]
  );

  const isSelectable = useMemo(
    () => selectable && !file?.survey_file && !file?.certification_document,
    [file?.certification_document, file?.survey_file, selectable]
  );
  const isIncludedOnFM = useMemo(
    () => selectable && (file?.survey_file || file?.certification_document),
    [file?.certification_document, file?.survey_file, selectable]
  );

  const thumbContent = useMemo(
    () => (
      <Box sx={{ height: 28, cursor: isSelectable ? 'pointer' : 'default' }}>
        <DescriptionIcon
          onClick={() => {
            if (!isDone) {
              if (!isSelectable) {
                return;
              }
              setSelectedAttachment({
                id: file?.id,
                messageId: file?.messageId,
              });
            }
          }}
          style={{ fontSize: 28 }}
        />

        {showDelete && !isDone && (
          <CloseIcon
            onClick={() => {
              removeFile(removeByID ? file?.id : file?.name);
            }}
            sx={{
              cursor: 'pointer',
              fontSize: 13,
              position: 'absolute',
              right: 3,
              top: 3,
            }}
          />
        )}
      </Box>
    ),
    [
      file?.id,
      file?.messageId,
      file?.name,
      isSelectable,
      removeFile,
      removeByID,
      setSelectedAttachment,
      showDelete,
      isDone,
    ]
  );

  return (
    <Box key={file?.name}>
      <ThumbStyle
        sx={
          isIncludedOnFM
            ? { backgroundColor: 'primary.main', color: 'white' }
            : {}
        }
      >
        {isSelectable || isIncludedOnFM ? (
          <Tooltip
            placement="top"
            title={
              isIncludedOnFM
                ? 'File included on File Manager.'
                : 'Select attachment to include in File Manager.'
            }
          >
            {thumbContent}
          </Tooltip>
        ) : showTooltipName ? (
          <Tooltip placement="top" title={fileName}>
            {thumbContent}
          </Tooltip>
        ) : (
          thumbContent
        )}
      </ThumbStyle>

      <Box
        {...(file?.url
          ? {
              href: file.url,
              rel: 'noreferrer',
              target: '_blank',
              download: true,
            }
          : {})}
        component={file?.url ? Link : 'div'}
        sx={{
          mt: 0.5,
          maxWidth: 56,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {fileName}
      </Box>
    </Box>
  );
}

// ----------------------------------------------------------------------

MailDetailsAttachments.propTypes = {
  files: PropTypes.arrayOf(PropTypes.any),
  showDelete: PropTypes.bool,
  removeFile: PropTypes.func,
  setSelectedAttachment: PropTypes.func,
};
MailDetailsAttachments.defaultProps = {
  files: [],
  showDelete: false,
  removeFile: () => null,
  setSelectedAttachment: () => null,
};

export default function MailDetailsAttachments({
  files,
  showDelete,
  removeFile,
  selectable,
  setSelectedAttachment,
  isDone,
  removeByID,
  showTooltipName,
  ...other
}) {
  return (
    <RootStyle {...other}>
      <Scrollbar>
        <Stack direction="row" spacing={1.5}>
          {files.map((file) => (
            <FileItem
              file={file}
              setSelectedAttachment={setSelectedAttachment}
              showDelete={showDelete}
              selectable={selectable}
              showTooltipName={showTooltipName}
              removeFile={removeFile}
              removeByID={removeByID}
              key={file?.name}
              isDone={isDone}
            />
          ))}
        </Stack>
      </Scrollbar>
    </RootStyle>
  );
}
