import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import parseQueryParams from '../../../utils/query';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current['conversation'][key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  isMessagesLoading: false,
  isSendingMessage: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  activeConversation: {},
  participants: [],
  recipients: [],
  template: '',
};

const slice = createSlice({
  name: 'Conversations',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startMessagesLoading(state) {
      state.isMessagesLoading = true;
    },

    startSendingMessage(state) {
      state.isSendingMessage = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isMessagesLoading = false;
    },

    // GET CONTACT SUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = objFromArray(contacts);
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    setTemplateSuccess(state, action) {
      state.template = action.payload;
    },

    removeTemplate(state) {
      state.template = '';
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      state.conversations = action.payload;
      state.isLoading = false;
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      state.isMessagesLoading = false;
      state.activeConversation = action.payload;
    },

    // ON SEND MESSAGE
    addMessageSuccess(state, action) {
      if (state.activeConversation?.id && !action.payload.isNewConversation) {
        state.activeConversation.messages.unshift(action.payload.message);
      }
      state.isSendingMessage = false;
    },

    markConversationAsReadSuccess(state, action) {
      const { conversationId } = action.payload;
      const conversation = state.conversations.byId[conversationId];
      if (conversation) {
        conversation.unreadCount = 0;
      }
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  removeTemplate,
  startMessagesLoading,
  setTemplateSuccess,
  getConversationsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getConversations(
  filterValue,
  filterBy,
  queryParams,
  loading = true
) {
  return async (dispatch) => {
    if (loading) {
      dispatch(slice.actions.startLoading());
    }
    try {
      const response = await axios.get(
        `communication/new_conversation/conversation/?${filterBy}=${filterValue}&${parseQueryParams(
          queryParams
        )}`
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(slice.actions.getConversationsSuccess(response.data));
      } else {
        throw new Error('Error getting communications');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendMessage(payload, isNewConversation) {
  return async (dispatch) => {
    dispatch(slice.actions.startSendingMessage());
    try {
      const response = await axios.post(
        `communication/new_conversation/message/`,
        payload
      );
      if (response.status === 200 || response.status === 201) {
        const messageRes = await axios.get(
          `communication/new_conversation/message/${response.data.id}/?expand=attachments,views,clicks,sender`
        );

        if (messageRes.data && messageRes.data.id) {
          dispatch(
            slice.actions.addMessageSuccess({
              message: messageRes.data,
              isNewConversation,
            })
          );
        }
      } else {
        throw new Error('Error sending message');
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(
  conversationKey,
  queryParams,
  startLoading = true
) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    if (startLoading) {
      dispatch(slice.actions.startMessagesLoading());
    }
    try {
      const response = await axios.get(
        `/communication/new_conversation/conversation/${conversationKey}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(slice.actions.getConversationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversationAndUpdateConversations(
  conversationKey,
  queryParams
) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/communication/new_conversation/conversation/${conversationKey}/?${parseQueryParams(
          queryParams
        )}`
      );
      dispatch(
        slice.actions.getConversationAndUpdateConversationsSuccess(
          response.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setConversation(conversation) {
  return async (dispatch) => {
    dispatch(slice.actions.getConversationSuccess(conversation));
  };
}

// ----------------------------------------------------------------------

export function markConversationAsRead(conversationId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversationId },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversationId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/chat/participants', {
        params: { conversationKey },
      });
      dispatch(
        slice.actions.getParticipantsSuccess(response.data.participants)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
