import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import { useRef, useState } from 'react';
import { StatusNotesBubbleForm } from './StatusNotesBubbleForm';
import MenuPopover from '../../../MenuPopover';

const useStyles = makeStyles((theme) => ({
  common: {
    backgroundColor: '#FFF',
    boxShadow: theme.shadows[10],
    transition: 'all 0.2s ease',
    zIndex: 1199,
    overflow: 'hidden',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closed: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  opened: {
    width: '660px',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    transition: 'all 0.2s ease',
  },
  background: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1198,
    transition: 'all 0.2s ease',
    backgroundColor: 'transparent',
  },
}));

export const StatusNotesBubble = ({ bigIcon }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [showContent, setShowContent] = useState(false);
  const iconButton = useRef(null);

  const handleOpen = () => {
    setOpen(true);
    setTimeout(() => {
      setShowContent(true);
    }, 100);
  };

  const handleClose = () => {
    setOpen(false);
    setShowContent(false);
  };

  return (
    <>
      <Box
        className={classes.common}
        style={{
          width: bigIcon ? 40 : 30,
          height: bigIcon ? 40 : 30,
        }}
      >
        <IconButton ref={iconButton} onClick={handleOpen} size="large">
          <SearchIcon fontSize="small" />
        </IconButton>
      </Box>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={iconButton?.current}
        sx={{ width: 660 }}
      >
        <StatusNotesBubbleForm onClose={handleClose} />
      </MenuPopover>
    </>
  );
};
